import React, { useState } from "react"
import { Link } from 'react-router-dom'
import Copyright from '../common/Copyright'
import Svg from '../common/Svg'
import PasswordChecklist from "react-password-checklist"


export default function ResetPassword() {
    const [password, setPassword] = useState("")
    const [passwordAgain, setPasswordAgain] = useState("")
    return (
        <>
            <div className='d-flex flex-column site-main-wrapper login-page'>

                <div className="flex-grow-1 main-content-wrap d-flex align-items-center justify-content-center">
                    <div className="container">
                        <div className="form-outer-wrapper">
                            <div className="logo-wrap text-center">
                                <Link to="/#0t" className='home-logo'>
                                    <Svg name="logo" />
                                </Link>
                            </div>
                            <div className="form-wrapper mb__20">
                                <div className="form-heading text-center">
                                    <h3>Reset password</h3>
                                    <p>Your new password must be different from previously used passwords.</p>
                                </div>
                                <div className="field-wrapper">
                                    <div className="form-floating mb__20 position-relative">
                                        <input type="password" className="form-control" placeholder="Enter new password" onChange={e => setPassword(e.target.value)} />
                                        <label htmlFor="floatingInput" className="mb-0">Enter new password</label>
                                        <span className="forgot-wrap password">
                                            <i className="fa fa-eye " aria-hidden="true" />
                                        </span>
                                    </div>
                                    <div className="form-floating mb__20 position-relative">
                                        <input type="password" className="form-control" placeholder="Confirm new password" onChange={e => setPasswordAgain(e.target.value)} />
                                        <label htmlFor="floatingInput" className="mb-0">Confirm new password</label>
                                        <span className="forgot-wrap password">
                                            <i className="fa fa-eye " aria-hidden="true" />
                                        </span>


                                    </div>
                                    {/* <div className="mb__20 position-relative">
                                        <input type="password" className="form-control" placeholder="Enter new password" onChange={e => setPassword(e.target.value)} />
                                        <span className="forgot-wrap password">
                                            <i className="fa fa-eye " aria-hidden="true" />
                                        </span>
                                    </div> */}
                                    {/* <div className="mb__20 position-relative">
                                        <input type="password" className="form-control" placeholder="Confirm new password" onChange={e => setPasswordAgain(e.target.value)} />
                                        <span className="forgot-wrap password">
                                            <i className="fa fa-eye " aria-hidden="true" />
                                        </span>
                                    </div> */}
                                    <div className="password-req">
                                        <h6>Requirements</h6>
                                        <PasswordChecklist
                                            rules={
                                                [
                                                    "minLength",
                                                    "capital",
                                                    "number",
                                                    "specialChar",
                                                    "match"
                                                ]
                                            }
                                            minLength={5}
                                            value={password}
                                            valueAgain={passwordAgain}
                                            onChange={(isValid) => { }}
                                            messages={{
                                                minLength: "Minimum of 6 characters",
                                                capital: "Contains an uppercase letter",
                                                number: "Contains a number (0-9)",
                                                match: "Contains special characters (!@#$%&*.)",
                                                match: "Password Matched",
                                            }}

                                        />
                                    </div>
                                </div>
                                <div className="button-wrap">
                                    <button type="button" className="btn-primary btn w-100" data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#changePasswordSuccess">Set new password</button>
                                </div>

                            </div>
                            <Copyright />
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
