import axios from "../utils/axios";
import { BASE_URL } from "./urls";

export const getModules = async () => {
  const token = localStorage.getItem("token");
  const response = await axios({
    headers: { Authorization: `Bearer ${token}` },
    method: "POST",
    url: `${BASE_URL}/roles/getAllModules`,
  });

  return response.data;
};
export const getRoles = async (payload = {}) => {
  const token = localStorage.getItem("token");
  const response = await axios({
    headers: { Authorization: `Bearer ${token}` },
    method: "POST",
    url: `${BASE_URL}/roles/getRoleList`,
    data: payload
  });

  return response.data;
};
export const addRoles = async (payload) => {
  const token = localStorage.getItem("token");
  const response = await axios({
    headers: { Authorization: `Bearer ${token}` },
    method: "POST",
    url: `${BASE_URL}/roles/addRole`,
    data: payload,
  });

  return response.data;
};
export const editRoles = async (payload) => {
  const token = localStorage.getItem("token");
  const response = await axios({
    headers: { Authorization: `Bearer ${token}` },
    method: "PATCH",
    url: `${BASE_URL}/roles/updRole`,
    data: payload,
  });

  return response.data;
};
export const deleteRoles = async (payload) => {
  const token = localStorage.getItem("token");
  const response = await axios({
    headers: { Authorization: `Bearer ${token}` },
    method: "DELETE",
    url: `${BASE_URL}/roles/delRole`,
    data: payload,
  });

  return response.data;
};
