import React from "react";
import { Link } from "react-router-dom";

export default function ScheduleHeader(props) {
  return (
    <div className="meeting-header">
      <ul>
        <li>
          <Link
            to="/events/schedule"
            state={props.eventId}
            className={props.schedule ? "active" : ""}
            content-attr="Schedule Metaverse Meeting"
            onClick={props.onChangeStep} 
          >
            Schedule Metaverse Meeting
          </Link>
        </li>
        <li>
          <Link
            to="/events/select-experience"
            state={props.eventId}
            className={props.selectExperience ? "active" : ""}
            content-attr="Select experience"
            onClick={props.onChangeStep}
          >
            Select experience
          </Link>
        </li>
        <li>
          <Link
            to="/events/invite-participants"
            state={props.eventId}
            className={props.inviteParticipants ? "active" : ""}
            content-attr="Invite participants"
            onClick={props.onChangeStep}
          >
            Invite participants
          </Link>
        </li>
        <li>
          <Link
            to="/events/share"
            state={props.eventId}
            className={props.share ? "active" : ""}
            content-attr="Share"
            onClick={props.onChangeStep}
          >
            Share
          </Link>
        </li>
      </ul>
    </div>
  );
}
