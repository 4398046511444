import axios from "../utils/axios";
import { BASE_URL } from "./urls";

export const getExperienceList = async (payload) => {
  const token = localStorage.getItem("token");
  const response = await axios({
    headers: { Authorization: `Bearer ${token}` },
    method: "POST",
    url: `${BASE_URL}/experience/getMyExperiences`,
    data: payload,
  });

  return response.data;
};

export const getPastExperienceList = async (payload) => {
  const token = localStorage.getItem("token");
  const response = await axios({
    headers: { Authorization: `Bearer ${token}` },
    method: "POST",
    url: `${BASE_URL}/experience/past`,
    data: payload,
  });

  return response.data;
};

export const getArchivedExperienceList = async (payload) => {
  const token = localStorage.getItem("token");
  const response = await axios({
    headers: { Authorization: `Bearer ${token}` },
    method: "POST",
    url: `${BASE_URL}/experience/getArchiveExperiences`,
    data: payload,
  });

  return response.data;
};

export const createExperience = async (payload) => {
  const token = localStorage.getItem("token");
  const response = await axios({
    headers: { Authorization: `Bearer ${token}` },
    method: "POST",
    url: `${BASE_URL}/experience/add`,
    data: payload,
  });

  return response.data;
};

export const updateExperience = async (payload) => {
  const token = localStorage.getItem("token");
  const response = await axios({
    headers: { Authorization: `Bearer ${token}` },
    method: "PATCH",
    url: `${BASE_URL}/experience/update`,
    data: payload,
  });

  return response.data;
};

export const getExperienceDetail = async (payload) => {
  const token = localStorage.getItem("token");
  const response = await axios({
    headers: { Authorization: `Bearer ${token}` },
    method: "POST",
    url: `${BASE_URL}/experience/getExperienceDetail`,
    data: payload,
  });

  return response.data;
};

export const getSelectedMediaSpot = async (payload) => {
  const token = localStorage.getItem("token");
  const response = await axios({
    headers: { Authorization: `Bearer ${token}` },
    method: "POST",
    url: `${BASE_URL}/experience/getExperienceHotspots`,
    data: payload,
  });

  return response.data;
};

export const getMediaSpotList = async (payload) => {
  const token = localStorage.getItem("token");
  const response = await axios({
    headers: { Authorization: `Bearer ${token}` },
    method: "POST",
    url: `${BASE_URL}/experience/getHotSpotList`,
    data: payload,
  });

  return response.data;
};

export const moveToArchived = async (payload) => {
  const token = localStorage.getItem("token");
  const response = await axios({
    headers: { Authorization: `Bearer ${token}` },
    method: "PATCH",
    url: `${BASE_URL}/experience/archive`,
    data: payload,
  });

  return response.data;
};

export const updateStructure = async (payload) => {
  const token = localStorage.getItem("token");
  const response = await axios({
    headers: { Authorization: `Bearer ${token}` },
    method: "PATCH",
    url: `${BASE_URL}/experience/updStructure`,
    data: payload,
  });

  return response.data;
};