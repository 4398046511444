export function range(start, end, step = 1) {
  const result = [];
  for (let i = start; i < end; i += step) {
    result.push(i);
  }
  return result;
}

export function getShortName(name) {
  const words = name.split(" ");
  const initials = words.map((word) => word[0]).join("");
  return initials;
}
