import React from 'react'
import DashboardHeader from '../common/DashboardHeader'
import DashboardSidebar from '../common/DashboardSidebar'
import Svg from '../common/Svg'

import template1 from "../../assets/images/template-1.jpg"
import template2 from "../../assets/images/template-2.jpg"
import template3 from "../../assets/images/template-3.jpg"
import template4 from "../../assets/images/template-4.jpg"
import { Link } from 'react-router-dom'
import TemplateDetailsModal from '../common/TemplateDetailsModal'
import TemplateList from '../common/TemplateList'

export default function Index() {
    return (
        <>

            <div className='d-flex flex-column main-outer-wrapper main-site-wrapper '>
                <DashboardHeader />
                <div id="viewport" className="d-flex flex-grow-1">
                    <DashboardSidebar />
                    <div className="flex-grow-1 page-content-flex">
                        <div className="page-content-wrap">
                            <div className="heading-section page-heading d-md-flex align-items-center">
                                <div className="flex-grow-1">
                                    <h1>Experiences</h1>
                                    <p className='pt-2'>Here you can create new experiences, prepare experience templates or repurpose past experiences</p>
                                </div>
                                <div className="button-wrap mt-3 mt-md-0">
                                    <Link to="/experiences/create-experience" className="btn btn-primary add-btn"> <Svg name="plus" /> Create new experience  </Link>
                                </div>
                            </div>


                            <TemplateList />


                        </div>
                    </div>
                </div>
            </div>
            {/* <TemplateDetailsModal /> */}
        </>

    )
}
