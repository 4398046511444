import AWS from "aws-sdk";

const S3_BUCKET = "demo-vmerisve";
const REGION = "ap-south-1";

export const s3Url = 'https://demo-vmerisve.s3.ap-south-1.amazonaws.com';
export const s3MediaUrl = `${s3Url}/media`;

AWS.config.update({
  accessKeyId: "AKIAX24VPFS53DJBAAOT",
  secretAccessKey: "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",
});

const awsBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
});

// function to convert base64 to file object
function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}

async function getThumbnailForVideo(videoFile, fileName) {
  let videoThumbnailName = `${fileName}.jpg`;

  // Set up application
  const videoUrl = URL.createObjectURL(videoFile);

  const video = document.createElement("video");
  const canvas = document.createElement("canvas");

  // Trigger video load
  let videoHeight = 0;
  let videoWidth = 0;
  await new Promise((resolve, reject) => {
    video.addEventListener("loadedmetadata", () => {
      videoHeight = video.videoHeight / 2;
      videoWidth = video.videoWidth / 2;
      video.width = videoWidth;
      video.height = videoHeight;
      canvas.width = videoWidth;
      canvas.height = videoHeight;

      // Seek the video to 25%
      video.currentTime = video.duration * 0.25;
    });
    video.addEventListener("seeked", () => resolve());
    video.src = videoUrl;
  });

  // Draw the thumbnailz
  canvas.getContext("2d").drawImage(video, 0, 0, videoWidth, videoHeight);
  const imageUrl = canvas.toDataURL("image/jpg");
  s3UploadVideoThumbnail(imageUrl, videoThumbnailName);

  return imageUrl;
}

async function s3UploadVideoThumbnail(videoThumbnailImg, videoThumbnailName) {
  var thumbnailFile = await dataURLtoFile(
    videoThumbnailImg,
    videoThumbnailName
  );

  var data = {
    ACL: "public-read",
    Key: `media/${videoThumbnailName}`,
    Body: thumbnailFile,
    ContentType: "image/jpeg",
    Bucket: S3_BUCKET,
  };
  awsBucket.putObject(data, function (err, data) {
    if (err) {
      console.log(err);
      console.log("Error uploading data: ", data);
    } else {
      console.log("successfully uploaded the image!");
    }
  });
}

export const S3SingleFileUpload = (
  fileObj,
  fileName,
  uploadProgressCallback
) => {
  const params = {
    ACL: "public-read",
    Body: fileObj,
    Bucket: S3_BUCKET,
    Key: `media/${fileName}`,
  };
  return new Promise((resolve, reject) => {
    awsBucket
      .putObject(params)
      .on("httpUploadProgress", (evt) => {
        if (uploadProgressCallback)
          uploadProgressCallback(Math.round((evt.loaded / evt.total) * 100));
      })
      .on("complete", function (response) {
        let mediaType = fileObj.type.split("/");
        if (mediaType.length > 0) {
          mediaType = mediaType[mediaType.length - 1];
          if (["mp4"].includes(mediaType)) {
            getThumbnailForVideo(fileObj, fileName);
          }
        }
        resolve(response);
      })
      .send((err, data) => {
        reject(err);
      });
  });
};
