import React, { useEffect, useState } from "react";
import DashboardHeader from "../common/DashboardHeader";
import DashboardSidebar from "../common/DashboardSidebar";
import ScheduleHeader from "./ScheduleHeader";
import { useLocation, useNavigate } from "react-router-dom";
import template1 from "../../assets/images/template-1.jpg";
import template2 from "../../assets/images/template-2.jpg";
import Svg from "../common/Svg";
import { toast, ToastContainer } from "react-toastify";
import {
  getExperienceList,
  getPastExperienceList,
} from "../../actions/experience";
import {
  getMeetingDetailEdit,
  updateMeetingExperience,
} from "../../actions/meeting";
import Pagination from "../common/Pagination";
import LeaveModal from "../common/LeaveModal";
import ExperirenceDetailModal from "../common/ExperienceDetailModal";
export default function ChooseTemplate() {
  const [experienceList, setExperienceList] = useState([]);
  const [pastExperienceList, setPastExperienceList] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [fetchingExperienceList, setFetchingExperienceList] = useState(false);
  const [serverParams, setServerParams] = useState({
    startingLimit: 0,
    resultsPerPage: 4,
    search: "",
    sort_by: "add_date_time",
    sort_order: "asc",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [currentTabId, setCurrentTabId] = useState(1);
  const [experienceIds, setExperienceIds] = useState([]);
  const [isUpdatingMeetingExperience, setIsUpdatingMeetingExperience] =
    useState(false);
  const [validationMessage, setValidationMessage] = useState("");
  const [selectedExperience, setSelectedExperience] = useState(null);

  const navigate = useNavigate();

  const { state } = useLocation();

  const tabs = [
    { id: 1, label: "Saved Experiences" },
    { id: 2, label: "Past Meeting Experiences" },
  ];

  const changeTab = (tabId) => {
    setCurrentTabId(tabId);
    setTotalPage(0);
    setCurrentPage(1);
    setServerParams(serverParams);
    if (tabId === 1) {
      setTotalPage(0);
      fetchExperienceList(serverParams);
    }
    if (tabId === 2) {
      fetchPastExperienceList(serverParams);
    }
  };

  const changePage = (page) => {
    setCurrentPage(page);
    setServerParams({
      ...serverParams,
      startingLimit: page === 1 ? 0 : (page - 1) * serverParams.resultsPerPage,
    });
    if (currentTabId === 1) {
      fetchExperienceList({
        ...serverParams,
        startingLimit:
          page === 1 ? 0 : (page - 1) * serverParams.resultsPerPage,
      });
    }
    if (currentTabId === 2) {
      fetchPastExperienceList({
        ...serverParams,
        startingLimit:
          page === 1 ? 0 : (page - 1) * serverParams.resultsPerPage,
      });
    }
  };

  const gotoPreviousPage = () => {
    let pageStarting = 0;
    setCurrentPage(currentPage - 1);
    if (currentPage === 1) {
      setServerParams({ ...serverParams, startingLimit: 0 });
    } else {
      pageStarting = serverParams.startingLimit - serverParams.resultsPerPage;
    }
    setServerParams({ ...serverParams, startingLimit: pageStarting });
    if (currentTabId === 1) {
      fetchExperienceList({ ...serverParams, startingLimit: pageStarting });
    }
    if (currentTabId === 2) {
      fetchPastExperienceList({ ...serverParams, startingLimit: pageStarting });
    }
  };

  const geToNextPage = () => {
    setCurrentPage(currentPage + 1);
    changePage(currentPage + 1);
  };

  const fetchExperienceList = async (serverParams) => {
    try {
      setFetchingExperienceList(true);
      const experienceListResponse = await getExperienceList(serverParams);
      if (experienceListResponse.statusCode === 200) {
        setExperienceList(experienceListResponse.data);
        let RoundedTotal = Math.ceil(
          experienceListResponse.total_count / serverParams.resultsPerPage
        );
        setTotalPage(RoundedTotal);
        setFetchingExperienceList(false);
      }
    } catch (error) {
      toast.error(error.response.data.msg);
    }
  };

  const fetchPastExperienceList = async (serverParams) => {
    try {
      setFetchingExperienceList(true);
      const pastExperienceListResponse = await getPastExperienceList(
        serverParams
      );
      if (pastExperienceListResponse.statusCode === 200) {
        setPastExperienceList(pastExperienceListResponse.data);
        let RoundedTotal = Math.ceil(
          pastExperienceListResponse.total_count / serverParams.resultsPerPage
        );
        setTotalPage(RoundedTotal);
      }
      setFetchingExperienceList(false);
    } catch (error) {
      toast.error(error.response.data.msg);
    }
  };

  const handleSearchExperiance = (serachValue) => {
    setServerParams({ ...serverParams, search: serachValue });
    if (currentTabId === 1) {
      fetchExperienceList({ ...serverParams, search: serachValue });
    }
    if (currentTabId === 2) {
      fetchPastExperienceList({ ...serverParams, search: serachValue });
    }
  };

  const setMeetingExperience = async (callfrom) => {
    setIsUpdatingMeetingExperience(true);
    if (experienceIds.length > 0) {
      setValidationMessage("");
      let dataToSend = {
        experience_id: experienceIds[0],
        id: state,
      };
      const res = await updateMeetingExperience(dataToSend);
      if (res.statusCode === 200) {
        if (callfrom !== "headerChange") {
          navigate("/events/invite-participants", { state: state });
        }
        setIsUpdatingMeetingExperience(false);
      } else {
        toast.error(res.msg);
      }
    } else {
      setValidationMessage("Select One Experience");
    }
  };

  const getMeetingExperieance = async () => {
    let dataToSend = {
      id: state,
    };
    try {
      const res = await getMeetingDetailEdit(dataToSend);
      if (res.statusCode === 200) {
        setExperienceIds([res.data[0].experience_id]);
      } else {
        toast.error(res.msg);
      }
    } catch (error) {}
  };

  const openDetailModal = (experience) => {
    setSelectedExperience(experience);
    let detailModal = document.getElementById("modalDetails");
    let modal = window.bootstrap.Modal.getOrCreateInstance(detailModal);
    if (modal && !modal._isShown) {
      modal.show();
    }
  };

  const closeDetailModal = (experienceId) => {
    setSelectedExperience(null);
    setExperienceIds([experienceId]);
    let detailModal = document.getElementById("modalDetails");
    let modal = window.bootstrap.Modal.getOrCreateInstance(detailModal);
    if (modal && modal._isShown) {
      modal.hide();
    }
  };

  const openLeavelModal = () => {
    let detailModal = document.getElementById("leaveScheduling");
    let modal = window.bootstrap.Modal.getOrCreateInstance(detailModal);
    if (modal && !modal._isShown) {
      modal.show();
    }
  };

  const closeLeaveModal = () => {
    let detailModal = document.getElementById("leaveScheduling");
    let modal = window.bootstrap.Modal.getOrCreateInstance(detailModal);
    if (modal && modal._isShown) {
      modal.hide();
    }
  };

  useEffect(() => {
    changeTab(currentTabId);
    if (state) {
      getMeetingExperieance();
    }
  }, [state]);

  return (
    <>
      <ToastContainer position="top-right" />
      <div className="d-flex flex-column main-outer-wrapper main-site-wrapper ">
        <DashboardHeader />
        <div id="viewport" className="d-flex flex-grow-1">
          <DashboardSidebar />
          <div className="flex-grow-1 page-content-flex">
            <ScheduleHeader
              selectExperience
              eventId={state}
              onChangeStep={() => setMeetingExperience("headerChange")}
            />
            <div className="page-content-wrap">
              <div className="heading-section page-heading d-md-flex align-items-center justify-content-between">
                <div className="left-right">
                  <h1>Experiences</h1>
                  <p>
                    Select an experience or{" "}
                    <button
                      onClick={() => {
                        openLeavelModal();
                      }}
                      className="text-primary cursor-pointer text-deco"
                    >
                      create a new experience
                    </button>
                  </p>
                  <p>{validationMessage ? validationMessage : ""}</p>
                </div>
                <div className="right-wrap mt-3 mt-md-0">
                  <button
                    type="button"
                    className="btn btn-primary add-btn"
                    disabled={isUpdatingMeetingExperience}
                    onClick={() => {
                      setMeetingExperience();
                    }}
                  >
                    {isUpdatingMeetingExperience ? (
                      <>
                        <span
                          className="white-spinner spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        <span className="visually-hidden">Loading...</span>
                      </>
                    ) : (
                      <>
                        Invite participants <Svg name="right-arrow" />
                      </>
                    )}
                  </button>
                </div>
              </div>

              <div className="exp-button-wrapper-outer">
                <div className="nav-tabs-wrapper vmersive-tabs align-items-center">
                  <ul className="nav nav-tabs" id="meetings" role="tablist">
                    {tabs.map((tab, tabIndex) => (
                      <li
                        className="nav-item"
                        role="presentation"
                        key={tabIndex}
                      >
                        <button
                          className={
                            currentTabId === tab.id
                              ? "nav-link active"
                              : "nav-link"
                          }
                          id={`tab-${tab.id}-tab`}
                          data-bs-toggle="tab"
                          data-bs-target={`#tab-${tab.id}`}
                          type="button"
                          role="tab"
                          aria-controls={`tab-${tab.id}`}
                          aria-selected="false"
                          onClick={() => {
                            changeTab(tab.id);
                          }}
                        >
                          {tab.label}
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="page-wrapper">
                  <div className="tab-content" id="meetingsContent">
                    {tabs.map((tabData, tabDataIndex) => (
                      <div
                        className={
                          currentTabId === tabData.id
                            ? "tab-pane fade show active"
                            : "tab-pane fade"
                        }
                        id={`tab-${currentTabId}`}
                        role="tabpanel"
                        aria-labelledby={`tab-${currentTabId}-tab`}
                        key={tabDataIndex}
                      >
                        <div className="templates-wrapper" key={tabDataIndex}>
                          <div className="tab-search search-filter d-flex align-items-center">
                            <div className="flex-grow-1 search-filter-padding">
                              <input
                                type="text"
                                className="form-control custom-search-input"
                                placeholder="Search experiences"
                                name="serach"
                                value={serverParams.search}
                                onChange={(e) => {
                                  handleSearchExperiance(e.target.value);
                                }}
                              />
                            </div>
                            <div className="drop-wrap d-flex align-items-center">
                              <label>Sort by </label>
                              <div className="dropdown table-dropdown ques-options">
                                <button
                                  className="btn dropdown-tgl"
                                  type="button"
                                  id="sort-by"
                                  data-bs-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  Recently added
                                </button>
                                <div
                                  className="dropdown-menu"
                                  aria-labelledby="sort-by"
                                >
                                  <a className="dropdown-item" href="/">
                                    Recently added
                                  </a>
                                  <a className="dropdown-item" href="/">
                                    Sort A-Z
                                  </a>
                                  <a className="dropdown-item" href="/">
                                    Sort Z-A
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                          {fetchingExperienceList ? (
                            <>
                              <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </>
                          ) : (
                            <div className="templates-row row">
                              {currentTabId === 1 &&
                                experienceList.map(
                                  (experience, experienceIndex) => (
                                    <div
                                      className="col-xxl-3 col-xl-4 col-md-6 mb-3"
                                      key={experienceIndex}
                                    >
                                      <div className="template-card">
                                        <div className="img-wrap">
                                          <img
                                            src={template1}
                                            className="img-fluid"
                                            alt=""
                                          />
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id="template1"
                                            name="chooseTemplate"
                                            checked={experienceIds.includes(
                                              experience.id
                                            )}
                                            onChange={(e) => {
                                              setExperienceIds([experience.id]);
                                            }}
                                          />
                                          <div className="d-flex align-items-center button-wrap">
                                            <button
                                              className="btn btn-primary"
                                              onClick={() => {
                                                openDetailModal(experience);
                                              }}
                                            >
                                              View details
                                            </button>
                                          </div>
                                        </div>
                                        <div className="text-wrapper">
                                          <h5 title={experience.name}>
                                            {experience.name}
                                          </h5>
                                        </div>
                                      </div>
                                    </div>
                                  )
                                )}
                              {currentTabId === 2 &&
                                pastExperienceList.map(
                                  (pastExperience, pastExperienceIndex) => (
                                    <div
                                      className="col-xxl-3 col-xl-4 col-md-6 mb-3"
                                      key={pastExperienceIndex}
                                    >
                                      <div className="template-card">
                                        <div className="img-wrap">
                                          <img
                                            src={template2}
                                            className="img-fluid"
                                            alt=""
                                          />
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id="template1"
                                            name="chooseTemplate"
                                            checked={experienceIds.includes(
                                              pastExperience.id
                                            )}
                                            onChange={(e) => {
                                              setExperienceIds([
                                                pastExperience.id,
                                              ]);
                                            }}
                                          />
                                          <div className="d-flex align-items-center button-wrap">
                                            <button
                                              className="btn btn-primary"
                                              onClick={() => {
                                                openDetailModal(pastExperience);
                                              }}
                                            >
                                              View details
                                            </button>
                                          </div>
                                        </div>
                                        <div className="text-wrapper">
                                          <h5>{pastExperience.name}</h5>
                                        </div>
                                      </div>
                                    </div>
                                  )
                                )}
                            </div>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <Pagination
                  currentPage={currentPage}
                  totalPage={totalPage}
                  gotoPreviousPage={gotoPreviousPage}
                  changePage={changePage}
                  geToNextPage={geToNextPage}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="customvar-modal modal fade modalDetails"
        id="modalDetails"
      >
        <div className="modal-dialog modal-dialog-centered modal-details-width">
          <div className="modal-content">
            <div className="modal-body">
              <ExperirenceDetailModal
                experience={selectedExperience}
                closeDetailModal={closeDetailModal}
              />
            </div>
          </div>
        </div>
      </div>

      <div
        className="customvar-modal modal custom-modal fade deleteModal"
        id="leaveScheduling"
      >
        <LeaveModal closeLeaveModal={closeLeaveModal} />
      </div>
    </>
  );
}
