import * as Yup from "yup";

export const createMeetingSchema = Yup.object().shape({
  name: Yup.string().required("Title is required"),
  description: Yup.string().required("description is required"),
  start_date: Yup.string().required("Start Date is required"),
  end_date: Yup.string().required("End Date is required"),
  start_time: Yup.string().required("Start time is required"),
  timezone: Yup.string().required("Timezone is required"),
  end_time: Yup.string().required("End time is required"),
});

export const addPeriticipantSchema = Yup.object().shape({
  firstName: Yup.string().matches(/^[A-Za-z]+$/, 'Only alphabetic characters are allowed').required("First Name is required"),
  lastName: Yup.string().matches(/^[A-Za-z]+$/, 'Only alphabetic characters are allowed').required("Last Name is required"),
  email: Yup.string().email().required("E-mail is required"),
  region: Yup.string().required("Region is required"),
});
