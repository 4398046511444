import React from 'react'
import DashboardHeader from '../common/DashboardHeader'
import DashboardSidebar from '../common/DashboardSidebar'

export default function Notifications() {
    return (
        <div className='d-flex flex-column main-outer-wrapper main-site-wrapper '>
            <DashboardHeader />
            <div id="viewport" className="d-flex flex-grow-1">
                <DashboardSidebar />
                <div className="flex-grow-1 page-content-flex">
                    
                    <div className="page-content-wrap">
                        <div className="page-heading">
                            <h1>Notifications setttings</h1>
                        </div>
                        <div className="profile-wrapper">
                            <div className="main-form-wrapper notification-setting">

                                <div className="field-wrapper d-flex justify-content-between align-items-center">
                                    <div className="small-heading">
                                        <h6 className='mb-0'>Default</h6>
                                    </div>
                                    <div className="switch-wrap">
                                        <div className="form-check form-switch">
                                            <input className="form-check-input" type="checkbox" id="flexSwitchCheckChecked" />
                                            
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}
