import React from "react";
import { Navigate, Outlet } from "react-router-dom";

function PublicLayout() {
  if (localStorage.getItem("token")) {
    return <Navigate to="/events/list" replace />;
  }
  return <Outlet />;
}

export default PublicLayout;
