import React, { useEffect, useState } from "react";
import DashboardHeader from "../common/DashboardHeader";
import DashboardSidebar from "../common/DashboardSidebar";
import Svg from "../common/Svg";
import { Link } from "react-router-dom";
import TemplateDetailsModal from "../common/TemplateDetailsModal";
import { getTemplates } from "../../actions/metaSpace";
import { toast, ToastContainer } from "react-toastify";
import template1 from "../../assets/images/template-1.jpg";
import ContentLoader from "react-content-loader";

const Loader = (props) => (
  <ContentLoader viewBox="0 0 100% 100%" height={600} width="100%">
    <rect x="0" y="20" rx="0" ry="0" width="160" height="23" />
    <rect x="0" y="60" rx="0" ry="0" width="380" height="240" />
    <rect x="0" y="320" rx="0" ry="0" width="380" height="23" />
    <rect x="410" y="60" rx="0" ry="0" width="380" height="240" />
    <rect x="410" y="320" rx="0" ry="0" width="380" height="23" />
    <rect x="820" y="60" rx="0" ry="0" width="380" height="240" />
    <rect x="820" y="320" rx="0" ry="0" width="380" height="23" />
    <rect x="1220" y="60" rx="0" ry="0" width="380" height="240" />
    <rect x="1220" y="320" rx="0" ry="0" width="380" height="23" />
  </ContentLoader>
);
export default function TemplatesList() {
  const [data, setData] = useState({});

  const [isFetchingData, setIsFetchingData] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  // const [serverParams, setServerParams] = useState({});
  const [selectedMetaspaceIds, setSelectedMetaspaceIds] = useState([]);
  const [metaspaceListServerParams, setMetaspacelistServerParams] = useState({
    startingLimit: 0,
    resultsPerPage: 100,
    search: "",
    sort_by: "template_name",
    sort_order: "asc",
  });
  const [searchMetaspace, setSearchMetaspace] = useState("");
  const [sortValue, setSortValue] = useState("Sort A-Z");
  const sortingOptions = [
    { id: 1, value: "asc", label: "Sort A-Z" },
    { id: 2, value: "desc", label: "Sort Z-A" },
  ];
  const getTemplatData = async (serverParams = metaspaceListServerParams) => {
    try {
      setIsFetchingData(true);
      const res = await getTemplates(serverParams);
      if (res.statusCode === 200) {
        setData(res.data);
      } else {
        toast.error(res.msg);
      }
    } catch (error) {
      toast.error(error.response.data.msg);
    } finally {
      setIsFetchingData(false);
    }
  };

  const handleSort = (sortOption) => {
    setSortValue(sortOption.value);
  };

  const openDetailModal = (templateId) => {
    setSelectedId(templateId);
    let detailModal = document.getElementById("modalDetails");
    let modal = window.bootstrap.Modal.getOrCreateInstance(detailModal);
    if (modal && !modal._isShown) {
      modal.show();
    }
  };

  const closeDetailModal = (data) => {
    console.log("data", data);
    setSelectedMetaspaceIds([...selectedMetaspaceIds, data]);
    // console.log("selectedMetaspaceIds", selectedMetaspaceIds);
    let detailModal = document.getElementById("modalDetails");
    let modal = window.bootstrap.Modal.getOrCreateInstance(detailModal);
    if (modal && modal._isShown) {
      modal.hide();
    }
  };

  const handleSearchMetasapce = (serachValue) => {
    setSearchMetaspace(serachValue);
    setMetaspacelistServerParams({
      ...metaspaceListServerParams,
      search: serachValue,
    });
    getTemplatData({ ...metaspaceListServerParams, search: serachValue });
  };

  const handleSorting = (sortValue) => {
    setMetaspacelistServerParams({
      ...metaspaceListServerParams,
      sort_order: sortValue,
    });
    getTemplatData({ ...metaspaceListServerParams, sort_order: sortValue });
  };

  useEffect(() => {
    console.log("setSelectedMetaspaceIds", selectedMetaspaceIds);
  }, [selectedMetaspaceIds]);

  useEffect(() => {
    getTemplatData();
  }, []);

  return (
    <>
      <ToastContainer position="top-right" />
      <div className="d-flex flex-column main-outer-wrapper main-site-wrapper ">
        <DashboardHeader />
        <div id="viewport" className="d-flex flex-grow-1">
          <DashboardSidebar />
          <div className="flex-grow-1 page-content-flex">
            <div className="page-content-wrap">
              <div className="page-heading d-md-flex align-items-center">
                <div className="flex-grow-1">
                  <h1>MetaSpaces</h1>
                  <p className="pt-2">
                    Choose the Metaspaces which you want to use in your
                    experience.
                  </p>
                </div>
                {/* <div className="d-md-flex align-items-center"> */}
                {/* <div className="search-filter-padding mt-3 mt-md-0 order-2 order-md-1">
                    <input
                      type="text"
                      className="form-control custom-search-input"
                      placeholder="Search metaspaces"
                    />
                  </div> */}
                <div className="button-wrap">
                  <Link
                    to="/experiences/create-experience"
                    className="btn btn-primary add-btn"
                    state={{ templates: selectedMetaspaceIds }}
                  >
                    <Svg name="plus" /> Create experience
                  </Link>
                </div>
                {/* </div> */}
              </div>

              <div className="tab-search search-filter d-flex align-items-center">
                <div className="flex-grow-1 search-filter-padding pe-0 d-none">
                  <input
                    type="text"
                    className="form-control custom-search-input"
                    placeholder="Search metaspaces"
                    name="serach"
                    value={searchMetaspace}
                    onChange={() => {
                      handleSearchMetasapce();
                    }}
                  />
                </div>
                <div className="drop-wrap d-flex align-items-center d-none">
                  <label>Sort by </label>
                  <div className="dropdown table-dropdown ques-options">
                    <button
                      className="btn dropdown-tgl"
                      type="button"
                      id="sort-by"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {sortValue}
                    </button>
                    <div className="dropdown-menu" aria-labelledby="sort-by">
                      {sortingOptions.map((sort, sortIndex) => (
                        <a
                          className="dropdown-item"
                          href="#"
                          key={sortIndex}
                          onClick={() => {
                            handleSort(sort);
                          }}
                        >
                          {sort.label}
                        </a>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="tmp-out">
                {isFetchingData ? (
                  <Loader />
                ) : (
                  <>
                    {data ? (
                      Object.keys(data).map((temp, tempIndex) => (
                        <div
                          className="templates-wrapper tmp-br"
                          key={tempIndex}
                        >
                          <div className="title-outer">
                            <h4>{temp}</h4>
                          </div>
                          <div className="templates-row row">
                            {Object.values(data[temp]).map((ele, eleIndex) => (
                              <div
                                className="col-xxl-3 col-xl-4 col-md-6 mb-3"
                                key={eleIndex}
                              >
                                <div className="template-card">
                                  <div className="img-wrap">
                                    <img
                                      src={ele.image}
                                      className="img-fluid"
                                      alt={ele.template_name}
                                    />
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="template1"
                                      name="chooseTemplate"
                                      value={ele}
                                      checked={
                                        selectedMetaspaceIds.length > 0 &&
                                        selectedMetaspaceIds.filter(
                                          (temp) => temp.id === ele.id
                                        ).length > 0
                                      }
                                      onChange={(v) => {
                                        if (v.target.checked) {
                                          setSelectedMetaspaceIds([
                                            ...selectedMetaspaceIds,
                                            ele,
                                          ]);
                                        } else {
                                          const tempArray =
                                            selectedMetaspaceIds.filter(
                                              (temp) => temp.id !== ele.id
                                            );
                                          setSelectedMetaspaceIds(tempArray);
                                        }
                                      }}
                                    />
                                    <div className="d-flex align-items-center button-wrap">
                                      <button
                                        className="btn btn-primary"
                                        srno="1"
                                        value="1"
                                        onClick={() => {
                                          openDetailModal(ele);
                                        }}
                                      >
                                        View MetaSpace
                                      </button>
                                    </div>
                                  </div>
                                  <div className="text-wrapper">
                                    <h5 title={ele.template_name}>
                                      {ele.template_name}
                                    </h5>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      ))
                    ) : (
                      <>No data found</>
                    )}
                  </>
                )}
              </div>
              {/* <div className="pagination-wrap d-flex justify-content-end">
                <ul className="pagination mb-0">
                  <li className="page-item disabled">
                    <a
                      className="page-link prev"
                      href="#"
                      tabIndex={-1}
                      disabled
                    >
                      Previous
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#">
                      1
                    </a>
                  </li>
                  <li className="page-item active" aria-current="page">
                    <a className="page-link" href="#">
                      2
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#">
                      3
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link next" href="#">
                      Next
                    </a>
                  </li>
                </ul>
              </div> */}
            </div>
          </div>
        </div>
      </div>

      <div
        className="customvar-modal modal fade modalDetails"
        id="modalDetails"
      >
        <div className="modal-dialog modal-dialog-centered modal-details-width">
          <div className="modal-content">
            <TemplateDetailsModal
              data={selectedId}
              closeDetailModal={closeDetailModal}
            />
          </div>
        </div>
      </div>
    </>
  );
}
