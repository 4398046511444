import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import DashboardHeader from "../common/DashboardHeader";
import DashboardSidebar from "../common/DashboardSidebar";
import Svg from "../common/Svg";
import anytics from "../../assets/images/anytics.png";
import { getMeetingList } from "../../actions/meeting";
import { toast } from "react-toastify";
import ContentLoader from "react-content-loader";
import CreateEvent from "../common/CreateEvent";


const moment = require("moment");

const Loader = (props) => (
  <ContentLoader viewBox="0 0 100% 100%" height={380} width="100%" {...props}>
    <rect x="0" y="0" rx="4" ry="4" width="520" height="60" />
    <rect x="0" y="80" rx="4" ry="4" width="520" height="60" />
    <rect x="0" y="160" rx="4" ry="4" width="520" height="60" />
  </ContentLoader>
);

export default function Dashboard() {

  let loggedinUserData = localStorage.getItem("loggedinUserData");
  loggedinUserData = JSON.parse(loggedinUserData);

  const [isFetchingMeetingList, setIsfetchingMeetingList] = useState(false);
  const [meetingList, setMeetingList] = useState([]);
  const [meetinglistServerParams, setMeetingListServerParams] = useState({
    startingLimit: 0,
    resultsPerPage: 5,
    search: "",
    sort_by: "",
    sort_order: "asc",
  });

  const fetchMeetingList = async () => {
    try {
      setIsfetchingMeetingList(true);
      const meetingListResponse = await getMeetingList(meetinglistServerParams);
      if (meetingListResponse.statusCode === 200) {
        setMeetingList(meetingListResponse.data);
      }
    } catch (error) {
      toast.error(error);
    } finally {
      setIsfetchingMeetingList(false);
    }
  };

  useEffect(() => {
    fetchMeetingList();
  }, []);

  return (
    <>
    <div className="d-flex flex-column main-outer-wrapper main-site-wrapper ">
      <DashboardHeader />
      <div id="viewport" className="d-flex flex-grow-1">
        <DashboardSidebar />
        <div className="flex-grow-1 page-content-flex">
          <div className="page-content-wrap bg-dashboard">
            <div className="heading-section page-heading d-sm-flex align-items-center justify-content-between">
              <h1>
                Welcome back,{" "}
                <span className="text-primary">
                  {loggedinUserData && loggedinUserData.name}!
                </span>
              </h1>

              <button
                className="btn btn-primary add-btn mt-2 mt-md-0"
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#createEvent"
              >
                <Svg name="plus" /> Create Event
              </button>

              {/* <Link to="/meetings/create-meeting" type="button" className="btn btn-primary add-btn mt-2 mt-md-0">
                                <Svg name="plus" /> New Meeting </Link> */}
            </div>

            <div className="page-wrapper">
              <div className="stats-list mb__40">
                <div className="row">
                  <div className="col-md-3 col-sm-6 mb-3 mb-md-0">
                    <Link
                      to="/events/schedule"
                      className="stat-hover stats-wrap d-flex justify-content-center stat-1"
                    >
                      <div className="d-flex align-items-center">
                        <div className="icon-wrap">
                          <Svg name="stat-add" />
                        </div>
                        <div className="flex-grow-1 text">New event</div>
                      </div>
                    </Link>
                  </div>
                  <div className="col-md-3 col-sm-6 mb-3 mb-md-0">
                    <Link
                      to="/events/list"
                      className="stat-hover stats-wrap d-flex justify-content-center stat-2"
                    >
                      <div className="d-flex align-items-center">
                        <div className="icon-wrap">
                          <Svg name="stat-meetings" />
                        </div>
                        <div className="flex-grow-1 text">Events</div>
                      </div>
                    </Link>
                  </div>
                  <div className="col-md-3 col-sm-6 mb-3 mb-md-0">
                    <Link
                      to="/metaspaces"
                      className="stat-hover stats-wrap d-flex justify-content-center stat-3"
                    >
                      <div className="d-flex align-items-center">
                        <div className="icon-wrap">
                          <Svg name="stat-templates" />
                        </div>
                        <div className="flex-grow-1 text">MetaSpaces</div>
                      </div>
                    </Link>
                  </div>
                  <div className="col-md-3 col-sm-6 mb-3 mb-md-0">
                    <Link
                      to="/media"
                      className="stat-hover stats-wrap d-flex justify-content-center stat-4"
                    >
                      <div className="d-flex align-items-center">
                        <div className="icon-wrap">
                          <Svg name="stat-media" />
                        </div>
                        <div className="flex-grow-1 text">Media</div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="dashboard-cards-wraper row">
                <div className="col-xl-8 mb-4 mb-xl-0">
                  <div className="card dashboard-card min-100">
                    <div className="dashboard-card-header d-md-flex justify-content-between align-items-center">
                      <div className="left-wrap">
                        <h3>Average time spent in top ten Experiences </h3>
                      </div>
                      <div className="right-wrap d-flex align-items-center view-more">
                        <Link to="/analytics" className="read-more">
                          View All
                        </Link>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="chart-wrapper mb__40">
                        <img src={anytics} alt="" className="img-fluid" />
                      </div>
                      <div className="stat-wrap-analytics db-analytics row gx-3">
                        <div className="col-md-3 col-sm-6 mb-3 mb-md-0">
                          <div className="stats-wrap analytics-stat stat-1 text-center">
                            <div className="text-wrap">
                              <h3>157 </h3>
                              <p>Total Events</p>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-3 col-sm-6 mb-3 mb-md-0">
                          <div className="stats-wrap analytics-stat stat-2 text-center">
                            <div className="text-wrap">
                              <h3>50 Mins </h3>
                              <p>Avg. event duration</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3 col-sm-6 mb-3 mb-md-0">
                          <div className="stats-wrap analytics-stat stat-3 text-center">
                            <div className="text-wrap">
                              <h3>17 Hrs </h3>
                              <p>Total time spent</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3 col-sm-6 mb-3 mb-md-0">
                          <div className="stats-wrap analytics-stat stat-4 text-center">
                            <div className="text-wrap">
                              <h3>20k </h3>
                              <p>Media clicks</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4">
                  <div className="card dashboard-card min-100">
                    <div className="dashboard-card-header">
                      <h3>Upcoming </h3>
                    </div>
                    <div className="card-body">
                      <div className="meeting-list">
                        {isFetchingMeetingList ? (
                          <Loader />
                        ) : (
                          <>
                            {meetingList.length > 0 ? (
                              <>
                                {meetingList
                                  .slice(0, 5)
                                  .map((meeting, meetingIndex) => (
                                    <div
                                      className={`meeting-list-wrapper type-${meetingIndex + 1
                                        }`}
                                      key={meetingIndex}
                                    >
                                      <a
                                        href="#"
                                        className="d-flex main-meeting"
                                      >
                                        <div className="text-wrapper flex-grow-1">
                                          <div className="heading">
                                            <h5>{meeting.event_name}</h5>
                                          </div>
                                          <ul className="category">
                                            <li>Metaverse</li>
                                            <li>
                                              Meeting ID : {meeting.event_code}
                                            </li>
                                          </ul>
                                        </div>
                                        <div className="date-time text-end">
                                          <p>
                                            {" "}
                                            {moment(meeting.event_date_time)
                                              .utc()
                                              .format("hh:mm")}{" "}
                                            |{" "}
                                            {moment(
                                              meeting.event_end_date_time
                                            ).diff(
                                              moment(meeting.event_date_time),
                                              "minutes"
                                            )}{" "}
                                            mins
                                          </p>
                                          <p>
                                            {moment(
                                              meeting.event_date_time
                                            ).format("DD MMM, YYYY")}
                                          </p>
                                        </div>
                                      </a>
                                    </div>
                                  ))}
                              </>
                            ) : (
                              <>No Events.</>
                            )}
                          </>
                        )}
                      </div>
                      <div className="view-more text-center pt-3">
                        {isFetchingMeetingList ? (
                          <></>
                        ) : (
                          <Link to="/events/list" className="read-more">
                            View All
                          </Link>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <CreateEvent />
    </>
  );
}
