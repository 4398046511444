import React, { useEffect, useState } from "react";
import DashboardHeader from "../common/DashboardHeader";
import DashboardSidebar from "../common/DashboardSidebar";
import ScheduleHeader from "./ScheduleHeader";
import { Link, useLocation, useNavigate } from "react-router-dom";
import CreatableSelect from "react-select/creatable";
import Svg from "../common/Svg";
import { Formik } from "formik";
import { addPeriticipantSchema } from "../../Schema/Meeting";
import { toast, ToastContainer } from "react-toastify";
import {
  addHostMeeting,
  addParticipants,
  deleteParticipants,
  getHostListMeeting,
  getMeetingDetailEdit,
  getParticipants,
  sendMeetingInvitation,
} from "../../actions/meeting";
import { getUsers } from "../../actions/user";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { regionList } from "../../actions/country";

const host = [
  { id: "1", value: "host1", label: "Sam Adams" },
  { id: "1", value: "host2", label: "John Cena" },
  { id: "1", value: "host3", label: "Dwane John" },
  { id: "1", value: "host4", label: "Dave Bat" },
];

export default function InviteParticipants() {
  const navigate = useNavigate();

  const { state } = useLocation();

  const [isAdding, setIsAdding] = useState(false);
  const [isDeleteing, setIsDeleteing] = useState(false);
  const [isgettingParticipants, setIsgettingParticipants] = useState(false);
  const [participants, setParticipants] = useState([]);
  const [selectedHost, setSelectedHost] = useState(null);
  const [userList, setUserList] = useState([]);
  const [regions, setRegions] = useState([]);

  const getRegionList = async () => {
    await regionList().then((res) => {
      if (res.statusCode === 200) {
        let data = res.data.regions;
        let tempRegions = [];
        Object.keys(data).map((ele) => {
          tempRegions.push({
            value: data[ele].region_code,
            label: data[ele].region_name,
          });
          setRegions(tempRegions);
        });
      }
    });
  };

  const getAllUser = async () => {
    let params = {
      startingLimit: 0,
      resultsPerPage: 1000,
      search: "",
    };
    try {
      const res = await getUsers(params);
      if (res.statusCode === 200) {
        let userOptions = [];
        res.data.map((ele) => {
          userOptions.push({
            value: ele.id,
            label: ele.name,
            email: ele.email,
          });
        });
        setUserList(userOptions);
      } else {
        toast.error(res.msg);
      }
    } catch (error) {
      toast.error(error.response.data.msg);
    }
  };

  const getHostList = async () => {
    let dataToSend = {
      id: state,
    };
    try {
      const res = await getHostListMeeting(dataToSend);
      if (res.statusCode === 200) {
        let hostOption = {
          value: res.data[0].user_id,
          label: res.data[0].name,
          email: res.data[0].email,
        };
        setSelectedHost(hostOption);
        setIsgettingParticipants(false);
      } else {
        toast.error(res.msg);
      }
    } catch (error) {}
  };

  const chooseHost = async (userObj) => {
    setSelectedHost(userObj);
    let dataToSend = {
      id: state,
      name: userObj.label,
      email: userObj.email,
      region: 1,
      user_id: userObj.value,
    };
    try {
      const res = await addHostMeeting(dataToSend);
      if (res.statusCode === 200) {
      } else {
        toast.error(res.msg);
      }
    } catch (error) {}
  };

  const getMeetingParticipants = async (meetingId) => {
    let dataToSend = {
      id: meetingId,
    };
    setIsgettingParticipants(true);
    try {
      const res = await getParticipants(dataToSend);
      if (res.statusCode === 200) {
        setParticipants(res.data);
        setIsgettingParticipants(false);
      } else {
        toast.error(res.msg);
      }
    } catch (error) {
      toast.error(error.response.data.msg);
    }
  };

  const addMeetingParticipants = async (data) => {
    setIsAdding(true);
    try {
      const res = await addParticipants(data);
      if (res.statusCode === 200) {
        // toast.success(res.msg);
        setIsAdding(false);
        // getMeetingParticipants("83fb4fc0-a94b-11ed-93e9-750425619f6e");
        getMeetingParticipants(state);
      } else {
        toast.error(res.msg);
      }
    } catch (error) {
      toast.error(error.response.data.msg);
    }
  };

  const shareMeeting = () => {
    navigate("/events/share", { state: state });
  };

  const sendInvitation = async (participantObj) => {
    let dataToSend = {
      user_id: participantObj.user_id,
      id: state,
      participant_id: participantObj.user_id,
    };
    try {
      const res = await sendMeetingInvitation(dataToSend);
      if (res.statusCode === 200) {
        // toast.success(res.msg);
        setIsAdding(false);
        // getMeetingParticipants("83fb4fc0-a94b-11ed-93e9-750425619f6e");
        // getMeetingParticipants(state);
      } else {
        toast.error(res.msg);
      }
    } catch (error) {}
  };

  const deleteParticipant = async (data) => {
    setIsDeleteing(true);
    let dataTosend = {
      id: data.event_id,
      participant_id: data.id,
    };
    await deleteParticipants(dataTosend)
      .then((res) => {
        setIsDeleteing(false);
        getMeetingParticipants(data.event_id);
      })
      .catch((error) => {
        setIsDeleteing(false);
      });
  };

  useEffect(() => {
    getHostList();
    // getMeetingParticipants("83fb4fc0-a94b-11ed-93e9-750425619f6e");
    getMeetingParticipants(state);
    getAllUser();
    getRegionList();
  }, []);

  return (
    <>
      <ToastContainer position="top-right" />

      <div className="d-flex flex-column main-outer-wrapper main-site-wrapper ">
        <DashboardHeader />
        <div id="viewport" className="d-flex flex-grow-1">
          <DashboardSidebar />
          <div className="flex-grow-1 page-content-flex">
            <ScheduleHeader inviteParticipants eventId={state} />
            <div className="page-content-wrap">
              <div className="heading-section page-heading d-md-flex align-items-center justify-content-between">
                <div className="left-right">
                  <h1>Invite participants</h1>
                  <p>Smart Factory Solutions </p>
                </div>
                <div className="right-wrap mt-3 mt-md-0">
                  <button
                    type="button"
                    className="btn btn-primary add-btn"
                    onClick={() => {
                      shareMeeting();
                    }}
                  >
                    {" "}
                    Share <Svg name="right-arrow" />
                  </button>
                </div>
              </div>
              <div className="schedule-form account-info">
                <div className="row">
                  <div className="col-xxl-4 col-lg-6">
                    <div className="meeting-card border-0 pb-0">
                      <div className="meeting-heading mb-2">Choose Host</div>
                      <div className="field-wrapper participants-form">
                        <div className="mb-3 cstom-select">
                          <div className="d-flex align-items-center">
                            <div className="select-wrap flex-grow-1">
                              <CreatableSelect
                                // isMulti
                                // isClearable
                                value={selectedHost}
                                options={userList}
                                onChange={(e) => chooseHost(e)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr className="dark-20" />
                <div className="row">
                  <div className="col-xxl-4 col-lg-6">
                    <div className="meeting-card border-0 ">
                      <div className="meeting-heading">
                        Add participants (maximum 15)
                      </div>
                      <Formik
                        initialValues={{
                          id: state,
                          // id: "344d20f0-b0e6-11ed-b234-3f2eb0100ade",
                          firstName: "",
                          lastName: "",
                          email: "",
                          region: "",
                          isEnabled: true,
                        }}
                        validationSchema={addPeriticipantSchema}
                        onSubmit={(
                          values,
                          { setSubmitting, resetForm, setFieldValue }
                        ) => {
                          let dataToSend = {
                            id: values.id,
                            name: values.firstName + " " + values.lastName,
                            email: values.email,
                            region: values.region,
                          };
                          addMeetingParticipants(dataToSend);
                          if (!isAdding) {
                            resetForm();
                            setFieldValue("region", "");
                            setSubmitting(false);
                          }
                        }}
                      >
                        {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          setFieldValue,
                          isSubmitting,
                        }) => (
                          <form onSubmit={handleSubmit}>
                            <div className="field-wrapper participants-form">
                              <div className="mb-3 position-relative">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="First name*"
                                  name="firstName"
                                  value={values.firstName}
                                  onChange={handleChange}
                                />
                                {errors.firstName &&
                                  touched.firstName &&
                                  errors.firstName && (
                                    <div className="validation-msg">
                                      {errors.firstName &&
                                        touched.firstName &&
                                        errors.firstName}
                                    </div>
                                  )}
                              </div>
                              <div className="mb-3 position-relative">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Last name*"
                                  name="lastName"
                                  value={values.lastName}
                                  onChange={handleChange}
                                />
                                {errors.lastName &&
                                  touched.lastName &&
                                  errors.lastName && (
                                    <div className="validation-msg">
                                      {errors.lastName &&
                                        touched.lastName &&
                                        errors.lastName}
                                    </div>
                                  )}
                              </div>
                              <div className="mb-3 position-relative">
                                <input
                                  type="email"
                                  className="form-control"
                                  placeholder="Enter email*"
                                  name="email"
                                  value={values.email}
                                  onChange={handleChange}
                                />
                                {errors.email &&
                                  touched.email &&
                                  errors.email && (
                                    <div className="validation-msg">
                                      {errors.email &&
                                        touched.email &&
                                        errors.email}
                                    </div>
                                  )}
                              </div>
                              <div className="mb-3 cstom-select">
                                <div className="d-flex align-items-center">
                                  <div className="select-wrap flex-grow-1 position-relative">
                                    <CreatableSelect
                                      key={values.region}
                                      // isClearable
                                      placeholder={"Select region"}
                                      options={regions}
                                      onChange={(v) => {
                                        setFieldValue("region", v.value);
                                      }}
                                      value={
                                        regions.length > 0
                                          ? regions.find(
                                              (option) =>
                                                option.value === values.region
                                            )
                                          : ""
                                      }
                                    />
                                    {errors.region &&
                                      touched.region &&
                                      errors.region && (
                                        <div className="validation-msg">
                                          {errors.region &&
                                            touched.region &&
                                            errors.region}
                                        </div>
                                      )}
                                  </div>
                                  <OverlayTrigger
                                    overlay={
                                      <Tooltip>
                                        Enter region for <br />
                                        best experience
                                      </Tooltip>
                                    }
                                  >
                                    <button className="btn btn-link btn-tooltip p-0 ms-2">
                                      <Svg name="tooltip-icon" />
                                    </button>
                                  </OverlayTrigger>
                                </div>
                              </div>
                            </div>
                            <div className="button-wrap mb-3">
                              <div className="">
                                <button
                                  type="submit"
                                  disabled={isSubmitting}
                                  className="btn btn-bordered"
                                >
                                  {isSubmitting ? (
                                    <>
                                      <span
                                        className="spinner-border spinner-border-sm"
                                        role="status"
                                        aria-hidden="true"
                                      ></span>
                                      <span className="visually-hidden">
                                        Loading...
                                      </span>
                                    </>
                                  ) : (
                                    "Add"
                                  )}
                                </button>
                              </div>
                            </div>
                          </form>
                        )}
                      </Formik>
                    </div>
                  </div>

                  <div className="col-xxl-4 col-lg-6 participant-list-wrapper">
                    <div className="meeting-card border-0 ps-0 ps-xxl-3">
                      <div className="meeting-heading border-bottom">
                        Participants (
                        {participants.length > 0 ? participants.length : ""})
                      </div>
                      {isgettingParticipants ? (
                        <>
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          <span className="visually-hidden">Loading...</span>
                        </>
                      ) : (
                        <ul className="participant-list">
                          {participants.length > 0 ? (
                            <>
                              {participants.map((participant, pIndex) => (
                                <li key={pIndex}>
                                  <span className="chips d-flex align-items-center justify-content-between">
                                    <p>{participant.name}</p>
                                    <div className="button-wrap">
                                      <OverlayTrigger
                                        overlay={<Tooltip>Copy</Tooltip>}
                                      >
                                        <button
                                          className="action-btn"
                                          onClick={() => {
                                            navigator.clipboard.writeText(
                                              participant?.link ?? null
                                            );
                                          }}
                                        >
                                          <Svg name="icon-copy" />
                                        </button>
                                      </OverlayTrigger>

                                      <OverlayTrigger
                                        overlay={
                                          <Tooltip>Send Invitation</Tooltip>
                                        }
                                      >
                                        <button
                                          className="action-btn"
                                          onClick={() => {
                                            sendInvitation(participant);
                                          }}
                                        >
                                          <Svg name="send-inv" />
                                        </button>
                                      </OverlayTrigger>
                                      <OverlayTrigger
                                        overlay={<Tooltip>Delete</Tooltip>}
                                      >
                                        <button
                                          className="action-btn"
                                          onClick={() => {
                                            deleteParticipant(participant);
                                          }}
                                        >
                                          {isDeleteing ? (
                                            <>
                                              <span
                                                className="spinner-border spinner-border-sm"
                                                style={{
                                                  height: "20px",
                                                  width: "20px",
                                                }}
                                                role="status"
                                                aria-hidden="true"
                                              ></span>
                                              <span className="visually-hidden">
                                                Loading...
                                              </span>
                                            </>
                                          ) : (
                                            <Svg name="icon-delete" />
                                          )}
                                        </button>
                                      </OverlayTrigger>
                                    </div>
                                  </span>
                                </li>
                              ))}
                            </>
                          ) : (
                            <>Add Participants</>
                          )}
                        </ul>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
