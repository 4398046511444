import axios from "../utils/axios";
import { BASE_URL } from "./urls";

export const getTemplates = async (payload) => {
  const token = localStorage.getItem("token");
  const response = await axios({
    headers: { Authorization: `Bearer ${token}` },
    method: "POST",
    url: `${BASE_URL}/template/getVmersiveTemplates`,
    data: payload
  });

  return response.data;
};

export const getTemplateDetail = async (payload) => {
  const token = localStorage.getItem("token");
  const response = await axios({
    headers: { Authorization: `Bearer ${token}` },
    method: "POST",
    url: `${BASE_URL}/template/getTemplateDetail`,
    data: payload,
  });

  return response.data;
};
