import * as Yup from "yup";

export const createTeamSchema = Yup.object().shape({
  team_name: Yup.string().required("Team name is required"),
//   user_id: Yup.object().shape({
//     label: Yup.string().required(),
//     value: Yup.string().required(),
//   }),
//   role_id: Yup.object().shape({
//     id: Yup.string().required(),
//     label: Yup.string().required(),
//     value: Yup.string().required(),
//   }),
});

export const editTeamSchema = Yup.object().shape({
  team_name: Yup.string().required("Team name is required"),
});

export const addTeamMemberSchema = Yup.object().shape({
  team_id: Yup.string().required("Team name is required"),
  role_id: Yup.string().required("role name is required"),
  user_id: Yup.string().required("user name is required"),
});