import { useFormik } from 'formik';
import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { forgotEmailSchema } from '../../Schema/Auth';
import Copyright from '../common/Copyright'
import Svg from '../common/Svg'

export default function ForgotPassword() {

    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            email: ""
        },
        validationSchema: forgotEmailSchema,
        onSubmit: (values) => {
            console.log(values);
            navigate("/otp", { state: values.email })
        },
        enableReinitialize: true,
    });

    return (
        <div className='d-flex flex-column site-main-wrapper login-page'>

            <div className="flex-grow-1 main-content-wrap d-flex align-items-center justify-content-center">
                <div className="container">
                    <div className="form-outer-wrapper">
                        <div className="logo-wrap text-center">
                            <Link to="/#0t" className='home-logo'>
                                <Svg name="logo" />
                            </Link>
                        </div>
                        <div className="form-wrapper mb__20">
                            <div className="form-heading text-center">
                                <h3>Let us know it’s you</h3>
                                <p>Enter your registered email and we'll send you instructions to reset your password</p>
                            </div>
                            <form onSubmit={formik.handleSubmit}>
                                <div className="field-wrapper">
                                    <div className="form-floating mb__20 position-relative">
                                        <input type="email" name='email' value={formik.values.email} onChange={formik.handleChange} className="form-control" placeholder='Email' />
                                        <label htmlFor="floatingInput" className="mb-0">Enter Email</label>
                                        {formik.touched.email && formik.errors.email && (
                                            <div className="validation-msg">
                                                {formik.errors.email}
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="button-wrap">
                                    <button type="submit" className="btn-primary btn w-100">Send link</button>
                                </div>
                            </form>
                            <div className="outer-form-text text-center">
                                <p className='mb-0'><Link to="/"> Back to login</Link></p>
                            </div>
                        </div>
                        <Copyright />
                    </div>
                </div>
            </div>
        </div>
    )
}
