import React from "react";
import { Link, useLocation } from "react-router-dom";
import Copyright from "./Copyright";
import Svg from "./Svg";

export default function DashboardSidebar() {
    const location = useLocation();

    return (
        <div className="dashboard-side-menu-wrapper d-flex flex-column align-items-stretch">
            <nav className="dashboard-side-menu position-static flex-grow-1">
                <ul className="top-menu" id="collapseMenu">
                    {/* <li>
                        <Link title="Dashboard" to="/dashboard" className={location.pathname.includes("/dashboard") ? "active" : ""}>
                            <Svg name="dashboard" />
                            <span className="nav-text">Dashboard</span>
                        </Link>
                    </li> */}

                    {/* <li className="has-subnav">
                        <div className="d-flex drop-wrap align-items-center">
                            <a className="collapsible collapsed" type="button" data-bs-toggle="collapse" href="#collapse1" aria-expanded="true" aria-controls="collapse1">
                                <Svg name="meetings" />
                                <span className="nav-text">
                                    Meetings
                                </span>
                                <div className="drop-icon">
                                    <Svg name="dropdown-icon" />
                                </div>
                            </a>
                        </div>
                        <div id="collapse1" className="collapse" data-bs-parent="#collapseMenu">
                            <ul className='subnav'>
                                <li>
                                    <Link to="#" className='active'>Metaverse</Link>
                                </li>
                                <li>
                                    <Link to="#">Immersive <small>(Guided)</small></Link>
                                </li>
                                <li>
                                    <Link to="#">Immersive <small>(Self-guided)</small></Link>
                                </li>
                                <li>
                                    <Link to="#">VR Experience</Link>
                                </li>
                            </ul>
                        </div>
                    </li> */}

                    <li>
                        <Link title="Events" to="/events/list" className={location.pathname.includes("/events") ? "active" : ""}>
                            <Svg name="events" />
                            <span className="nav-text">Events</span>
                        </Link>
                    </li>
                    <li>
                        <Link title="Experiences" to="/experiences" className={location.pathname.includes("/experiences") ? "active" : ""}>
                            <Svg name="experiences" />
                            <span className="nav-text">Experiences</span>
                        </Link>
                    </li>
                    <li>
                        <Link title="MetaSpaces" to="/metaspaces" className={location.pathname.includes("/metaspaces") ? "active" : ""}>
                            <Svg name="metaspaces" />
                            <span className="nav-text">MetaSpaces</span>
                        </Link>
                    </li>

                    <li>
                        <Link title="Media" to="/media" className={location.pathname.includes("/media") ? "active" : ""}>
                            <Svg name="media" />
                            <span className="nav-text">Media</span>
                        </Link>
                    </li>
                    <li>
                        <Link title="Analytics" to="/analytics" className={location.pathname.includes("/analytics") ? "active" : ""}>
                            <Svg name="analytics" />
                            <span className="nav-text">Analytics</span>
                        </Link>
                    </li>

                    <li className="has-subnav">
                        <div className="d-flex drop-wrap align-items-center">
                            <a
                                // className="collapsible collapsed active"
                                className={location.pathname.includes("/settings") ? "collapsible collapsed active" : "collapsible collapsed"}
                                type="button"
                                data-bs-toggle="collapse"
                                href="#collapse2"
                                aria-expanded="true"
                                aria-controls="collapse2"
                                title="Settings"
                            >
                                <Svg name="settings" />
                                <span className="nav-text">Settings</span>
                                <div className="drop-icon">
                                    <Svg name="dropdown-icon" />
                                </div>
                            </a>
                        </div>
                        <div
                            id="collapse2"
                            className="collapse"
                            aria-labelledby="heading2"
                            data-bs-parent="#collapseMenu"
                        >
                            <ul className="subnav">
                                <li>
                                    <Link title="My Profile" to="/settings/profile" className={location.pathname.includes("/settings/profile") ? "active" : ""}>
                                        My Profile
                                    </Link>
                                </li>
                                <li>
                                    <Link title="User roles" to="/settings/user-roles" className={location.pathname.includes("/settings/user-roles") ? "active" : ""}>User roles</Link>
                                </li>
                                <li>
                                    <Link title="Users" to="/settings/users" className={location.pathname.includes("/settings/users") ? "active" : ""}>Users</Link>
                                </li>
                                <li>
                                    <Link title="Teams" to="/settings/teams/list" className={location.pathname.includes("/settings/teams") ? "active" : ""}>Teams</Link>
                                </li>
                            </ul>
                        </div>
                    </li>
                </ul>
            </nav>
            <Copyright />
        </div>
    );
}
