import React, { useState } from 'react'
import DashboardHeader from '../common/DashboardHeader'
import DashboardSidebar from '../common/DashboardSidebar'
import DeleteMeetingModal from '../common/DeleteMeetingModal'
import Svg from '../common/Svg'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { Link } from 'react-router-dom'
import CreateEvent from '../common/CreateEvent'
const moment = require("moment");

export default function List() {

    const [state, setState] = useState({
        start: moment().subtract(29, 'days'),
        end: moment(),
    });
    const { start, end } = state;
    const handleCallback = (start, end) => {
        setState({ start, end });
    };
    const label = start.format('DD/MM/YY') + ' - ' + end.format('DD/MM/YY');

    return (
        <>

            <div className='d-flex flex-column main-outer-wrapper main-site-wrapper '>
                <DashboardHeader />
                <div id="viewport" className="d-flex flex-grow-1">
                    <DashboardSidebar />
                    <div className="flex-grow-1 page-content-flex">
                        <div className="page-content-wrap pb-0">
                            <div className="heading-section page-heading d-md-flex align-items-center justify-content-between">
                                <div className="left-right">
                                    <h1>VR Experience</h1>
                                    <p>Users can explore experiences via a VR headset</p>
                                </div>
                                <div className="right-wrap mt-3 mt-md-0">
                                    <button
                                        className="btn btn-primary add-btn mt-2 mt-md-0"
                                        type="button"
                                        data-bs-toggle="modal"
                                        data-bs-target="#createEvent"
                                    >
                                        <Svg name="plus" /> Create Event
                                    </button>
                                </div>
                            </div>
                            <div className="meeting-pg">
                                <div className="nav-tabs-wrapper vmersive-tabs mb-0">
                                    <ul className="nav nav-tabs" id="meetings" role="tablist">
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link active" id="tab-1-tab" data-bs-toggle="tab" data-bs-target="#tab-1" type="button" role="tab" aria-controls="tab-1" aria-selected="true">Upcoming </button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link" id="tab-2-tab" data-bs-toggle="tab" data-bs-target="#tab-2" type="button" role="tab" aria-controls="tab-2" aria-selected="false">Past</button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link" id="tab-3-tab" data-bs-toggle="tab" data-bs-target="#tab-3" type="button" role="tab" aria-controls="tab-3" aria-selected="false">Draft</button>
                                        </li>
                                    </ul>
                                </div>
                                <div className="page-wrapper">
                                    <div className="tab-content" id="meetingsContent">
                                        <div className="tab-pane fade show active" id="tab-1" role="tabpanel" aria-labelledby="tab-1-tab">
                                            <div className="meeting-pg-wrapper d-flex">
                                                <div className="meeting-list-wrap">
                                                    <div className="tab-search search-filter mb-4">

                                                        <div className="drop-wrap d-sm-flex align-items-center mb-3 list-search">
                                                            <label className="flex-grow-1">Sort By - </label>



                                                            <div className="dropdown table-dropdown ques-options">
                                                                <button className="btn dropdown-tgl" type="button" id="sort-by" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                    All Events
                                                                </button>
                                                                <div className="dropdown-menu" aria-labelledby="sort-by">
                                                                    <a className="dropdown-item" href="#"> All events </a>
                                                                    <a className="dropdown-item" href="#"> Meeting </a>
                                                                    <a className="dropdown-item" href="#"> Immersive (Guided) </a>
                                                                    <a className="dropdown-item" href="#"> Immersive (Self-guided) </a>
                                                                    <a className="dropdown-item" href="#"> VR Experience </a>
                                                                </div>
                                                            </div>

                                                            <div className='drop-wrap'>
                                                                <DateRangePicker
                                                                    initialSettings={{
                                                                        showDropdowns: true,
                                                                        startDate: start.toDate(),
                                                                        endDate: end.toDate(),
                                                                        ranges: {
                                                                            Today: [moment().toDate(), moment().toDate()],
                                                                            Yesterday: [
                                                                                moment().subtract(1, 'days').toDate(),
                                                                                moment().subtract(1, 'days').toDate(),
                                                                            ],
                                                                            'Last 7 Days': [
                                                                                moment().subtract(6, 'days').toDate(),
                                                                                moment().toDate(),
                                                                            ],
                                                                            'Last 30 Days': [
                                                                                moment().subtract(29, 'days').toDate(),
                                                                                moment().toDate(),
                                                                            ],
                                                                            'This Month': [
                                                                                moment().startOf('month').toDate(),
                                                                                moment().endOf('month').toDate(),
                                                                            ],
                                                                            'Last Month': [
                                                                                moment().subtract(1, 'month').startOf('month').toDate(),
                                                                                moment().subtract(1, 'month').endOf('month').toDate(),
                                                                            ],
                                                                        },
                                                                    }}
                                                                    onCallback={handleCallback}
                                                                >
                                                                    <div id="reportrange" className="date-range-picker">
                                                                        {label}
                                                                    </div>
                                                                </DateRangePicker>
                                                            </div>

                                                        </div>

                                                        <div className="search-filter-padding pe-0">
                                                            <input
                                                                type="text"
                                                                className="form-control custom-search-input"
                                                                placeholder="Search events"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="today-list">
                                                        {/* <div className="heading">Today</div> */}
                                                        <div className="list-wrapper">
                                                            <div className="meeting-list">
                                                                <div className="meeting-list-wrapper type-3">
                                                                    <a href='#' className="d-flex main-meeting active">
                                                                        <div className="text-wrapper flex-grow-1">
                                                                            <div className="heading">
                                                                                <h5>Smart Factory Solutions</h5>
                                                                            </div>
                                                                            <ul className="category">
                                                                                <li>VR Experience </li>
                                                                            </ul>
                                                                        </div>
                                                                        <div className="date-time text-end">
                                                                            <p>10:30 | 30 mins</p>
                                                                            <p>15 Jan, 2023</p>
                                                                        </div>
                                                                    </a>
                                                                </div>

                                                                <div className="meeting-list-wrapper type-3">
                                                                    <a href='#' className="d-flex main-meeting">
                                                                        <div className="text-wrapper flex-grow-1">
                                                                            <div className="heading">
                                                                                <h5>Europe Customer Experience center</h5>
                                                                            </div>
                                                                            <ul className="category">

                                                                                <li>VR Experience </li>
                                                                            </ul>
                                                                        </div>
                                                                        <div className="date-time text-end">
                                                                            <p>10:30 | 30 mins</p>
                                                                            <p>15 Jan, 2023</p>
                                                                        </div>
                                                                    </a>
                                                                </div>


                                                                <div className="meeting-list-wrapper type-3">
                                                                    <a href='#' className="d-flex main-meeting">
                                                                        <div className="text-wrapper flex-grow-1">
                                                                            <div className="heading">
                                                                                <h5>Virtual car showroom</h5>
                                                                            </div>
                                                                            <ul className="category">

                                                                                <li>VR Experience </li>
                                                                            </ul>
                                                                        </div>
                                                                        <div className="date-time text-end">
                                                                            <p>10:30 | 30 mins</p>
                                                                            <p>15 Jan, 2023</p>
                                                                        </div>
                                                                    </a>
                                                                </div>


                                                                <div className="meeting-list-wrapper type-3">
                                                                    <a href='#' className="d-flex main-meeting">
                                                                        <div className="text-wrapper flex-grow-1">
                                                                            <div className="heading">
                                                                                <h5>Americas Customer Experience center</h5>
                                                                            </div>
                                                                            <ul className="category">

                                                                                <li>VR Experience </li>
                                                                            </ul>
                                                                        </div>
                                                                        <div className="date-time text-end">
                                                                            <p>10:30 | 30 mins</p>
                                                                            <p>15 Jan, 2023</p>
                                                                        </div>
                                                                    </a>
                                                                </div>


                                                                <div className="meeting-list-wrapper type-3">
                                                                    <a href='#' className="d-flex main-meeting">
                                                                        <div className="text-wrapper flex-grow-1">
                                                                            <div className="heading">
                                                                                <h5>Event with client </h5>
                                                                            </div>
                                                                            <ul className="category">

                                                                                <li>VR Experience </li>
                                                                            </ul>
                                                                        </div>
                                                                        <div className="date-time text-end">
                                                                            <p>10:30 | 30 mins</p>
                                                                            <p>15 Jan, 2023</p>
                                                                        </div>
                                                                    </a>
                                                                </div>


                                                                <div className="meeting-list-wrapper type-3">
                                                                    <a href='#' className="d-flex main-meeting">
                                                                        <div className="text-wrapper flex-grow-1">
                                                                            <div className="heading">
                                                                                <h5>Europe Customer Experience center</h5>
                                                                            </div>
                                                                            <ul className="category">

                                                                                <li>VR Experience </li>
                                                                            </ul>
                                                                        </div>
                                                                        <div className="date-time text-end">
                                                                            <p>10:30 | 30 mins</p>
                                                                            <p>15 Jan, 2023</p>
                                                                        </div>
                                                                    </a>
                                                                </div>



                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>
                                                {/**For Mobile, this will toggel from bottom, so add class active so that it can toggle from bottom */}
                                                <div className="meeting-data flex-grow-1 active">
                                                    {/**Remove Class on click of this button*/}
                                                    <button type="button" className="btn-close d-block d-lg-none" data-bs-dismiss="modal" aria-label="Close" />
                                                    <div className="meeting-info">
                                                        <div className="title-wra">
                                                            <h2>Smart Factory Solutions</h2>
                                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla ac metus sit amet elit porttitor pellentesque non laore</p>
                                                        </div>
                                                        <ul className="meta">
                                                            <li className='d-flex align-items-center'><Svg name="calendar" /> 23 Jan, 2023</li>
                                                            <li className='d-flex align-items-center'><Svg name="clock" /> 10:30 AM  </li>
                                                            <li className='d-flex align-items-center'><Svg name="timer-icon" /> 30 mins </li>
                                                            {/* <li className='d-flex align-items-center'><Svg name="meetings" /> 10 </li> */}
                                                        </ul>
                                                        <ul className="meta user-list-wrapper">
                                                            <li>
                                                                <span className="text">People invited </span>
                                                                <div className="user-list">
                                                                    <OverlayTrigger overlay={<Tooltip>Sam King</Tooltip>}>
                                                                        <span>SK</span>
                                                                    </OverlayTrigger>
                                                                    <OverlayTrigger overlay={<Tooltip>Iva Gone</Tooltip>}>
                                                                        <span>IG</span>
                                                                    </OverlayTrigger>
                                                                    <OverlayTrigger overlay={<Tooltip>Pam Harris</Tooltip>}>
                                                                        <span>PH</span>
                                                                    </OverlayTrigger>
                                                                    <OverlayTrigger overlay={<Tooltip>Cam King</Tooltip>}>
                                                                        <span>CK</span>
                                                                    </OverlayTrigger>
                                                                    <OverlayTrigger overlay={<Tooltip>Rose Viking</Tooltip>}>
                                                                        <span>RV</span>
                                                                    </OverlayTrigger>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                        <div className="meeting-details">
                                                            <div className="heading-top">
                                                                <h4 className='part-rg'>Participants by region</h4>
                                                            </div>
                                                            <div className="meeting-list-inner">
                                                                <span className="heading">Europe: </span>
                                                                <span className="value">04</span>
                                                            </div>
                                                            <div className="meeting-list-inner">
                                                                <span className="heading">North America: </span>
                                                                <span className="value">02 </span>
                                                            </div>
                                                            <div className="meeting-list-inner">
                                                                <span className="heading">APAC: </span>
                                                                <span className="value">02 </span>
                                                            </div>
                                                            <hr className='dark-20' />
                                                            <div className="meeting-list-inner">
                                                                <span className="heading">Host name:</span>
                                                                <span className="value">Jason Trevas</span>
                                                            </div>
                                                            <div className="meeting-list-inner">
                                                                <span className="heading">Event Type: </span>
                                                                <span className="value">VR Experience  <OverlayTrigger overlay={<Tooltip>Users can explore experiences via a VR headset</Tooltip>}><button className='btn btn-link btn-tooltip p-0 ms-2'><Svg name="tooltip-icon" /></button></OverlayTrigger></span>
                                                            </div>
                                                            <div className="meeting-list-inner">
                                                                <span className="heading">Region: </span>
                                                                <span className="value">Europe </span>
                                                            </div>
                                                            <div className="meeting-list-inner">
                                                                <span className="heading">Time zone: </span>
                                                                <span className="value">(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi </span>
                                                            </div>
                                                            <div className="meeting-list-inner">
                                                                <span className="heading">Selected experiences: </span>
                                                                <span className="value" >
                                                                    <button type="button" data-bs-toggle="modal" data-bs-target="#modalDetails" srno="1" value="1">
                                                                        Walmart: Supply chain automation pitch </button>
                                                                </span>
                                                            </div>

                                                            <div className="meeting-list-inner">
                                                                <span className="heading">Meeting ID: </span>
                                                                <span className="value">XYE 134  424</span>
                                                            </div>
                                                            <div className="meeting-list-inner">
                                                                <span className="heading">Created by: </span>
                                                                <span className="value">Robin .H</span>
                                                            </div>
                                                            <div className="meeting-list-inner">
                                                                <span className="heading">Created on: </span>
                                                                <span className="value">23 Jan, 2023</span>
                                                            </div>

                                                        </div>

                                                        <div className="button-wrapper pt-2 pt-md-4">
                                                            <button type="button" className="btn btn-primary"> Start </button>
                                                            <button type="button" className="btn btn-bordered alternate-color icon-btn"><Svg name="icon-copy" /> Copy invitation </button>
                                                            <button type="button" className="btn btn-bordered alternate-color icon-btn"><Svg name="icon-copy" /> Copy host invitation </button>


                                                            <OverlayTrigger overlay={<Tooltip>Edit</Tooltip>}>
                                                                <button type="button" className="btn btn-bordered alternate-color danger icon-btn no-text">
                                                                    <Svg name="icon-edit" />
                                                                </button>
                                                            </OverlayTrigger>
                                                            <OverlayTrigger overlay={<Tooltip>Delete</Tooltip>}>

                                                                <button
                                                                    type="button"
                                                                    className="btn btn-bordered alternate-color danger icon-btn no-text"
                                                                    data-bs-toggle="modal" data-bs-target="#deleteModal"
                                                                >
                                                                    <Svg name="icon-delete" />
                                                                </button>
                                                            </OverlayTrigger>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-pane fade" id="tab-2" role="tabpanel" aria-labelledby="tab-2-tab">
                                            <div className="meeting-pg-wrapper d-flex">
                                                <div className="meeting-list-wrap">
                                                    <div className="tab-search search-filter mb-4">

                                                        <div className="drop-wrap d-sm-flex align-items-center mb-3 list-search">
                                                            <label className="flex-grow-1">Sort By - </label>



                                                            <div className="dropdown table-dropdown ques-options">
                                                                <button className="btn dropdown-tgl" type="button" id="sort-by" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                    All Events
                                                                </button>
                                                                <div className="dropdown-menu" aria-labelledby="sort-by">
                                                                    <a className="dropdown-item" href="#"> All events </a>
                                                                    <a className="dropdown-item" href="#"> Meeting </a>
                                                                    <a className="dropdown-item" href="#"> Immersive (Guided) </a>
                                                                    <a className="dropdown-item" href="#"> Immersive (Self-guided) </a>
                                                                    <a className="dropdown-item" href="#"> VR Experience </a>
                                                                </div>
                                                            </div>

                                                            <div className='drop-wrap'>
                                                                <DateRangePicker
                                                                    initialSettings={{
                                                                        showDropdowns: true,
                                                                        startDate: start.toDate(),
                                                                        endDate: end.toDate(),
                                                                        ranges: {
                                                                            Today: [moment().toDate(), moment().toDate()],
                                                                            Yesterday: [
                                                                                moment().subtract(1, 'days').toDate(),
                                                                                moment().subtract(1, 'days').toDate(),
                                                                            ],
                                                                            'Last 7 Days': [
                                                                                moment().subtract(6, 'days').toDate(),
                                                                                moment().toDate(),
                                                                            ],
                                                                            'Last 30 Days': [
                                                                                moment().subtract(29, 'days').toDate(),
                                                                                moment().toDate(),
                                                                            ],
                                                                            'This Month': [
                                                                                moment().startOf('month').toDate(),
                                                                                moment().endOf('month').toDate(),
                                                                            ],
                                                                            'Last Month': [
                                                                                moment().subtract(1, 'month').startOf('month').toDate(),
                                                                                moment().subtract(1, 'month').endOf('month').toDate(),
                                                                            ],
                                                                        },
                                                                    }}
                                                                    onCallback={handleCallback}
                                                                >
                                                                    <div id="reportrange" className="date-range-picker">
                                                                        {label}
                                                                    </div>
                                                                </DateRangePicker>
                                                            </div>

                                                        </div>

                                                        <div className="search-filter-padding pe-0">
                                                            <input
                                                                type="text"
                                                                className="form-control custom-search-input"
                                                                placeholder="Search events"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="today-list">
                                                        {/* <div className="heading">Today</div> */}
                                                        <div className="list-wrapper">
                                                            <div className="meeting-list">
                                                                <div className="meeting-list-wrapper type-3">
                                                                    <a href='#' className="d-flex main-meeting active">
                                                                        <div className="text-wrapper flex-grow-1">
                                                                            <div className="heading">
                                                                                <h5>Smart Factory Solutions</h5>
                                                                            </div>
                                                                            <ul className="category">
                                                                                <li>VR Experience </li>
                                                                            </ul>
                                                                        </div>
                                                                        <div className="date-time text-end">
                                                                            <p>10:30 | 30 mins</p>
                                                                            <p>15 Jan, 2023</p>
                                                                        </div>
                                                                    </a>
                                                                </div>

                                                                <div className="meeting-list-wrapper type-3">
                                                                    <a href='#' className="d-flex main-meeting">
                                                                        <div className="text-wrapper flex-grow-1">
                                                                            <div className="heading">
                                                                                <h5>Europe Customer Experience center</h5>
                                                                            </div>
                                                                            <ul className="category">

                                                                                <li>VR Experience </li>
                                                                            </ul>
                                                                        </div>
                                                                        <div className="date-time text-end">
                                                                            <p>10:30 | 30 mins</p>
                                                                            <p>15 Jan, 2023</p>
                                                                        </div>
                                                                    </a>
                                                                </div>


                                                                <div className="meeting-list-wrapper type-3">
                                                                    <a href='#' className="d-flex main-meeting">
                                                                        <div className="text-wrapper flex-grow-1">
                                                                            <div className="heading">
                                                                                <h5>Virtual car showroom</h5>
                                                                            </div>
                                                                            <ul className="category">

                                                                                <li>VR Experience </li>
                                                                            </ul>
                                                                        </div>
                                                                        <div className="date-time text-end">
                                                                            <p>10:30 | 30 mins</p>
                                                                            <p>15 Jan, 2023</p>
                                                                        </div>
                                                                    </a>
                                                                </div>


                                                                <div className="meeting-list-wrapper type-3">
                                                                    <a href='#' className="d-flex main-meeting">
                                                                        <div className="text-wrapper flex-grow-1">
                                                                            <div className="heading">
                                                                                <h5>Americas Customer Experience center</h5>
                                                                            </div>
                                                                            <ul className="category">

                                                                                <li>VR Experience </li>
                                                                            </ul>
                                                                        </div>
                                                                        <div className="date-time text-end">
                                                                            <p>10:30 | 30 mins</p>
                                                                            <p>15 Jan, 2023</p>
                                                                        </div>
                                                                    </a>
                                                                </div>


                                                                <div className="meeting-list-wrapper type-3">
                                                                    <a href='#' className="d-flex main-meeting">
                                                                        <div className="text-wrapper flex-grow-1">
                                                                            <div className="heading">
                                                                                <h5>Event with client </h5>
                                                                            </div>
                                                                            <ul className="category">

                                                                                <li>VR Experience </li>
                                                                            </ul>
                                                                        </div>
                                                                        <div className="date-time text-end">
                                                                            <p>10:30 | 30 mins</p>
                                                                            <p>15 Jan, 2023</p>
                                                                        </div>
                                                                    </a>
                                                                </div>


                                                                <div className="meeting-list-wrapper type-3">
                                                                    <a href='#' className="d-flex main-meeting">
                                                                        <div className="text-wrapper flex-grow-1">
                                                                            <div className="heading">
                                                                                <h5>Europe Customer Experience center</h5>
                                                                            </div>
                                                                            <ul className="category">

                                                                                <li>VR Experience </li>
                                                                            </ul>
                                                                        </div>
                                                                        <div className="date-time text-end">
                                                                            <p>10:30 | 30 mins</p>
                                                                            <p>15 Jan, 2023</p>
                                                                        </div>
                                                                    </a>
                                                                </div>



                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>
                                                {/**For Mobile, this will toggel from bottom, so add class active so that it can toggle from bottom */}
                                                <div className="meeting-data flex-grow-1 active">
                                                    {/**Remove Class on click of this button*/}
                                                    <button type="button" className="btn-close d-block d-lg-none" data-bs-dismiss="modal" aria-label="Close" />
                                                    <div className="meeting-info">
                                                        <div className="title-wra">
                                                            <h2>Smart Factory Solutions</h2>
                                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla ac metus sit amet elit porttitor pellentesque non laore</p>
                                                        </div>
                                                        <ul className="meta">
                                                            <li className='d-flex align-items-center'><Svg name="calendar" /> 23 Jan, 2023</li>
                                                            <li className='d-flex align-items-center'><Svg name="clock" /> 10:30 AM  </li>
                                                            <li className='d-flex align-items-center'><Svg name="timer-icon" /> 30 mins </li>
                                                            {/* <li className='d-flex align-items-center'><Svg name="meetings" /> 10 </li> */}
                                                        </ul>
                                                        <ul className="meta user-list-wrapper">
                                                            <li>
                                                                <span className="text">People invited </span>
                                                                <div className="user-list">
                                                                    <OverlayTrigger overlay={<Tooltip>Sam King</Tooltip>}>
                                                                        <span>SK</span>
                                                                    </OverlayTrigger>
                                                                    <OverlayTrigger overlay={<Tooltip>Iva Gone</Tooltip>}>
                                                                        <span>IG</span>
                                                                    </OverlayTrigger>
                                                                    <OverlayTrigger overlay={<Tooltip>Pam Harris</Tooltip>}>
                                                                        <span>PH</span>
                                                                    </OverlayTrigger>
                                                                    <OverlayTrigger overlay={<Tooltip>Cam King</Tooltip>}>
                                                                        <span>CK</span>
                                                                    </OverlayTrigger>
                                                                    <OverlayTrigger overlay={<Tooltip>Rose Viking</Tooltip>}>
                                                                        <span>RV</span>
                                                                    </OverlayTrigger>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                        <div className="meeting-details">
                                                            <div className="heading-top">
                                                                <h4 className='part-rg'>Participants by region</h4>
                                                            </div>
                                                            <div className="meeting-list-inner">
                                                                <span className="heading">Europe: </span>
                                                                <span className="value">04</span>
                                                            </div>
                                                            <div className="meeting-list-inner">
                                                                <span className="heading">North America: </span>
                                                                <span className="value">02 </span>
                                                            </div>
                                                            <div className="meeting-list-inner">
                                                                <span className="heading">APAC: </span>
                                                                <span className="value">02 </span>
                                                            </div>
                                                            <hr className='dark-20' />
                                                            <div className="meeting-list-inner">
                                                                <span className="heading">Host name:</span>
                                                                <span className="value">Jason Trevas</span>
                                                            </div>
                                                            <div className="meeting-list-inner">
                                                                <span className="heading">Event Type: </span>
                                                                <span className="value">VR Experience  <OverlayTrigger overlay={<Tooltip>Users can explore experiences via a VR headset</Tooltip>}><button className='btn btn-link btn-tooltip p-0 ms-2'><Svg name="tooltip-icon" /></button></OverlayTrigger></span>
                                                            </div>
                                                            <div className="meeting-list-inner">
                                                                <span className="heading">Region: </span>
                                                                <span className="value">Europe </span>
                                                            </div>
                                                            <div className="meeting-list-inner">
                                                                <span className="heading">Time zone: </span>
                                                                <span className="value">(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi </span>
                                                            </div>
                                                            <div className="meeting-list-inner">
                                                                <span className="heading">Selected experiences: </span>
                                                                <span className="value" >
                                                                    <button type="button" data-bs-toggle="modal" data-bs-target="#modalDetails" srno="1" value="1">
                                                                        Walmart: Supply chain automation pitch </button>
                                                                </span>
                                                            </div>

                                                            <div className="meeting-list-inner">
                                                                <span className="heading">Meeting ID: </span>
                                                                <span className="value">XYE 134  424</span>
                                                            </div>
                                                            <div className="meeting-list-inner">
                                                                <span className="heading">Created by: </span>
                                                                <span className="value">Robin .H</span>
                                                            </div>
                                                            <div className="meeting-list-inner">
                                                                <span className="heading">Created on: </span>
                                                                <span className="value">23 Jan, 2023</span>
                                                            </div>

                                                        </div>

                                                        <div className="button-wrapper pt-2 pt-md-4">
                                                            <button type="button" className="btn btn-primary"> Start </button>
                                                            <button type="button" className="btn btn-bordered alternate-color icon-btn"><Svg name="icon-copy" /> Copy invitation </button>
                                                            <button type="button" className="btn btn-bordered alternate-color icon-btn"><Svg name="icon-copy" /> Copy host invitation </button>


                                                            <OverlayTrigger overlay={<Tooltip>Edit</Tooltip>}>
                                                                <button type="button" className="btn btn-bordered alternate-color danger icon-btn no-text">
                                                                    <Svg name="icon-edit" />
                                                                </button>
                                                            </OverlayTrigger>
                                                            <OverlayTrigger overlay={<Tooltip>Delete</Tooltip>}>

                                                                <button
                                                                    type="button"
                                                                    className="btn btn-bordered alternate-color danger icon-btn no-text"
                                                                    data-bs-toggle="modal" data-bs-target="#deleteModal"
                                                                >
                                                                    <Svg name="icon-delete" />
                                                                </button>
                                                            </OverlayTrigger>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-pane fade" id="tab-3" role="tabpanel" aria-labelledby="tab-3-tab">
                                            <div className="meeting-pg-wrapper d-flex">
                                                <div className="meeting-list-wrap">
                                                    <div className="tab-search search-filter mb-4">

                                                        <div className="drop-wrap d-sm-flex align-items-center mb-3 list-search">
                                                            <label className="flex-grow-1">Sort By - </label>



                                                            <div className="dropdown table-dropdown ques-options">
                                                                <button className="btn dropdown-tgl" type="button" id="sort-by" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                    All Events
                                                                </button>
                                                                <div className="dropdown-menu" aria-labelledby="sort-by">
                                                                    <a className="dropdown-item" href="#"> All events </a>
                                                                    <a className="dropdown-item" href="#"> Meeting </a>
                                                                    <a className="dropdown-item" href="#"> Immersive (Guided) </a>
                                                                    <a className="dropdown-item" href="#"> Immersive (Self-guided) </a>
                                                                    <a className="dropdown-item" href="#"> VR Experience </a>
                                                                </div>
                                                            </div>

                                                            <div className='drop-wrap'>
                                                                <DateRangePicker
                                                                    initialSettings={{
                                                                        showDropdowns: true,
                                                                        startDate: start.toDate(),
                                                                        endDate: end.toDate(),
                                                                        ranges: {
                                                                            Today: [moment().toDate(), moment().toDate()],
                                                                            Yesterday: [
                                                                                moment().subtract(1, 'days').toDate(),
                                                                                moment().subtract(1, 'days').toDate(),
                                                                            ],
                                                                            'Last 7 Days': [
                                                                                moment().subtract(6, 'days').toDate(),
                                                                                moment().toDate(),
                                                                            ],
                                                                            'Last 30 Days': [
                                                                                moment().subtract(29, 'days').toDate(),
                                                                                moment().toDate(),
                                                                            ],
                                                                            'This Month': [
                                                                                moment().startOf('month').toDate(),
                                                                                moment().endOf('month').toDate(),
                                                                            ],
                                                                            'Last Month': [
                                                                                moment().subtract(1, 'month').startOf('month').toDate(),
                                                                                moment().subtract(1, 'month').endOf('month').toDate(),
                                                                            ],
                                                                        },
                                                                    }}
                                                                    onCallback={handleCallback}
                                                                >
                                                                    <div id="reportrange" className="date-range-picker">
                                                                        {label}
                                                                    </div>
                                                                </DateRangePicker>
                                                            </div>

                                                        </div>

                                                        <div className="search-filter-padding pe-0">
                                                            <input
                                                                type="text"
                                                                className="form-control custom-search-input"
                                                                placeholder="Search events"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="today-list">
                                                        {/* <div className="heading">Today</div> */}
                                                        <div className="list-wrapper">
                                                            <div className="meeting-list">
                                                                <div className="meeting-list-wrapper type-3">
                                                                    <a href='#' className="d-flex main-meeting active">
                                                                        <div className="text-wrapper flex-grow-1">
                                                                            <div className="heading">
                                                                                <h5>Smart Factory Solutions</h5>
                                                                            </div>
                                                                            <ul className="category">
                                                                                <li>VR Experience </li>
                                                                            </ul>
                                                                        </div>
                                                                        <div className="date-time text-end">
                                                                            <p>10:30 | 30 mins</p>
                                                                            <p>15 Jan, 2023</p>
                                                                        </div>
                                                                    </a>
                                                                </div>

                                                                <div className="meeting-list-wrapper type-3">
                                                                    <a href='#' className="d-flex main-meeting">
                                                                        <div className="text-wrapper flex-grow-1">
                                                                            <div className="heading">
                                                                                <h5>Europe Customer Experience center</h5>
                                                                            </div>
                                                                            <ul className="category">

                                                                                <li>VR Experience </li>
                                                                            </ul>
                                                                        </div>
                                                                        <div className="date-time text-end">
                                                                            <p>10:30 | 30 mins</p>
                                                                            <p>15 Jan, 2023</p>
                                                                        </div>
                                                                    </a>
                                                                </div>


                                                                <div className="meeting-list-wrapper type-3">
                                                                    <a href='#' className="d-flex main-meeting">
                                                                        <div className="text-wrapper flex-grow-1">
                                                                            <div className="heading">
                                                                                <h5>Virtual car showroom</h5>
                                                                            </div>
                                                                            <ul className="category">

                                                                                <li>VR Experience </li>
                                                                            </ul>
                                                                        </div>
                                                                        <div className="date-time text-end">
                                                                            <p>10:30 | 30 mins</p>
                                                                            <p>15 Jan, 2023</p>
                                                                        </div>
                                                                    </a>
                                                                </div>


                                                                <div className="meeting-list-wrapper type-3">
                                                                    <a href='#' className="d-flex main-meeting">
                                                                        <div className="text-wrapper flex-grow-1">
                                                                            <div className="heading">
                                                                                <h5>Americas Customer Experience center</h5>
                                                                            </div>
                                                                            <ul className="category">

                                                                                <li>VR Experience </li>
                                                                            </ul>
                                                                        </div>
                                                                        <div className="date-time text-end">
                                                                            <p>10:30 | 30 mins</p>
                                                                            <p>15 Jan, 2023</p>
                                                                        </div>
                                                                    </a>
                                                                </div>


                                                                <div className="meeting-list-wrapper type-3">
                                                                    <a href='#' className="d-flex main-meeting">
                                                                        <div className="text-wrapper flex-grow-1">
                                                                            <div className="heading">
                                                                                <h5>Event with client </h5>
                                                                            </div>
                                                                            <ul className="category">

                                                                                <li>VR Experience </li>
                                                                            </ul>
                                                                        </div>
                                                                        <div className="date-time text-end">
                                                                            <p>10:30 | 30 mins</p>
                                                                            <p>15 Jan, 2023</p>
                                                                        </div>
                                                                    </a>
                                                                </div>


                                                                <div className="meeting-list-wrapper type-3">
                                                                    <a href='#' className="d-flex main-meeting">
                                                                        <div className="text-wrapper flex-grow-1">
                                                                            <div className="heading">
                                                                                <h5>Europe Customer Experience center</h5>
                                                                            </div>
                                                                            <ul className="category">

                                                                                <li>VR Experience </li>
                                                                            </ul>
                                                                        </div>
                                                                        <div className="date-time text-end">
                                                                            <p>10:30 | 30 mins</p>
                                                                            <p>15 Jan, 2023</p>
                                                                        </div>
                                                                    </a>
                                                                </div>



                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/**For Mobile, this will toggel from bottom, so add class active so that it can toggle from bottom */}
                                                <div className="meeting-data flex-grow-1 active">
                                                    {/**Remove Class on click of this button*/}
                                                    <button type="button" className="btn-close d-block d-lg-none" data-bs-dismiss="modal" aria-label="Close" />
                                                    <div className="meeting-info">
                                                        <div className="title-wra">
                                                            <h2>Smart Factory Solutions</h2>
                                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla ac metus sit amet elit porttitor pellentesque non laore</p>
                                                        </div>
                                                        <ul className="meta">
                                                            <li className='d-flex align-items-center'><Svg name="calendar" /> 23 Jan, 2023</li>
                                                            <li className='d-flex align-items-center'><Svg name="clock" /> 10:30 AM  </li>
                                                            <li className='d-flex align-items-center'><Svg name="timer-icon" /> 30 mins </li>
                                                            {/* <li className='d-flex align-items-center'><Svg name="meetings" /> 10 </li> */}
                                                        </ul>
                                                        <ul className="meta user-list-wrapper">
                                                            <li>
                                                                <span className="text">People invited </span>
                                                                <div className="user-list">
                                                                    <OverlayTrigger overlay={<Tooltip>Sam King</Tooltip>}>
                                                                        <span>SK</span>
                                                                    </OverlayTrigger>
                                                                    <OverlayTrigger overlay={<Tooltip>Iva Gone</Tooltip>}>
                                                                        <span>IG</span>
                                                                    </OverlayTrigger>
                                                                    <OverlayTrigger overlay={<Tooltip>Pam Harris</Tooltip>}>
                                                                        <span>PH</span>
                                                                    </OverlayTrigger>
                                                                    <OverlayTrigger overlay={<Tooltip>Cam King</Tooltip>}>
                                                                        <span>CK</span>
                                                                    </OverlayTrigger>
                                                                    <OverlayTrigger overlay={<Tooltip>Rose Viking</Tooltip>}>
                                                                        <span>RV</span>
                                                                    </OverlayTrigger>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                        <div className="meeting-details">
                                                            <div className="heading-top">
                                                                <h4 className='part-rg'>Participants by region</h4>
                                                            </div>
                                                            <div className="meeting-list-inner">
                                                                <span className="heading">Europe: </span>
                                                                <span className="value">04</span>
                                                            </div>
                                                            <div className="meeting-list-inner">
                                                                <span className="heading">North America: </span>
                                                                <span className="value">02 </span>
                                                            </div>
                                                            <div className="meeting-list-inner">
                                                                <span className="heading">APAC: </span>
                                                                <span className="value">02 </span>
                                                            </div>
                                                            <hr className='dark-20' />
                                                            <div className="meeting-list-inner">
                                                                <span className="heading">Host name:</span>
                                                                <span className="value">Jason Trevas</span>
                                                            </div>
                                                            <div className="meeting-list-inner">
                                                                <span className="heading">Event Type: </span>
                                                                <span className="value">VR Experience  <OverlayTrigger overlay={<Tooltip>Users can explore experiences via a VR headset</Tooltip>}><button className='btn btn-link btn-tooltip p-0 ms-2'><Svg name="tooltip-icon" /></button></OverlayTrigger></span>
                                                            </div>
                                                            <div className="meeting-list-inner">
                                                                <span className="heading">Region: </span>
                                                                <span className="value">Europe </span>
                                                            </div>
                                                            <div className="meeting-list-inner">
                                                                <span className="heading">Time zone: </span>
                                                                <span className="value">(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi </span>
                                                            </div>
                                                            <div className="meeting-list-inner">
                                                                <span className="heading">Selected experiences: </span>
                                                                <span className="value" >
                                                                    <button type="button" data-bs-toggle="modal" data-bs-target="#modalDetails" srno="1" value="1">
                                                                        Walmart: Supply chain automation pitch </button>
                                                                </span>
                                                            </div>

                                                            <div className="meeting-list-inner">
                                                                <span className="heading">Meeting ID: </span>
                                                                <span className="value">XYE 134  424</span>
                                                            </div>
                                                            <div className="meeting-list-inner">
                                                                <span className="heading">Created by: </span>
                                                                <span className="value">Robin .H</span>
                                                            </div>
                                                            <div className="meeting-list-inner">
                                                                <span className="heading">Created on: </span>
                                                                <span className="value">23 Jan, 2023</span>
                                                            </div>

                                                        </div>

                                                        <div className="button-wrapper pt-2 pt-md-4">
                                                            <button type="button" className="btn btn-primary"> Start </button>
                                                            <button type="button" className="btn btn-bordered alternate-color icon-btn"><Svg name="icon-copy" /> Copy invitation </button>
                                                            <button type="button" className="btn btn-bordered alternate-color icon-btn"><Svg name="icon-copy" /> Copy host invitation </button>


                                                            <OverlayTrigger overlay={<Tooltip>Edit</Tooltip>}>
                                                                <button type="button" className="btn btn-bordered alternate-color danger icon-btn no-text">
                                                                    <Svg name="icon-edit" />
                                                                </button>
                                                            </OverlayTrigger>
                                                            <OverlayTrigger overlay={<Tooltip>Delete</Tooltip>}>

                                                                <button
                                                                    type="button"
                                                                    className="btn btn-bordered alternate-color danger icon-btn no-text"
                                                                    data-bs-toggle="modal" data-bs-target="#deleteModal"
                                                                >
                                                                    <Svg name="icon-delete" />
                                                                </button>
                                                            </OverlayTrigger>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <CreateEvent />
        </>
    )
}
