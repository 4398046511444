import React from 'react'
import { Link } from 'react-router-dom'
import DashboardHeader from '../common/DashboardHeader'
import DashboardSidebar from '../common/DashboardSidebar'
import ScheduleHeader from './ScheduleHeader'
import Svg from '../common/Svg'

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

export default function Share() {
    return (
        <>
            <div className='d-flex flex-column main-outer-wrapper main-site-wrapper'>
                <DashboardHeader />
                <div id="viewport" className="d-flex flex-grow-1">
                    <DashboardSidebar />
                    <div className="flex-grow-1 page-content-flex">
                        <ScheduleHeader />
                        <div className="page-content-wrap">
                            <div className="heading-section page-heading mb-3">
                                <h1>Share</h1>
                            </div>
                            <div className="share-wrapper">
                                <div className="share-list">
                                    <div className="share-inner align-items-start">
                                        <div className="title">Topic:</div>
                                        <div className="data ps-md-3 ps-0">
                                            <span>Smart Factory Solutions</span>
                                            <p>Enterprise level product demo and solutions</p>
                                        </div>
                                    </div>

                                </div>
                                <div className="share-list">
                                    <div className="share-inner">
                                        <div className="title">Date:</div>
                                        <div className="data ps-md-3 ps-0">Jan 01, 2023</div>
                                    </div>
                                    <div className="share-inner">
                                        <div className="title">Time:</div>
                                        <div className="data ps-md-3 ps-0">03:00 PM</div>
                                    </div>
                                    <div className="share-inner">
                                        <div className="title">Timezone:</div>
                                        <div className="data ps-md-3 ps-0">(GMT+05:30) Chennai, Kolkata</div>
                                    </div>

                                </div>
                                <div className="share-list">
                                    <div className="share-inner">
                                        <div className="title">Event ID:</div>
                                        <div className="data ps-md-3 ps-0">XYE 134  424</div>
                                    </div>
                                    <div className="share-inner">
                                        <div className="title">Passcode:</div>
                                        <div className="data ps-md-3 ps-0">232#DFs</div>
                                    </div>
                                </div>
                                <div className="share-list">
                                    <div className="share-inner">
                                        <div className="title"> Copy Link: </div>


                                        <div className="data d-flex align-items-center ps-md-3 ps-0">
                                            <div className="form-group mb-0">
                                                <input type="text" className="form-control" defaultValue={'https://vmersive.meet/pwf-upyr-gec?hs='} readOnly />
                                            </div>
                                            <OverlayTrigger overlay={<Tooltip>Copy</Tooltip>}>
                                                <button className="action-btn copy-link">
                                                    <Svg name="icon-copy-3" />
                                                </button>
                                            </OverlayTrigger>


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
