import React from 'react'
import { Link } from 'react-router-dom'

export default function HeaderContentPages(props) {
    return (
        <div className='header-menu-content'>
            <ul>
                <li>
                    <Link to="/privacy-policies" className={props.privacyPolicy ? "active" : ""}>Privacy policy</Link>
                </li>
                <li>
                    <Link to="/terms" className={props.terms ? "active" : ""} >Terms of service</Link>
                </li>
                <li>
                    <Link to="/cookie-policy"  className={props.cookiePolicy ? "active" : ""}>Cookie policy</Link>
                </li>
            </ul>
        </div>
    )
}
