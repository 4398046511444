import axios from "../utils/axios";
import { BASE_URL } from "./urls";

export const getToken = async () => {
  const response = await axios({
    method: "GET",
    url: `${BASE_URL}/auth/getPubToken`,
  });
  if (response.data) {
    localStorage.setItem("token", response?.data?.token);
    axios.defaults.headers.common["Authorization"] = response?.data?.token;
  }
  return response.data;
};
export const login = async (payload, token) => {
  const response = await axios({
    headers: { Authorization: `Bearer ${token}` },
    method: "POST",
    url: `${BASE_URL}/auth/checkLogin`,
    data: payload,
  });
  if (response.data) {
    localStorage.setItem("token", response?.data?.token);
    axios.defaults.headers.common["Authorization"] = response?.data?.token;
  }
  return response.data;
};

export const changePassword = async (payload) => {
  console.log("asd",`${BASE_URL}/users/changePassword`);
  const token = localStorage.getItem("token");

  const response = await axios({
    headers: { Authorization: `Bearer ${token}` },
    method: "POST",
    url: `${BASE_URL}/users/changePassword`,
    data: payload,
  });
  return response.data;
};
