import React from 'react'
import DashboardHeader from '../common/DashboardHeader'
import DashboardSidebar from '../common/DashboardSidebar'
import ScheduleHeader from './ScheduleHeader'
import { Link } from 'react-router-dom'
import template1 from "../../assets/images/template-1.jpg"
import template2 from "../../assets/images/template-2.jpg"
import template3 from "../../assets/images/template-3.jpg"
import template4 from "../../assets/images/template-4.jpg"
import TemplateDetailsModal from '../common/TemplateDetailsModal'
import Svg from '../common/Svg'
export default function ChooseTemplate() {

    return (
        <>
            <div className='d-flex flex-column main-outer-wrapper main-site-wrapper '>
                <DashboardHeader />
                <div id="viewport" className="d-flex flex-grow-1">
                    <DashboardSidebar />
                    <div className="flex-grow-1 page-content-flex">
                        <ScheduleHeader />
                        <div className="page-content-wrap">

                            <div className="heading-section page-heading d-md-flex align-items-center justify-content-between">
                                <div className="left-right">
                                    <h1>Experiences</h1>
                                    <p>Select an experience or <a data-bs-toggle="modal" data-bs-target="#leaveScheduling" className='text-primary cursor-pointer text-deco'>create a new experience</a></p>
                                </div>
                                <div className="right-wrap mt-3 mt-md-0">
                                    <Link to="/immersive-guided/invite-participants" type="button" className="btn btn-primary add-btn"> Invite participants <Svg name="right-arrow" /> </Link>
                                </div>
                            </div>


                            <div className="exp-button-wrapper-outer">
                                <div className="nav-tabs-wrapper vmersive-tabs align-items-center">
                                    <ul className="nav nav-tabs" id="meetings" role="tablist">

                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link active" id="tab-2-tab" data-bs-toggle="tab" data-bs-target="#tab-2" type="button" role="tab" aria-controls="tab-2" aria-selected="false">Saved Experiences</button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link" id="tab-3-tab" data-bs-toggle="tab" data-bs-target="#tab-3" type="button" role="tab" aria-controls="tab-3" aria-selected="false">Past Meeting Experiences</button>
                                        </li>
                                    </ul>

                                </div>
                                <div className="page-wrapper">
                                    <div className="tab-content" id="meetingsContent">

                                        <div className="tab-pane fade show active" id="tab-2" role="tabpanel" aria-labelledby="tab-2-tab">
                                            <div className="templates-wrapper">
                                                <div className="templates-row row">
                                                    <div className="col-xxl-3 col-xl-4 col-md-6 mb-3">
                                                        <div className="template-card">
                                                            <div className="img-wrap">
                                                                <img src={template1} className='img-fluid' alt="" />
                                                                <input className="form-check-input" type="checkbox" id="template1" name="chooseTemplate" />
                                                                <div className="d-flex align-items-center button-wrap">
                                                                    <button className='btn btn-primary' data-bs-toggle="modal" data-bs-target="#modalDetails" srno="1" value="1">View details</button>
                                                                </div>

                                                            </div>
                                                            <div className="text-wrapper">
                                                                <h5 title="Meeting with suburban client">Meeting with suburban client</h5>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-xxl-3 col-xl-4 col-md-6 mb-3">
                                                        <div className="template-card">
                                                            <div className="img-wrap">
                                                                <img src={template2} className='img-fluid' alt="" />
                                                                <input className="form-check-input" type="checkbox" id="template1" name="chooseTemplate" />

                                                                <div className="d-flex align-items-center button-wrap">
                                                                    <button className='btn btn-primary' data-bs-toggle="modal" data-bs-target="#modalDetails" srno="1" value="1">View details</button>
                                                                </div>
                                                            </div>
                                                            <div className="text-wrapper">
                                                                <h5 title="Meeting with suburban client">Meeting with suburban client</h5>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-xxl-3 col-xl-4 col-md-6 mb-3">
                                                        <div className="template-card">
                                                            <div className="img-wrap">
                                                                <img src={template3} className='img-fluid' alt="" />
                                                                <input className="form-check-input" type="checkbox" id="template1" name="chooseTemplate" />

                                                                <div className="d-flex align-items-center button-wrap">
                                                                    <button className='btn btn-primary' data-bs-toggle="modal" data-bs-target="#modalDetails" srno="1" value="1">View details</button>
                                                                </div>
                                                            </div>
                                                            <div className="text-wrapper">
                                                                <h5 title="Meeting with suburban client">Meeting with suburban client</h5>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-xxl-3 col-xl-4 col-md-6 mb-3">
                                                        <div className="template-card">
                                                            <div className="img-wrap">
                                                                <img src={template4} className='img-fluid' alt="" />
                                                                <input className="form-check-input" type="checkbox" id="template1" name="chooseTemplate" />

                                                                <div className="d-flex align-items-center button-wrap">
                                                                    <button className='btn btn-primary' data-bs-toggle="modal" data-bs-target="#modalDetails" srno="1" value="1">View details</button>
                                                                </div>
                                                            </div>
                                                            <div className="text-wrapper">
                                                                <h5 title="Meeting with suburban client">Meeting with suburban client</h5>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-pane fade" id="tab-3" role="tabpanel" aria-labelledby="tab-3-tab">
                                            <div className="templates-wrapper">

                                                <div className="templates-row row">
                                                    <div className="col-xxl-3 col-xl-4 col-md-6 mb-3">
                                                        <div className="template-card">
                                                            <div className="img-wrap">
                                                                <img src={template1} className='img-fluid' alt="" />
                                                                <input className="form-check-input" type="checkbox" id="template1" name="chooseTemplate" />
                                                                <div className="d-flex align-items-center button-wrap">
                                                                    <button className='btn btn-primary' data-bs-toggle="modal" data-bs-target="#modalDetails" srno="1" value="1">View details</button>
                                                                </div>

                                                            </div>
                                                            <div className="text-wrapper">
                                                                <h5 title="Meeting with suburban client">Meeting with suburban client</h5>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-xxl-3 col-xl-4 col-md-6 mb-3">
                                                        <div className="template-card">
                                                            <div className="img-wrap">
                                                                <img src={template2} className='img-fluid' alt="" />
                                                                <input className="form-check-input" type="checkbox" id="template1" name="chooseTemplate" />

                                                                <div className="d-flex align-items-center button-wrap">
                                                                    <button className='btn btn-primary' data-bs-toggle="modal" data-bs-target="#modalDetails" srno="1" value="1">View details</button>
                                                                </div>
                                                            </div>
                                                            <div className="text-wrapper">
                                                                <h5 title="Meeting with suburban client">Meeting with suburban client</h5>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-xxl-3 col-xl-4 col-md-6 mb-3">
                                                        <div className="template-card">
                                                            <div className="img-wrap">
                                                                <img src={template3} className='img-fluid' alt="" />
                                                                <input className="form-check-input" type="checkbox" id="template1" name="chooseTemplate" />

                                                                <div className="d-flex align-items-center button-wrap">
                                                                    <button className='btn btn-primary' data-bs-toggle="modal" data-bs-target="#modalDetails" srno="1" value="1">View details</button>
                                                                </div>
                                                            </div>
                                                            <div className="text-wrapper">
                                                                <h5 title="Meeting with suburban client">Meeting with suburban client</h5>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-xxl-3 col-xl-4 col-md-6 mb-3">
                                                        <div className="template-card">
                                                            <div className="img-wrap">
                                                                <img src={template4} className='img-fluid' alt="" />
                                                                <input className="form-check-input" type="checkbox" id="template1" name="chooseTemplate" />

                                                                <div className="d-flex align-items-center button-wrap">
                                                                    <button className='btn btn-primary' data-bs-toggle="modal" data-bs-target="#modalDetails" srno="1" value="1">View details</button>
                                                                </div>
                                                            </div>
                                                            <div className="text-wrapper">
                                                                <h5 title="Meeting with suburban client">Meeting with suburban client</h5>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <TemplateDetailsModal />



            <div className="customvar-modal modal custom-modal fade deleteModal" id="leaveScheduling" tabIndex={-1} aria-labelledby="deleteModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className={"formvawrapper delete-modal-wrap modal-setting-medium"}>
                                <div className={"formstyle p-md-2"} >
                                    <div className="delete-body-wrap delete-language">
                                        <div className="heading">
                                            <h3>Leave Scheduling an event?</h3>
                                        </div>
                                        <div className="text-normal">
                                            <p>To create a new experience you have to leave schedule meeting page, all your unsaved data will be be lost.</p>
                                        </div>
                                        <div className="button-wrap d-flex justify-content-end">
                                            <button type="button" className="btn btn-primary">Leave </button>
                                            <button type="button" className="btn btn-bordered color-cancel ms-3" data-bs-dismiss="modal">Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            </div >
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}