import React from 'react'
import { Link } from 'react-router-dom'

export default function ScheduleHeader() {
    return (
        <div className="meeting-header">
            <ul>
                <li><Link to="/vr-experience/schedule" className='active' content-attr="Schedule meeting">Schedule VR Event</Link></li>
                <li><Link to="/vr-experience/select-experience" content-attr="Select experience">Select experience</Link></li>
                <li><Link to="/vr-experience/invite-participants" content-attr="Invite participants">Invite participants</Link></li>
                <li><Link to="/vr-experience/share" content-attr="Share">Share</Link></li>
            </ul>
        </div>
    )
}
