import React, { useState, forwardRef } from 'react'
import Svg from './Svg'
import templateImg from "../../assets/images/image-airport.jpg"
import { ReactSortable } from "react-sortablejs";

const CustomComponent = forwardRef < HTMLDivElement > ((props, ref) => {
    return <div ref={ref}>{props.children}</div>;
});


export default function CustomizeTemplateArea() {
    const [list1, setState] = useState([
        { id: 1, name: "Launch point" },
        { id: 2, name: "Entrance" },
        { id: 3, name: "Runway" },
        { id: 4, name: "Main Entrance" },
        { id: 5, name: "Asset tracking" },
    ]);
    return (
        <>
            <div className="tab-3-wrapper canvas-tabs">
                <div className="nav-tabs-wrapper vmersive-tabs mb-4">
                    <ul className="nav nav-tabs" id="meetings" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button className="nav-link active" id="tab-1-tab" data-bs-toggle="tab" data-bs-target="#tab-1" type="button" role="tab" aria-controls="tab-1" aria-selected="true">Airport entrance </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="tab-2-tab" data-bs-toggle="tab" data-bs-target="#tab-2" type="button" role="tab" aria-controls="tab-2" aria-selected="false">Experience center</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="tab-3-tab" data-bs-toggle="tab" data-bs-target="#tab-3" type="button" role="tab" aria-controls="tab-3" aria-selected="false">Event room</button>
                        </li>
                    </ul>
                </div>
                <div className="page-wrapper">
                    <div className="tab-content" id="meetingsContent">
                        <div className="tab-pane fade show active" id="tab-1" role="tabpanel" aria-labelledby="tab-1-tab">
                            <div className="d-flex customize-wrapper">
                                <div className="left-side">
                                    <div className="accordion customize-sidebar" id="tab-1-accordionCustomize">


                                        <ReactSortable animation={200} delayOnTouchStart={true} delay={2} tag={CustomComponent} list={list1} setList={setState}>
                                            {list1.map((item) => (

                                                <div className="accordion-item" key={item.id}>
                                                    <div className="accordion-header collapsed" id={"tab-1-heading1" + item.name}>
                                                        <div className="accordion-icons d-flex align-items-center">
                                                            <button className="sort-button">
                                                                <Svg name="sort-icon" />
                                                            </button>
                                                            <div className="form-checks">
                                                                <input type="checkbox" className="form-check-input" id={"hotspot1" + item.id} />
                                                            </div>
                                                        </div>
                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={"#tab-1-collapse" + item.id} aria-expanded="true" aria-controls={"collapse" + item.id}>
                                                            {item.name}
                                                        </button>
                                                    </div>
                                                    <div id={"tab-1-collapse" + item.id} className="accordion-collapse collapse" aria-labelledby={"heading" + item.id} data-bs-parent="#tab-1-accordionCustomize">
                                                        <div className="accordion-body">
                                                            <ul className="accordion-checklist">
                                                                <li className='check-list d-flex align-items-center active'>
                                                                    <div className="flex-grow-1 check-wrap">
                                                                        <div className="form-check">
                                                                            <input className="form-check-input" type="checkbox" defaultValue id={"tab1check" + item.id} />
                                                                            <label className="form-check-label" htmlFor={"tab1check" + item.id}>
                                                                                Media spot 1
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="action-buttons d-flex align-items-center">
                                                                        <button className='action-btn share'>
                                                                            <Svg name="share-icon" />
                                                                        </button>
                                                                        <button className='action-btn delete' data-bs-toggle="modal" data-bs-target="#deleteModal">
                                                                            <Svg name="trash-icon" />
                                                                        </button>

                                                                    </div>
                                                                </li>
                                                                <li className='check-list d-flex align-items-center'>
                                                                    <div className="flex-grow-1 check-wrap">
                                                                        <div className="form-check">
                                                                            <input className="form-check-input" type="checkbox" defaultValue id="tab1check2" />
                                                                            <label className="form-check-label" htmlFor="tab1check2">
                                                                                Media spot 2
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="action-buttons d-flex align-items-center">
                                                                        <button className='action-btn share'>
                                                                            <Svg name="share-icon" />
                                                                        </button>
                                                                        <button className='action-btn delete' data-bs-toggle="modal" data-bs-target="#deleteModal">
                                                                            <Svg name="trash-icon" />
                                                                        </button>

                                                                    </div>
                                                                </li>
                                                            </ul>
                                                            {/** For Mobile, the Media will show here */}
                                                            <div className="customize-wrap-inner mt-3 d-block d-lg-none">
                                                                <div className="img-wrap">
                                                                    <img src={templateImg} alt="" className='img-fluid w-100' />
                                                                </div>
                                                                <div className="cust-wrap d-flex flex-wrap align-items-center">
                                                                    <div className="img-data d-flex align-items-center">
                                                                        <div className="icon-wrap">
                                                                            <Svg name="media" />
                                                                        </div>
                                                                        <div className="flex-grow-1">
                                                                            <p className='m-0'>Factory banner.jpg</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="change-button">

                                                                        <button type="button" className="btn btn-bordered" data-bs-toggle="modal" data-bs-target="#fileUpload" srno="1" value="1"> Change media </button>

                                                                    </div>
                                                                    <div className="copy-link-button">
                                                                        <button type="button" className="btn btn-bordered alternate-color icon-btn"><Svg name="eye-icon" /> Preview media </button>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            ))}
                                        </ReactSortable>

                                    </div>
                                </div>
                                <div className="main-customize flex-grow-1 d-none d-lg-block d-none d-lg-block">
                                    <div className="customize-wrap-inner">
                                        <div className="img-wrap">
                                            <img src={templateImg} alt="" className='img-fluid w-100' />
                                        </div>
                                        <div className="cust-wrap d-flex flex-wrap align-items-center">
                                            <div className="img-data d-flex align-items-center">
                                                <div className="icon-wrap">
                                                    <Svg name="media" />
                                                </div>
                                                <div className="flex-grow-1">
                                                    <p className='m-0'>Factory banner.jpg</p>
                                                </div>
                                            </div>
                                            <div className="change-button">

                                                <button type="button" className="btn btn-bordered" data-bs-toggle="modal" data-bs-target="#fileUpload" srno="1" value="1"> Change media </button>

                                            </div>
                                            <div className="copy-link-button">
                                                <button type="button" className="btn btn-bordered alternate-color icon-btn"><Svg name="eye-icon" /> Preview media </button>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="tab-pane fade" id="tab-2" role="tabpanel" aria-labelledby="tab-2-tab">
                            <div className="d-flex customize-wrapper">
                                <div className="left-side">
                                    <div className="accordion customize-sidebar" id="tab-2-accordionCustomize">

                                        <ReactSortable animation={200} delayOnTouchStart={true} delay={2} tag={CustomComponent} list={list1} setList={setState}>
                                            {list1.map((item) => (

                                                <div className="accordion-item" key={item.id}>
                                                    <div className="accordion-header collapsed" id={"tab-2-heading1" + item.name}>
                                                        <div className="accordion-icons d-flex align-items-center">
                                                            <button className="sort-button">
                                                                <Svg name="sort-icon" />
                                                            </button>
                                                            <div className="form-checks">
                                                                <input type="checkbox" className="form-check-input" id={"hotspot1" + item.id} />
                                                            </div>
                                                        </div>
                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={"#tab-2-collapse" + item.id} aria-expanded="true" aria-controls={"collapse" + item.id}>
                                                            {item.name}
                                                        </button>
                                                    </div>
                                                    <div id={"tab-2-collapse" + item.id} className="accordion-collapse collapse" aria-labelledby={"heading" + item.id} data-bs-parent="#tab-2-accordionCustomize">
                                                        <div className="accordion-body">
                                                            <ul className="accordion-checklist">
                                                                <li className='check-list d-flex align-items-center active'>
                                                                    <div className="flex-grow-1 check-wrap">
                                                                        <div className="form-check">
                                                                            <input className="form-check-input" type="checkbox" defaultValue id={"tab2check" + item.id} />
                                                                            <label className="form-check-label" htmlFor={"tab2check" + item.id}>
                                                                                Media spot 1
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="action-buttons d-flex align-items-center">
                                                                        <button className='action-btn share'>
                                                                            <Svg name="share-icon" />
                                                                        </button>
                                                                        <button className='action-btn delete' data-bs-toggle="modal" data-bs-target="#deleteModal">
                                                                            <Svg name="trash-icon" />
                                                                        </button>

                                                                    </div>
                                                                </li>
                                                                <li className='check-list d-flex align-items-center'>
                                                                    <div className="flex-grow-1 check-wrap">
                                                                        <div className="form-check">
                                                                            <input className="form-check-input" type="checkbox" defaultValue id="tab2check2" />
                                                                            <label className="form-check-label" htmlFor="tab2check2">
                                                                                Media spot 2
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="action-buttons d-flex align-items-center">
                                                                        <button className='action-btn share'>
                                                                            <Svg name="share-icon" />
                                                                        </button>
                                                                        <button className='action-btn delete' data-bs-toggle="modal" data-bs-target="#deleteModal">
                                                                            <Svg name="trash-icon" />
                                                                        </button>

                                                                    </div>
                                                                </li>
                                                            </ul>
                                                            {/** For Mobile, the Media will show here */}
                                                            <div className="customize-wrap-inner mt-3 d-block d-lg-none">
                                                                <div className="img-wrap">
                                                                    <img src={templateImg} alt="" className='img-fluid w-100' />
                                                                </div>
                                                                <div className="cust-wrap d-flex flex-wrap align-items-center">
                                                                    <div className="img-data d-flex align-items-center">
                                                                        <div className="icon-wrap">
                                                                            <Svg name="media" />
                                                                        </div>
                                                                        <div className="flex-grow-1">
                                                                            <p className='m-0'>Factory banner.jpg</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="change-button">

                                                                        <button type="button" className="btn btn-bordered" data-bs-toggle="modal" data-bs-target="#fileUpload" srno="1" value="1"> Change media </button>

                                                                    </div>
                                                                    <div className="copy-link-button">
                                                                        <button type="button" className="btn btn-bordered alternate-color icon-btn"><Svg name="eye-icon" /> Preview media </button>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            ))}
                                        </ReactSortable>

                                    </div>
                                </div>
                                <div className="main-customize flex-grow-1 d-none d-lg-block">
                                    <div className="customize-wrap-inner">
                                        <div className="img-wrap">
                                            <img src={templateImg} alt="" className='img-fluid w-100' />
                                        </div>
                                        <div className="cust-wrap d-flex flex-wrap align-items-center">
                                            <div className="img-data d-flex align-items-center">
                                                <div className="icon-wrap">
                                                    <Svg name="media" />
                                                </div>
                                                <div className="flex-grow-1">
                                                    <p className='m-0'>Factory banner.jpg</p>
                                                </div>
                                            </div>
                                            <div className="change-button">

                                                <button type="button" className="btn btn-bordered" data-bs-toggle="modal" data-bs-target="#fileUpload" srno="1" value="1"> Change media </button>

                                            </div>
                                            <div className="copy-link-button">
                                                <button type="button" className="btn btn-bordered alternate-color icon-btn"><Svg name="eye-icon" /> Preview media </button>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="tab-pane fade" id="tab-3" role="tabpanel" aria-labelledby="tab-3-tab">
                            <div className="d-flex customize-wrapper">
                                <div className="left-side">
                                    <div className="accordion customize-sidebar" id="tab-3-accordionCustomize">
                                        <ReactSortable animation={200} delayOnTouchStart={true} delay={2} tag={CustomComponent} list={list1} setList={setState}>
                                            {list1.map((item) => (

                                                <div className="accordion-item" key={item.id}>
                                                    <div className="accordion-header collapsed" id={"tab-3-heading1" + item.name}>
                                                        <div className="accordion-icons d-flex align-items-center">
                                                            <button className="sort-button">
                                                                <Svg name="sort-icon" />
                                                            </button>
                                                            <div className="form-checks">
                                                                <input type="checkbox" className="form-check-input" id={"hotspot1" + item.id} />
                                                            </div>
                                                        </div>
                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={"#tab-3-collapse" + item.id} aria-expanded="true" aria-controls={"collapse" + item.id}>
                                                            {item.name}
                                                        </button>
                                                    </div>
                                                    <div id={"tab-3-collapse" + item.id} className="accordion-collapse collapse" aria-labelledby={"heading" + item.id} data-bs-parent="#tab-3-accordionCustomize">
                                                        <div className="accordion-body">
                                                            <ul className="accordion-checklist">
                                                                <li className='check-list d-flex align-items-center active'>
                                                                    <div className="flex-grow-1 check-wrap">
                                                                        <div className="form-check">
                                                                            <input className="form-check-input" type="checkbox" defaultValue id={"tab3check" + item.id} />
                                                                            <label className="form-check-label" htmlFor={"tab3check" + item.id}>
                                                                                Media spot 1
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="action-buttons d-flex align-items-center">
                                                                        <button className='action-btn share'>
                                                                            <Svg name="share-icon" />
                                                                        </button>
                                                                        <button className='action-btn delete' data-bs-toggle="modal" data-bs-target="#deleteModal">
                                                                            <Svg name="trash-icon" />
                                                                        </button>

                                                                    </div>
                                                                </li>
                                                                <li className='check-list d-flex align-items-center'>
                                                                    <div className="flex-grow-1 check-wrap">
                                                                        <div className="form-check">
                                                                            <input className="form-check-input" type="checkbox" defaultValue id="tab3check2" />
                                                                            <label className="form-check-label" htmlFor="tab3check2">
                                                                                Media spot 2
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="action-buttons d-flex align-items-center">
                                                                        <button className='action-btn share'>
                                                                            <Svg name="share-icon" />
                                                                        </button>
                                                                        <button className='action-btn delete' data-bs-toggle="modal" data-bs-target="#deleteModal">
                                                                            <Svg name="trash-icon" />
                                                                        </button>

                                                                    </div>
                                                                </li>
                                                            </ul>
                                                            {/** For Mobile, the Media will show here */}
                                                            <div className="customize-wrap-inner mt-3 d-block d-lg-none">
                                                                <div className="img-wrap">
                                                                    <img src={templateImg} alt="" className='img-fluid w-100' />
                                                                </div>
                                                                <div className="cust-wrap d-flex flex-wrap align-items-center">
                                                                    <div className="img-data d-flex align-items-center">
                                                                        <div className="icon-wrap">
                                                                            <Svg name="media" />
                                                                        </div>
                                                                        <div className="flex-grow-1">
                                                                            <p className='m-0'>Factory banner.jpg</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="change-button">

                                                                        <button type="button" className="btn btn-bordered" data-bs-toggle="modal" data-bs-target="#fileUpload" srno="1" value="1"> Change media </button>

                                                                    </div>
                                                                    <div className="copy-link-button">
                                                                        <button type="button" className="btn btn-bordered alternate-color icon-btn"><Svg name="eye-icon" /> Preview media </button>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            ))}
                                        </ReactSortable>
                                    </div>
                                </div>
                                <div className="main-customize flex-grow-1 d-none d-lg-block">
                                    <div className="customize-wrap-inner">
                                        <div className="img-wrap">
                                            <img src={templateImg} alt="" className='img-fluid w-100' />
                                        </div>
                                        <div className="cust-wrap d-flex flex-wrap align-items-center">
                                            <div className="img-data d-flex align-items-center">
                                                <div className="icon-wrap">
                                                    <Svg name="media" />
                                                </div>
                                                <div className="flex-grow-1">
                                                    <p className='m-0'>Factory banner.jpg</p>
                                                </div>
                                            </div>
                                            <div className="change-button">

                                                <button type="button" className="btn btn-bordered" data-bs-toggle="modal" data-bs-target="#fileUpload" srno="1" value="1"> Change media </button>

                                            </div>
                                            <div className="copy-link-button">
                                                <button type="button" className="btn btn-bordered alternate-color icon-btn"><Svg name="eye-icon" /> Preview media </button>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>



                    </div>
                </div>
            </div>



            <div className="d-flex align-items-center custom-off-canvas canvas-wrap">

                <div className="checks-wrap">
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" id="enableAll" />
                        <label className="form-check-label" htmlFor="enableAll">
                            Enable all
                        </label>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" id="default" defaultChecked />
                        <label className="form-check-label" htmlFor="default">
                            Set as default landing template
                        </label>
                    </div>
                </div>
            </div>
        </>
    )
}
