import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  getArchivedExperienceList,
  getExperienceList,
  getPastExperienceList,
  moveToArchived,
} from "../../actions/experience";
import template1 from "../../assets/images/template-1.jpg";
import TemplateDetailsModal from "./TemplateDetailsModal";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/ReactToastify.min.css";

import ContentLoader from "react-content-loader";
import Pagination from "./Pagination";
import Svg from "./Svg";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

const Loader = (props) => (
  <ContentLoader viewBox="0 0 100% 100%" height={600} width="100%" {...props}>
    <rect x="0" y="20" rx="0" ry="0" width="380" height="240" />
    <rect x="0" y="270" rx="0" ry="0" width="380" height="23" />
    <rect x="0" y="300" rx="0" ry="0" width="380" height="23" />
    <rect x="410" y="20" rx="0" ry="0" width="380" height="240" />
    <rect x="410" y="270" rx="0" ry="0" width="380" height="23" />
    <rect x="410" y="300" rx="0" ry="0" width="380" height="23" />
    <rect x="820" y="20" rx="0" ry="0" width="380" height="240" />
    <rect x="820" y="270" rx="0" ry="0" width="380" height="23" />
    <rect x="820" y="300" rx="0" ry="0" width="380" height="23" />
    <rect x="1220" y="20" rx="0" ry="0" width="380" height="240" />
    <rect x="1220" y="270" rx="0" ry="0" width="380" height="23" />
    <rect x="1220" y="300" rx="0" ry="0" width="380" height="23" />
  </ContentLoader>
);

const moment = require("moment");

export default function TemplateList() {
  const navigate = useNavigate();

  const [experienceList, setExperienceList] = useState([]);
  const [pastExperienceList, setPastExperienceList] = useState([]);
  const [archivedExperienceList, setArchivedExperienceList] = useState([]);

  const [totalPage, setTotalPage] = useState(0);

  const [currentPage, setCurrentPage] = useState(1);

  const [fetchingExperienceList, setFetchingExperienceList] = useState(false);
  const [serverParams, setServerParams] = useState({
    startingLimit: 0,
    resultsPerPage: 8,
    search: "",
    sort_by: "name",
    sort_order: "asc",
  });

  const [searchExperience, setSarchExperience] = useState("");
  const [sortValue, setSortValue] = useState("Recently added");
  const [currentTabId, setCurrentTabId] = useState(1);

  const [isMovingToArchive, setIsMovingToArchive] = useState(false);

  const sortingOptions = [
    { id: 1, value: "asc", label: "Sort A-Z" },
    { id: 2, value: "desc", label: "Sort Z-A" },
  ];

  const tabs = [
    { id: 1, label: "Saved Experiences" },
    { id: 2, label: "Past Event Experiences" },
    { id: 3, label: "Archived" },
  ];

  const changeTab = (tabId) => {
    setSarchExperience("")
    setCurrentTabId(tabId);
    setTotalPage(0);
    setCurrentPage(1);
    setSortValue("Recently added");
    setServerParams({
      startingLimit: 0,
      resultsPerPage: 8,
      search: "",
      sort_by: "name",
      sort_order: "asc",
    });
    if (tabId === 1) {
      setTotalPage(0);
      fetchExperienceList({
        startingLimit: 0,
        resultsPerPage: 8,
        search: "",
        sort_by: "name",
        sort_order: "asc",
      });
    }
    if (tabId === 2) {
      fetchPastExperienceList({
        startingLimit: 0,
        resultsPerPage: 8,
        search: "",
        sort_by: "name",
        sort_order: "asc",
      });
    }
    if (tabId === 3) {
      setTotalPage(0);
      fetchArchivedExperienceList({
        startingLimit: 0,
        resultsPerPage: 8,
        search: "",
        sort_by: "name",
        sort_order: "asc",
      });
    }
  };

  const fetchArchivedExperienceList = async (serverParams) => {
    setFetchingExperienceList(true);
    try {
      const archivedListResponse = await getArchivedExperienceList(
        serverParams
      );
      if (archivedListResponse.statusCode === 200) {
        setArchivedExperienceList(archivedListResponse.data);
        let RoundedTotal = Math.ceil(
          archivedListResponse.total_count / serverParams.resultsPerPage
        );
        setTotalPage(RoundedTotal);
      }
    } catch (error) {
      toast.error(error.response.data.msg);
    } finally {
      setFetchingExperienceList(false);
    }
  };

  const fetchPastExperienceList = async (serverParams) => {
    setFetchingExperienceList(true);
    try {
      const pastExperienceListResponse = await getPastExperienceList(
        serverParams
      );
      if (pastExperienceListResponse.statusCode === 200) {
        setPastExperienceList(pastExperienceListResponse.data);
        let RoundedTotal = Math.ceil(
          pastExperienceListResponse.total_count / serverParams.resultsPerPage
        );
        setTotalPage(RoundedTotal);
      }
    } catch (error) {
      toast.error(error.response.data.msg);
    } finally {
      setFetchingExperienceList(false);
    }
  };

  const fetchExperienceList = async (serverParams) => {
    setFetchingExperienceList(true);
    try {
      const experienceListResponse = await getExperienceList(serverParams);
      if (experienceListResponse.statusCode === 200) {
        setExperienceList(experienceListResponse.data);
        let RoundedTotal = Math.ceil(
          experienceListResponse.total_count / serverParams.resultsPerPage
        );
        setTotalPage(RoundedTotal);
      }
    } catch (error) {
      toast.error(error.response.data.msg);
    } finally {
      setFetchingExperienceList(false);
    }
  };

  const changePage = (page) => {
    setCurrentPage(page);
    setServerParams({
      ...serverParams,
      startingLimit: page == 1 ? 0 : (page - 1) * serverParams.resultsPerPage,
    });
    if (currentTabId === 1) {
      fetchExperienceList({
        ...serverParams,
        startingLimit: page == 1 ? 0 : (page - 1) * serverParams.resultsPerPage,
      });
    }
    if (currentTabId === 2) {
      fetchPastExperienceList({
        ...serverParams,
        startingLimit: page == 1 ? 0 : (page - 1) * serverParams.resultsPerPage,
      });
    }
    if (currentTabId === 3) {
      fetchArchivedExperienceList({
        ...serverParams,
        startingLimit: page == 1 ? 0 : (page - 1) * serverParams.resultsPerPage,
      });
    }
  };

  const gotoPreviousPage = () => {
    let pageStarting = 0;
    setCurrentPage(currentPage - 1);
    if (currentPage === 1) {
      setServerParams({ ...serverParams, startingLimit: 0 });
    } else {
      pageStarting = serverParams.startingLimit - serverParams.resultsPerPage;
    }
    setServerParams({ ...serverParams, startingLimit: pageStarting });
    if (currentTabId === 1) {
      fetchExperienceList({ ...serverParams, startingLimit: pageStarting });
    }
    if (currentTabId === 2) {
      fetchPastExperienceList({ ...serverParams, startingLimit: pageStarting });
    }
    if (currentTabId === 3) {
      fetchArchivedExperienceList({
        ...serverParams,
        startingLimit: pageStarting,
      });
    }
  };

  const geToNextPage = () => {
    setCurrentPage(currentPage + 1);
    changePage(currentPage + 1);
  };

  const handleSearchExperiance = (serachValue) => {
    setSarchExperience(serachValue);
    setServerParams({ ...serverParams, search: serachValue });
    if (currentTabId === 1) {
      fetchExperienceList({ ...serverParams, search: serachValue });
    }
    if (currentTabId === 2) {
      fetchPastExperienceList({ ...serverParams, search: serachValue });
    }
    if (currentTabId === 3) {
      fetchArchivedExperienceList({ ...serverParams, search: serachValue });
    }
  };

  const handleSorting = (sortValue) => {
    setServerParams({ ...serverParams, sort_order: sortValue });
    if (currentTabId === 1) {
      fetchExperienceList({ ...serverParams, sort_order: sortValue });
    }
    if (currentTabId === 2) {
      fetchPastExperienceList({ ...serverParams, sort_order: sortValue });
    }
    if (currentTabId === 3) {
      fetchArchivedExperienceList({ ...serverParams, sort_order: sortValue });
    }
  };

  const moveToArchiveExperience = async (experienceId) => {
    setIsMovingToArchive(true);
    let dataToSend = {
      id: experienceId,
    };
    try {
      const res = await moveToArchived(dataToSend);
      if (res.statusCode === 200) {
        // toast.success(res.msg);
        fetchPastExperienceList(serverParams);
      } else {
        toast.error(res.msg);
      }
    } catch (error) {
      toast.error(error.response.data.msg);
    } finally {
      setIsMovingToArchive(false);
    }
  };

  useEffect(() => {
    changeTab(currentTabId);
  }, []);

  return (
    <>
      <ToastContainer position="top-right" />
      <div className="exp-button-wrapper-outer">
        <div className="nav-tabs-wrapper vmersive-tabs align-items-center">
          <ul className="nav nav-tabs" id="meetings" role="tablist">
            {tabs.map((tab, tabIndex) => (
              <li className="nav-item" role="presentation" key={tabIndex}>
                <button
                  className={
                    currentTabId === tab.id ? "nav-link active" : "nav-link"
                  }
                  id={`tab-${tab.id}-tab`}
                  data-bs-toggle="tab"
                  data-bs-target={`#tab-${tab.id}`}
                  type="button"
                  role="tab"
                  aria-controls={`tab-${tab.id}`}
                  aria-selected="false"
                  onClick={() => {
                    changeTab(tab.id);
                  }}
                >
                  {tab.label}
                </button>
              </li>
            ))}
          </ul>
        </div>

        {/** Empty State Experience Starts **/}

        {/* <div className="empty-state text-center">
          <div className="icon-text-wrap">
            <div className="icon-wrap">
              <Svg name="saved-exp-empty" />
            </div>
            <div className="text-wrap">
              <p>We were unable to find any saved experiences. Please begin by creating a new one</p>
            </div>
            <div className="right-wrap mt-3 mt-md-4">
              <div className="right-wrap mt-3 mt-md-4">
                <Link to="/experiences/create-experience" className="btn btn-primary add-btn">
                  <Svg name="plus" /> Create new experience  </Link>
              </div>
            </div>
          </div>
        </div> */}

        {/** Empty State Experience Ends **/}

        {/** Empty State Experience Starts **/}

        {/* <div className="empty-state text-center">
          <div className="icon-text-wrap">
            <div className="icon-wrap">
              <Svg name="past-exp-empty" />
            </div>
            <div className="text-wrap">
              <p>No experiences have been created in the past. To get started, please create a new one.</p>
            </div>
            <div className="right-wrap mt-3 mt-md-4">
              <div className="right-wrap mt-3 mt-md-4">
                <Link to="/experiences/create-experience" className="btn btn-primary add-btn">
                  <Svg name="plus" /> Create new experience  </Link>
              </div>
            </div>
          </div>
        </div> */}

        {/** Empty State Experience Ends **/}

        {/** Empty State Experience Starts **/}

        {/* <div className="empty-state text-center">
          <div className="icon-text-wrap">
            <div className="icon-wrap">
              <Svg name="archieve-exp-empty" />
            </div>
            <div className="text-wrap">
              <p>No experiences have been<br/> saved in the archives.</p>
            </div>
            
          </div>
        </div> */}

        {/** Empty State Experience Ends **/}

        <div className="tab-search search-filter d-flex align-items-center">
          <div className="flex-grow-1 search-filter-padding">
            <input
              type="text"
              className="form-control custom-search-input"
              placeholder="Search experiences"
              value={searchExperience}
              onChange={(e) => {
                handleSearchExperiance(e.target.value);
              }}
            />
          </div>
          <div className="drop-wrap d-flex align-items-center">
            <label>Sort By - </label>
            <div className="dropdown table-dropdown ques-options">
              <button
                className="btn dropdown-tgl"
                type="button"
                id="sort-by"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {sortValue}
              </button>
              <div className="dropdown-menu" aria-labelledby="sort-by">
                {sortingOptions.map((sort) => (
                  <a
                    className="dropdown-item"
                    href="#"
                    key={sort.id}
                    onClick={() => {
                      handleSorting(sort.value);
                      setSortValue(sort.label);
                    }}
                  >
                    {sort.label}
                  </a>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="page-wrapper">
          <div className="tab-content" id="meetingsContent">
            <div
              className="tab-pane fade show active"
              id="tab-1"
              role="tabpanel"
              aria-labelledby="tab-1-tab"
            >
              {!fetchingExperienceList ? (
                <div className="templates-wrapper">
                  {experienceList.length > 0 ? (
                    <div className="templates-row row">
                      {experienceList.map((experience, experienceIndex) => (
                        <div
                          className="col-xxl-3 col-xl-4 col-md-6 mb-3"
                          key={experienceIndex}
                        >
                          <div className="template-card exp-grid">
                            <div className="img-wrap">
                              <img
                                src={template1}
                                className="img-fluid"
                                alt=""
                              />
                              {/* <input
                                className="form-check-input"
                                type="checkbox"
                                id="template1"
                                name="chooseTemplate"
                              /> */}
                              <div className="action-buttons">
                                <OverlayTrigger
                                  overlay={<Tooltip>Edit</Tooltip>}
                                >
                                  <Link
                                    to={"/experiences/edit-experience"}
                                    state={experience.id}
                                    className="btn btn-white"
                                  >
                                    <Svg name="icon-edit" />
                                  </Link>
                                </OverlayTrigger>
                                <OverlayTrigger
                                  overlay={<Tooltip>Duplicate</Tooltip>}
                                >
                                  <button className="btn btn-white">
                                    <Svg name="icon-copy" />
                                  </button>
                                </OverlayTrigger>
                              </div>
                            </div>
                            <div className="text-wrapper">
                              <h5 title={experience.name}>{experience.name}</h5>
                              <div className="description">
                                <p>
                                  Date modified :{" "}
                                  {experience.updated_date_time
                                    ? moment(
                                        experience.updated_date_time
                                      ).format("DD MMM, yyyy")
                                    : "-"}
                                </p>
                                <p className="pt-1">
                                  Added by :{" "}
                                  {experience.added_by_name
                                    ? experience.added_by_name
                                    : "-"}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="templates-row row">No Data Found</div>
                  )}
                </div>
              ) : (
                <div className="templates-wrapper">
                  <Loader />
                </div>
              )}
            </div>
            <div
              className="tab-pane fade"
              id="tab-2"
              role="tabpanel"
              aria-labelledby="tab-2-tab"
            >
              {!fetchingExperienceList ? (
                <div className="templates-wrapper">
                  {pastExperienceList.length > 0 ? (
                    <div className="templates-row row">
                      {pastExperienceList.map(
                        (pastExperience, pastExperienceIndex) => (
                          <div
                            className="col-xxl-3 col-xl-4 col-md-6 mb-3"
                            key={pastExperienceIndex}
                          >
                            <div className="template-card exp-grid">
                              <div className="img-wrap">
                                <img
                                  src={template1}
                                  className="img-fluid"
                                  alt=""
                                />
                                <div className="action-buttons">
                                  <OverlayTrigger
                                    overlay={
                                      <Tooltip>
                                        {isMovingToArchive
                                          ? "Moving"
                                          : "Move to archive"}
                                      </Tooltip>
                                    }
                                  >
                                    <button
                                      className="btn btn-primary archieve-color"
                                      onClick={() => {
                                        moveToArchiveExperience(
                                          pastExperience.id
                                        );
                                      }}
                                    >
                                      <Svg name="icon-archieve" />
                                    </button>
                                  </OverlayTrigger>
                                  <OverlayTrigger
                                    overlay={<Tooltip>Duplicate</Tooltip>}
                                  >
                                    <button className="btn btn-white">
                                      <Svg name="icon-copy" />
                                    </button>
                                  </OverlayTrigger>
                                </div>
                              </div>
                              <div className="text-wrapper">
                                <h5 title={pastExperience.name}>
                                  {pastExperience.name}
                                </h5>
                                <div className="description">
                                  <p>
                                    {" "}
                                    Date modified :{" "}
                                    {pastExperience.updated_date_time
                                      ? moment(
                                          pastExperience.updated_date_time
                                        ).format("DD MMM, yyyy")
                                      : "-"}{" "}
                                  </p>
                                  <p className="pt-1">
                                    Added by :{" "}
                                    {pastExperience.added_by_name
                                      ? pastExperience.added_by_name
                                      : "-"}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  ) : (
                    <>No Data Found</>
                  )}
                </div>
              ) : (
                <Loader />
              )}
            </div>
            <div
              className="tab-pane fade"
              id="tab-3"
              role="tabpanel"
              aria-labelledby="tab-3-tab"
            >
              {!fetchingExperienceList ? (
                <div className="templates-wrapper">
                  {archivedExperienceList.length > 0 ? (
                    <div className="templates-row row">
                      {archivedExperienceList.map(
                        (archivedExperience, archivedExperienceIndex) => (
                          <div
                            className="col-xxl-3 col-xl-4 col-md-6 mb-3"
                            key={archivedExperienceIndex}
                          >
                            <div className="template-card exp-grid">
                              <div className="img-wrap">
                                <img
                                  src={template1}
                                  className="img-fluid"
                                  alt=""
                                />
                                {/* <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="template1"
                                  name="chooseTemplate"
                                /> */}

                                <div className="action-buttons">
                                  <OverlayTrigger
                                    overlay={<Tooltip>Restore</Tooltip>}
                                  >
                                    <button className="btn btn-white">
                                      <Svg name="icon-restore" />
                                    </button>
                                  </OverlayTrigger>
                                </div>
                              </div>
                              <div className="text-wrapper">
                                <h5 title={archivedExperience.name}>
                                  {archivedExperience.name}
                                </h5>
                                <div className="description">
                                  <p>
                                    {" "}
                                    Date modified :
                                    {archivedExperience.updated_date_time
                                      ? moment(
                                          archivedExperience.updated_date_time
                                        ).format("DD MMM, yyyy")
                                      : "-"}
                                  </p>
                                  <p className="pt-1">
                                    Added by :{" "}
                                    {archivedExperience.added_by_name
                                      ? archivedExperience.added_by_name
                                      : "-"}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  ) : (
                    <>No Data Found</>
                  )}
                </div>
              ) : (
                <Loader />
              )}
            </div>
          </div>
        </div>
        <Pagination
          currentPage={currentPage}
          totalPage={totalPage}
          gotoPreviousPage={gotoPreviousPage}
          changePage={changePage}
          geToNextPage={geToNextPage}
        />
      </div>

      {/* <TemplateDetailsModal /> */}
    </>
  );
}
