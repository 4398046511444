import axios from "../utils/axios";
import { BASE_URL } from "./urls";

export const getMeetingList = async (payload) => {
  const token = localStorage.getItem("token");
  const response = await axios({
    headers: { Authorization: `Bearer ${token}` },
    method: "POST",
    url: `${BASE_URL}/event/getMeetingList`,
    data: payload,
  });

  return response.data;
};

export const getPastMeetingList = async (payload) => {
  const token = localStorage.getItem("token");
  const response = await axios({
    headers: { Authorization: `Bearer ${token}` },
    method: "POST",
    url: `${BASE_URL}/event/getPastMeetingList`,
    data: payload,
  });

  return response.data;
};

export const getDraftMeetingList = async (payload) => {
  const token = localStorage.getItem("token");
  const response = await axios({
    headers: { Authorization: `Bearer ${token}` },
    method: "POST",
    url: `${BASE_URL}/event/getDraftMeetingList`,
    data: payload,
  });

  return response.data;
};

export const getMeetingDetail = async (payload) => {
  const token = localStorage.getItem("token");
  const response = await axios({
    headers: { Authorization: `Bearer ${token}` },
    method: "POST",
    url: `${BASE_URL}/event/getMeetingDetail`,
    data: payload,
  });

  return response.data;
};

export const createMeeting = async (payload) => {
  const token = localStorage.getItem("token");
  const response = await axios({
    headers: { Authorization: `Bearer ${token}` },
    method: "POST",
    url: `${BASE_URL}/event/createMeeting`,
    data: payload,
  });

  return response.data;
}

export const addParticipants = async (payload) => {
  const token = localStorage.getItem("token");
  const response = await axios({
    headers: { Authorization: `Bearer ${token}` },
    method: "POST",
    url: `${BASE_URL}/event/addParticipantMeeting`,
    data: payload,
  });

  return response.data;
}

export const getParticipants = async (payload) => {
  const token = localStorage.getItem("token");
  const response = await axios({
    headers: { Authorization: `Bearer ${token}` },
    method: "POST",
    url: `${BASE_URL}/event/getParticipantListMeeting`,
    data: payload,
  });

  return response.data;
}

export const deleteParticipants = async (payload) => {
  const token = localStorage.getItem("token");
  const response = await axios({
    headers: { Authorization: `Bearer ${token}` },
    method: "DELETE",
    url: `${BASE_URL}/event/deleteParticipant`,
    data: payload,
  });

  return response.data;
}

export const updateMeetingExperience = async (payload) => {
  const token = localStorage.getItem("token");
  const response = await axios({
    headers: { Authorization: `Bearer ${token}` },
    method: "PATCH",
    url: `${BASE_URL}/event/updateMeetingExp`,
    data: payload,
  });

  return response.data;
}

export const getMeetingDetailEdit = async (payload) => {
  const token = localStorage.getItem("token");
  const response = await axios({
    headers: { Authorization: `Bearer ${token}` },
    method: "POST",
    url: `${BASE_URL}/event/getMeetingDetailEdit`,
    data: payload,
  });

  return response.data;
}

export const editMeeting = async (payload) => {
  const token = localStorage.getItem("token");
  const response = await axios({
    headers: { Authorization: `Bearer ${token}` },
    method: "PATCH",
    url: `${BASE_URL}/event/updateMeeting`,
    data: payload,
  });

  return response.data;
}

export const getHostListMeeting = async (payload) => {
  const token = localStorage.getItem("token");
  const response = await axios({
    headers: { Authorization: `Bearer ${token}` },
    method: "POST",
    url: `${BASE_URL}/event/getHostListMeeting`,
    data: payload,
  });

  return response.data;
}

export const addHostMeeting = async (payload) => {
  const token = localStorage.getItem("token");
  const response = await axios({
    headers: { Authorization: `Bearer ${token}` },
    method: "POST",
    url: `${BASE_URL}/event/addHostMeeting`,
    data: payload,
  });

  return response.data;
}

export const sendMeetingInvitation = async (payload) => {
  const token = localStorage.getItem("token");
  const response = await axios({
    headers: { Authorization: `Bearer ${token}` },
    method: "POST",
    url: `${BASE_URL}/event/sendMeetingInvitation`,
    data: payload,
  });

  return response.data;
}

export const deleteMeeting = async (payload) => {
  const token = localStorage.getItem("token");
  const response = await axios({
    headers: { Authorization: `Bearer ${token}` },
    method: "DELETE",
    url: `${BASE_URL}/event/deleteMeeting`,
    data: payload,
  });

  return response.data;
}

export const getTimezone = async () => {
  const token = localStorage.getItem("token");
  const response = await axios({
    headers: { Authorization: `Bearer ${token}` },
    method: "POST",
    url: `${BASE_URL}/event/getTimezone`
  });

  return response.data;
}