import React, { useState, useEffect, useMemo } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import DashboardHeader from "../common/DashboardHeader";
import DashboardSidebar from "../common/DashboardSidebar";
import Svg from "../common/Svg";
import { ReactSortable } from "react-sortablejs";
import templateImg from "../../assets/images/image-airport.jpg";
import {
  getExperienceDetail,
  getSelectedMediaSpot,
  updateExperience,
  updateStructure,
} from "../../actions/experience";
import { toast, ToastContainer } from "react-toastify";
import empty from "../../assets/images/empty.jpg";
import concat from "lodash/concat";
import ExperienceSubHeader from "./ExperienceSubHeader";

export default function Reorder() {
  const { state } = useLocation();

  const navigate = useNavigate();
  const [sortList, setSortList] = useState([]);
  const [isFeatchingMediaspotDetail, setIsFeatchingMMediaspotDetail] =
    useState(false);
  const [isFetchingDetail, setIsFetchingDetail] = useState(false);
  const [experianceDetail, setExperianceDetail] = useState(null);
  const [spotAreas, setSpotAreas] = useState([]);
  const [templates, setTemplates] = useState({});
  const [currentAreaId, setCurrentAreaId] = useState("");
  const [isOpenArea, setIsOpenArea] = useState(false);

  const fetchExperienceDetail = async (experienceId) => {
    setIsFetchingDetail(true);
    let dataToSend = {
      id: experienceId,
    };
    try {
      const res = await getExperienceDetail(dataToSend);
      if (res.statusCode === 200) {
        setExperianceDetail(res.data);
        setSpotAreas(res.data.templates);
        res.data.templates.map((template) => {
          getExperienceMediaspot(template.id);
        });
      } else {
        toast.error(res.msg);
      }
    } catch (error) {
      toast.error(error.response.data.msg);
    } finally {
      setIsFetchingDetail(false);
    }
  };

  const getExperienceMediaspot = async (templateId) => {
    setIsFeatchingMMediaspotDetail(true);
    let dataToSend = {
      id: state.experienceId,
      t_id: templateId,
    };
    try {
      const res = await getSelectedMediaSpot(dataToSend);
      if (res.statusCode === 200) {
        // let tempStructure =
        //   res.data.structure.length > 0 ? JSON.parse(res.data.structure) : "{}";
        let tempStructure = res.data.structure;
        setTemplates((prevState) => {
          let tempMediaSpotsOrder = [];
          if (
            tempStructure.media_spots_order &&
            tempStructure.media_spots_order.length > 0
          ) {
            let filteredData = Object.keys(tempStructure.media_spots).filter(
              (e) => {
                return !tempStructure.media_spots_order.includes(e);
              }
            );
            tempMediaSpotsOrder = concat(
              tempStructure.media_spots_order,
              filteredData
            );
          } else {
            tempMediaSpotsOrder = Object.keys(tempStructure.media_spots);
          }

          return {
            ...prevState,
            [templateId]: {
              ...tempStructure,
              media_spots_order: tempMediaSpotsOrder,
            },
          };
        });
      } else {
        toast.error(res.msg);
      }
    } catch (error) {
      toast.error(error.response.data.msg);
    } finally {
      setIsFeatchingMMediaspotDetail(false);
    }
  };

  const experienceStructureUpdate = async () => {
    for (const spotAreasObj of spotAreas) {
      let dataToSend = {
        id: state.experienceId,
        template_id: spotAreasObj.id,
        structure: JSON.stringify(templates[spotAreasObj.id]),
      };
      await updateStructure(dataToSend);
    }
  };

  const saveExperience = () => {
    experienceStructureUpdate();
    navigate("/experiences");
  };

  function editExperience(templates) {
    if (experianceDetail) {
      let templateIds = templates.map((e) => e.id);
      let dataToSend = {
        id: experianceDetail.id,
        name: experianceDetail.name,
        description: experianceDetail.description,
        templates: templateIds,
        default_template: experianceDetail.templates[0].id,
      };
      updateExperience(dataToSend);
    }
  }

  useEffect(() => {
    if (state && state.experienceId) {
      fetchExperienceDetail(state.experienceId);
    }
  }, []);

  useEffect(() => {}, [templates]);

  return (
    <>
      <ToastContainer position="top-right" />

      <div className="d-flex flex-column main-outer-wrapper main-site-wrapper ">
        <DashboardHeader />
        <div id="viewport" className="d-flex flex-grow-1">
          <DashboardSidebar />
          <div className="flex-grow-1 page-content-flex">
            <ExperienceSubHeader
              reorder
              data={state}
              handleClick={experienceStructureUpdate}
            />
            <div className="page-content-wrap">
              <div className="heading-section page-heading d-lg-flex exp-button-wrapper border-0 pb-0 mb__20 align-items-start">
                <div className="flex-grow-1">
                  {/* <h6 className="pb-2">
                    <strong>Experience: </strong>
                    {experianceDetail ? experianceDetail.name : ""}
                  </h6> */}
                  <nav
                    aria-label="breadcrumb"
                    className="vmersive-breadcrumbs mb-2"
                  >
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/experiences">Experiences</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link
                          to={
                            state.mode === "editMode"
                              ? "/experiences/edit-experience"
                              : "/experiences/create-experience"
                          }
                          state={
                            state.mode === "editMode"
                              ? experianceDetail && experianceDetail.id
                              : {
                                  experienceId: experianceDetail
                                    ? experianceDetail.id
                                    : "",
                                }
                          }
                        >
                          {experianceDetail && experianceDetail.name}
                        </Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        Reorder
                      </li>
                    </ol>
                  </nav>
                  <h1>Review & Reorder</h1>
                </div>

                <div className="button-wrap mt-3 mt-lg-0 d-flex">
                  <Link
                    className="btn btn-bordered color-cancel add-btn me-3"
                    to={
                      state.mode === "editMode"
                        ? "/experiences/edit-experience/select-hotspot/add-media"
                        : "/experiences/create-experience/select-hotspot/add-media"
                    }
                    state={{
                      experienceId: state.experienceId,
                      mode: state.mode,
                    }}
                  >
                    {" "}
                    <Svg name="left-arrow" /> Back{" "}
                  </Link>
                  <button
                    type="button"
                    onClick={() => {
                      saveExperience();
                    }}
                    className="btn btn-primary"
                  >
                    Save experience
                  </button>
                </div>
              </div>
              <div className="add-media-text">
                <p>Review & reorder Metaspaces and hotspots</p>
              </div>
              <hr className="dark-20" />
              <div className="page-wrapper">
                <div
                  className="accordion review-reorder"
                  id="tab-1-review-reorder"
                >
                  {isFetchingDetail ? (
                    <>
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      <span className="visually-hidden">Loading...</span>
                    </>
                  ) : (
                    <ReactSortable
                      list={spotAreas}
                      setList={(newState) => {
                        setSpotAreas(newState);
                        editExperience(newState);
                      }}
                    >
                      {spotAreas.map((area, areaIndex) => (
                        <div
                          className={
                            area.id === currentAreaId && isOpenArea
                              ? "accordion-item active"
                              : "accordion-item"
                          }
                          key={areaIndex}
                        >
                          <div
                            className="accordion-header collapsed"
                            id={`tab-${area.id}-heading${areaIndex}`}
                          >
                            <button className="sort-button">Sort</button>
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target={`#tab-${area.id}-collapse`}
                              aria-expanded="true"
                              aria-controls={`collapse${area.id}`}
                              onClick={() => {
                                setCurrentAreaId(area.id);
                                setSortList(
                                  Object.keys(templates[area.id].media_spots)
                                );
                                setIsOpenArea(!isOpenArea);
                              }}
                            >
                              <div
                                className="d-flex align-items-center w-100"
                                title={area.name}
                              >
                                <div className="img-wrap">
                                  <img
                                    src={area.image ? area.image : empty}
                                    alt=""
                                  />
                                </div>
                                <div className="media-body text-body">
                                  {area.name}
                                </div>
                              </div>
                            </button>
                          </div>
                          <div
                            id={`tab-${area.id}-collapse`}
                            className="accordion-collapse collapse"
                            aria-labelledby={`heading${areaIndex}`}
                            data-bs-parent="#tab-1-review-reorder"
                          >
                            <div className="accordion-body">
                              <ul className="accordion-checklist">
                                {templates && templates[currentAreaId] && (
                                  <ReactSortable
                                    dragClass="sortableDrag"
                                    animation="200"
                                    easing="ease-out"
                                    list={
                                      templates[area.id]["media_spots_order"]
                                    }
                                    setList={(data, data1, data2) => {
                                      setTemplates((prevState) => {
                                        let test = {
                                          ...prevState,
                                          [area.id]: {
                                            ...templates[area.id],
                                            media_spots_order: data.map((e) =>
                                              e.valueOf()
                                            ),
                                          },
                                        };
                                        return test;
                                        // return JSON.parse(JSON.stringify(test));
                                      });
                                    }}
                                  >
                                    {templates[area.id][
                                      "media_spots_order"
                                    ].map((media, mediaIndex) => (
                                      <li
                                        className="check-list"
                                        key={mediaIndex}
                                      >
                                        <button className="sort-icon">
                                          <Svg name="sort-icon-v" />
                                        </button>
                                        <div>
                                          <div className="" title={media}>
                                            {media}
                                            {Object.prototype.hasOwnProperty.call(
                                              templates[area.id][
                                                "teleport_points"
                                              ],
                                              templates[area.id]["media_spots"][
                                                media
                                              ]["teleport_point"]
                                            )
                                              ? " - " +
                                                templates[area.id][
                                                  "teleport_points"
                                                ][
                                                  templates[area.id][
                                                    "media_spots"
                                                  ][media]["teleport_point"]
                                                ]["name"]
                                              : ""}
                                          </div>
                                        </div>
                                        {/* <input
                                          type="text"
                                          defaultValue={media}
                                        /> */}
                                      </li>
                                    ))}
                                  </ReactSortable>
                                )}
                              </ul>
                            </div>
                          </div>
                        </div>
                      ))}
                    </ReactSortable>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
