import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import DashboardHeader from "../../common/DashboardHeader";
import DashboardSidebar from "../../common/DashboardSidebar";
import empty from "../../../assets/images/empty.jpg";

import Svg from "../../common/Svg";
import UploadMediaModal from "../../common/UploadMediaModal";
import MediaViewModal from "../../common/MediaViewModal";
import {
  getExperienceDetail,
  getSelectedMediaSpot,
  updateStructure,
} from "../../../actions/experience";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/ReactToastify.min.css";
import ExperienceSubHeader from "../ExperienceSubHeader";
import { s3MediaUrl } from "../../../utils/s3FileUpload";
import { getThumbnail } from "../../../utils/imageThumbnail";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
const asset_typ_reverse = {
  1: "mp4",
  2: "png",
  3: "jpg",
  // 3: "jpeg",
  4: "gif",
  5: "html",
  6: "youtube link",
  7: "ppt",
  8: "pdf",
  9: "svg",
  10: "vimeo link",
  12: "pptx",
  13: "doc",
  14: "docx",
};

export default function AddMedia() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [isFeatchingMediaspotDetail, setIsFeatchingMMediaspotDetail] =
    useState(false);

  const [isFetchingDetail, setIsFetchingDetail] = useState(false);
  const [isUpdatingStructure, setIsUpdatingStructure] = useState(false);
  const [experianceDetail, setExperianceDetail] = useState(null);
  const [spotAreas, setSpotAreas] = useState([]);
  const [selectedSpotAreaId, setSelectedSpotAreaId] = useState("");
  const [currentMediaSpotId, setCurrentMediaSpotId] = useState("");
  const [currentMediaSpotData, setCurrentMediaSpotData] = useState({});
  const [toggleImagePreview, setToggleImagePreview] = useState(false);
  const [currentMediaData, setCurrentMediaData] = useState(null);

  const [templates, setTemplates] = useState({});

  const fileUploadModal = useRef(null);
  const [isOpenModal, setIsOpenModal] = useState(false);

  const fetchExperienceDetail = async (experienceId) => {
    setIsFetchingDetail(true);
    let dataToSend = {
      id: experienceId,
    };
    try {
      const res = await getExperienceDetail(dataToSend);
      if (res.statusCode === 200) {
        setExperianceDetail(res.data);
        setSpotAreas(res.data.templates);
        setSelectedSpotAreaId(res.data.templates[0].id);
      } else {
        toast.error(res.msg);
      }
    } catch (error) {
      toast.error(error);
    } finally {
      setIsFetchingDetail(false);
    }
  };

  const updateTemplates = (templateId, templateData) => {
    setTemplates((prevState) => {
      return {
        ...prevState,
        [templateId]: templateData,
      };
    });
  };

  const getExperienceMediaspot = async (templateId) => {
    return new Promise(async (resolve, reject) => {
      setIsFeatchingMMediaspotDetail(true);
      let dataToSend = {
        id: state.experienceId,
        t_id: templateId,
      };
      try {
        const selectesHotSpotdata = await getSelectedMediaSpot(dataToSend);
        if (selectesHotSpotdata.statusCode === 200) {
          let selectedHotspotStructure = selectesHotSpotdata.data.structure;
          updateTemplates(templateId, selectedHotspotStructure);
          resolve(selectedHotspotStructure);
        } else {
          toast.error(selectesHotSpotdata.msg);
          reject("no data");
        }
      } catch (error) {
        toast.error(error);
        reject("no data");
      } finally {
        setIsFeatchingMMediaspotDetail(false);
      }
    });
  };

  const openModal = (mediaspotId, mediaspotData) => {
    const mediaModal = window.bootstrap.Modal.getOrCreateInstance(
      fileUploadModal.current
    );
    mediaModal.show();

    const myModalEl = document.getElementById("fileUpload");
    const handleHide = (event) => {
      setIsOpenModal(false);
      myModalEl.removeEventListener("hidden.bs.modal", handleHide);
    };
    myModalEl.addEventListener("hidden.bs.modal", handleHide);

    setCurrentMediaSpotId(mediaspotId);
    setCurrentMediaSpotData(mediaspotData);
    setIsOpenModal(true);
  };

  const closeModal = () => {
    window.bootstrap.Modal.getOrCreateInstance(fileUploadModal.current).hide();
    setCurrentMediaSpotId("");
    setCurrentMediaSpotData("");
    setIsOpenModal(false);
  };

  const updateMediaspotStructure = async (templateId, templateStrucuture) => {
    setIsUpdatingStructure(true);
    let dataToSend = {
      id: state.experienceId,
      template_id: templateId,
      structure: JSON.stringify(templateStrucuture),
      // structure: templateStrucuture,
    };
    try {
      const res = await updateStructure(dataToSend);
      if (res.statusCode === 200) {
        setIsUpdatingStructure(false);
        window.bootstrap.Modal.getOrCreateInstance(
          fileUploadModal.current
        ).hide();
        setIsOpenModal(false);
        getExperienceMediaspot(templateId);
      } else {
        toast.error(res.msg);
      }
    } catch (error) {
      toast.error(error);
    } finally {
      setIsUpdatingStructure(false);
    }
  };

  const changeMediaSpotData = (data) => {
    let dataForChange = {
      ...templates[selectedSpotAreaId]["media_spots"][currentMediaSpotId],
      isActive: "1",
      asset_id: data.id,
      media_type: data.file_type,
      media_url: data.link,
      name: data.name,
      parent_id: data.parent_id,
      dimensions: data.dimensions
    };
    templates[selectedSpotAreaId]["media_spots"][currentMediaSpotId] =
      dataForChange;
    let dataToChange = Object.assign(
      JSON.parse(JSON.stringify(templates[selectedSpotAreaId]))
    );
    // let dataToChange = Object.assign(templates[selectedSpotAreaId]);
    updateMediaspotStructure(selectedSpotAreaId, dataToChange);
    setCurrentMediaSpotData(data);
  };

  const changeSpotArea = async (areaId) => {
    await updateMediaspotStructure(
      selectedSpotAreaId,
      templates[selectedSpotAreaId]
    );
    setSelectedSpotAreaId(areaId);
  };

  const experienceStructureUpdate = async () => {
    try {
      for (const spotAreasObj of spotAreas) {
        // load alredy stored data other wise get data then load
        if (Object.prototype.hasOwnProperty.call(templates, spotAreasObj.id)) {
          let dataToSend = {
            id: state.experienceId,
            template_id: spotAreasObj.id,
            structure: JSON.stringify(templates[spotAreasObj.id]),
          };
          await updateStructure(dataToSend);
        } else {
          const tempStructure = await getExperienceMediaspot(spotAreasObj.id);

          let dataToSend = {
            id: state.experienceId,
            template_id: spotAreasObj.id,
            structure: JSON.stringify(tempStructure),
          };
          await updateStructure(dataToSend);
        }
      }
    } catch (error) {
      console.log("add media 222", error);
    }
  };

  const goForReviewAndReorder = async () => {
    try {
      await experienceStructureUpdate();
      if (state.mode === "editMode") {
        navigate(
          "/experiences/edit-experience/select-hotspot/add-media/reorder",
          { state: state }
        );
      } else {
        navigate(
          "/experiences/create-experience/select-hotspot/add-media/reorder",
          { state: state }
        );
      }
    } catch (error) {
      console.log("uploadtemplateStructure = ", error);
    }
  };

  const onImgLoad = ({ target: img }) => {
    // this.setState({dimensions:{height:img.offsetHeight,
    //                            width:img.offsetWidth}});
  };

  const toggleMediaViewModal = (mediaData, toggleImage = false) => {
    setToggleImagePreview(toggleImage);
    let mediaView = document.getElementById("modalDetails");
    let modal = window.bootstrap.Modal.getOrCreateInstance(mediaView);
    if (modal) {
      setCurrentMediaData(mediaData);
      modal.toggle();
    }
  };

  useEffect(() => {
    if (state && state.experienceId) {
      fetchExperienceDetail(state.experienceId);
    }
  }, []);

  useEffect(() => {
    if (
      selectedSpotAreaId &&
      !Object.prototype.hasOwnProperty.call(templates, selectedSpotAreaId)
    ) {
      getExperienceMediaspot(selectedSpotAreaId);
    }
  }, [selectedSpotAreaId]);

  return (
    <>
      <ToastContainer position="top-right" />

      <div className="d-flex flex-column main-outer-wrapper main-site-wrapper ">
        <DashboardHeader />
        <div id="viewport" className="d-flex flex-grow-1">
          <DashboardSidebar />
          <div className="flex-grow-1 page-content-flex">
            <ExperienceSubHeader
              addMedia
              data={state}
              handleClick={experienceStructureUpdate}
            />
            <div className="page-content-wrap">
              <div className="heading-section page-heading d-lg-flex exp-button-wrapper border-0 pb-0 mb__20 align-items-start">
                <div className="flex-grow-1">
                  {/* <h6 className="pb-2">
                                        <strong>Experience: </strong>
                                        {experianceDetail ? experianceDetail.name : ""}
                                    </h6> */}
                  <nav
                    aria-label="breadcrumb"
                    className="vmersive-breadcrumbs mb-2"
                  >
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/experiences">Experiences</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link
                          to={
                            state.mode === "editMode"
                              ? "/experiences/edit-experience"
                              : "/experiences/create-experience"
                          }
                          state={
                            state.mode === "editMode"
                              ? experianceDetail && experianceDetail.id
                              : {
                                  experienceId: experianceDetail
                                    ? experianceDetail.id
                                    : "",
                                }
                          }
                        >
                          {experianceDetail && experianceDetail.name}
                        </Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        Add media
                      </li>
                    </ol>
                  </nav>
                  <h1>Add Media </h1>
                </div>

                <div className="button-wrap mt-3 mt-lg-0 d-flex">
                  <Link
                    className="btn btn-bordered color-cancel add-btn me-3"
                    to={
                      state.mode === "editMode"
                        ? "/experiences/edit-experience/select-hotspot"
                        : "/experiences/create-experience/select-hotspot"
                    }
                    state={{
                      experienceId: state.experienceId,
                      mode: state.mode,
                    }}
                  >
                    {" "}
                    <Svg name="left-arrow" /> Back{" "}
                  </Link>
                  <button
                    // to="/experiences/create-experience/select-hotspot/add-media/reorder"
                    // state={experianceDetail ? experianceDetail.id : ""}
                    className="btn btn-primary"
                    type="button"
                    onClick={() => {
                      goForReviewAndReorder();
                    }}
                  >
                    {" "}
                    Review & Reorder
                  </button>
                </div>
              </div>

              {/* <div className="add-media-text">
                            <p>Add media to selected mediaspots</p>
                            </div> */}

              <div className="nav-tabs-wrapper vmersive-tabs-2">
                <ul
                  className="nav nav-pills mb__20"
                  id="meetings"
                  role="tablist"
                >
                  {spotAreas.length > 0 &&
                    spotAreas.map((area, areaIndex) => (
                      <li
                        className="nav-item"
                        role="presentation"
                        key={areaIndex}
                      >
                        <button
                          className={
                            selectedSpotAreaId === area.id
                              ? "nav-link active"
                              : "nav-link"
                          }
                          id={`tab-${area.id}-tab`}
                          data-bs-toggle="tab"
                          data-bs-target={`#tab-${area.id}`}
                          type="button"
                          role="tab"
                          aria-controls={`tab-${area.id}`}
                          aria-selected="false"
                          onClick={() => {
                            changeSpotArea(area.id, area.name);
                          }}
                        >
                          {area.name}
                        </button>
                      </li>
                    ))}
                </ul>
              </div>
              <hr className="dark-20" />
              <div className="page-wrapper">
                <div className="tab-content" id="meetingsContent">
                  {spotAreas.length > 0 &&
                    spotAreas.map((area, areaIndex) => (
                      <div
                        className={
                          area.id === selectedSpotAreaId
                            ? "tab-pane fade show active"
                            : "tab-pane fade"
                        }
                        id={`tab-${areaIndex}`}
                        role="tabpanel"
                        aria-labelledby={`tab-${areaIndex}-tab`}
                        key={`tab-${areaIndex}`}
                      >
                        <div className="templates-wrapper tmp-br">
                          {isFeatchingMediaspotDetail ? (
                            <>
                              <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </>
                          ) : (
                            <div className="templates-row row">
                              {templates &&
                              templates[selectedSpotAreaId] &&
                              templates[selectedSpotAreaId].media_spots ? (
                                <>
                                  {Object.entries(
                                    templates[selectedSpotAreaId].media_spots
                                  ).map((media, mediaIndex) => (
                                    <div
                                      className="col-xxl-3 col-xl-4 col-md-6 mb-3"
                                      key={mediaIndex}
                                    >
                                      <div className="template-card add-media-card-bg">
                                        <div className="img-wrap">
                                          {media[1].media_type === 6 &&
                                            getThumbnail(
                                              media[1].media_url
                                            ) && (
                                              <img
                                                src={getThumbnail(
                                                  media[1].media_url
                                                )}
                                                alt={media[1].name}
                                                className={
                                                  "img-fluid uploaded-view"
                                                }
                                              />
                                            )}
                                          {(media[1].media_type === 7 ||
                                            media[1].media_type === 12) && (
                                            <div className="preview-file">
                                              <Svg
                                                name="ppt-icon"
                                                className="preview-file"
                                              />
                                            </div>
                                          )}
                                          {media[1].media_type === 8 && (
                                            <div className="preview-file">
                                              <Svg name="pdf-icon" />
                                            </div>
                                          )}
                                          {media[1].media_type === 10 && (
                                            <div className="preview-file">
                                              <Svg name="vimeo-icon-list" />
                                            </div>
                                          )}
                                          {(media[1].media_type === 13 ||
                                            media[1].media_type === 14) && (
                                            <div className="preview-file">
                                              <Svg name="doc-file-icon" />
                                            </div>
                                          )}
                                          {["mp4"].includes(
                                            media[1].name.split(".").pop()
                                          ) && (
                                            <img
                                              src={`${s3MediaUrl}/${
                                                media[1].asset_id
                                              }.${
                                                asset_typ_reverse[
                                                  media[1].media_type
                                                ]
                                              }.jpg`}
                                              alt=""
                                              className="img-fluid"
                                            />
                                          )}
                                          {[
                                            "png",
                                            "jpg",
                                            "jpeg",
                                            "gif",
                                            "svg",
                                          ].includes(
                                            media[1].name.split(".").pop()
                                          ) &&
                                            media[1].media_type !== 6 && (
                                              <img
                                                onError={({
                                                  currentTarget,
                                                }) => {
                                                  currentTarget.onerror = null; // prevents looping
                                                  currentTarget.src = empty;
                                                }}
                                                src={
                                                  media[1].media_url
                                                    ? media[1].media_url
                                                    : empty
                                                }
                                                className={
                                                  "img-fluid uploaded-view"
                                                }
                                                alt=""
                                              />
                                            )}
                                          <img
                                            onError={({ currentTarget }) => {
                                              currentTarget.onerror = null; // prevents looping
                                              currentTarget.src = empty;
                                            }}
                                            onLoad={onImgLoad}
                                            src={
                                              media[1].default_media_url
                                                ? media[1].default_media_url
                                                : empty
                                            }
                                            className={"img-fluid bg-vew"}
                                            alt={media[1].name}
                                            onClick={() => {
                                              toggleMediaViewModal(
                                                media[1],
                                                true
                                              );
                                            }}
                                          />

                                          {/* <input
																							className="form-check-input"
																							type="checkbox"
																							id="template1-1"
																							name="chooseTemplate"
																					/> */}

                                          <div className="action-buttons">
                                            <OverlayTrigger
                                              overlay={
                                                <Tooltip>Expand</Tooltip>
                                              }
                                            >
                                              <button
                                                className="btn btn-white"
                                                onClick={() => {
                                                  toggleMediaViewModal(
                                                    media[1],
                                                    false
                                                  );
                                                }}
                                              >
                                                <Svg name="zoom" />
                                              </button>
                                            </OverlayTrigger>
                                            <OverlayTrigger
                                              overlay={
                                                <Tooltip>
                                                  {media[1].media_url
                                                    ? "Edit Media"
                                                    : "Upload Media"}
                                                </Tooltip>
                                              }
                                            >
                                              <button
                                                className="btn btn-white"
                                                onClick={() => {
                                                  openModal(media[0], media[1]);
                                                }}
                                              >
                                                {media[1].media_url ? (
                                                  <>
                                                    <Svg name="icon-edit" />
                                                  </>
                                                ) : (
                                                  <>
                                                    <Svg name="new-upload-icon" />
                                                  </>
                                                )}
                                              </button>
                                            </OverlayTrigger>
                                            <OverlayTrigger
                                              overlay={
                                                <Tooltip>Delete File</Tooltip>
                                              }
                                            >
                                              <button
                                                className="btn btn-white"
                                                data-bs-toggle="modal"
                                                data-bs-target="#deleteFile"
                                              >
                                                <Svg name="icon-delete" />
                                              </button>
                                            </OverlayTrigger>
                                          </div>
                                        </div>
                                        <div className="text-wrapper">
                                          <div className="d-flex media-status">
                                            {/* <div className="hotspot-name flex-grow-1 pe-2">
                                              {" "}
                                              Welcome Area{" "}
                                            </div> */}
                                            {/* <div className="hotspot-temp"> */}
                                            {/* {JSON.stringify(templates)} */}
                                            {/* {media[1].unique_name} */}
                                            {/* </div> */}
                                            <div
                                              className="media-name flex-grow-1 cursor-pointer"
                                              title={
                                                media[1].name.length > 0
                                                  ? media[1].name
                                                  : "-"
                                              }
                                              onClick={() => {
                                                openModal(media[0], media[1]);
                                              }}
                                            >
                                              {media[1].name.length > 0
                                                ? media[1].name
                                                : "-"}
                                            </div>
                                            <div className="hotspot-temp">
                                              {media[1].unique_name}
                                            </div>
                                          </div>
                                          <div className="media-status d-flex align-items-center justify-content-between mt-1">
                                            <div className="hotspot-temp">
                                              {media[1].dimensions &&
                                                media[1].dimensions}
                                            </div>
                                            <div className="form-check">
                                              <input
                                                type="checkbox"
                                                className="form-check-input"
                                                id="autoplay1"
                                              />
                                              <label
                                                className="form-check-label"
                                                htmlFor="autoplay1"
                                              >
                                                Autoplay
                                              </label>
                                              <input
                                                type="checkbox"
                                                className="form-check-input"
                                                id={`showcircle-${media[0]}`}
                                                checked={media[1].showCircle}
                                                onChange={(e) => {
                                                  setTemplates((templates) => {
                                                    templates[
                                                      selectedSpotAreaId
                                                    ].media_spots[
                                                      media[0]
                                                    ].showCircle =
                                                      e.target.checked;
                                                    return { ...templates };
                                                  });
                                                }}
                                              />
                                              <label
                                                className="form-check-label"
                                                style={{ marginLeft: "10px" }}
                                                htmlFor={`showcircle-${media[0]}`}
                                              >
                                                show circle
                                              </label>
                                            </div>
                                          </div>
                                          {/* <div
																						className={
																						media[1].media_url
																						? "media-status uploaded"
																						: "media-status"
																						}
																						>
																						{media[1].media_url
																						? "Media uploaded"
																						: "Waiting for your media"}
																						</div> */}
                                          {/* <h5>{media[1].name}</h5> */}
                                          {/* <h5 className="account-info">
                                            <input
                                              className="title-form"
                                              type="text"
                                              name="name"
                                              id="name"
                                              placeholder="Enter name"
                                              defaultValue={media[1].name}
                                              onChange={(e) => {
                                                setTemplates((templates) => {
                                                  templates[
                                                    selectedSpotAreaId
                                                  ].media_spots[media[0]].name =
                                                    e.target.value;

                                                  return { ...templates };
                                                });
                                              }}
                                            />
                                          </h5> */}
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                </>
                              ) : (
                                <>No Data Found.</>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={
          isOpenModal
            ? "customvar-modal modal fade fileUpload uploadMedia show"
            : "customvar-modal modal fade fileUpload uploadMedia"
        }
        id="fileUpload"
        ref={fileUploadModal}
        tabIndex={-1}
        aria-labelledby="fileUploadLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-details-width modal-dialog-scrollable">
          <UploadMediaModal
            currentMediaSpotId={currentMediaSpotId}
            selectedSpotAreaId={selectedSpotAreaId}
            currentMediaSpotData={currentMediaSpotData}
            changeMediaSpotData={changeMediaSpotData}
            closeModal={closeModal}
            isOpenModal={isOpenModal}
          />
        </div>
      </div>

      <div
        className="customvar-modal modal custom-modal fade deleteModal"
        id="deleteModal"
        tabIndex={-1}
        aria-labelledby="deleteModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div
                className={
                  "formvawrapper delete-modal-wrap modal-setting-medium"
                }
              >
                <div className={"formstyle p-md-2"}>
                  <div className="delete-body-wrap delete-language">
                    <div className="heading">
                      <h3>Leave Experiences edit media?</h3>
                    </div>
                    <div className="text-normal">
                      <p>
                        Are you sure you want to edit media page, all your
                        unsaved data will be be lost.
                      </p>
                    </div>
                    <div className="button-wrap d-flex justify-content-end">
                      <button type="button" className="btn btn-delete">
                        Leave
                      </button>
                      <button
                        type="button"
                        className="btn btn-bordered color-cancel ms-3"
                        data-bs-dismiss="modal"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="customvar-modal modal custom-modal fade deleteModal"
        id="deleteFile"
        tabIndex={-1}
        aria-labelledby="deleteModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div
                className={
                  "formvawrapper delete-modal-wrap modal-setting-medium"
                }
              >
                <div className={"formstyle p-md-2"}>
                  <div className="delete-body-wrap delete-language">
                    <div className="heading">
                      <h3>Delete file?</h3>
                    </div>
                    <div className="text-normal">
                      <p>
                        Are you sure you want to delete File_Name_Here.jpg from
                        your experience?
                      </p>
                    </div>
                    <div className="button-wrap d-flex justify-content-end">
                      <button type="button" className="btn btn-delete">
                        Delete
                      </button>
                      <button
                        type="button"
                        className="btn btn-bordered color-cancel ms-3"
                        data-bs-dismiss="modal"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="customvar-modal modal fade modalDetails"
        id="modalDetails"
      >
        <MediaViewModal
          currentMediaData={currentMediaData}
          closeMediaViewModal={toggleMediaViewModal}
          toggleImagePreview={toggleImagePreview}
        />
      </div>
    </>
  );
}
