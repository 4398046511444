import React from "react";

export default function DeleteMeetingModal({ deleteId, handleDelete, isDeleteing, closeDeleteModal }) {
 
  return (
    <div className={"formvawrapper delete-modal-wrap modal-setting-medium"}>
      <div className={"formstyle p-md-2"}>
        <div className="delete-body-wrap delete-language">
          <div className="heading">
            <h3>Delete Event</h3>
          </div>
          <div className="warning-message">
            <div className="title d-flex align-items-center">
              <svg
                width={40}
                height={42}
                viewBox="0 0 40 42"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20 2L37.3205 32H2.67949L20 2Z"
                  stroke="#F05454"
                  strokeWidth={3}
                  strokeLinejoin="round"
                />
                <path d="M20 13.5385V22" stroke="#F05454" strokeWidth={3} />
                <circle
                  cx="19.9994"
                  cy="25.8461"
                  r={1}
                  fill="black"
                  stroke="#F05454"
                  strokeWidth="1.07692"
                />
              </svg>
              <h4>Warning</h4>
            </div>
            <p>You can not revert once you delete event</p>
          </div>

          <div className="button-wrap d-flex justify-content-end">
            <button
              type="button"
              className="btn btn-delete"
              onClick={() => {
                handleDelete(deleteId);
              }}
            >
              {isDeleteing ? (
                <>
                  <span
                    className="red-spinner spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden">Loading...</span>
                </>
              ) : (
                "Delete"
              )}
            </button>
            <button
              type="button"
              className="btn btn-bordered color-cancel ms-3"
              onClick={() => {
                closeDeleteModal();
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
