import React from "react";
import { useNavigate } from "react-router-dom";
import userIcon from "../../assets/images/user.png";
import Avatar from "./userAvatar";

export default function ProfileDropdown() {
  const navigate = useNavigate();

  let loggedinUserData = localStorage.getItem("loggedinUserData");
  loggedinUserData = JSON.parse(loggedinUserData);

  return (
    <>
      <div className="dropdown profile-dropdown d-none d-md-flex">
        <button
          className="profile-button d-flex align-items-center"
          type="button"
          id="dropdownprofile"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <p className="mb-0">{loggedinUserData && loggedinUserData.name}</p>
          {loggedinUserData ? (
            <Avatar name={loggedinUserData.name} />
          ) : (
            <img
              src={userIcon}
              alt=""
              width={45}
              height={45}
              className="ms-3"
            />
          )}
          {/* <Avatar name="aaa bbb" /> */}
        </button>
        <ul className="dropdown-menu" aria-labelledby="dropdownprofile">
          <li>
            <a className="dropdown-item" href="/settings/profile">
              Profile
            </a>
          </li>
          <li>
            <a
              onClick={() => {
                localStorage.clear();
                navigate("/login");
              }}
              className="dropdown-item"
            >
              Logout
            </a>
          </li>
        </ul>
      </div>

      <div
        className="mobile-dropdown-profile d-md-none"
        id="collapseProfileMenu"
      >
        <a
          className="collapsible collapsed d-flex align-items-center accordion-button"
          type="button"
          data-bs-toggle="collapse"
          href="#collapseProfile"
          aria-expanded="true"
          aria-controls="collapseProfile"
        >
          <div className="icon-wrap">
            {loggedinUserData ? (
              <Avatar name={loggedinUserData.name} />
            ) : (
              <img
                src={userIcon}
                alt=""
                width={45}
                height={45}
                className="ms-3"
              />
            )}
          </div>
          <div className="text-wrap flex-grow-1">{loggedinUserData && loggedinUserData.name}</div>
        </a>

        <div
          id="collapseProfile"
          className="collapse"
          aria-labelledby="headingOne"
          data-bs-parent="#collapseProfileMenu"
        >
          <ul className="subnav">
            <li>
              <a className="dropdown-item" href="/settings/profile">
                Edit Profile
              </a>
            </li>
            <li>
              <a
                onClick={() => {
                  localStorage.clear();
                  navigate("/login");
                }}
                className="dropdown-item"
              >
                Logout
              </a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}
