import axios from "axios";
import { BASE_URL } from "../actions/urls";

const axiosIns = axios.create({
  baseURL: BASE_URL,
});

axiosIns.interceptors.response.use(
  (response) => response,
  (error) => {
    // ** const { config, response: { status } } = error
    const { response } = error;
    
    if (response && response.status === 401) {
      axiosIns.defaults.headers.common["Authorization"] = "";
      localStorage.clear();
      window.location = window.location.origin + "/login";
    }
    return Promise.reject(error);
  }
);

export default axiosIns;
