import { useNavigate } from "react-router-dom";

export default function LeaveModal({ closeLeaveModal }) {
  const navigate = useNavigate();

  return (
    <div className="modal-dialog modal-dialog-centered">
      <div className="modal-content">
        <div className="modal-body">
          <div
            className={"formvawrapper delete-modal-wrap modal-setting-medium"}
          >
            <div className={"formstyle p-md-2"}>
              <div className="delete-body-wrap delete-language">
                <div className="heading">
                  <h3>Leave Scheduling an event?</h3>
                </div>
                <div className="text-normal">
                  <p>
                    To create a new experience you have to leave schedule event
                    page, all your data will be saved in draft.
                  </p>
                </div>
                <div className="button-wrap d-flex justify-content-end">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => {
                      closeLeaveModal();
                      navigate("/experiences/create-experience");
                    }}
                  >
                    Leave{" "}
                  </button>
                  <button
                    type="button"
                    className="btn btn-bordered color-cancel ms-3"
                    onClick={() => {
                      closeLeaveModal();
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
