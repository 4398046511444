import axios from "../utils/axios";
import { BASE_URL } from "./urls";

export const getTeamList = async (payload) => {
  const token = localStorage.getItem("token");
  const response = await axios({
    headers: { Authorization: `Bearer ${token}` },
    method: "POST",
    url: `${BASE_URL}/teams/getTeamList`,
    data: payload,
  });

  return response.data;
};

export const deleteTeam = async (payload) => {
  const token = localStorage.getItem("token");
  const response = await axios({
    headers: { Authorization: `Bearer ${token}` },
    method: "DELETE",
    url: `${BASE_URL}/teams/deleteTeam`,
    data: payload,
  });

  return response.data;
};

export const addTeam = async (payload) => {
  const token = localStorage.getItem("token");
  const response = await axios({
    headers: { Authorization: `Bearer ${token}` },
    method: "POST",
    url: `${BASE_URL}/teams/addTeam`,
    data: payload,
  });

  return response.data;
};

export const updateTeam = async (payload) => {
  const token = localStorage.getItem("token");
  const response = await axios({
    headers: { Authorization: `Bearer ${token}` },
    method: "PUT",
    url: `${BASE_URL}/teams/updateTeam`,
    data: payload,
  });

  return response.data;
};

export const getTeamDetail = async (payload) => {
  const token = localStorage.getItem("token");
  const response = await axios({
    headers: { Authorization: `Bearer ${token}` },
    method: "POST",
    url: `${BASE_URL}/teams/getTeamDetail`,
    data: payload,
    // params: payload,
  });

  return response.data;
};

export const addTeamMember = async (payload) => {
  const token = localStorage.getItem("token");
  const response = await axios({
    headers: { Authorization: `Bearer ${token}` },
    method: "POST",
    url: `${BASE_URL}/teams/addTeamMember`,
    data: payload,
  });

  return response.data;
};

export const updateTeamMember = async (payload) => {
  const token = localStorage.getItem("token");
  const response = await axios({
    headers: { Authorization: `Bearer ${token}` },
    method: "POST",
    url: `${BASE_URL}/teams/updateTeamMember`,
    data: payload,
  });

  return response.data;
};

export const deleteTeamMember = async (payload) => {
  const token = localStorage.getItem("token");
  const response = await axios({
    headers: { Authorization: `Bearer ${token}` },
    method: "DELETE",
    url: `${BASE_URL}/teams/deleteTeamMember`,
    data: payload,
  });

  return response.data;
};

export const leaveTeam = async (payload) => {
  const token = localStorage.getItem("token");
  const response = await axios({
    headers: { Authorization: `Bearer ${token}` },
    method: "post",
    url: `${BASE_URL}/teams/leaveTeam`,
    data: payload
  });

  return response.data;
};