import axios from "../utils/axios";
import { BASE_URL } from "./urls";

const token = localStorage.getItem("token");

export const getUsers = async (payload) => {
  const token = localStorage.getItem("token");
  const response = await axios({
    headers: { Authorization: `Bearer ${token}` },
    method: "POST",
    url: `${BASE_URL}/users/getUserList`,
    data: payload,
  });

  return response.data;
};
export const addUser = async (payload) => {
  const token = localStorage.getItem("token");
  const response = await axios({
    headers: { Authorization: `Bearer ${token}` },
    method: "POST",
    url: `${BASE_URL}/users/addUser`,
    data: payload,
  });

  return response.data;
};
export const deleteUser = async (payload) => {
  const token = localStorage.getItem("token");
  const response = await axios({
    headers: { Authorization: `Bearer ${token}` },
    method: "DELETE",
    url: `${BASE_URL}/users/deleteUser`,
    data: { id: payload },
  });

  return response.data;
};
export const editUser = async (payload) => {
  const token = localStorage.getItem("token");
  const response = await axios({
    headers: { Authorization: `Bearer ${token}` },
    method: "POST",
    url: `${BASE_URL}/users/updateUser`,
    data: payload,
  });

  return response.data;
};

export const changeStatus = async (payload) => {
  const token = localStorage.getItem("token");
  const response = await axios({
    headers: { Authorization: `Bearer ${token}` },
    method: "POST",
    url: `${BASE_URL}/users/changeStatus`,
    data: payload,
  });

  return response.data;
};

export const getUserDetail = async (payload) => {
  const token = localStorage.getItem("token");
  const response = await axios({
    headers: { Authorization: `Bearer ${token}` },
    method: "POST",
    url: `${BASE_URL}/users/getUserDetail`,
    data: payload,
  });

  return response.data;
};

export const updateProfileData = async (payload) => {
  const token = localStorage.getItem("token");
  const response = await axios({
    headers: { Authorization: `Bearer ${token}` },
    method: "POST",
    url: `${BASE_URL}/users/updateProfileData`,
    data: payload,
  });

  return response.data;
};
