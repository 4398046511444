import React, { useEffect, useState } from "react";
import DashboardHeader from "../common/DashboardHeader";
import DashboardSidebar from "../common/DashboardSidebar";
import Svg from "../common/Svg";

import { Link, useLocation, useNavigate } from "react-router-dom";
import TemplateDetailsModal from "../common/TemplateDetailsModal";
import { Formik } from "formik";
import { createExperienceSchema } from "../../Schema/Experience";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/ReactToastify.min.css";
import {
  getExperienceDetail,
  updateExperience,
} from "../../actions/experience";
import { getTemplates } from "../../actions/metaSpace";
import MediaSpotTooltip from "../common/MediaSpotTooltip";
import ExperienceSubHeader from "./ExperienceSubHeader";
import { useRef } from "react";

// import { EditText } from 'react-edit-text';
// import 'react-edit-text/dist/index.css';

export default function EditExperience() {
  let { state } = useLocation();
  const navigate = useNavigate();

  const [isFetchingDetail, setIsFetchingDetail] = useState(false);
  const [experianceDetail, setExperianceDetail] = useState(null);
  const [isFetchingMetaspaces, setIsFetchingMetaspaces] = useState(false);
  const [metaSpaceList, setMetaspaceList] = useState([]);
  const [selectedMetaspace, setSelectedMetaspace] = useState(null);
  const [isUpdatingExpereance, setIsUpdatingExpereance] = useState(false);

  const [metaspaceListServerParams, setMetaspacelistServerParams] = useState({
    startingLimit: 0,
    resultsPerPage: 100,
    search: "",
    sort_by: "template_name",
    sort_order: "asc",
  });
  const [searchMetaspace, setSearchMetaspace] = useState("");
  const [sortValue, setSortValue] = useState("Sort A-Z");
  const sortingOptions = [
    { id: 1, value: "asc", label: "Sort A-Z" },
    { id: 2, value: "desc", label: "Sort Z-A" },
  ];

  const formikRef = useRef();

  const getTemplatData = async (serverParams = metaspaceListServerParams) => {
    setIsFetchingMetaspaces(true);
    try {
      const res = await getTemplates(serverParams);
      if (res.statusCode === 200) {
        setMetaspaceList(res.data);
      } else {
        toast.error(res.msg);
      }
    } catch (error) {
      toast.error(error.response.data.msg);
    } finally {
      setIsFetchingMetaspaces(false);
    }
  };

  const fetchExperienceDetail = async (experienceId) => {
    setIsFetchingDetail(true);
    let dataToSend = {
      id: experienceId,
    };
    try {
      const res = await getExperienceDetail(dataToSend);
      if (res.statusCode === 200) {
        setExperianceDetail(res.data);
      } else {
        toast.error(res.msg);
      }
    } catch (error) {
      toast.error(error.response.data.msg);
    } finally {
      setIsFetchingDetail(false);
    }
  };

  const editingExperience = async (data) => {
    setIsUpdatingExpereance(true);
    try {
      const createRes = await updateExperience(data);
      if (createRes.statusCode === 200) {
        // toast.success(createRes.msg);
        navigate("/experiences/edit-experience/select-hotspot", {
          state: { experienceId: data.id, mode: "editMode" },
        });
      } else {
        toast.error(createRes.msg);
      }
    } catch (error) {
      toast.error(error.response.data.msg);
    } finally {
      setIsUpdatingExpereance(false);
    }
  };

  const editExperienceWithBradcrumb = (e, path) => {
    e.preventDefault();
    formikRef.current
      .validateForm()
      .then((res) => {
        if (Object.keys(res).length !== 0) {
          formikRef.current.handleSubmit();
        } else {
          let values = formikRef.current.values;
          let templateIds = values.templates.map((e) => e.id);
          let dataToSend = {
            id: values.id,
            name: values.name,
            description: values.description,
            templates: templateIds,
            default_template: values.templates[0].id,
          };
          updateExperience(dataToSend).then((response) => {
            navigate(path, {
              state: { experienceId: dataToSend.id, mode: "editMode" },
            });
          });
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const handleSearchMetasapce = (serachValue) => {
    setSearchMetaspace(serachValue);
    setMetaspacelistServerParams({
      ...metaspaceListServerParams,
      search: serachValue,
    });
    getTemplatData({ ...metaspaceListServerParams, search: serachValue });
  };

  const handleSorting = (sortValue) => {
    setMetaspacelistServerParams({
      ...metaspaceListServerParams,
      sort_order: sortValue,
    });
    getTemplatData({ ...metaspaceListServerParams, sort_order: sortValue });
  };

  useEffect(() => {
    getTemplatData();
    if (state) {
      fetchExperienceDetail(state);
    }
  }, [state]);

  const bredCrumbData = { experienceId: state, mode: "editMode" };

  return (
    <>
      <ToastContainer position="top-right" />

      <div className="d-flex flex-column main-outer-wrapper main-site-wrapper ">
        <DashboardHeader />
        <div id="viewport" className="d-flex flex-grow-1">
          <DashboardSidebar />
          {isFetchingDetail ? (
            <>
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Loading...</span>
            </>
          ) : (
            <>
              <div className="flex-grow-1 page-content-flex">
                {/* <ExperienceSubHeader editExperience data={bredCrumbData} handleClick={editExperienceWithBradcrumb}/> */}
                <div className="meeting-header">
                  <ul>
                    <li>
                      <Link
                        to={"/experiences/edit-experience"}
                        state={bredCrumbData}
                        content-attr="Edit experience"
                        className={"active"}
                        onClick={(e) =>
                          editExperienceWithBradcrumb(
                            e,
                            "/experiences/edit-experience"
                          )
                        }
                      >
                        Edit Experience
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={"/experiences/edit-experience/select-hotspot"}
                        state={bredCrumbData}
                        content-attr="Select mediaspots"
                        onClick={(e) => {
                          editExperienceWithBradcrumb(
                            e,
                            "/experiences/edit-experience/select-hotspot"
                          );
                        }}
                      >
                        Select Hotspots
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={
                          "/experiences/edit-experience/select-hotspot/add-media"
                        }
                        state={bredCrumbData}
                        content-attr="Add Media"
                        onClick={(e) => {
                          editExperienceWithBradcrumb(
                            e,
                            "/experiences/edit-experience/select-hotspot/add-media"
                          );
                        }}
                      >
                        Add Media
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={
                          "/experiences/edit-experience/select-hotspot/add-media/reorder"
                        }
                        content-attr="Reorder"
                        state={bredCrumbData}
                        onClick={(e) => {
                          editExperienceWithBradcrumb(
                            e,
                            "/experiences/edit-experience/select-hotspot/add-media/reorder"
                          );
                        }}
                      >
                        Reorder
                      </Link>
                    </li>
                  </ul>
                </div>
                <Formik
                  innerRef={formikRef}
                  initialValues={{
                    id: experianceDetail?.id,
                    name: experianceDetail?.name,
                    description: experianceDetail?.description,
                    templates: experianceDetail?.templates ?? [],
                    default_template: experianceDetail?.default_template,
                    isEnabled: true,
                  }}
                  validationSchema={createExperienceSchema}
                  onSubmit={(values, { setSubmitting, resetForm }) => {
                    let templateIds = values.templates.map((e) => e.id);
                    let dataToSend = {
                      id: values.id,
                      name: values.name,
                      description: values.description,
                      templates: templateIds,
                      default_template: values.templates[0].id,
                    };
                    editingExperience(dataToSend);
                    setSubmitting(false);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                    isSubmitting,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <div className="page-content-wrap">
                        <div className="heading-section page-heading d-md-flex exp-button-wrapper">
                          <div className="flex-grow-1">
                            <nav
                              aria-label="breadcrumb"
                              className="vmersive-breadcrumbs"
                            >
                              <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                  <Link to="/experiences">Experiences</Link>
                                </li>
                                <li
                                  className="breadcrumb-item active"
                                  aria-current="page"
                                >
                                  Edit Experience
                                </li>
                              </ol>
                            </nav>
                            <h1>Edit Experience</h1>
                            <p className="pt-2">
                              Prepare experience templates or repurpose past
                              experiences
                            </p>
                          </div>
                          <div className="button-wrap mt-3 mt-md-0">
                            <button
                              type="submit"
                              disabled={isUpdatingExpereance}
                              className="btn btn-primary add-btn"
                            >
                              {isUpdatingExpereance ? (
                                <>
                                  <span
                                    className="white-spinner spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                  ></span>
                                  <span className="visually-hidden">
                                    Loading...
                                  </span>
                                </>
                              ) : (
                                <>
                                  Edit hotspots
                                  <Svg name="right-arrow" />
                                </>
                              )}
                            </button>
                          </div>
                        </div>
                        <div className="exp-button-wrapper-outer">
                          <div className="page-wrapper">
                            <div className="new-experience-wrap exp-button-wrapper">
                              <div className="expt-input-wrapper d-md-flex">
                                <h6 className="mb-0 exp-name">
                                  Experience name
                                </h6>
                                <div className="flex-grow-1 input-outer">
                                  <div className="inputs-wrap account-info">
                                    <h4 className="mb-2 position-relative">
                                      <input
                                        type="text"
                                        className="form-control title"
                                        placeholder="Enter your experience name here"
                                        name="name"
                                        value={values.name}
                                        onChange={handleChange}
                                      />
                                      {errors.name && touched.name && (
                                        <div className="validation-msg">
                                          {errors.name}
                                        </div>
                                      )}
                                    </h4>

                                    <p className="mb-0 position-relative">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Add description"
                                        name="description"
                                        value={values.description}
                                        onChange={handleChange}
                                      />
                                      {errors.description &&
                                        touched.description && (
                                          <div className="validation-msg">
                                            {errors.description}
                                          </div>
                                        )}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="expt-input-wrapper d-md-flex align-items-center">
                                <h6 className="mb-0">MetaSpaces</h6>
                                <div className="flex-grow-1 input-outer">
                                  <div className="placeholder-text">
                                    <p>
                                      {values.templates.length === 0 &&
                                        "Your selected MetaSpaces will apper here"}
                                    </p>
                                  </div>
                                  <div className="chips-wrap d-flex flex-wrap align-items-center">
                                    {values.templates && (
                                      <>
                                        {values.templates.map(
                                          (
                                            selectedMetaspace,
                                            metaspaceIndex
                                          ) => (
                                            <span
                                              className="chips d-flex align-items-center"
                                              key={metaspaceIndex}
                                            >
                                              <p>{selectedMetaspace.name}</p>
                                              <button
                                                type="button"
                                                onClick={() => {
                                                  const tempArray =
                                                    values.templates.filter(
                                                      (ele) =>
                                                        ele.id !==
                                                        selectedMetaspace.id
                                                    );
                                                  setFieldValue(
                                                    "templates",
                                                    tempArray
                                                  );
                                                }}
                                              >
                                                <Svg name="times-icon" />
                                              </button>
                                            </span>
                                          )
                                        )}
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="templates-wrapper">
                              <div className="heading-section page-heading">
                                <div className="d-flex">
                                  <h3>Select MetaSpaces</h3>
                                  <MediaSpotTooltip />
                                </div>
                                <p className="pt-2">
                                  Choose the Metaspaces which you want to use in
                                  your experience. Each MetaSpace has predefined
                                  Hotspots and media spots which you can
                                  customize for your experience.
                                </p>
                                {/* <div className="d-flex align-items-center pt-2">
                                  <Svg name="i-icon" />
                                  <p className="flex-grow-1 ps-1">
                                    Note: You can only select metaspaces from
                                    one build
                                  </p>
                                </div> */}
                              </div>
                              <div className="tab-search search-filter d-flex align-items-center">
                                <div className="flex-grow-1 search-filter-padding">
                                  <input
                                    type="text"
                                    className="form-control custom-search-input"
                                    placeholder="Search metaspaces"
                                    value={searchMetaspace}
                                    onChange={(e) => {
                                      handleSearchMetasapce(e.target.value);
                                    }}
                                  />
                                </div>
                                <div className="drop-wrap d-flex align-items-center">
                                  <label>Sort By - </label>
                                  <div className="dropdown table-dropdown ques-options">
                                    <button
                                      className="btn dropdown-tgl"
                                      type="button"
                                      id="sort-by"
                                      data-bs-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                    >
                                      {sortValue}
                                    </button>
                                    <div
                                      className="dropdown-menu"
                                      aria-labelledby="sort-by"
                                    >
                                      {sortingOptions.map((sort) => (
                                        <a
                                          className="dropdown-item"
                                          href="#"
                                          key={sort.id}
                                          onClick={() => {
                                            handleSorting(sort.value);
                                            setSortValue(sort.label);
                                          }}
                                        >
                                          {sort.label}
                                        </a>
                                      ))}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {isFetchingMetaspaces ? (
                                <>
                                  <span
                                    className="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                  ></span>
                                  <span className="visually-hidden">
                                    Loading...
                                  </span>
                                </>
                              ) : (
                                <div className="template-outer-wrapper">
                                  {metaSpaceList ? (
                                    <>
                                      {metaSpaceList &&
                                        Object.entries(metaSpaceList).map(
                                          (metaSpace, metaSpaceIndex) => (
                                            <div
                                              className="temp-wrapper"
                                              key={metaSpaceIndex}
                                            >
                                              <h4 className="title">
                                                {metaSpace[0]}
                                              </h4>
                                              <div className="templates-row row">
                                                {Object.values(
                                                  metaSpace[1]
                                                ).map((space, spaceIndex) => (
                                                  <div
                                                    className="col-xxl-3 col-xl-4 col-md-6 mb-3"
                                                    key={spaceIndex}
                                                  >
                                                    <div className="template-card">
                                                      <div className="img-wrap">
                                                        <img
                                                          src={space.image}
                                                          className="img-fluid"
                                                          alt=""
                                                        />
                                                        <input
                                                          className="form-check-input"
                                                          type="checkbox"
                                                          id="templates"
                                                          name="templates"
                                                          checked={
                                                            values.templates.filter(
                                                              (temp) =>
                                                                temp.id ===
                                                                space.id
                                                            ).length > 0
                                                          }
                                                          onChange={(v) => {
                                                            if (
                                                              v.target.checked
                                                            ) {
                                                              setFieldValue(
                                                                "templates",
                                                                [
                                                                  ...values.templates,
                                                                  {
                                                                    id: space.id,
                                                                    name: space.template_name,
                                                                  },
                                                                ]
                                                              );
                                                            } else {
                                                              const tempArray =
                                                                values.templates.filter(
                                                                  (temp) => {
                                                                    return (
                                                                      temp.id !==
                                                                      space.id
                                                                    );
                                                                  }
                                                                );
                                                              setFieldValue(
                                                                "templates",
                                                                tempArray
                                                              );
                                                            }
                                                          }}
                                                        />
                                                        <div className="d-flex align-items-center button-wrap">
                                                          <button
                                                            type="button"
                                                            className="btn btn-primary"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#modalDetails"
                                                            srno="1"
                                                            value="1"
                                                            onClick={() => {
                                                              setSelectedMetaspace(
                                                                space.id
                                                              );
                                                            }}
                                                          >
                                                            View MetaSpace
                                                          </button>
                                                        </div>
                                                      </div>
                                                      <div className="text-wrapper">
                                                        <h5>
                                                          {space?.template_name}
                                                        </h5>
                                                      </div>
                                                    </div>
                                                  </div>
                                                ))}
                                              </div>
                                            </div>
                                          )
                                        )}
                                    </>
                                  ) : (
                                    <>No Data Found</>
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </>
          )}
        </div>
      </div>
      <div
        className="customvar-modal modal fade modalDetails"
        id="modalDetails"
        tabIndex={-1}
        aria-labelledby="modalDetailsLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-details-width">
          <div className="modal-content">
            <TemplateDetailsModal
              data={selectedMetaspace}
              key={selectedMetaspace}
            />
          </div>
        </div>
      </div>
    </>
  );
}
