import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import DashboardHeader from "../../common/DashboardHeader";
import DashboardSidebar from "../../common/DashboardSidebar";
import Svg from "../../common/Svg";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/ReactToastify.min.css";
import {
  getExperienceDetail,
  getMediaSpotList,
  getSelectedMediaSpot,
  updateStructure,
} from "../../../actions/experience";
import merge from "lodash/merge";
import ContentLoader from "react-content-loader";
import ExperienceSubHeader from "../ExperienceSubHeader";

const Loader = (props) => (
  <ContentLoader viewBox="0 0 100% 100%" height={600} width="100%" {...props}>
    <rect x="0" y="20" rx="0" ry="0" width="380" height="190" />
    <rect x="0" y="225" rx="0" ry="0" width="380" height="30" />
    <rect x="0" y="270" rx="0" ry="0" width="380" height="50" />
    <rect x="410" y="20" rx="0" ry="0" width="380" height="190" />
    <rect x="410" y="225" rx="0" ry="0" width="380" height="30" />
    <rect x="410" y="270" rx="0" ry="0" width="380" height="50" />
    <rect x="820" y="20" rx="0" ry="0" width="380" height="190" />
    <rect x="820" y="225" rx="0" ry="0" width="380" height="30" />
    <rect x="820" y="270" rx="0" ry="0" width="380" height="50" />
    <rect x="1220" y="20" rx="0" ry="0" width="380" height="190" />
    <rect x="1220" y="225" rx="0" ry="0" width="380" height="30" />
    <rect x="1220" y="270" rx="0" ry="0" width="380" height="50" />
  </ContentLoader>
);

export default function SelectMediaspot() {
  const { state } = useLocation();
  const navigate = useNavigate();

  const [isFeatchingMediaspotDetail, setIsFeatchingMMediaspotDetail] =
    useState(true);

  const [isFetchingDetail, setIsFetchingDetail] = useState(false);
  const [isUploading, setIsUploading] = useState(true);
  const [isUpdatingStructure, setIsUpdatingStructure] = useState(false);

  const [experianceDetail, setExperianceDetail] = useState(null);
  const [spotAreas, setSpotAreas] = useState([]);
  const [selectedSpotAreaId, setSelectedSpotAreaId] = useState(null);

  const [templates, setTemplates] = useState({});

  const [isEditing, setIsEditing] = useState({});

  const fetchExperienceDetail = async (experienceId) => {
    setIsFetchingDetail(true);
    let dataToSend = {
      id: experienceId,
    };
    try {
      const res = await getExperienceDetail(dataToSend);
      if (res.statusCode === 200) {
        setExperianceDetail(res.data);
        setSpotAreas(res.data.templates);
        setSelectedSpotAreaId(res.data.templates[0].id);
      } else {
        toast.error(res.msg);
      }
    } catch (error) {
      toast.error(error);
    } finally {
      setIsFetchingDetail(false);
    }
  };

  const updateTemplates = (templateId, templateData) => {
    setTemplates((prevState) => {
      return {
        ...prevState,
        [templateId]: templateData,
      };
    });
  };

  const mergeTemplateData = (masterData, experienceData, templateId) => {
    if (typeof experienceData === "string") {
      experienceData = JSON.parse(experienceData);
    }
    // if has no data then clone master data else merge data
    if (
      !Object.prototype.hasOwnProperty.call(experienceData, "teleport_count")
    ) {
      return masterData;
    } else {
      // load alredy stored data other wise get data then load - because data might be modified by user
      if (Object.prototype.hasOwnProperty.call(templates, templateId)) {
        return templates[templateId];
      }
      // merge data here
      let mergedData = merge(masterData, experienceData);
      mergedData = {
        ...mergedData,
        media_count: Object.keys(mergedData.media_spots).length,
        teleport_count: Object.keys(mergedData.teleport_points).length,
      };
      return mergedData;
    }
  };

  const getSelectedMediaspotList = async (templateId) => {
    return new Promise(async (resolve, reject) => {
      setIsFeatchingMMediaspotDetail(true);
      let dataToSend = {
        t_id: templateId,
      };
      try {
        const masterHotspot = await getMediaSpotList(dataToSend);
        if (masterHotspot.statusCode === 200) {
          let dataToSend = {
            id: state.experienceId,
            t_id: templateId,
          };
          const experienceHotspot = await getSelectedMediaSpot(dataToSend);
          if (experienceHotspot.statusCode === 200) {
            let newTemplateData = mergeTemplateData(
              masterHotspot.data[0].template_structure,
              experienceHotspot.data.structure,
              templateId
            );
            updateTemplates(templateId, newTemplateData);
            resolve(newTemplateData);
          } else {
            toast.error(experienceHotspot.msg);
            reject("no data");
          }
        } else {
          toast.error(masterHotspot.msg);
          reject("no data");
        }
      } catch (error) {
        toast.error(error);
        reject("no data");
      } finally {
        setIsFeatchingMMediaspotDetail(false);
      }
    });
  };

  const changeSpotArea = async (areaId) => {
    await updateMediaspotStructure(
      selectedSpotAreaId,
      templates[selectedSpotAreaId]
    );
    setSelectedSpotAreaId(areaId);
  };

  const updateMediaspotStructure = async (templateId, templateStrucuture) => {
    return new Promise(async (resolve, reject) => {
      setIsUpdatingStructure(true);
      let dataToSend = {
        id: state.experienceId,
        template_id: templateId,
        structure: JSON.stringify(templateStrucuture),
      };
      try {
        const res = await updateStructure(dataToSend);
        if (res.statusCode === 200) {
          resolve(res);
        } else {
          toast.error(res.msg);
        }
      } catch (error) {
        toast.error(error);
        reject(error);
      } finally {
        setIsUpdatingStructure(false);
      }
    });
  };

  const experienceStructureUpdate = async () => {
    try {
      for (const spotAreasObj of spotAreas) {
        // load alredy stored data other wise get data then load
        if (Object.prototype.hasOwnProperty.call(templates, spotAreasObj.id)) {
          let dataToSend = {
            id: state.experienceId,
            template_id: spotAreasObj.id,
            structure: JSON.stringify(templates[spotAreasObj.id]),
          };
          await updateStructure(dataToSend);
        } else {
          const tempStructure = await getSelectedMediaspotList(spotAreasObj.id);

          let dataToSend = {
            id: state.experienceId,
            template_id: spotAreasObj.id,
            structure: JSON.stringify(tempStructure),
          };
          await updateStructure(dataToSend);
        }
      }
    } catch (error) {
      console.log("select media spot 204", error);
    }
  };

  const uploadtemplateStructure = async () => {
    setIsUploading(true);
    try {
      experienceStructureUpdate();
      if (state.mode === "editMode") {
        navigate("/experiences/edit-experience/select-hotspot/add-media", {
          state: state,
        });
      } else {
        navigate("/experiences/create-experience/select-hotspot/add-media", {
          state: state,
        });
      }
    } catch (error) {
      console.log("uploadtemplateStructure = ", error);
    } finally {
      setIsUploading(false);
    }
  };

  useEffect(() => {
    if (state && state.experienceId) {
      fetchExperienceDetail(state.experienceId);
    }
  }, [state]);

  useEffect(() => {
    if (
      selectedSpotAreaId &&
      !Object.prototype.hasOwnProperty.call(templates, selectedSpotAreaId)
    ) {
      getSelectedMediaspotList(selectedSpotAreaId);
    }
  }, [selectedSpotAreaId]);

  return (
    <>
      <ToastContainer position="top-right" />
      <div className="d-flex flex-column main-outer-wrapper main-site-wrapper ">
        <DashboardHeader />
        <div id="viewport" className="d-flex flex-grow-1">
          <DashboardSidebar />
          <div className="flex-grow-1 page-content-flex">
            <ExperienceSubHeader
              selectMediaSpot
              redirect={false}
              data={state}
              handleClick={experienceStructureUpdate}
            />
            {experianceDetail && (
              <div className="page-content-wrap">
                <div className="heading-section page-heading d-lg-flex exp-button-wrapper border-0 pb-0 mb__20 align-items-start">
                  <div className="flex-grow-1">
                    {/* <h6 className="pb-2">
                      <strong>Experience: </strong>
                      {!isFetchingDetail && experianceDetail.name}
                    </h6> */}
                    <nav
                      aria-label="breadcrumb"
                      className="vmersive-breadcrumbs mb-2"
                    >
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                          <Link to="/experiences">Experiences</Link>
                        </li>
                        <li className="breadcrumb-item">
                          <Link
                            to={
                              state.mode === "editMode"
                                ? "/experiences/edit-experience"
                                : "/experiences/create-experience"
                            }
                            state={
                              state.mode === "editMode"
                                ? experianceDetail && experianceDetail.id
                                : {
                                    experienceId: experianceDetail
                                      ? experianceDetail.id
                                      : "",
                                  }
                            }
                          >
                            {experianceDetail && experianceDetail.name}
                          </Link>
                        </li>
                        <li
                          className="breadcrumb-item active"
                          aria-current="page"
                        >
                          <Link
                            to="/experiences/create-experience/select-hotspot"
                            state={experianceDetail.id}
                          >
                            Select Hotspots
                          </Link>
                        </li>
                      </ol>
                    </nav>
                    <h1>Select Hotspots </h1>
                  </div>

                  <div className="button-wrap mt-3 mt-lg-0 d-flex">
                    <Link
                      to={
                        state.mode === "editMode"
                          ? "/experiences/edit-experience"
                          : "/experiences/create-experience"
                      }
                      state={
                        state.mode === "editMode"
                          ? experianceDetail.id
                          : { experienceId: experianceDetail.id }
                      }
                      className="btn btn-bordered color-cancel add-btn me-3"
                    >
                      {" "}
                      <Svg name="left-arrow" /> Back{" "}
                    </Link>
                    <button
                      onClick={() => {
                        uploadtemplateStructure();
                      }}
                      className="btn btn-primary add-btn"
                    >
                      {" "}
                      {isUpdatingStructure ? (
                        <>
                          <span
                            className="white-spinner spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          <span className="visually-hidden">Loading...</span>
                        </>
                      ) : (
                        <>
                          {" "}
                          Add media <Svg name="right-arrow" />{" "}
                        </>
                      )}{" "}
                    </button>
                  </div>
                </div>

                <div className="nav-tabs-wrapper vmersive-tabs-2">
                  <ul
                    className="nav nav-pills mb__20"
                    id="meetings"
                    role="tablist"
                  >
                    {spotAreas.length > 0 &&
                      spotAreas.map((area, areaIndex) => (
                        <li
                          className="nav-item"
                          role="presentation"
                          key={areaIndex}
                        >
                          <button
                            className={
                              selectedSpotAreaId === area.id
                                ? "nav-link active"
                                : "nav-link"
                            }
                            id={`tab-${areaIndex}-tab`}
                            data-bs-toggle="tab"
                            data-bs-target={`#tab-${areaIndex}`}
                            type="button"
                            role="tab"
                            aria-controls={`tab-${areaIndex}`}
                            aria-selected="false"
                            onClick={() => {
                              changeSpotArea(area.id);
                            }}
                          >
                            {area.name}
                          </button>
                        </li>
                      ))}
                  </ul>
                </div>
                <hr className="dark-20" />
                <div className="page-wrapper">
                  <div className="tab-content" id="meetingsContent">
                    {spotAreas.length > 0 &&
                      spotAreas.map((area, areaIndex) => (
                        <div
                          className={
                            area.id === selectedSpotAreaId
                              ? "tab-pane fade show active"
                              : "tab-pane fade"
                          }
                          id={`tab-${areaIndex}`}
                          role="tabpanel"
                          aria-labelledby={`tab-${areaIndex}-tab`}
                          key={`tab-${areaIndex}`}
                        >
                          <div className="templates-wrapper">
                            {/* <div className="select-hotspot-text">
                                  <p>{area.name} - Select hotspot areas</p>
                                </div> */}
                            {isFeatchingMediaspotDetail ? (
                              <Loader />
                            ) : (
                              <div className="templates-row row">
                                {templates &&
                                  templates[selectedSpotAreaId] &&
                                  Object.entries(
                                    templates[selectedSpotAreaId]
                                      .teleport_points
                                  ).map((teleport, teleportIndex) => (
                                    <div
                                      className="col-xxl-3 col-xl-4 col-md-6 mb-3"
                                      key={teleportIndex}
                                    >
                                      <div className="template-card">
                                        <div className="img-wrap">
                                          <img
                                            src={teleport[1].image_url}
                                            className="img-fluid"
                                            alt={teleport[1].name}
                                            //  onError={({
                                            //     currentTarget,
                                            //   }) => {
                                            //     currentTarget.onerror = null; // prevents looping
                                            //     currentTarget.src = empty;
                                            //   }}
                                          />
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id="template1-1"
                                            name="chooseTemplate"
                                            defaultChecked={
                                              teleport[1].isActive === 1
                                                ? true
                                                : false
                                            }
                                            onChange={(e) => {
                                              if (e.target.checked === false) {
                                                setTemplates((templates) => {
                                                  templates[
                                                    selectedSpotAreaId
                                                  ].teleport_points[
                                                    teleport[0]
                                                  ].isActive = 0;
                                                  return { ...templates };
                                                });
                                              } else {
                                                setTemplates((templates) => {
                                                  templates[
                                                    selectedSpotAreaId
                                                  ].teleport_points[
                                                    teleport[0]
                                                  ].isActive = 1;
                                                  return { ...templates };
                                                });
                                              }
                                            }}
                                          />
                                        </div>
                                        <div className="text-wrapper">
                                          {teleport[1].isEditingName ? (
                                            <input
                                              type="text"
                                              name="name"
                                              defaultValue={teleport[1].name}
                                              onBlur={(e) => {
                                                console.log(e);
                                                setTemplates((templates) => {
                                                  templates[
                                                    selectedSpotAreaId
                                                  ].teleport_points[
                                                    teleport[0]
                                                  ].isEditingName = false;
                                                  return { ...templates };
                                                });
                                              }}
                                              onChange={(e) => {
                                                setTemplates((templates) => {
                                                  templates[
                                                    selectedSpotAreaId
                                                  ].teleport_points[
                                                    teleport[0]
                                                  ].name = e.target.value;
                                                  return { ...templates };
                                                });
                                              }}
                                            />
                                          ) : (
                                            <h5
                                              title={teleport[1].name}
                                              onClick={() => {
                                                setTemplates((templates) => {
                                                  templates[
                                                    selectedSpotAreaId
                                                  ].teleport_points[
                                                    teleport[0]
                                                  ].isEditingName = true;
                                                  return { ...templates };
                                                });
                                              }}
                                            >
                                              {teleport[1].name}
                                            </h5>
                                          )}
                                          <div className="description">
                                            <textarea
                                              className={
                                                teleport[1].description.length >
                                                200
                                                  ? "form-control error"
                                                  : "form-control"
                                              }
                                              name="description"
                                              defaultValue={
                                                teleport[1].description
                                              }
                                              placeholder="Add hotspot description"
                                              onChange={(e) => {
                                                setTemplates((templates) => {
                                                  templates[
                                                    selectedSpotAreaId
                                                  ].teleport_points[
                                                    teleport[0]
                                                  ].description =
                                                    e.target.value;
                                                  return { ...templates };
                                                });
                                              }}
                                            />
                                            <div className="form-text text-end word-count">
                                              {teleport[1].description.length}
                                              /200
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                              </div>
                            )}
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
