import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { getMeetingDetail } from "../../actions/meeting";
import DashboardHeader from "../common/DashboardHeader";
import DashboardSidebar from "../common/DashboardSidebar";
import ScheduleHeader from "./ScheduleHeader";
import Svg from "../common/Svg";

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

const moment = require("moment");

export default function Share() {
  const { state } = useLocation();

  const [meetingDetail, setMeetingDetail] = useState(null);
  const [isFetchingMeetingDetail, setIsfetchingMeetingDetail] = useState(false);

  const fetchMeetingDetail = async (meetingId) => {
    setIsfetchingMeetingDetail(true);
    let dataToSend = {
      id: meetingId,
    };
    try {
      const meetingDetailResponse = await getMeetingDetail(dataToSend);
      if (meetingDetailResponse.statusCode === 200) {
        setMeetingDetail(meetingDetailResponse.data[0]);
      }
      setIsfetchingMeetingDetail(false);
    } catch (error) {
      toast.error(error.response.data.msg);
    }
  };

  useEffect(() => {
    fetchMeetingDetail(state);
  }, []);

  return (
    <>
      <ToastContainer position="top-right" />
      <div className="d-flex flex-column main-outer-wrapper main-site-wrapper">
        <DashboardHeader />
        <div id="viewport" className="d-flex flex-grow-1">
          <DashboardSidebar />
          <div className="flex-grow-1 page-content-flex">
            <ScheduleHeader share eventId={state} />
            <div className="page-content-wrap">
              <div className="heading-section page-heading mb-3">
                <h1>Share</h1>
              </div>
              {isFetchingMeetingDetail ? (
                <>
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden">Loading...</span>
                </>
              ) : (
                <>
                  {meetingDetail ? (
                    <div className="share-wrapper">
                      <div className="share-list">
                        <div className="share-inner align-items-start">
                          <div className="title">Topic:</div>
                          <div className="data ps-md-3 ps-0">
                            <span>{meetingDetail.event_name}</span>
                            <p>{meetingDetail.event_description}</p>
                          </div>
                        </div>
                      </div>
                      <div className="share-list">
                        <div className="share-inner">
                          <div className="title">Date:</div>
                          <div className="data ps-md-3 ps-0">
                            {moment(meetingDetail.event_date_time).format(
                              "MMM DD, YYYY"
                            )}
                          </div>
                        </div>
                        <div className="share-inner">
                          <div className="title">Time:</div>
                          <div className="data ps-md-3 ps-0">
                            {moment(meetingDetail.event_date_time).format(
                              "hh:mm A"
                            )}
                          </div>
                        </div>
                        <div className="share-inner">
                          <div className="title">Timezone:</div>
                          <div className="data ps-md-3 ps-0">
                            {moment(meetingDetail.event_date_time).format(
                              "([GMT]Z)"
                            )}
                            , {Intl.DateTimeFormat().resolvedOptions().timeZone}
                          </div>
                        </div>

                      </div>
                      <div className="share-list">
                        <div className="share-inner d-none">
                          <div className="title">Event ID:</div>
                          <div className="data ps-md-3 ps-0">
                            {meetingDetail.id}
                          </div>
                        </div>
                        <div className="share-inner">
                          <div className="title">Event ID:</div>
                          <div className="data ps-md-3 ps-0">
                            {meetingDetail.event_code}
                          </div>
                        </div>
                      </div>
                      <div className="share-list">
                        <div className="share-inner">
                          {/* <div className="title order-2 order-md-1">
                            <button
                              type="button"
                              className="btn btn-primary w-100"
                            >
                              Share
                            </button>
                          </div> */}
                          <div className="title">Copy Link:</div>

                          <div className="data d-flex align-items-center ps-md-3 ps-0">
                            <div className="form-group mb-0">
                              <input
                                type="text"
                                className="form-control"
                                defaultValue={meetingDetail.link}
                                readOnly
                              />
                            </div>


                            <OverlayTrigger overlay={<Tooltip>Copy</Tooltip>}>
                              <button
                                className="action-btn copy-link"
                                onClick={() => {
                                  navigator.clipboard.writeText(meetingDetail.link);
                                }}
                              >
                                <Svg name="icon-copy-3" />
                              </button>
                            </OverlayTrigger>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>No Data Found</>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
