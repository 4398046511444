import React, { useState } from "react";
import DashboardSidebar from './DashboardSidebar'
import Logo from './Logo'
import ProfileDropdown from './ProfileDropdown'
import { Navigation } from "react-minimal-side-navigation";
import "react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css";
import userIcon from '../../assets/images/user.png';


export default function DashboardHeader() {

    const [isLeftSidebarOpen, setisLeftSidebarOpen] = useState(false);

    return (
        <>
            <div onClick={() => setisLeftSidebarOpen(false)} className={`body-overlay ${isLeftSidebarOpen ? "block" : "hidden"}`} />



            <div className='site-header border-bottom'>
                <div className="row align-items-center">
                    <div className="col-6">
                        <Logo />
                    </div>
                    <div className="col-6">
                        <div className="right-menu-wrap align-items-center d-flex justify-content-end">
                            {/* <div className="dropdown collection-dropdown">
                            <button type="button">
                                <Svg name="add-meeting-dark" /> New Event
                            </button>
                        </div> */}
                            {/* <NotificationDropdown /> */}
                            <ProfileDropdown />
                            <button className={`sideb-toggle d-lg-none ${isLeftSidebarOpen ? "open" : ""
                                }`} onClick={(): void => setisLeftSidebarOpen(true)}>
                                <span className="hamburger hamburger-1" />
                                <span className="hamburger hamburger-2" />
                                <span className="hamburger hamburger-3" />
                            </button>
                        </div>
                    </div>
                </div>
            </div >

            <div
                className={`left-navigation-menu transition ease-out transform ${isLeftSidebarOpen ? "ease-out translate-x-0" : "ease-in translate-x-full"
                    }`}
            >   <ProfileDropdown />
                <DashboardSidebar />
            </div>
        </>
    )
}
