import React, { useEffect, useState } from "react";
import DashboardHeader from "../../common/DashboardHeader";
import DashboardSidebar from "../../common/DashboardSidebar";
import { Link, useNavigate } from "react-router-dom";
import Svg from "../../common/Svg";
import { toast } from "react-toastify";
import { deleteTeam, getTeamList, leaveTeam } from "../../../actions/team";
import ContentLoader from "react-content-loader";
import Pagination from "../../common/Pagination";

const Loader = (props) => (
  <ContentLoader viewBox="0 0 100% 100%" height={600} width="100%" {...props}>
    <rect x="0" y="0" rx="0" ry="0" width="380" height="200" />
    <rect x="0" y="215" rx="0" ry="0" width="380" height="23" />
    <rect x="0" y="245" rx="0" ry="0" width="380" height="23" />
    <rect x="410" y="0" rx="0" ry="0" width="380" height="200" />
    <rect x="410" y="215" rx="0" ry="0" width="380" height="23" />
    <rect x="410" y="245" rx="0" ry="0" width="380" height="23" />
    <rect x="820" y="0" rx="0" ry="0" width="380" height="200" />
    <rect x="820" y="215" rx="0" ry="0" width="380" height="23" />
    <rect x="820" y="245" rx="0" ry="0" width="380" height="23" />
    <rect x="1220" y="0" rx="0" ry="0" width="380" height="200" />
    <rect x="1220" y="215" rx="0" ry="0" width="380" height="23" />
    <rect x="1220" y="245" rx="0" ry="0" width="380" height="23" />
  </ContentLoader>
);

export default function Teams() {
  const [list, setList] = useState([]);
  const [isFetchingList, setIsFetchingList] = useState(false);
  const [serverParams, setServerParams] = useState({
    startingLimit: 0,
    resultsPerPage: 8,
    search: "",
    sort_by: "team_name",
    sort_order: "asc",
  });
  const [deleteTeamData, setDeleteTeamData] = useState(null);
  const [isDeleteing, setIsDeleteing] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [sortValue, setSortValue] = useState("Recently added");
  const sortingOptions = [
    { id: 1, value: "asc", label: "Sort A-Z" },
    { id: 2, value: "desc", label: "Sort Z-A" },
  ];

  const navigate = useNavigate();

  const handleSorting = (sortValue) => {
    setServerParams({ ...serverParams, sort_order: sortValue });
    getTeams({ ...serverParams, sort_order: sortValue });
  };

  const getTeams = async (serverParams) => {
    setIsFetchingList(true);
    try {
      const res = await getTeamList(serverParams);
      if (res.statusCode === 200) {
        setList(res.data);
        let RoundedTotal = Math.ceil(
          res.total_count / serverParams.resultsPerPage
        );
        setTotalPage(RoundedTotal);
      } else {
        toast.error(res.msg);
      }
    } catch (error) {
      toast.error(error.msg);
    } finally {
      setIsFetchingList(false);
    }
  };

  const openDeleteModal = (team) => {
    setDeleteTeamData(team);
    let deleteModal = document.getElementById("deleteModal");
    let modal = window.bootstrap.Modal.getOrCreateInstance(deleteModal);
    if (modal && !modal._isShown) {
      modal.show();
    }
  };

  const closeDeleteModal = () => {
    let deleteModal = document.getElementById("deleteModal");
    let modal = window.bootstrap.Modal.getOrCreateInstance(deleteModal);
    if (modal && modal._isShown) {
      modal.hide();
    }
    setDeleteTeamData(null);
  };

  const dropTeam = async (teamId) => {
    setIsDeleteing(true);
    try {
      let dataToSend = {
        team_id: teamId,
      };
      const res = await deleteTeam(dataToSend);
      console.log(res);
      if (res.statusCode === 200) {
        closeDeleteModal();
        getTeams(serverParams);
      } else {
        toast.error(res.msg);
      }
    } catch (error) {
      toast.error(error.msg);
    } finally {
      setIsDeleteing(false);
    }
  };

  const changePage = (page) => {
    setCurrentPage(page);
    setServerParams({
      ...serverParams,
      startingLimit: page === 1 ? 0 : (page - 1) * serverParams.resultsPerPage,
    });
    getTeams({
      ...serverParams,
      startingLimit: page === 1 ? 0 : (page - 1) * serverParams.resultsPerPage,
    });
  };

  const gotoPreviousPage = () => {
    let pageStarting = 0;
    setCurrentPage(currentPage - 1);
    if (currentPage === 1) {
      setServerParams({ ...serverParams, startingLimit: 0 });
    } else {
      pageStarting = serverParams.startingLimit - serverParams.resultsPerPage;
    }
    setServerParams({ ...serverParams, startingLimit: pageStarting });
    getTeams({ ...serverParams, startingLimit: pageStarting });
  };

  const geToNextPage = () => {
    setCurrentPage(currentPage + 1);
    changePage(currentPage + 1);
  };

  const teamLeave = (teamID) => {
    let dataToSend = { team_id: teamID };
    leaveTeam(dataToSend).then((res) => {
      console.log(res);
      getTeams(serverParams);
    });
  };

  useEffect(() => {
    getTeams(serverParams);
  }, []);

  return (
    <>
      <div className="d-flex flex-column main-outer-wrapper main-site-wrapper ">
        <DashboardHeader />
        <div id="viewport" className="d-flex flex-grow-1">
          <DashboardSidebar />
          <div className="flex-grow-1 page-content-flex">
            <div className="page-content-wrap">
              <div className="page-heading d-md-flex align-items-center">
                <div className="flex-grow-1">
                  <h1>Teams</h1>
                  <p className="pt-2">
                    Create a team to work on shared projects.
                  </p>
                </div>
                <div className="button-wrap mt-3 mt-md-0">
                  <Link
                    to="/settings/teams/create"
                    className="btn btn-primary add-btn"
                  >
                    <Svg name="plus" /> Add new{" "}
                  </Link>
                </div>
              </div>
              <div className="role-wrapper flex-grow-1">
                {/** Empty State Starts **/}

                {/* <div className="empty-state text-center">
                  <div className="icon-text-wrap">
                    <div className="icon-wrap">
                      <Svg name="roles-empty" />
                    </div>
                    <div className="text-wrap">
                      <p>Create a team to work on shared projects.</p>
                    </div>
                    <div className="button-wrap mt-3 mt-md-4">
                      <Link
                        to="/settings/teams/create"
                        className="btn btn-primary add-btn"
                      >
                        <Svg name="plus" /> Add new
                      </Link>
                    </div>
                  </div>
                </div> */}

                {/** Empty State Ends **/}
                <div className="table-wrapper-view">
                  <div className="tab-search search-filter d-flex align-items-center">
                    <div className="flex-grow-1 search-filter-padding">
                      <input
                        type="text"
                        className="form-control custom-search-input"
                        placeholder="Search"
                        value={serverParams.search}
                        onChange={(e) => {
                          let tempParams = {
                            ...serverParams,
                            search: e.target.value,
                          };
                          setServerParams(tempParams);
                          getTeams(tempParams);
                        }}
                      />
                    </div>
                    <div className="drop-wrap d-flex align-items-center">
                      <label>Sort By - </label>
                      <div className="dropdown table-dropdown ques-options">
                        <button
                          className="btn dropdown-tgl"
                          type="button"
                          id="sort-by"
                          data-bs-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          {sortValue}
                        </button>
                        <div
                          className="dropdown-menu"
                          aria-labelledby="sort-by"
                        >
                          {sortingOptions.map((sort) => (
                            <a
                              className="dropdown-item"
                              href="#"
                              key={sort.id}
                              onClick={() => {
                                handleSorting(sort.value);
                                setSortValue(sort.label);
                              }}
                            >
                              {sort.label}
                            </a>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="teams-wrap pt-3 templates-wrapper">
                    <div className="row">
                      {isFetchingList ? (
                        <Loader />
                      ) : (
                        <>
                          {list.length > 0 ? (
                            <>
                              {list.map((team, teamIndex) => (
                                <div
                                  className="col-xl-3 col-md-6 col-lg-4 mb-4"
                                  key={teamIndex}
                                >
                                  <div className="team-wrapper team-1 template-card">
                                    <div className="img-wrapper">
                                      <Svg name="teams-icon" />
                                    </div>
                                    <div className="text-wrapper d-flex">
                                      <div className="text flex-grow-1">
                                        <h5>{team.team_name}</h5>
                                        <p>{team.count_user} Members</p>
                                      </div>
                                      <div className="btn-group table-dropdown action-end">
                                        <button
                                          type="button"
                                          className="btn-dropdown btn-icon"
                                          data-bs-toggle="dropdown"
                                          aria-expanded="false"
                                        >
                                          <Svg name="action-dropdown-icon" />
                                        </button>
                                        <ul className="dropdown-menu dropdown-menu-end">
                                          <li>
                                            <button
                                              className="dropdown-item icon-btn"
                                              type="button"
                                              onClick={() => {
                                                navigate(
                                                  "/settings/teams/edit",
                                                  {
                                                    state: {
                                                      teamId: team.team_id,
                                                    },
                                                  }
                                                );
                                              }}
                                            >
                                              <Svg name="icon-edit" /> Edit
                                            </button>
                                          </li>
                                          <li>
                                            <button
                                              className="dropdown-item icon-btn"
                                              type="button"
                                              onClick={() =>
                                                teamLeave(team.team_id)
                                              }
                                            >
                                              <Svg name="icon-leave" /> Leave
                                            </button>
                                          </li>

                                          <li>
                                            <button
                                              className="dropdown-item icon-btn"
                                              onClick={() => {
                                                openDeleteModal(team);
                                              }}
                                            >
                                              <Svg name="icon-delete" /> Delete
                                            </button>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </>
                          ) : (
                            <>No Data Found</>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <Pagination
                currentPage={currentPage}
                totalPage={totalPage}
                gotoPreviousPage={gotoPreviousPage}
                changePage={changePage}
                geToNextPage={geToNextPage}
              />
            </div>
          </div>
        </div>
      </div>

      <div
        className="customvar-modal modal custom-modal fade deleteModal"
        id="deleteModal"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div
                className={
                  "formvawrapper delete-modal-wrap modal-setting-medium"
                }
              >
                <div className={"formstyle p-md-2"}>
                  <div className="delete-body-wrap delete-language">
                    <div className="heading">
                      <h3>
                        Delete{" "}
                        <strong>
                          {deleteTeamData && deleteTeamData.team_name}
                        </strong>
                        ?
                      </h3>
                    </div>
                    <div className="text-normal">
                      <p>
                        By deleting this team you will be deleting all the team
                        members and related infomation
                      </p>
                    </div>
                    <div className="button-wrap d-flex justify-content-end">
                      <button
                        type="button"
                        className="btn btn-delete"
                        onClick={() => {
                          dropTeam(deleteTeamData.team_id);
                        }}
                      >
                        {isDeleteing ? (
                          <>
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            <span className="visually-hidden">Loading...</span>
                          </>
                        ) : (
                          "Delete"
                        )}
                      </button>
                      <button
                        type="button"
                        className="btn btn-bordered color-cancel ms-3"
                        onClick={() => {
                          closeDeleteModal();
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
