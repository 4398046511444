import React, { useCallback, useEffect, useRef, useState } from "react";
import reactCSS from "reactcss";
import Svg from "./Svg";
import empty from "../../assets/images/empty.jpg";
import { ChromePicker } from "react-color";
import {
  getMediaList,
  updateFileStatus,
  uploadMedia,
} from "../../actions/media";
import { s3MediaUrl, S3SingleFileUpload } from "../../utils/s3FileUpload";
import { toast } from "react-toastify";
import { getThumbnail } from "../../utils/imageThumbnail";
import Cropper from "react-easy-crop";
import getCroppedImg from "./CropImage";
const moment = require("moment");

const asset_type = {
  mp4: 1,
  png: 2,
  jpg: 3,
  jpeg: 3,
  gif: 4,
  html: 5,
  "youtube link": 6,
  ppt: 7,
  pptx: 12,
  pdf: 8,
  SVG: 9,
  "vimeo link": 10,
  doc: 13,
  docx: 14,
};

const asset_typ_reverse = {
  1: "mp4",
  2: "png",
  3: "jpg",
  11: "jpeg",
  4: "gif",
  5: "html",
  6: "youtube link",
  7: "ppt",
  8: "pdf",
  9: "svg",
  10: "vimeo link",
  12: "pptx",
  13: "doc",
  14: "docx",
};

export default function UploadMediaModal({
  currentMediaSpotId,
  currentMediaSpotData,
  selectedSpotAreaId,
  changeMediaSpotData,
  closeModal,
  isOpenModal,
}) {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [image, setImage] = useState(null);
  const [title, setTitle] = useState("");
  const [imageDimensions, setImageDimensions] = useState({});

  const [displayTextColorPicker, setDisplayTextColorPicker] = useState(false);
  const [color, setColor] = useState({
    r: "245",
    g: "245",
    b: "245",
    a: "1",
  });

  const [textColor, setTextColor] = useState({
    r: "0",
    g: "0",
    b: "0",
    a: "1",
  });
  const [serverParams, setServerParams] = useState({
    startingLimit: 0,
    resultsPerPage: 10,
    parent_id: "",
    file_type: "",
    search: "",
    sort_by: "add_date_time",
    sort_order: "desc",
  });
  const [isFetching, setIsFetching] = useState(false);
  const [mediaData, setMediaData] = useState([]);
  const [totalMediaData, setTotalMediaData] = useState("");
  const [selectedMedia, setselectedMedia] = useState(null);
  const tabs = [
    { id: 1, label: "From media" },
    { id: 2, label: "Upload" },
  ];
  const [currentTabId, setCurrentTabId] = useState(1);
  const [showProgress, setShowProgress] = useState(0);
  const [updateMediaSpotData, setUpdateMediaSpotData] = useState(null);
  const [uploadedFileId, setUploadedFileId] = useState("");
  const [isUpdating, setIsUpdating] = useState(false);
  const [folderData, setFolderData] = useState([]);
  const [isOpenCropModal, setIsOpenCropModal] = useState(false);
  const cropperModal = useRef(null);

  const getMediaFolderList = async (serverParams, action) => {
    setIsFetching(true);
    try {
      const mediaListResponse = await getMediaList(serverParams);
      if (mediaListResponse.statusCode === 200) {
        let data = mediaListResponse.data;
        if (action === "loadMore") {
          setMediaData((mediaData) => [...mediaData, ...data]);
        } else {
          setMediaData(data);
        }
        setTotalMediaData(mediaListResponse.total_count);
        mediaListResponse.data.map((data) => {
          if (data.id === currentMediaSpotData.asset_id) {
            setselectedMedia(data);
          }
          if (data.id === currentMediaSpotData.parent_id) {
            setFolderData([...folderData, data]);
          }
          // console.log("data.parent_id.length > 0", data, data.parent_id.length > 0);
          // if (data.parent_id.length > 0) {
          //   let tempParams = { ...serverParams, parent_id: data.parent_id};
          //   setServerParams(tempParams);
          //   getMediaFolderList(tempParams, "");
          // }
        });
      }
    } catch (error) {
      toast.error(error);
    } finally {
      setIsFetching(false);
    }
  };

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };
  const handleTextClick = () => {
    setDisplayTextColorPicker(!displayTextColorPicker);
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
  };
  const handleTextClose = () => {
    setDisplayTextColorPicker(false);
  };

  const handleChange = (newColor) => {
    setColor(newColor.rgb);
  };
  const handleTextChange = (newColor) => {
    setTextColor(newColor.rgb);
  };

  const handleSearch = (event) => {
    const tempParams = { ...serverParams, search: event.target.value };
    setServerParams(tempParams);
    getMediaFolderList(tempParams);
  };

  const styles = reactCSS({
    default: {
      color: {
        background: `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`,
      },
      textColor: {
        background: `rgba(${textColor.r}, ${textColor.g}, ${textColor.b}, ${textColor.a})`,
      },

      swatch: {
        background: "#fff",
        width: "26px",
        height: "26px",
        borderRadius: "2px",
        border: "1px solid #C3C8CC",
      },
      popover: {
        position: "absolute",
        zIndex: "2",
      },
      cover: {
        position: "fixed",
        top: "0px",
        right: "0px",
        bottom: "0px",
        left: "0px",
      },
    },
  });

  const hiddenFileInput = useRef(null);
  const hiddenFileUpdateInput = useRef(null);

  const uploadMediaLink = async (
    data,
    file = null,
    fileUploadProgress = null
  ) => {
    return new Promise(async (resolve, reject) => {
      setIsUploading(true);
      try {
        const uploadMediaLinkResponse = await uploadMedia(data);
        if (uploadMediaLinkResponse.statusCode === 200) {
          setUploadedFileId(uploadMediaLinkResponse.data.file_id);
          if (file) {
            let mediaType = file.type.split("/");
            mediaType = mediaType[mediaType.length - 1];
            let fileName =
              uploadMediaLinkResponse.data.file_id + "." + mediaType;
            S3SingleFileUpload(file, fileName, fileUploadProgress)
              .then(() => {
                updateFileStatus({
                  id: uploadMediaLinkResponse?.data?.file_id,
                });
              })
              .finally(() => {
                // toast.success(uploadMediaLinkResponse.msg);
                resolve();
              });
          } else {
            // toast.success(uploadMediaLinkResponse.msg);
            resolve();
          }
          setIsUploading(false);
        }
      } catch (error) {
        toast.error(error.response.data.msg);
        reject(error);
      }
    });
  };

  const getFolderMediaList = (media) => {
    if (media.is_folder === 1) {
      setFolderData([...folderData, media]);
      let tempParams = { ...serverParams, parent_id: media.id };
      setServerParams(tempParams);
      getMediaFolderList(tempParams, "");
    }
  };

  const goToBackAllMediaList = () => {
    setFolderData([]);
    setServerParams({
      ...serverParams,
      parent_id: "",
    });
    getMediaFolderList({
      ...serverParams,
      parent_id: "",
    });
  };

  const handleFileInputClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleUpdateFileInputClick = (event) => {
    hiddenFileUpdateInput.current.click();
  };

  const handleFileInput = async (e, action) => {
    let image = null;
    if (action === "dragFile") {
      image = e.dataTransfer.files[0];
    } else {
      image = e.target.files[0];
    }
    setImage(image);
    let tempFileName = "";
    let fileLink = "";
    let _asset_type_id;
    if (image) {
      let mediaType = image.type.split("/");
      if (mediaType.length > 0) {
        mediaType = mediaType[mediaType.length - 1];
        _asset_type_id = asset_type[mediaType];
      }
      tempFileName = Date.now() + "-" + image.name;
      fileLink = `${s3MediaUrl}/${tempFileName}`;
    }
    let dataToSend = {
      name: image ? tempFileName : "",
      link: image ? fileLink : currentMediaSpotData.media_url,
      asset_type_id: image ? _asset_type_id : currentMediaSpotData.media_type,
      parent_id: "",
      template_id: currentMediaSpotId,
      title: title ? title : currentMediaSpotData.title,
      dimensions: `${imageDimensions.height} X ${imageDimensions.width}`,
    };

    setUpdateMediaSpotData(dataToSend);
    await uploadMediaLink(dataToSend, image, (progress) => {
      setShowProgress(progress);
    });
  };

  const onUploadMedia = () => {
    setIsUpdating(true);
    try {
      if (currentTabId === 1) {
        let dataForSend = {
          id: selectedMedia.id,
          file_type: selectedMedia.asset_type_id,
          link:
            selectedMedia.asset_type_id == 6
              ? selectedMedia.link
              : s3MediaUrl +
                "/" +
                selectedMedia.id +
                "." +
                asset_typ_reverse[selectedMedia.asset_type_id],
          name: selectedMedia.user_asset_library_name,
          parent_id: selectedMedia.parent_id,
          dimensions: `${imageDimensions.height} X ${imageDimensions.width}`,
        };
        changeMediaSpotData(dataForSend);
        setselectedMedia(null);
      }
      if (currentTabId === 2) {
        let dataForSend = {
          id: uploadedFileId,
          file_type: updateMediaSpotData.asset_type_id,
          link: updateMediaSpotData.link,
          title: updateMediaSpotData.title,
        };
        changeMediaSpotData(dataForSend);
        setImage(null);
        setCurrentTabId(1);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setMediaData([]);
      setServerParams({
        startingLimit: 0,
        resultsPerPage: 10,
        parent_id: "",
        file_type: "",
        search: "",
        sort_by: "add_date_time",
        sort_order: "desc",
      });
      setIsUpdating(false);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    // e.stopPropagation();
    handleFileInput(e, "dragFile");
  };

  const loadImage = (setImageDimensions, imageUrl) => {
    const img = new Image();
    img.src = imageUrl;

    img.onload = () => {
      setImageDimensions({
        height: img.height,
        width: img.width,
      });
    };
    img.onerror = (err) => {
      console.log("img error");
      console.error(err);
    };
  };

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(async () => {
    // let imgSrc = "https://img.huffingtonpost.com/asset/5ab4d4ac2000007d06eb2c56.jpeg?cache=sih0jwle4e&ops=1910_1000";
    let imgSrc = `${s3MediaUrl}/${selectedMedia.id}.${
      asset_typ_reverse[selectedMedia.asset_type_id]
    }`;
    try {
      const croppedImage = await getCroppedImg(
        imgSrc,
        croppedAreaPixels,
        rotation
      );
      console.log("donee", { croppedImage });
      setCroppedImage(croppedImage);
    } catch (e) {
      console.error("showCroppedImage error", e);
    }
  }, [croppedAreaPixels, rotation]);

  const openCropModal = () => {
    const modal = window.bootstrap.Modal.getOrCreateInstance(
      cropperModal.current
    );
    modal.show();
    setIsOpenCropModal(true);
  };

  const closeCropModal = () => {
    const modal = window.bootstrap.Modal.getOrCreateInstance(
      cropperModal.current
    );
    console.log("modal", modal);
    modal.hide();
    setIsOpenCropModal(false);
  };

  useEffect(() => {
    getMediaFolderList(serverParams);
    if ([2, 3, 4, 9, 11].includes(currentMediaSpotData.media_type)) {
      loadImage(setImageDimensions, currentMediaSpotData.media_url);
    }
  }, [currentMediaSpotData]);

  useEffect(() => {
    if (isOpenModal) {
      getMediaFolderList({
        startingLimit: 0,
        resultsPerPage: 10,
        parent_id: "",
        file_type: "",
        search: "",
        sort_by: "add_date_time",
        sort_order: "desc",
      });
    } else {
      setselectedMedia(null);
      setFolderData([]);
      setImageDimensions({});
    }
  }, [isOpenModal]);

  useEffect(() => {
    if (folderData.length > 0) {
      const tempParams = {
        ...serverParams,
        parent_id: folderData[folderData.length - 1]["id"],
      };
      setServerParams(tempParams);
      getMediaFolderList(tempParams);
    }
  }, [folderData]);

  return (
    <>
      <div className="modal-content add-media-wrap d-none">
        <div className="modal-header d-block m-0">
          <h5 className="modal-title">
            {currentMediaSpotData.media_url ? "Edit" : "Add"} Media
          </h5>
          <p>You can add single or multiple media files</p>
        </div>
        <div className="modal-body">
          <div className="upload-media-outer-wrapper">
            {/* <div className="dimensions">Dimensions: 2045 x 832</div> */}
            <div className="upload-inner" style={styles.color}>
              <div className="title-wrap d-md-flex align-items-start">
                <div className="flex-grow-1 pe-3">
                  {currentMediaSpotData.media_url ||
                  currentMediaSpotData.title ? (
                    <></>
                  ) : (
                    <input
                      type="text"
                      placeholder="Enter title"
                      className="title-form"
                      name="title"
                      onChange={(e) => {
                        setTitle(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="action-buttons d-flex align-items-center justify-content-end">
                  <button className="action-btn prev" disabled>
                    <Svg name="left-arrow-long" />
                  </button>
                  <button className="action-btn next">
                    <Svg name="right-arrow-long" />
                  </button>
                </div>
              </div>
              <div className="media-upload-list-outer">
                <div className="list-inner-wrapper">
                  {currentMediaSpotData.media_url || image ? (
                    <div className="list-wrap">
                      <div className="count">1</div>
                      <div className="img-wrapper">
                        <button
                          className="remove-btn"
                          onClick={() => {
                            currentMediaSpotData.media_url = "";
                            currentMediaSpotData.title = "";
                          }}
                        >
                          <Svg name="rmv-btn" />
                        </button>
                        {image ? (
                          <img src={URL.createObjectURL(image)} />
                        ) : (
                          <img src={currentMediaSpotData.media_url} />
                        )}
                        <div className="button-wrap media-btn">
                          <button
                            className="btn btn-primary add-btn"
                            onClick={() => {
                              handleUpdateFileInputClick();
                            }}
                          >
                            <input
                              type="file"
                              hidden
                              ref={hiddenFileUpdateInput}
                              // onChange={handleFileInput}
                            />
                            <Svg name="change-icon" /> Change media
                          </button>
                        </div>
                      </div>
                      <div className="text-wrapper">
                        <input
                          type="text"
                          placeholder="Enter title"
                          defaultValue={currentMediaSpotData.title}
                          onChange={(e) => {
                            setTitle(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="add-button">
                      <button
                        className="add-btn"
                        // onClick={() => {
                        //   handleFileInputClick();
                        // }}
                      >
                        <input
                          type="file"
                          hidden
                          // ref={hiddenFileInput}
                          // onChange={handleFileInput}
                        />
                        <Svg name="add-btn" />
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="color-picker d-flex">
              <div className="d-flex pe-3">
                <p>Background</p>
                <div className="trigger-color">
                  <div style={styles.swatch} onClick={handleClick}>
                    <div className="inside-color-box" style={styles.color} />
                  </div>
                  {displayColorPicker ? (
                    <div style={styles.popover}>
                      <div style={styles.cover} onClick={handleClose} />
                      <ChromePicker color={color} onChange={handleChange} />
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="d-flex">
                <p>Text & Buttons</p>
                <div className="trigger-color">
                  <div style={styles.swatch} onClick={handleTextClick}>
                    <div
                      className="inside-color-box"
                      style={styles.textColor}
                    />
                  </div>
                  {displayTextColorPicker ? (
                    <div style={styles.popover}>
                      <div style={styles.cover} onClick={handleTextClose} />
                      <ChromePicker
                        color={textColor}
                        onChange={handleTextChange}
                      />
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer btn-160">
          <button
            type="button"
            className="btn btn-primary"
            disabled={isUploading}
            onClick={() => {
              onUploadMedia();
            }}
          >
            {isUploading ? (
              <>
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Loading...</span>
              </>
            ) : (
              "Save"
            )}
          </button>
          <button
            type="button"
            className="btn btn-bordered color-cancel ms-3"
            data-bs-dismiss="modal"
            onClick={() => {
              setImage(null);
              setTitle("");
            }}
          >
            Cancel
          </button>
        </div>
      </div>
      <div
        className={isOpenCropModal ? "modal fade show" : "modal fade"}
        id="cropperModal"
        ref={cropperModal}
        aria-hidden="true"
        aria-labelledby="exampleModalToggleLabel2"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalToggleLabel2">
                Crop Image
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body" style={{ height: "250px" }}>
              {selectedMedia && (
                <div style={{ position: "relative", display: "contents" }}>
                  <Cropper
                    image={`${s3MediaUrl}/${selectedMedia.id}.${
                      asset_typ_reverse[selectedMedia.asset_type_id]
                    }`}
                    // image={'https://img.huffingtonpost.com/asset/5ab4d4ac2000007d06eb2c56.jpeg?cache=sih0jwle4e&ops=1910_1000'}
                    crop={crop}
                    rotation={rotation}
                    zoom={zoom}
                    aspect={4 / 3}
                    onCropChange={setCrop}
                    onRotationChange={setRotation}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                  />
                </div>
              )}
            </div>
            <div className="modal-footer">
              {croppedImage && <img src={croppedImage} />}
              <button
                onClick={showCroppedImage}
                variant="contained"
                color="primary"
              >
                Show Result
              </button>
              <button className="btn btn-primary" onClick={closeCropModal}>
                Done
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-content media-upload-wrap">
        <div className="modal-header d-block pb-0 pt-2 border-0">
          <div className="nav-tabs-wrapper vmersive-tabs mb-2">
            <ul className="nav nav-tabs" id="meetings" role="tablist">
              {tabs.map((tab, tabIndex) => (
                <li className="nav-item" role="presentation" key={tabIndex}>
                  <button
                    className={
                      currentTabId === tab.id ? "nav-link active" : "nav-link"
                    }
                    id={`popup-tab-${tab.id}-tab`}
                    data-bs-toggle="tab"
                    data-bs-target={`#popup-tab-${tab.id}`}
                    type="button"
                    role="tab"
                    aria-controls={`popup-tab-${tab.id}`}
                    aria-selected="true"
                    onClick={() => {
                      setCurrentTabId(tab.id);
                    }}
                  >
                    {tab.label}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="modal-body">
          <div className={"formstyle"}>
            <div className="tab-3-wrapper">
              <div className="page-wrapper">
                <div className="tab-content" id="meetingsContent">
                  <div
                    className={
                      currentTabId === 1
                        ? "tab-pane fade show active"
                        : "tab-pane fade"
                    }
                    id="popup-tab-1"
                    role="tabpanel"
                    aria-labelledby="popup-tab-1-tab"
                  >
                    <div className="table-wrapper-view">
                      <div className="tab-search search-filter d-flex align-items-center">
                        <div className="flex-grow-1 search-filter-padding pe-0">
                          <input
                            type="text"
                            className="form-control custom-search-input"
                            placeholder="Search files"
                            value={serverParams.search}
                            onChange={handleSearch}
                          />
                        </div>
                      </div>
                      {folderData.length > 0 && (
                        <nav
                          className="folder-breadcrumbs"
                          aria-label="breadcrumb"
                        >
                          <ol className="breadcrumb">
                            <li
                              className="breadcrumb-item"
                              onClick={() => {
                                goToBackAllMediaList();
                              }}
                            >
                              <a>Media</a>
                            </li>
                            {folderData.map((folder, folderIndex) => (
                              <li
                                className="breadcrumb-item active cursor-pointer"
                                aria-current="page"
                                key={folderIndex}
                                onClick={() => {
                                  setFolderData(
                                    folderData.slice(0, folderIndex + 1)
                                  );
                                }}
                              >
                                <Svg name="folder-icon" />{" "}
                                {folder.user_asset_library_name}
                              </li>
                            ))}
                          </ol>
                        </nav>
                      )}
                      <div className="file-grid-view modal-grid-view">
                        <div className="row">
                          <div className="col-md-8 col-lg-9">
                            <div className="row">
                              {isFetching ? (
                                <>
                                  <span
                                    className="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                  ></span>
                                  <span className="visually-hidden">
                                    Loading...
                                  </span>
                                </>
                              ) : (
                                <>
                                  {mediaData.length > 0 ? (
                                    <>
                                      {mediaData.length > 0 &&
                                        mediaData.map((media, mediaIndex) => (
                                          <div
                                            className="col position-relative up-media"
                                            key={mediaIndex}
                                            onClick={() => {
                                              getFolderMediaList(media);
                                            }}
                                          >
                                            <input
                                              className="form-check-input"
                                              type="radio"
                                              id={`template-${mediaIndex}`}
                                              name="chooseTemplate"
                                              defaultChecked={
                                                selectedMedia &&
                                                selectedMedia.id === media.id
                                              }
                                              onChange={(e) => {
                                                if (e.target.value === "on") {
                                                  if (
                                                    [
                                                      "png",
                                                      "jpg",
                                                      "jpeg",
                                                      "gif",
                                                      "svg",
                                                    ].includes(
                                                      media.user_asset_library_name
                                                        .split(".")
                                                        .pop()
                                                    )
                                                  ) {
                                                    // openCropModal();
                                                    let imgUrl = `${s3MediaUrl}/${
                                                      media.id
                                                    }.${
                                                      asset_typ_reverse[
                                                        media.asset_type_id
                                                      ]
                                                    }`;
                                                    loadImage(
                                                      setImageDimensions,
                                                      imgUrl
                                                    );
                                                  }
                                                  setselectedMedia(media);
                                                }
                                              }}
                                            />
                                            <label
                                              className="file-wrapper"
                                              htmlFor={`template-${mediaIndex}`}
                                            >
                                              <div className="icon-wrap">
                                                {media.is_folder === 1 && (
                                                  <Svg name="folder-icon" />
                                                )}
                                                {media.is_folder === 0 && (
                                                  <>
                                                    {media.asset_type_id && (
                                                      <>
                                                        {media.asset_type_id ===
                                                          6 &&
                                                          getThumbnail(
                                                            media.link
                                                          ) && (
                                                            <img
                                                              src={getThumbnail(
                                                                media.link
                                                              )}
                                                              alt={
                                                                media.user_asset_library_name
                                                              }
                                                            />
                                                          )}
                                                        {(media.asset_type_id ===
                                                          7 ||
                                                          media.asset_type_id ===
                                                            12) && (
                                                          <Svg name="ppt-icon" />
                                                        )}
                                                        {media.asset_type_id ===
                                                          8 && (
                                                          <Svg name="pdf-icon" />
                                                        )}
                                                        {media.asset_type_id ===
                                                          10 && (
                                                          <Svg name="vimeo-icon-list" />
                                                        )}
                                                        {[
                                                          "png",
                                                          "jpg",
                                                          "jpeg",
                                                          "gif",
                                                          "svg",
                                                        ].includes(
                                                          media.user_asset_library_name
                                                            .split(".")
                                                            .pop()
                                                        ) && (
                                                          <img
                                                            src={`${s3MediaUrl}/${
                                                              media.id
                                                            }.${
                                                              asset_typ_reverse[
                                                                media
                                                                  .asset_type_id
                                                              ]
                                                            }`}
                                                            alt=""
                                                            className="img-fluid"
                                                            onError={({
                                                              currentTarget,
                                                            }) => {
                                                              currentTarget.onerror =
                                                                null; // prevents looping
                                                              currentTarget.src =
                                                                empty;
                                                            }}
                                                          />
                                                        )}
                                                        {["mp4"].includes(
                                                          media.user_asset_library_name
                                                            .split(".")
                                                            .pop()
                                                        ) && (
                                                          <img
                                                            src={`${s3MediaUrl}/${
                                                              media.id
                                                            }.${
                                                              asset_typ_reverse[
                                                                media
                                                                  .asset_type_id
                                                              ]
                                                            }.jpg`}
                                                            alt=""
                                                            className="img-fluid"
                                                            onError={({
                                                              currentTarget,
                                                            }) => {
                                                              currentTarget.onerror =
                                                                null; // prevents looping
                                                              currentTarget.src =
                                                                empty;
                                                            }}
                                                          />
                                                        )}
                                                      </>
                                                    )}
                                                    {(media.asset_type_id ===
                                                      13 ||
                                                      media.asset_type_id ===
                                                        14) && (
                                                      <Svg name="doc-file-icon" />
                                                    )}
                                                  </>
                                                )}
                                              </div>
                                              <div
                                                custom-attr={
                                                  media.asset_type_id
                                                }
                                                className="text-wrap"
                                              >
                                                <p
                                                  title={
                                                    media.user_asset_library_name
                                                  }
                                                >
                                                  {" "}
                                                  {
                                                    media.user_asset_library_name
                                                  }
                                                </p>
                                              </div>
                                            </label>
                                          </div>
                                        ))}
                                    </>
                                  ) : (
                                    "No Data Found"
                                  )}
                                </>
                              )}
                            </div>
                            <div className="text-center">
                              {totalMediaData === mediaData.length ? (
                                <></>
                              ) : (
                                <button
                                  type="button"
                                  className="btn btn-primary mx-auto"
                                  onClick={() => {
                                    let tempParams = {
                                      ...serverParams,
                                      startingLimit:
                                        serverParams.startingLimit + 10,
                                    };
                                    setServerParams(tempParams);
                                    getMediaFolderList(tempParams, "loadMore");
                                  }}
                                >
                                  Load More
                                </button>
                              )}
                            </div>
                          </div>
                          <div className="col-md-4 col-lg-3 d-none d-md-block">
                            {selectedMedia ? (
                              <div className="media-details">
                                <div className="med-title">Media Details</div>
                                <div className="file-wrap">
                                  <div className="icon-wrap">
                                    {selectedMedia.is_folder === 1 && (
                                      <Svg name="folder-icon" />
                                    )}
                                    {selectedMedia.is_folder === 0 && (
                                      <>
                                        {selectedMedia.asset_type_id && (
                                          <>
                                            {selectedMedia.asset_type_id ===
                                              6 &&
                                              getThumbnail(
                                                selectedMedia.link
                                              ) && (
                                                <img
                                                  src={getThumbnail(
                                                    selectedMedia.link
                                                  )}
                                                  alt={
                                                    selectedMedia.user_asset_library_name
                                                  }
                                                />
                                              )}
                                            {(selectedMedia.asset_type_id ===
                                              7 ||
                                              selectedMedia.asset_type_id ===
                                                12) && <Svg name="ppt-icon" />}
                                            {selectedMedia.asset_type_id ===
                                              8 && <Svg name="pdf-icon" />}
                                            {selectedMedia.asset_type_id ===
                                              10 && (
                                              <Svg name="vimeo-icon-list" />
                                            )}
                                            {[
                                              "png",
                                              "jpg",
                                              "jpeg",
                                              "gif",
                                              "svg",
                                            ].includes(
                                              selectedMedia.user_asset_library_name
                                                .split(".")
                                                .pop()
                                            ) && (
                                              <img
                                                src={`${s3MediaUrl}/${
                                                  selectedMedia.id
                                                }.${
                                                  asset_typ_reverse[
                                                    selectedMedia.asset_type_id
                                                  ]
                                                }`}
                                                alt=""
                                                className="img-fluid"
                                                onError={({
                                                  currentTarget,
                                                }) => {
                                                  currentTarget.onerror = null; // prevents looping
                                                  currentTarget.src = empty;
                                                }}
                                              />
                                            )}
                                            {["mp4"].includes(
                                              selectedMedia.user_asset_library_name
                                                .split(".")
                                                .pop()
                                            ) && (
                                              <img
                                                src={`${s3MediaUrl}/${
                                                  selectedMedia.id
                                                }.${
                                                  asset_typ_reverse[
                                                    selectedMedia.asset_type_id
                                                  ]
                                                }.jpg`}
                                                alt=""
                                                className="img-fluid"
                                                onError={({
                                                  currentTarget,
                                                }) => {
                                                  currentTarget.onerror = null; // prevents looping
                                                  currentTarget.src = empty;
                                                }}
                                              />
                                            )}
                                          </>
                                        )}
                                        {(selectedMedia.asset_type_id === 13 ||
                                          selectedMedia.asset_type_id ===
                                            14) && <Svg name="doc-file-icon" />}
                                      </>
                                    )}
                                  </div>
                                </div>
                                <div className="text-wrap">
                                  <div className="title">
                                    {selectedMedia.user_asset_library_name}
                                  </div>
                                  {imageDimensions && (
                                    <div className="dimensions text-start">
                                      Dimensions: {imageDimensions.height} X{" "}
                                      {imageDimensions.width}
                                    </div>
                                  )}
                                  <hr className="dark-20" />
                                  <div className="dimensions text-start mb-0">
                                    Uploaded on:{" "}
                                    {moment(selectedMedia.add_date_time).format(
                                      "DD MMM, YYYY"
                                    )}
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={
                      currentTabId === 2
                        ? "tab-pane fade show active"
                        : "tab-pane fade"
                    }
                    id="popup-tab-2"
                    role="tabpanel"
                    aria-labelledby="popup-tab-2-tab"
                  >
                    <div className="dimensions">Dimensions: 2045 x 832</div>

                    <label
                      className="file-upload-wrapper text-center d-flex align-items-center justify-content-center upload-two"
                      htmlFor="input-file-upload"
                      onDrop={(e) => handleDrop(e)}
                      onDragOver={(e) => handleDragOver(e)}
                    >
                      {/*Upload Options */}
                      <div className="text-inner">
                        <div className="title">
                          Drag & drop file here or <span>browse</span>
                          <input
                            id="input-file-upload"
                            type="file"
                            multiple
                            ref={hiddenFileInput}
                            hidden
                            onChange={handleFileInput}
                          />
                        </div>
                      </div>
                    </label>

                    {image && (
                      <div className="upload-media-percentage">
                        <div className="upload-media d-flex align-items-center link-box">
                          <div className="icon-wrap">
                            {/* <Svg name="media" /> */}
                            <img
                              src={URL.createObjectURL(image)}
                              height={80}
                              width="auto"
                              alt={image.name}
                            />
                          </div>
                          <div className="flex-grow-1 text-progress">
                            <div className="text d-flex justify-content-between">
                              <div className="file-name"> {image.name} </div>
                              <div className="percentage">{showProgress}%</div>
                            </div>
                            <div className="progress">
                              <div
                                className="progress-bar"
                                role="progressbar"
                                style={{ width: `${showProgress}%` }}
                                aria-valuenow={showProgress}
                                aria-valuemin={0}
                                aria-valuemax={100}
                              />
                            </div>
                          </div>

                          {/* <button className="remove-icon">
                            <Svg name="remove-file-icon" />
                          </button> */}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer btn-160">
          <button
            type="button"
            className="btn btn-primary"
            disabled={isUpdating}
            onClick={() => {
              onUploadMedia();
            }}
          >
            {isUpdating ? (
              <>
                <span
                  className="white-spinner spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Loading...</span>
              </>
            ) : (
              "Add"
            )}
          </button>
          <button
            type="button"
            className="btn btn-bordered color-cancel ms-3"
            disabled={isUpdating}
            onClick={() => {
              setServerParams({
                startingLimit: 0,
                resultsPerPage: 10,
                parent_id: "",
                file_type: "",
                search: "",
                sort_by: "add_date_time",
                sort_order: "desc",
              });
              setCurrentTabId(1);
              setImage(null);
              closeModal();
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    </>
  );
}
