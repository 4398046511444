import React, { useEffect, useState } from "react";
import DashboardHeader from "../common/DashboardHeader";
import DashboardSidebar from "../common/DashboardSidebar";
import Svg from "../common/Svg";
import CreatableSelect from "react-select/creatable";

import { EditText } from "react-edit-text";
import "react-edit-text/dist/index.css";

import avatar_1 from "../../assets/images/avatar-1.png";
import avatar_2 from "../../assets/images/avatar-2.png";
import avatar_3 from "../../assets/images/avatar-3.png";
import avatar_4 from "../../assets/images/avatar-4.png";
import avatar_5 from "../../assets/images/avatar-5.png";
import avatar_6 from "../../assets/images/avatar-6.png";
import avatar_7 from "../../assets/images/avatar-7.png";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { getUserDetail, updateProfileData } from "../../actions/user";
import { Formik } from "formik";
import ChangePassword from "./modals/ChangePassword";
import { getTimezone } from "../../actions/meeting";
import { countryList, regionList } from "../../actions/country";
import { updateUserSchema } from "../../Schema/User";

export default function Profile() {
  let loggedinUserData = localStorage.getItem("loggedinUserData");
  loggedinUserData = JSON.parse(loggedinUserData);
  const [isProfileUpdating, setIsProfileUpdating] = useState(false);
  const [timeZones, setTimeZones] = useState([]);
  const [regions, setRegions] = useState([]);
  const [countries, setCountries] = useState([]);
  const [selectedCountryCode, setSelectedCountryCode] = useState(null);
  const [selectedCountryName, setSelectedCountryName] = useState(null);
  const [designation, setDesignation] = useState("");
  const [userData, setUserData] = useState(null);
  const [formValue, setFormValue] = useState({
    email: "",
    name: "",
    designation: "",
    country_code: null,
    region_code: null,
    timezone: null,
    default_region_code: null,
    isEnabled: true,
  });
  const [isOpenModal, setIsOpenModal] = useState(false);

  const getTimezones = async () => {
    return new Promise(async (resolve, reject) => {
      const res = await getTimezone();
      if (res.statusCode === 200) {
        let data = res.data;
        let tempTimeZones = [];
        Object.keys(data).map((ele) => {
          tempTimeZones.push({
            value: data[ele].value,
            label: data[ele].text,
          });
          setTimeZones(tempTimeZones);
        });
        resolve(res);
      }
    });
  };

  const getCountryList = async (regionCode) => {
    let dataToSend = {
      regionCode: regionCode,
    };
    await countryList(dataToSend).then((res) => {
      if (res.statusCode === 200) {
        let data = res.data.countries;
        let tempCountries = [];
        Object.keys(data).map((ele) => {
          console.log("test", data[ele].countrycode === selectedCountryCode);
          if (data[ele].countrycode === selectedCountryCode) {
            console.log("data[ele].name", data[ele].name);
            setSelectedCountryName(data[ele].name);
          }
          tempCountries.push({
            value: data[ele].countrycode,
            label: data[ele].name,
          });
          setCountries(tempCountries);
        });
      }
    });
  };
  const getRegionList = async () => {
    await regionList().then((res) => {
      if (res.statusCode === 200) {
        let data = res.data.regions;
        let tempRegions = [];
        Object.keys(data).map((ele) => {
          tempRegions.push({
            value: data[ele].region_code,
            label: data[ele].region_name,
          });
          setRegions(tempRegions);
        });
      }
    });
  };

  const userDetail = () => {
    let dataToSend = {
      user_id: loggedinUserData.user_id,
    };
    getUserDetail(dataToSend).then((res) => {
      setUserData(res.data);
      if (res.data) {
        setSelectedCountryCode(res.data.country_code);
        setDesignation(res.data.designation);
        setFormValue({
          email: res.data.email,
          name: res.data.name,
          country_code: res.data.country_code,
          region_code: res.data.region_code,
          timezone: res.data.timezone,
          designation: res.data.designation,
          default_region_code: res.data.default_region_code,
        });
        getCountryList(res.data.region_code);
      }
    });
  };

  const updateProfile = async (data) => {
    setIsProfileUpdating(true);
    await updateProfileData(data)
      .then((res) => {
        console.log("res", res);
        userDetail();
      })
      .catch((error) => {
        console.log("updateProfile error", error);
      })
      .finally(() => {
        setIsProfileUpdating(false);
      });
  };

  const openModal = () => {
    let changePasswordmodal = document.getElementById("changePassword");
    let modal = window.bootstrap.Modal.getOrCreateInstance(changePasswordmodal);
    if (modal && !modal._isShown) {
      modal.show();
      setIsOpenModal(true);
    }
  };

  const closeModal = () => {
    let changePasswordmodal = document.getElementById("changePassword");
    let modal = window.bootstrap.Modal.getOrCreateInstance(changePasswordmodal);
    if (modal && modal._isShown) {
      modal.hide();
      setIsOpenModal(false);
    }
  };

  useEffect(() => {
    userDetail();
    getTimezones();
    getRegionList();
  }, []);

  return (
    <>
      <div className="d-flex flex-column main-outer-wrapper main-site-wrapper ">
        <DashboardHeader />
        <div id="viewport" className="d-flex flex-grow-1">
          <DashboardSidebar />
          <div className="flex-grow-1 page-content-flex">
            <div className="page-content-wrap">
              <div className="page-heading">
                {/* <h1>Account setttings</h1> */}
              </div>
              <div className="profile-wrapper">
                <div className="account-info">
                  <Formik
                    initialValues={formValue}
                    enableReinitialize
                    validationSchema={updateUserSchema}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                      console.log("values", values);
                      let dataToSend = {
                        id: loggedinUserData.user_id,
                        name: values.name,
                        designation: values.designation,
                        email: values.email,
                        country_code: values.country_code,
                        region_code: values.region_code,
                        timezone: values.timezone,
                        default_region_code: values.default_region_code,
                      };
                      console.log("dataToSend", dataToSend);
                      updateProfile(dataToSend);
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      setFieldValue,
                      isSubmitting,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        {console.log("values", values)}
                        {console.log("errors", errors)}
                        <div className="form-wrapper custom-width">
                          <div className="profile-heading-image d-md-flex align-items-end">
                            {/* <div className="profile-image">
                              <div className="img-wrap">
                                <img
                                  src={avatar_1}
                                  alt=""
                                  className="img-fluid"
                                  title="John Doe"
                                />
                              </div>
                              <div className="edit-wrap">
                                <OverlayTrigger
                                  overlay={<Tooltip>Change Avatar</Tooltip>}
                                >
                                  <label
                                    data-bs-toggle="modal"
                                    className="editAvatar"
                                    data-bs-target="#changeAvatar"
                                    srno="1"
                                    value="1"
                                  >
                                    <Svg name="edit-white" />
                                  </label>
                                </OverlayTrigger>
                              </div>
                            </div> */}
                            <div className="flex-grow-1">
                              <div className="text-wrapper d-md-flex align-items-center">
                                <div className="text-wrap flex-grow-1 pe-md-5 pb-2 pb-md-0">
                                  <h2>
                                    <input
                                      type="text"
                                      className="form-control heading-input"
                                      placeholder="Enter name"
                                      defaultValue={values.name}
                                      readOnly
                                    />
                                  </h2>
                                  {/* <p>
                                    <input
                                      type="text"
                                      className="form-control heading-input"
                                      placeholder="Enter description"
                                      defaultValue={
                                        "I’m a " +
                                        designation +
                                        "based in" +
                                        selectedCountryName
                                      }
                                      readOnly
                                    />
                                  </p> */}
                                </div>
                                <div className="button-wrap">
                                  <button
                                    type="submit"
                                    className="btn-primary btn"
                                  >
                                    {isProfileUpdating ? (
                                      <>
                                        <span
                                          className="white-spinner spinner-border spinner-border-sm"
                                          role="status"
                                          aria-hidden="true"
                                        ></span>
                                        <span className="visually-hidden">
                                          Loading...
                                        </span>
                                      </>
                                    ) : (
                                      "Save"
                                    )}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="mb-3">
                                <label htmlFor="">Email</label>
                                <input
                                  type="email"
                                  name="email"
                                  className="form-control"
                                  placeholder="Email"
                                  value={values.email}
                                  onChange={handleChange}
                                  readOnly
                                />
                              </div>
                            </div>
                            <div className="col-md-6 position-relative">
                              <div className="mb-3">
                                <label htmlFor="">Username</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Username"
                                  name="name"
                                  value={values.name}
                                  onChange={handleChange}
                                />
                              </div>
                              {errors.name && touched.name && (
                                <div className="validation-msg">
                                  {errors.name && touched.name}
                                </div>
                              )}
                            </div>
                            <div className="col-md-6 position-relative">
                              <div className="mb-3">
                                <label htmlFor="">Designation</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="designation"
                                  name="designation"
                                  value={values.designation}
                                  onChange={handleChange}
                                />
                              </div>
                              {errors.designation && touched.designation && (
                                <div className="validation-msg">
                                  {errors.designation && touched.designation}
                                </div>
                              )}
                            </div>
                            <div className="col-md-6 position-relative">
                              <div className="mb-3 cstom-select">
                                <label htmlFor="">Region</label>
                                <CreatableSelect
                                  value={
                                    regions
                                      ? regions.find(
                                          (option) =>
                                            option.value === values.region_code
                                        )
                                      : ""
                                  }
                                  placeholder={"Select Region"}
                                  options={regions}
                                  onChange={(e) => {
                                    getCountryList(e.value);
                                    setFieldValue("region_code", e.value);
                                  }}
                                />
                              </div>
                              {errors.region_code && touched.region_code && (
                                <div className="validation-msg">
                                  {errors.region_code && touched.region_code}
                                </div>
                              )}
                            </div>
                            <div className="col-md-6 position-relative">
                              <div className="mb-3 cstom-select">
                                <label htmlFor="">Country</label>
                                <CreatableSelect
                                  value={
                                    countries
                                      ? countries.find(
                                          (option) =>
                                            option.value === values.country_code
                                        )
                                      : ""
                                  }
                                  placeholder={"Select country"}
                                  options={countries}
                                  onChange={(e) => {
                                    setFieldValue("country_code", e.value);
                                  }}
                                />
                              </div>
                              {errors.country_code && touched.country_code && (
                                <div className="validation-msg">
                                  {errors.country_code && touched.country_code}
                                </div>
                              )}
                            </div>

                            <div className="col-md-6">
                              <div className="mb-3 cstom-select">
                                <label htmlFor="">Timezone</label>
                                <CreatableSelect
                                  value={
                                    timeZones
                                      ? timeZones.find(
                                          (option) =>
                                            option.value === values.timezone
                                        )
                                      : ""
                                  }
                                  placeholder={"Select Timezone"}
                                  options={timeZones}
                                  onChange={(e) => {
                                    setFieldValue("timezone", e.value);
                                  }}
                                />
                              </div>
                              {errors.timezone && touched.timezone && (
                                <div className="validation-msg">
                                  {errors.timezone && touched.timezone}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="row pt-3">
                            <div className="col-md-12">
                              <h4 className="label">
                                Default region for event participants
                              </h4>
                            </div>
                            <div className="col-md-6">
                              <div className="mb-3">
                                <CreatableSelect
                                  value={
                                    regions
                                      ? regions.find(
                                          (option) =>
                                            option.value ===
                                            values.default_region_code
                                        )
                                      : ""
                                  }
                                  placeholder={"Select default Region"}
                                  options={regions}
                                  onChange={(e) => {
                                    setFieldValue(
                                      "default_region_code",
                                      e.value
                                    );
                                  }}
                                />
                              </div>
                              {errors.default_region_code &&
                                touched.default_region_code && (
                                  <div className="validation-msg">
                                    {errors.default_region_code &&
                                      touched.default_region_code}
                                  </div>
                                )}
                            </div>
                          </div>
                          <div className="row pt-3">
                            <div className="col-md-12">
                              <h4 className="label">Security</h4>
                            </div>
                            <div className="col-md-6">
                              <div className="mb-3">
                                {/* <label htmlFor="">Password</label>
                                <div className="position-relative">
                                  <input
                                    type="password"
                                    className="form-control"
                                    placeholder="Password"
                                    readOnly
                                  />
                                  <span className="forgot-wrap">
                                    <i
                                      className="fa fa-eye "
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                </div> */}
                                <span
                                  className="change-password pt-2 d-inline-block cursor-pointer"
                                  srno="1"
                                  value="1"
                                  onClick={() => openModal()}
                                >
                                  {" "}
                                  Change Password
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={
          isOpenModal
            ? "customvar-modal modal fade changePassword show"
            : "customvar-modal modal fade changePassword"
        }
        id="changePassword"
        tabIndex={-1}
        aria-labelledby="changePasswordLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <ChangePassword closeModal={closeModal} isOpenModal={isOpenModal} />
          </div>
        </div>
      </div>

      <div
        className="customvar-modal modal fade changeAvatar"
        id="changeAvatar"
        tabIndex={-1}
        aria-labelledby="changeAvatarLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="heading text-center avatar-moodal">
              <h3>Select your profile image</h3>
            </div>
            <div className="modal-body">
              <div className={"formstyle"}>
                <div className="profile-list">
                  <div className="profile-list-wrap">
                    <a href="#" className="usericon active">
                      <img src={avatar_1} alt="" />
                    </a>
                  </div>
                  <div className="profile-list-wrap">
                    <a href="#" className="usericon">
                      <img src={avatar_2} alt="" />
                    </a>
                  </div>
                  <div className="profile-list-wrap">
                    <a href="#" className="usericon">
                      <img src={avatar_3} alt="" />
                    </a>
                  </div>
                  <div className="profile-list-wrap">
                    <a href="#" className="usericon">
                      <img src={avatar_4} alt="" />
                    </a>
                  </div>
                  <div className="profile-list-wrap">
                    <a href="#" className="usericon">
                      <img src={avatar_5} alt="" />
                    </a>
                  </div>
                  <div className="profile-list-wrap">
                    <a href="#" className="usericon">
                      <img src={avatar_6} alt="" />
                    </a>
                  </div>
                  <div className="profile-list-wrap">
                    <a href="#" className="usericon">
                      <img src={avatar_7} alt="" />
                    </a>
                  </div>
                </div>
                <div className="button-wrap">
                  <button className="btn btn-primary">Save</button>
                  <button
                    className="btn btn-bordered color-cancel"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
