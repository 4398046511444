import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { toast, ToastContainer } from "react-toastify";
import { getTemplateDetail } from "../../actions/metaSpace";

export default function TemplateDetailsModal({ data, closeDetailModal }) {
  const settings = {
    slidesToShow: 1,
    speed: 500,
    slidesToScroll: 1,
    arrows: true,
    dots: true,
  };

  const [sliderImgs, setSliderImgs] = useState([]);
  const [isFetchingData, setIsFetchingData] = useState(false);
  const [detail, setDetail] = useState(null);

  const getTemplatDetail = async () => {
    setIsFetchingData(true);
    let dataToSend = { t_id: data.id };
    try {
      const res = await getTemplateDetail(dataToSend);
      if (res.statusCode === 200) {
        setDetail(res.data[0]);
        let tempObj = [];
        let images = res.data[0].images;
        Object.keys(images).forEach((k) => {
          if (images[k] === null) delete images[k];
        });
        if (images) {
          tempObj = tempObj.concat(Object.values(images));
        }
        let videos = res.data[0].videos;
        Object.keys(videos).forEach((k) => {
          if (videos[k] === null) delete videos[k];
        });
        if (videos) {
          tempObj = tempObj.concat(Object.values(videos));
        }
        setSliderImgs(tempObj);
        setIsFetchingData(false);
      } else {
        toast.error(res.msg);
      }
    } catch (error) {
      toast.error(error.response.data.msg);
    }
  };

  useEffect(() => {
    if (data) {
      getTemplatDetail();
    }
  }, [data]);

  return (
    <>
      <ToastContainer position="top-right" />
      <div className="modal-body">
        <div className={"formstyle"}>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
          {isFetchingData ? (
            <>
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Loading...</span>
            </>
          ) : (
            <>
              {detail ? (
                <div className="row gx-0">
                  <div className="col-lg-6">
                    <div className="img-wrap">
                      {sliderImgs ? (
                        <Slider {...settings}>
                          {Object.values(sliderImgs).map((img, imgIndex) => (
                            <div className="img-inner" key={imgIndex}>
                              {img && img.includes("embed") ? (
                                <iframe src={img} title={imgIndex}></iframe>
                              ) : (
                                <img
                                  className="trending-img"
                                  key={imgIndex}
                                  src={img}
                                  alt=""
                                />
                              )}
                            </div>
                          ))}
                        </Slider>
                      ) : (
                        <>No Images</>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="text-wrapper">
                      <ul className="mata-info">
                        <li className="hotspots">{detail.media_count} Mediaspots</li>
                        {/* <li className='mediaspots'>13 UI Cards</li> */}
                      </ul>
                      {/* <h3>Smart Factory & Supply Chain</h3> */}
                      <h3>{detail.template_name || "-"}</h3>

                      <div className="text-wrap">
                        <p>{detail.description}</p>
                      </div>
                      {/* <div className="text-wrap area-wrap d-flex">
                        <strong className="align-self-start pt-1">
                          Areas :{" "}
                        </strong>
                        <div className="flex-grow-1 chips-wrap d-flex flex-wrap ps-3">
                          <span className="chips">
                            <p>Automobile factory</p>
                          </span>
                          <span className="chips">
                            <p>Warehouse</p>
                          </span>
                          <span className="chips">
                            <p>Dockyard</p>
                          </span>
                        </div>
                      </div> */}
                      <div className="button-wrap">
                        <button className="btn btn-primary w-auto" onClick={() => {closeDetailModal(data)}}>
                          Select Experience
                        </button>
                        {/* <button className="btn btn-bordered ms-3 w-auto">
                          Preview
                        </button> */}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <>No Data Found..</>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
}
