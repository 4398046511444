import React from 'react'
import { Link } from 'react-router-dom'

export default function ScheduleHeader() {
    return (
        <div className="meeting-header">
            <ul>
                <li><Link to="/immersive-self-guided/schedule" className='active' content-attr="Schedule Immersive(Self-Guided) Event">Schedule Immersive(Self-Guided) Event</Link></li>
                <li><Link to="/immersive-self-guided/select-experience" content-attr="Select experience">Select experience</Link></li>
                {/* <li><Link to="/immersive-self-guided/invite-participants" content-attr="Invite participants">Invite participants</Link></li> */}
                <li><Link to="/immersive-self-guided/create-form" content-attr="Create form">Create form</Link></li>
                <li><Link to="/immersive-self-guided/share" content-attr="Share">Share</Link></li>
            </ul>
        </div>
    )
}
