import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import DataTable from "react-data-table-component";

import DashboardHeader from "../common/DashboardHeader";
import DashboardSidebar from "../common/DashboardSidebar";
import Svg from "../common/Svg";
import MoveMediaModal from "../common/MoveMediaModal";
import { useFormik } from "formik";
import empty from "../../assets/images/empty.jpg";
import { useDropzone } from "react-dropzone";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import {
  createFolderSchema,
  mediaUploadSchema,
  reUploadFileSchema,
  updateFileSchema,
  youtubeLinkSchema,
} from "../../Schema/Media";
import {
  createFolder,
  deleteMediaFiles,
  getMediaList,
  reUploadFiles,
  updateFile,
  updateFileStatus,
  updateFolder,
  uploadMedia,
} from "../../actions/media";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/ReactToastify.min.css";
import { S3SingleFileUpload, s3MediaUrl } from "../../utils/s3FileUpload";
import { getThumbnail } from "../../utils/imageThumbnail";
import Pagination from "../common/Pagination";
import ContentLoader from "react-content-loader";
import AsyncSelect from "react-select/async";

const moment = require("moment");

let tempCancleArray = [];

const TableLoader = () => (
  <div style={{ fontSize: "20px", fontWeight: 500, padding: "24px" }}>
    <>
      <span
        className="spinner-border spinner-border-sm"
        role="status"
        aria-hidden="true"
      ></span>
      <span className="visually-hidden">Loading...</span>
    </>
  </div>
);

const GridLoader = (props) => (
  <ContentLoader viewBox="0 0 100% 100%" height={260} width="100%" {...props}>
    <rect x="0" y="0" rx="0" ry="0" width="130" height="90" />
    <rect x="160" y="0" rx="0" ry="0" width="130" height="90" />
    <rect x="320" y="0" rx="0" ry="0" width="130" height="90" />
    <rect x="480" y="0" rx="0" ry="0" width="130" height="90" />
    <rect x="640" y="0" rx="0" ry="0" width="130" height="90" />
    <rect x="800" y="0" rx="0" ry="0" width="130" height="90" />
    <rect x="960" y="0" rx="0" ry="0" width="130" height="90" />
    <rect x="1120" y="0" rx="0" ry="0" width="130" height="90" />
    <rect x="1280" y="0" rx="0" ry="0" width="130" height="90" />
    <rect x="1440" y="0" rx="0" ry="0" width="130" height="90" />
  </ContentLoader>
);

const TableNoData = () => (
  <div style={{ padding: "24px" }}>Nothing to show here</div>
);

export default function Media() {
  const [isCreatingFolder, setIsCreatingFolder] = useState(false);
  const [isUpdateingFile, setIsUpdateingFile] = useState(false);
  const [serverParams, setServerParams] = useState({
    startingLimit: 0,
    resultsPerPage: 10,
    parent_id: "",
    file_type: "",
    search: "",
    sort_by: "add_date_time",
    sort_order: "desc",
  });
  const [isFetching, setIsFetching] = useState(false);
  const [mediaData, setMediaData] = useState([]);
  const [totalMediaData, setTotalMediaData] = useState("");
  const [isAddVimeoLink, setIsAddVimeoLink] = useState(false);
  const [isAddYouTubeLink, setIsAddYouTubeLink] = useState(false);
  const [isAddLink, setIsAddLink] = useState(false);
  const [isFileUploading, setIsFileUploading] = useState(false);
  const [isFullSizeFileInput, setIsFullSizeFileInput] = useState(false);
  const [isDeleteingFile, setIsDeleteingFile] = useState(false);

  const [fileUploadingProgress, setFileUploadingProgresss] = useState(0);
  const [selectedFiles, setSelectedFiles] = useState(null);
  const [fileToReupload, setFileToReupload] = useState(null);

  const [uploadingFiles, setUploadingFiles] = useState([]);
  const [cancleUploadingFiles, setCancleUploadingFiles] = useState([]);

  const [currentUploadingFile, setCurrentUploadingFile] = useState(null);

  const [filterValue, setFilterValue] = useState("All File Types");
  const [currentDeleteFolderName, setCurrentDeleteFolderName] = useState("");
  const [currentDeleteMediaType, setCurrentDeleteMediaType] = useState(null);
  const [deleteFilesParams, setDeleteFilesParams] = useState({
    id: [],
    is_folder: 0,
    delete_all: 0,
  });

  const [selectedRowsIds, setSelectedRowsIds] = useState([]);
  const [folderData, setFolderData] = useState([]);
  const [isReuploadMedia, setIsReuploadMedia] = useState(false);
  const [downloadMedia, setDownloadMedia] = useState([]);
  const [openMoveFileModal, setOpenMoveFileModal] = useState(false);
  const [moveMediaData, setMoveMediaData] = useState([]);
  const [isClearSelectedRows, setIsClearSelectedRows] = useState(false);
  const [currentView, setCurrentView] = useState({
    id: 1,
    label: "List View",
    svg: "list-view",
    tabView: "list",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [searchSuggestions, setSearchSuggestions] = useState([]);

  const onDrop = useCallback(
    async (acceptedFiles) => {
      let folderName = "";
      let folderCreateRes = null;
      acceptedFiles.forEach((file) => {
        if (file && file.path.includes("/")) {
          folderName = file.path.split("/")[1];
        }
      });
      if (folderName !== "") {
        let data = {
          name: folderName,
          template_id: null,
          parent_id: serverParams.parent_id,
        };
        folderCreateRes = await createFolder(data);
      }
      if (folderCreateRes) {
        fileUpload(acceptedFiles, folderCreateRes.data.folder_id);
      } else {
        fileUpload(acceptedFiles, serverParams.parent_id);
      }
    },
    [serverParams]
  );

  const fileUpload = async (acceptedFiles, folderId) => {
    toggleMedialUploadModal();
    const tempArray = Object.values(acceptedFiles).map((file, index) => {
      return {
        id: null,
        file: file,
        fileName: file.name,
        uploadProgress: 0,
        uniqueId: `${index}-${Date.now()}`,
        fileTypeId: null,
      };
    });
    setUploadingFiles([...uploadingFiles, ...tempArray]);
    for (const e of tempArray) {
      if (!tempCancleArray.includes(e.uniqueId)) {
        setCurrentUploadingFile(e.uniqueId);
        let mediaType = e.file.type.split("/");
        let _asset_type_id;
        if (mediaType.length > 0) {
          mediaType = mediaType[mediaType.length - 1];
          if (
            mediaType ===
            "vnd.openxmlformats-officedocument.presentationml.presentation"
          ) {
            _asset_type_id = 12;
          } else if (mediaType === "vnd.ms-powerpoint") {
            _asset_type_id = 7;
          } else if (mediaType === "msword") {
            _asset_type_id = 13;
          } else if (
            mediaType ===
            "vnd.openxmlformats-officedocument.wordprocessingml.document"
          ) {
            _asset_type_id = 14;
          } else {
            _asset_type_id = asset_type[mediaType];
          }
        }
        const tempFileName = e.fileName;
        // const fileLink = `${s3MediaUrl}/${tempFileName}`;
        let dataToSend = {
          name: tempFileName,
          link: null,
          asset_type_id: _asset_type_id,
          parent_id: folderId,
        };
        await uploadMediaLink(dataToSend, e.file, (progress) => {
          const tempUploadingFiles = [...uploadingFiles, ...tempArray];
          const found = tempUploadingFiles.findIndex(
            (uF) => uF.uniqueId === e.uniqueId
          );

          if (found > -1) {
            tempUploadingFiles[found].uploadProgress = progress;
            tempUploadingFiles[found].fileTypeId = _asset_type_id;
            setUploadingFiles(tempUploadingFiles);
          }
        });
      }
    }
    setCurrentUploadingFile(null);
  };

  const asset_type = {
    mp4: 1,
    png: 2,
    jpg: 3,
    jpeg: 11,
    gif: 4,
    html: 5,
    "Youtube link": 6,
    ppt: 7,
    pptx: 12,
    pdf: 8,
    SVG: 9,
    "Vimeo link": 10,
    doc: 13,
    docx: 14,
  };

  const asset_typ_reverse = {
    1: "mp4",
    2: "png",
    3: "jpg",
    11: "jpeg",
    4: "gif",
    5: "html",
    6: "Youtube link",
    7: "ppt",
    8: "pdf",
    9: "svg",
    10: "Vimeo link",
    12: "pptx",
    13: "doc",
    14: "docx",
  };

  const filterOptions = [
    { id: 1, type: "All", value: "" },
    { id: 2, type: "PPT", value: "7" },
    { id: 3, type: "DOC", value: "13" },
    { id: 4, type: "MP4", value: "1" },
    { id: 5, type: "PNG", value: "2" },
    { id: 6, type: "JPG", value: "3" },
    { id: 7, type: "SVG", value: "9" },
    { id: 8, type: "Link", value: "5" },
    { id: 9, type: "Youtube link", value: "6" },
    { id: 10, type: "Vimeo link", value: "10" },
    { id: 11, type: "Folder", value: "11" },
  ];

  const views = [
    { id: 1, label: "List View", svg: "list-view", tabView: "list" },
    { id: 2, label: "Grid View", svg: "grid-view", tabView: "grid" },
  ];

  const deleteFiles = async () => {
    setIsDeleteingFile(true);
    try {
      const deleteFilesResponse = await deleteMediaFiles(deleteFilesParams);
      if (deleteFilesResponse.statusCode === 200) {
        setSelectedRowsIds([]);
        // toast.success(deleteFilesResponse.msg);
        setIsDeleteingFile(false);
        let deleteFolderModal = document.getElementById("deleteModal");
        let modal =
          window.bootstrap.Modal.getOrCreateInstance(deleteFolderModal);
        if (modal && modal._isShown) {
          modal.hide();
        }
        getMediaFolderList(serverParams);
      }
    } catch (error) {
      toast.error(error.response.data.msg);
    }
  };

  function toggleDeleteFolderFilesModal(id, name, type) {
    setDeleteFilesParams({ ...deleteFilesParams, id: id });
    setCurrentDeleteFolderName(name);
    setCurrentDeleteMediaType(type);
    let deleteFolderModal = document.getElementById("deleteModal");
    let modal = window.bootstrap.Modal.getOrCreateInstance(deleteFolderModal);
    if (modal && !modal._isShown) {
      modal.show();
    }
  }

  const columns = [
    {
      name: "Name",
      width: "350px",
      sortable: true,
      sortField: "user_asset_library_name",
      selector: (row) => row.user_asset_library_name,
      cell: (row) => (
        <>
          <div
            className="d-flex table-media align-items-center"
            onClick={() => {
              getFolderMediaList(row);
            }}
          >
            <div className="img-wrapper">
              {row.is_folder === 1 && <Svg name="folder-icon" />}
              {row.is_folder === 0 && (
                <>
                  {/* {row.user_asset_library_name.split(".").pop()} */}
                  {/* {row.asset_type_id === null && (
                    <>
                      {row.user_asset_library_name.split(".").pop() ===
                        "pdf" && <Svg name="pdf-icon" />}
                      {row.user_asset_library_name.split(".").pop() ===
                        "ppt" && <Svg name="ppt-icon" />}
                      {row.user_asset_library_name.split(".").pop() ===
                        "mp4" && <Svg name="video-file-icon" />}
                      {row.asset_type_id === 6 && <Svg name="youtube-icon" />}
                      {row.asset_type_id === 10 && <Svg name="vimeo-icon" />}
                      {["png", "jpg", "jpeg", "gif", "svg"].includes(
                        row.user_asset_library_name.split(".").pop()
                      ) && <img src={row.link} alt="" className="img-fluid" />}
                    </>
                  )} */}
                  {row.asset_type_id && (
                    <>
                      {row.asset_type_id === 6 && getThumbnail(row.link) && (
                        <img
                          src={getThumbnail(row.link)}
                          alt={row.user_asset_library_name}
                        />
                      )}
                      {(row.asset_type_id === 7 ||
                        row.asset_type_id === 12) && <Svg name="ppt-icon" />}
                      {row.asset_type_id === 8 && <Svg name="pdf-icon" />}
                      {row.asset_type_id === 10 && (
                        <Svg name="vimeo-icon-list" />
                      )}
                      {["png", "jpg", "jpeg", "gif", "svg"].includes(
                        row.user_asset_library_name.split(".").pop()
                      ) &&
                        row.asset_type_id !== 6 && (
                          <img
                            src={`${s3MediaUrl}/${row.id}.${
                              asset_typ_reverse[row.asset_type_id]
                            }`}
                            alt=""
                            className="img-fluid"
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null; // prevents looping
                              currentTarget.src = empty;
                            }}
                          />
                        )}
                      {["mp4"].includes(
                        row.user_asset_library_name.split(".").pop()
                      ) && (
                        <img
                          src={`${s3MediaUrl}/${row.id}.${
                            asset_typ_reverse[row.asset_type_id]
                          }.jpg`}
                          alt=""
                          className="img-fluid"
                        />
                      )}
                    </>
                  )}
                  {(row.asset_type_id === 13 || row.asset_type_id === 14) && (
                    <Svg name="doc-file-icon" />
                  )}
                </>
              )}
            </div>
            <div
              className="flex-grow-1 ps-3"
              onClick={() => {
                if (row.asset_type_id === 6) {
                  window.open(row.link, "_blank");
                } else {
                  if ([...Array(15).keys()].includes(row.asset_type_id)) {
                    window.open(
                      `${s3MediaUrl}/${row.id}.${
                        asset_typ_reverse[row.asset_type_id]
                      }`,
                      "_blank"
                    );
                  }
                }
              }}
            >
              <span
                className="file-name strong mb-0"
                title={row.user_asset_library_name}
              >
                {/* <a
                  className="file-name strong mb-0"
                  href={`${s3MediaUrl}/${row.id}.${
                    asset_typ_reverse[row.asset_type_id]
                  }`}
                >
                  aa
                </a> */}
                {row.user_asset_library_name}
              </span>
            </div>
          </div>
        </>
      ),
    },
    {
      name: "Type",
      sortable: false,
      sortField: "asset_type_id",
      selector: (row) =>
        row.asset_type_id === 1
          ? "MP4"
          : row.asset_type_id === 2
          ? "PNG"
          : row.asset_type_id === 3
          ? "JPG"
          : row.asset_type_id === 4
          ? "GIF"
          : row.asset_type_id === 5
          ? "HTML"
          : row.asset_type_id === 6
          ? "Youtube link"
          : row.asset_type_id === 7
          ? "PPT"
          : row.asset_type_id === 8
          ? "PDF"
          : row.asset_type_id === 9
          ? "SVG"
          : row.asset_type_id === 10
          ? "Vimeo link"
          : row.asset_type_id === 11
          ? "JPEG"
          : row.asset_type_id === 12
          ? "PPTX"
          : row.asset_type_id === 13
          ? "DOC"
          : row.asset_type_id === 14
          ? "DOCX"
          : "-",
    },
    {
      name: "Date modified",
      sortable: true,
      sortField: "add_date_time",
      selector: (row) => moment(row.add_date_time).format("DD MMM, YYYY"),
    },
    {
      name: "Action",
      cell: (row) => (
        <>
          <div className="btn-group table-dropdown action-end">
            <button
              type="button"
              className="btn-dropdown btn-icon"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <Svg name="action-dropdown-icon" />
            </button>
            <ul className="dropdown-menu dropdown-menu-end">
              <li>
                <button
                  className="dropdown-item icon-btn"
                  type="button"
                  onClick={() => {
                    row.is_folder === 1 && toggleUpdateFolderModal(row);
                    row.is_folder === 0 && toggleUpdateFileModal("open", row);
                  }}
                >
                  <Svg name="icon-edit" /> Rename
                </button>
              </li>
              {row.is_folder === 0 && (
                <>
                  <li>
                    <button
                      className="dropdown-item icon-btn"
                      type="button"
                      onClick={() => {
                        openMoveMediaModal([row.id]);
                      }}
                    >
                      <Svg name="move-icon" /> Move
                    </button>
                  </li>
                  <li>
                    <button
                      className="dropdown-item icon-btn"
                      type="button"
                      onClick={() => {
                        toggleReuploadFileModal(row);
                      }}
                    >
                      <Svg name="change-icon" /> Change media
                    </button>
                  </li>
                </>
              )}
              <li>
                <button
                  className="dropdown-item icon-btn"
                  type="button"
                  onClick={() => {
                    toggleDeleteFolderFilesModal(
                      [row.id],
                      row.user_asset_library_name,
                      row.asset_type_id
                    );
                  }}
                >
                  <Svg name="icon-delete" /> Delete
                </button>
              </li>
              <li>
                <button
                  className="dropdown-item icon-btn"
                  onClick={() => {
                    if (row.asset_type_id === 6) {
                      window.open(row.link, "_blank");
                    } else {
                      if ([...Array(15).keys()].includes(row.asset_type_id)) {
                        window.open(
                          `${s3MediaUrl}/${row.id}.${
                            asset_typ_reverse[row.asset_type_id]
                          }`,
                          "_blank"
                        );
                      }
                    }
                  }}
                  type="button"
                >
                  <Svg name="download-icon" /> Download
                </button>
              </li>
            </ul>
          </div>
        </>
      ),
    },
  ];

  const handleChange = ({ selectedRows }) => {
    setSelectedRowsIds(selectedRows.map((e) => e.id));
    setDownloadMedia(selectedRows);
  };

  const hiddenFileInput = useRef(null);
  const hiddenReuploadFileInput = useRef(null);

  const handleReuploadClick = (event) => {
    hiddenReuploadFileInput.current.click();
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      template_id: null,
      parent_id: serverParams.parent_id,
    },
    validationSchema: createFolderSchema,
    onSubmit: (values) => {
      setIsCreatingFolder(true);
      onCreateOrUpdateFolder(values);
    },
    enableReinitialize: true,
  });

  function toggleAddFolderModal(fromModal) {
    if (fromModal === "createFromModal") {
      setOpenMoveFileModal(false);
      let moveMediaModal = document.getElementById("fileMove");
      let fileMovemodal =
        window.bootstrap.Modal.getOrCreateInstance(moveMediaModal);
      if (fileMovemodal && fileMovemodal._isShown) {
        fileMovemodal.hide();
      }
    }
    let createFolderModal = document.getElementById("newFolder");
    setIsCreatingFolder(false);
    let modal = window.bootstrap.Modal.getOrCreateInstance(createFolderModal);
    if (modal) {
      modal.toggle();
    }
  }

  const onCreateOrUpdateFolder = async (data) => {
    try {
      let createFolderResponse = null;
      if (data && data.folder_id) {
        // update has not require template_id that's why remove key from object
        delete data["template_id"];
        createFolderResponse = await updateFolder(data);
      } else {
        createFolderResponse = await createFolder(data);
      }

      if (createFolderResponse.statusCode === 200) {
        formik.resetForm();
        // toast.success(createFolderResponse.msg);
        toggleAddFolderModal("");
        getMediaFolderList(serverParams);
        // if(folderData) {
        //   setFolderData(null);
        // }
      } else {
        toast.error(createFolderResponse.msg);
      }
      setIsCreatingFolder(false);
    } catch (error) {
      toast.error(error.response.data.msg);
    }
  };

  const toggleUpdateFolderModal = (data) => {
    if (data.id && data.user_asset_library_name) {
      formik.setFieldValue("name", data.user_asset_library_name);
      formik.setFieldValue("folder_id", data.id);
      formik.setFieldValue("parent_id", serverParams.parent_id);
      if (data.template_id) {
        formik.setFieldValue("template_id", data.template_id);
      }
      toggleAddFolderModal("");
    }
  };

  const fileFormik = useFormik({
    initialValues: {
      id: "",
      assets_type: "",
      name: "",
    },
    validationSchema: updateFileSchema,
    onSubmit: (values) => {
      setIsUpdateingFile(true);
      onUpdateFile(values);
    },
  });

  const toggleUpdateFileModal = (action, data) => {
    let updateFileModal = document.getElementById("updateFileModal");
    let modal = window.bootstrap.Modal.getOrCreateInstance(updateFileModal);
    if (!modal._isShown) {
      fileFormik.resetForm();
    }
    if (modal) {
      if (action === "open") {
        if (data.asset_type_id) {
          if (
            data.asset_type_id === 5 ||
            data.asset_type_id === 6 ||
            data.asset_type_id === 10
          ) {
            fileFormik.setFieldValue("name", data.user_asset_library_name);
            fileFormik.setFieldValue("assets_type", data.assets_type);
          } else {
            let assets_type = data.user_asset_library_name.split(".").pop();
            /*
            fileFormik.setFieldValue(
              "name",
              (data.user_asset_library_name + data.user_asset_library_name)
                .split(".")
                .slice(0, -1)
                .join(".")
            );
            */
            fileFormik.setFieldValue(
              "name",
              data.user_asset_library_name.split(".").slice(0, -1).join(".")
            );
            fileFormik.setFieldValue("assets_type", assets_type);
          }
        } else {
          fileFormik.setFieldValue("name", data.user_asset_library_name);
        }
        fileFormik.setFieldValue("id", data.id);
        modal.show();
      } else if (action === "close") {
        fileFormik.resetForm();
        modal.hide();
      } else {
        modal.toggle();
      }
    }
  };

  function toggleReuploadFileModal(data) {
    if (data) {
      setFileToReupload(data);
    }
    if (data && [1, 2, 3, 4, 7, 8, 9].includes(data.asset_type_id)) {
      setIsReuploadMedia(true);
      setIsAddYouTubeLink(false);
      setIsAddVimeoLink(false);
    }
    if (data && data.asset_type_id === 5) {
      setIsAddLink(true);
      addLinkFormik.setFieldValue("name", data.user_asset_library_name);
      addLinkFormik.setFieldValue("link", data.link);
      addLinkFormik.setFieldValue("template_id", data.template_id);
      addLinkFormik.setFieldValue("parent_id", data.parent_id);
      addLinkFormik.setFieldValue("file_id", data.id);
      setIsReuploadMedia(false);
      setIsAddVimeoLink(false);
      setIsAddYouTubeLink(false);
    }
    if (data && data.asset_type_id === 6) {
      setIsAddYouTubeLink(true);
      youTubeLinkFormik.setFieldValue("name", data.user_asset_library_name);
      youTubeLinkFormik.setFieldValue("link", data.link);
      youTubeLinkFormik.setFieldValue("template_id", data.template_id);
      youTubeLinkFormik.setFieldValue("parent_id", data.parent_id);
      youTubeLinkFormik.setFieldValue("file_id", data.id);
      setIsReuploadMedia(false);
      setIsAddVimeoLink(false);
      setIsAddLink(false);
    }
    if (data && data.asset_type_id === 10) {
      setIsAddVimeoLink(true);
      vimeoLinkFormik.setFieldValue("name", data.user_asset_library_name);
      vimeoLinkFormik.setFieldValue("link", data.link);
      vimeoLinkFormik.setFieldValue("template_id", data.template_id);
      vimeoLinkFormik.setFieldValue("parent_id", data.parent_id);
      vimeoLinkFormik.setFieldValue("file_id", data.id);
      setIsReuploadMedia(false);
      setIsAddYouTubeLink(false);
      setIsAddLink(false);
    }
    let reuploadFileModal = document.getElementById("reuploadFileModal");
    let modal = window.bootstrap.Modal.getOrCreateInstance(reuploadFileModal);
    if (modal) {
      modal.toggle();
    }
    if (!modal._isShown) {
      setIsAddVimeoLink(false);
      setIsAddYouTubeLink(false);
      setIsAddLink(false);
      setIsFullSizeFileInput(false);
    }
  }

  const onReuploadChange = async (e) => {
    let reuploadFile = e.target.files[0];
    if (reuploadFile) {
      toggleReuploadFileModal(null);
    }
    const tempArray = {
      id: null,
      file: e.target.files[0],
      fileName: e.target.files[0].name,
      uploadProgress: 0,
      uniqueId: `1-${Date.now()}`,
      fileTypeId: null,
    };

    setUploadingFiles([...uploadingFiles, tempArray]);

    let mediaType = e.target.files[0].type.split("/");
    let _asset_type_id;

    if (mediaType.length > 0) {
      mediaType = mediaType[mediaType.length - 1];
      if (
        mediaType ===
        "vnd.openxmlformats-officedocument.presentationml.presentation"
      ) {
        _asset_type_id = 12;
      } else if (mediaType === "vnd.ms-powerpoint") {
        _asset_type_id = 7;
      } else if (mediaType === "msword") {
        _asset_type_id = 13;
      } else if (
        mediaType ===
        "vnd.openxmlformats-officedocument.wordprocessingml.document"
      ) {
        _asset_type_id = 14;
      } else {
        _asset_type_id = asset_type[mediaType];
      }
    }

    const tempReuploadfileName = e.target.files[0].name;
    setSelectedFiles(e.target.files[0]);
    let fileName = fileToReupload?.id + "." + mediaType;
    S3SingleFileUpload(e.target.files[0], fileName, (progress) => {
      const tempUploadingFiles = [...uploadingFiles, tempArray];
      const found = tempUploadingFiles.findIndex(
        (uF) => uF.uniqueId === tempArray.uniqueId
      );
      if (found > -1) {
        tempUploadingFiles[found].uploadProgress = progress;
        tempUploadingFiles[found].fileTypeId = _asset_type_id;
        setUploadingFiles(tempUploadingFiles);
      }
    }).then(async () => {
      const dataToSend = {
        template_id: null,
        user_asset_library_name: tempReuploadfileName,
        added_by: fileToReupload.added_by,
        parent_id: serverParams.parent_id,
        link: null,
        asset_type_id: _asset_type_id,
        file_id: fileToReupload?.id,
      };
      const reUploadFileResponse = await reUploadFiles(dataToSend);
      if (reUploadFileResponse.statusCode === 200) {
        getMediaList(serverParams);
        // toast.success(reUploadFileResponse.msg);
      } else {
        toast.error(reUploadFileResponse.msg);
      }
    });
  };

  const onUpdateFile = async (data) => {
    try {
      let updateFileResponse = null;
      if (data && data.id) {
        delete data["template_id"];
        if (data["assets_type"]) {
          data["name"] = data["name"] + "." + data["assets_type"];
        }
        updateFileResponse = await updateFile(data);
      }

      if (updateFileResponse.statusCode === 200) {
        fileFormik.resetForm();
        // toast.success(updateFileResponse.msg);
        toggleUpdateFileModal("close", {});
        getMediaFolderList(serverParams);
      } else {
        toast.error(updateFileResponse.msg);
      }
      setIsUpdateingFile(false);
    } catch (error) {
      toast.error(error.response.data.msg);
    }
  };

  function toggleMedialUploadModal() {
    setFileToReupload(null);
    let mediaUploadModal = document.getElementById("fileUpload");
    let modal = window.bootstrap.Modal.getOrCreateInstance(mediaUploadModal);
    if (modal) {
      modal.toggle();
    }
    setIsAddVimeoLink(false);
    setIsAddYouTubeLink(false);
    setIsAddLink(false);
    setIsFullSizeFileInput(false);
  }

  const addLinkFormik = useFormik({
    initialValues: {
      name: "",
      link: "",
      asset_type_id: 5,
      template_id: null,
      parent_id: null,
      file_id: "",
    },
    validationSchema: mediaUploadSchema,
    onSubmit: async (values, { resetForm }) => {
      if (fileToReupload) {
        values.file_id = fileToReupload.id;
        const reUploadFileResponse = await reUploadFiles(values);
        if (reUploadFileResponse.statusCode === 200) {
          setFileToReupload(null);
          getMediaFolderList(serverParams);
          // toast.success(reUploadFileResponse.msg);
          toggleReuploadFileModal(null);
        }
      }
      if (!fileToReupload) {
        values.parent_id = serverParams.parent_id;
        uploadMediaLink(values);
      }
      resetForm();
      setIsAddLink(false);
    },
  });

  const youTubeLinkFormik = useFormik({
    initialValues: {
      name: "",
      link: "",
      asset_type_id: 6,
      template_id: null,
      parent_id: null,
      file_id: "",
    },
    validationSchema: youtubeLinkSchema,
    onSubmit: async (values, { resetForm }) => {
      if (fileToReupload) {
        values.file_id = fileToReupload.id;
        const reUploadFileResponse = await reUploadFiles(values);
        if (reUploadFileResponse.statusCode === 200) {
          setFileToReupload(null);
          getMediaFolderList(serverParams);
          // toast.success(reUploadFileResponse.msg);
          toggleReuploadFileModal(null);
        }
      }
      if (!fileToReupload) {
        values.parent_id = serverParams.parent_id;
        uploadMediaLink(values);
      }
      resetForm();
      setIsAddYouTubeLink(false);
    },
  });

  const vimeoLinkFormik = useFormik({
    initialValues: {
      name: "",
      link: "",
      asset_type_id: 10,
      template_id: null,
      parent_id: serverParams.parent_id,
      file_id: "",
    },
    validationSchema: mediaUploadSchema,
    onSubmit: async (values, { resetForm }) => {
      if (fileToReupload) {
        values.file_id = fileToReupload.id;
        const reUploadFileResponse = await reUploadFiles(values);
        if (reUploadFileResponse.statusCode === 200) {
          setFileToReupload(null);
          getMediaFolderList(serverParams);
          // toast.success(reUploadFileResponse.msg);
          toggleReuploadFileModal(null);
        }
      }
      if (!fileToReupload) {
        values.parent_id = serverParams.parent_id;
        uploadMediaLink(values);
      }
      resetForm();
      setIsAddVimeoLink(false);
    },
  });

  const uploadMediaLink = async (
    data,
    file = null,
    fileUploadProgress = null
  ) => {
    return new Promise(async (resolve, reject) => {
      try {
        const uploadMediaLinkResponse = await uploadMedia(data);
        if (uploadMediaLinkResponse.statusCode === 200) {
          if (file) {
            let mediaType = file.type.split("/");
            mediaType = mediaType[mediaType.length - 1];
            if (
              mediaType ===
              "vnd.openxmlformats-officedocument.presentationml.presentation"
            ) {
              mediaType = "pptx";
            } else if (mediaType === "vnd.ms-powerpoint") {
              mediaType = "ppt";
            } else if (mediaType === "msword") {
              mediaType = "doc";
            } else if (
              mediaType ===
              "vnd.openxmlformats-officedocument.wordprocessingml.document"
            ) {
              mediaType = "docx";
            }
            let fileName =
              uploadMediaLinkResponse.data.file_id + "." + mediaType;
            S3SingleFileUpload(file, fileName, fileUploadProgress)
              .then(() => {
                updateFileStatus({
                  id: uploadMediaLinkResponse?.data?.file_id,
                });
                getMediaFolderList(serverParams);
              })
              .finally(() => {
                setIsFullSizeFileInput(false);
                // toast.success(uploadMediaLinkResponse.msg);
                resolve();
              });
          } else {
            // toast.success(uploadMediaLinkResponse.msg);
            setIsFullSizeFileInput(false);
            getMediaFolderList(serverParams);
            resolve();
          }
        }
      } catch (error) {
        toast.error(error.response.data.msg);
        reject(error);
      }
    });
  };

  const handleSort = async (column, sortDirection) => {
    const tempParams = {
      ...serverParams,
      sort_by: column.sortField,
      sort_order: sortDirection,
    };
    setServerParams(tempParams);
    getMediaFolderList(tempParams);
  };

  const filterSearchOptions = (inputValue) => {
    // const tempParams = { ...serverParams, search: "" };
    // setServerParams(tempParams);
    return searchSuggestions.filter((i) =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const handleInputChange = (inputValue, { action }) => {
    console.log("handleInputChange inputValue", inputValue);
    console.log("handleInputChange action", action);
    let tempParams = {};
    if (action === "input-change") {
      tempParams = { ...serverParams, search: inputValue };
    } else {
      tempParams = { ...serverParams, search: "" };
    }
    setServerParams(tempParams);
    getMediaFolderList(tempParams)
  };

  const loadOptions = (inputValue, callback) => {
    console.log("inputValue", inputValue);
    getMediaFolderList(serverParams);
    setTimeout(() => {
      callback(filterSearchOptions(inputValue));
    }, 1000);
  };

  const handleFileFilter = (filterObj) => {
    setFilterValue(filterObj.type);
    const tempParams = { ...serverParams, file_type: filterObj.value };
    setServerParams(tempParams);
    getMediaFolderList(tempParams);
  };

  const getMediaFolderList = async (serverParams) => {
    setIsFetching(true);
    try {
      const mediaListResponse = await getMediaList(serverParams);
      if (mediaListResponse.statusCode === 200) {
        setMediaData(mediaListResponse.data);
        let RoundedTotal = Math.ceil(
          mediaListResponse.total_count / serverParams.resultsPerPage
        );
        setTotalMediaData(RoundedTotal);
        if (serverParams.search !== "") {
          let temp = mediaListResponse.data.map((item) => ({
            value: item.user_asset_library_name,
            label: item.user_asset_library_name,
          }));
          setSearchSuggestions(temp);
        }
      }
    } catch (error) {
      toast.error(error.response.data.msg);
    } finally {
      setIsFetching(false);
    }
  };

  const changePage = (page) => {
    setCurrentPage(page);
    setServerParams({
      ...serverParams,
      startingLimit: page === 1 ? 0 : (page - 1) * serverParams.resultsPerPage,
    });
    getMediaFolderList({
      ...serverParams,
      startingLimit: page === 1 ? 0 : (page - 1) * serverParams.resultsPerPage,
    });
  };

  const gotoPreviousPage = () => {
    let pageStarting = 0;
    setCurrentPage(currentPage - 1);
    if (currentPage === 1) {
      setServerParams({ ...serverParams, startingLimit: 0 });
    } else {
      pageStarting = serverParams.startingLimit - serverParams.resultsPerPage;
    }
    setServerParams({ ...serverParams, startingLimit: pageStarting });
    getMediaFolderList({ ...serverParams, startingLimit: pageStarting });
  };

  const geToNextPage = () => {
    setCurrentPage(currentPage + 1);
    changePage(currentPage + 1);
  };

  const getFolderMediaList = async (folderDataObj) => {
    if (folderDataObj.is_folder === 1) {
      setFolderData([...folderData, folderDataObj]);
      const tempParams = { ...serverParams, parent_id: folderDataObj.id };
      setServerParams(tempParams);
      getMediaFolderList(tempParams);
    }
  };

  const goToBackAllMediaList = () => {
    setFolderData([]);
    setServerParams({
      ...serverParams,
      parent_id: "",
    });
    getMediaFolderList({
      ...serverParams,
      parent_id: "",
    });
  };

  const onClickDownloadMedia = () => {
    downloadMedia.forEach((ele, idx) => {
      if (ele.asset_type_id === 6) {
        window.open(ele.link, "_blank");
      } else {
        if ([...Array(15).keys()].includes(ele.asset_type_id)) {
          setTimeout(() => {
            window.open(
              `${s3MediaUrl}/${ele.id}.${asset_typ_reverse[ele.asset_type_id]}`,
              "_blank"
            );
          }, 500);
        }
      }
    });
  };

  const openMoveMediaModal = (mediaIds) => {
    setOpenMoveFileModal(true);
    setMoveMediaData(mediaIds);
    let moveMediaModal = document.getElementById("fileMove");
    let modal = window.bootstrap.Modal.getOrCreateInstance(moveMediaModal);
    if (modal && !modal._isShown) {
      modal.show();
    }
  };

  const closeMoveMediaModal = () => {
    setOpenMoveFileModal(false);
    setIsClearSelectedRows(true);
    setSelectedRowsIds([]);
    getMediaFolderList(serverParams);
    let moveMediaModal = document.getElementById("fileMove");
    let modal = window.bootstrap.Modal.getOrCreateInstance(moveMediaModal);
    if (modal && modal._isShown) {
      modal.hide();
    }
  };

  useEffect(() => {
    getMediaFolderList(serverParams);
  }, []);

  useEffect(() => {
    if (folderData.length > 0) {
      const tempParams = {
        ...serverParams,
        parent_id: folderData[folderData.length - 1]["id"],
      };
      setServerParams(tempParams);
      getMediaFolderList(tempParams);
    }
  }, [folderData]);

  const { isDragReject, isFocused, isDragAccept, getRootProps, getInputProps } =
    useDropzone({
      // accept: {
      //   'image/jpeg': ['.jpeg']
      // },
      onDrop,
    });
  const baseStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
  };

  const focusedStyle = {
    borderColor: "#2196f3",
    backgroundColor: "#fafafa",
  };

  const acceptStyle = {
    borderColor: "#00e676",
    backgroundColor: "#fafafa",
  };

  const style = useMemo(
    () => ({
      // ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  return (
    <>
      <ToastContainer position="top-right" />
      <div className="d-flex flex-column main-outer-wrapper main-site-wrapper ">
        <DashboardHeader />
        <div id="viewport" className="d-flex flex-grow-1">
          <DashboardSidebar />
          <div className="flex-grow-1 page-content-flex">
            <div className="page-content-wrap">
              <div className="heading-section page-heading d-sm-flex align-items-center justify-content-between">
                {folderData.length === 0 && (
                  <div className="flex-grow-1">
                    <h1>Media</h1>
                    <p className="pt-2">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Nulla ac metus sit amet elit porttitor pellentesque non
                      laore
                    </p>
                  </div>
                )}
                {folderData.length > 0 && (
                  <nav className="folder-breadcrumbs" aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li
                        className="breadcrumb-item"
                        onClick={() => {
                          goToBackAllMediaList();
                        }}
                      >
                        <a href="#">Media</a>
                      </li>
                      {folderData.map((folder, folderIndex) => (
                        <li
                          className="breadcrumb-item active cursor-pointer"
                          aria-current="page"
                          key={folderIndex}
                          onClick={() => {
                            setFolderData(folderData.slice(0, folderIndex + 1));
                          }}
                        >
                          <Svg name="folder-icon" />{" "}
                          {folder.user_asset_library_name}
                        </li>
                      ))}
                    </ol>
                  </nav>
                )}

                <div className="search-filter mb-0 d-flex">
                  <div className="drop-wrap me-3">
                    {/* <label>Filter - </label> */}
                    <div className="dropdown table-dropdown ques-options">
                      <button
                        className="btn dropdown-tgl"
                        type="button"
                        id="sort-by"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        {filterValue}
                      </button>
                      <div className="dropdown-menu" aria-labelledby="sort-by">
                        {filterOptions.map((filter, filterIndex) => (
                          <span
                            className="dropdown-item"
                            href="#"
                            key={filterIndex}
                            onClick={() => {
                              handleFileFilter(filter);
                            }}
                          >
                            {filter.type}
                          </span>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="ques-options flex-grow-1">
                    <div className="nav">
                      {views.map((view, viewIndex) => (
                        <OverlayTrigger
                          key={viewIndex}
                          overlay={<Tooltip>{view.label}</Tooltip>}
                        >
                          <button
                            className={
                              view.id === currentView.id
                                ? "dropdown-item active icon-btn"
                                : "dropdown-item icon-btn"
                            }
                            id={`${view.tabView}-tab`}
                            data-bs-toggle="tab"
                            data-bs-target={`#${view.tabView}`}
                            type="button"
                            role="tab"
                            aria-controls={view.tabView}
                            aria-selected="true"
                            onClick={() => {
                              setCurrentView(view);
                            }}
                            key={viewIndex}
                          >
                            <Svg name={view.svg} />
                          </button>
                        </OverlayTrigger>
                      ))}
                    </div>
                  </div>
                </div>
              </div>

              {/** Empty State Experience Starts **/}

              {/* <div className="empty-state text-center">
                <div className="icon-text-wrap">
                  <div className="icon-wrap">
                    <Svg name="media-empty" />
                  </div>
                  <div className="text-wrap">
                    <p>Please add media as there are currently<br /> no files to display.</p>
                  </div>

                  <div className="dropdown table-dropdown ques-options mt-3 mt-md-4">
                    <button
                      className="btn btn-primary add-btn"
                      type="button"
                      id="file-type"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <Svg name="plus" /> Add new
                    </button>
                    <div className="dropdown-menu" aria-labelledby="file-type">
                      <button
                        type="button"
                        className="dropdown-item"
                        onClick={() => {
                          toggleMedialUploadModal();
                        }}
                      >
                        {" "}
                        Media{" "}
                      </button>
                      <button
                        type="button"
                        className="dropdown-item"
                        onClick={() => {
                          formik.resetForm();
                          toggleAddFolderModal();
                        }}
                      >
                        New Folder
                      </button>
                    </div>
                  </div>

                </div>
              </div> */}

              {/** Empty State Experience Ends **/}

              <div className="role-wrapper tab-content">
                <div className="tab-search search-filter d-flex align-items-center">
                  <div className="dropdown table-dropdown ques-options">
                    <button
                      className="btn btn-primary add-btn"
                      type="button"
                      id="file-type"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <Svg name="plus" /> Add new
                    </button>
                    <div className="dropdown-menu" aria-labelledby="file-type">
                      <button
                        type="button"
                        className="dropdown-item"
                        onClick={() => {
                          toggleMedialUploadModal();
                        }}
                      >
                        {" "}
                        Media{" "}
                      </button>
                      <button
                        type="button"
                        className="dropdown-item"
                        onClick={() => {
                          formik.resetForm();
                          toggleAddFolderModal();
                        }}
                      >
                        New Folder
                      </button>
                    </div>
                  </div>

                  <div className="selection-button-wrap ">
                    <OverlayTrigger overlay={<Tooltip>Delete</Tooltip>}>
                      <button
                        className="btn btn-new delete add-btn"
                        type="button"
                        disabled={selectedRowsIds.length === 0}
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        data-bs-title="Delete"
                        onClick={() => {
                          setDeleteFilesParams({
                            ...deleteFilesParams,
                            id: selectedRowsIds,
                          });
                          toggleDeleteFolderFilesModal(selectedRowsIds);
                        }}
                      >
                        <Svg name="icon-delete" />
                      </button>
                    </OverlayTrigger>
                    <OverlayTrigger overlay={<Tooltip>Move</Tooltip>}>
                      <button
                        className="btn btn-new move add-btn"
                        disabled={selectedRowsIds.length === 0}
                        type="button"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        data-bs-title="Move"
                        onClick={() => {
                          openMoveMediaModal(selectedRowsIds);
                        }}
                      >
                        <Svg name="move-icon" />
                      </button>
                    </OverlayTrigger>
                    <OverlayTrigger overlay={<Tooltip>Download</Tooltip>}>
                      <button
                        className="btn btn-new delete add-btn"
                        disabled={selectedRowsIds.length === 0}
                        type="button"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        data-bs-title="Download"
                        onClick={() => {
                          onClickDownloadMedia();
                        }}
                      >
                        <Svg name="download-icon" />
                      </button>
                    </OverlayTrigger>
                  </div>

                  <div className="flex-grow-1 search-filter-custom search-filter-padding search-filter-padding-left pe-0">
                    {/* <input
                      type="text"
                      className="form-control custom-search-input"
                      placeholder="Search files"
                      value={serverParams.search}
                      onChange={handleSearch}
                    /> */}
                    <AsyncSelect
                      //  className="custom-search-input"
                      value={serverParams.search}
                      onInputChange={handleInputChange}
                      isClearable
                      cacheOptions
                      defaultOptions
                      loadOptions={loadOptions}
                      placeholder="Search media"
                    />
                  </div>
                </div>

                {/** Empty State Search **/}
                {/* <div className="empty-state text-center">
                  <div className="icon-text-wrap">
                    <div className="icon-wrap">
                      <Svg name="search-empty" />
                    </div>
                    <div className="text-wrap">
                      <h4>No results found </h4>
                      <p>We couldn't find what you searched for please try another way</p>
                    </div>

                  </div>
                </div> */}

                {/** Empty State Search Ends**/}

                <div
                  className={
                    currentView.id === 1
                      ? "tab-pane fade show active"
                      : "tab-pane fade"
                  }
                  id="list"
                  role="tabpanel"
                  aria-labelledby="list-tab"
                >
                  <div className="table-wrapper-view">
                    <div className="table-responsive">
                      <div className="table-wrap custom-data-table table-media-wrapper media-checks">
                        <DataTable
                          // title="Movies"
                          columns={columns}
                          data={mediaData}
                          selectableRows
                          onSelectedRowsChange={handleChange}
                          selectableRowsHighlight
                          progressPending={isFetching}
                          pagination
                          paginationComponent={(props) => {
                            return (
                              <Pagination
                                currentPage={currentPage}
                                totalPage={totalMediaData}
                                gotoPreviousPage={gotoPreviousPage}
                                changePage={changePage}
                                geToNextPage={geToNextPage}
                              />
                            );
                          }}
                          onSort={handleSort}
                          sortServer
                          progressComponent={<TableLoader />}
                          noDataComponent={<TableNoData />}
                          conditionalRowStyles={[
                            {
                              when: (row) => selectedRowsIds.includes(row.id),
                              classNames: ["selected"],
                            },
                          ]}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={
                    currentView.id === 2
                      ? "tab-pane fade show active"
                      : "tab-pane fade"
                  }
                  id="grid"
                  role="tabpanel"
                  aria-labelledby="grid-tab"
                >
                  <div className="file-grid-view file-grd-six">
                    <div className="row">
                      {isFetching ? (
                        <GridLoader />
                      ) : (
                        <>
                          {mediaData.length > 0 ? (
                            mediaData.map((media, mediaIndex) => (
                              <div className="col" key={mediaIndex}>
                                {/* <div className="file-wrapper selected"> */}
                                <div
                                  type="button"
                                  className={
                                    selectedRowsIds.includes(media.id)
                                      ? "file-wrapper selected"
                                      : "file-wrapper"
                                  }
                                  onClick={() => {
                                    getFolderMediaList(media);
                                  }}
                                >
                                  <div className="icon-wrap">
                                    {media.is_folder === 1 && (
                                      <Svg name="folder-icon" />
                                    )}
                                    {media.is_folder === 0 && (
                                      <>
                                        {media.asset_type_id && (
                                          <>
                                            {media.asset_type_id === 6 &&
                                              getThumbnail(media.link) && (
                                                <img
                                                  src={getThumbnail(media.link)}
                                                  alt={
                                                    media.user_asset_library_name
                                                  }
                                                />
                                              )}
                                            {(media.asset_type_id === 13 ||
                                              media.asset_type_id === 14) && (
                                              <Svg name="doc-file-icon" />
                                            )}

                                            {(media.asset_type_id === 7 ||
                                              media.asset_type_id === 12) && (
                                              <Svg name="ppt-icon" />
                                            )}
                                            {media.asset_type_id === 8 && (
                                              <Svg name="pdf-icon" />
                                            )}
                                            {media.asset_type_id === 10 && (
                                              <Svg name="vimeo-icon-list" />
                                            )}
                                            {[
                                              "png",
                                              "jpg",
                                              "jpeg",
                                              "gif",
                                              "svg",
                                            ].includes(
                                              media.user_asset_library_name
                                                .split(".")
                                                .pop()
                                            ) &&
                                              media.asset_type_id !== 6 && (
                                                <img
                                                  src={`${s3MediaUrl}/${
                                                    media.id
                                                  }.${
                                                    asset_typ_reverse[
                                                      media.asset_type_id
                                                    ]
                                                  }`}
                                                  alt=""
                                                  className="img-fluid"
                                                  onError={({
                                                    currentTarget,
                                                  }) => {
                                                    currentTarget.onerror =
                                                      null; // prevents looping
                                                    currentTarget.src = empty;
                                                  }}
                                                />
                                              )}
                                            {["mp4"].includes(
                                              media.user_asset_library_name
                                                .split(".")
                                                .pop()
                                            ) &&
                                              media.asset_type_id === 1 && (
                                                <img
                                                  src={`${s3MediaUrl}/${
                                                    media.id
                                                  }.${
                                                    asset_typ_reverse[
                                                      media.asset_type_id
                                                    ]
                                                  }.jpg`}
                                                  alt=""
                                                  className="img-fluid"
                                                  onError={({
                                                    currentTarget,
                                                  }) => {
                                                    currentTarget.onerror =
                                                      null; // prevents looping
                                                    currentTarget.src = empty;
                                                  }}
                                                />
                                              )}
                                          </>
                                        )}
                                        {!media.asset_type_id && (
                                          <Svg name="doc-file-icon" />
                                        )}
                                      </>
                                    )}
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="media"
                                      name="selectMedia"
                                      defaultChecked={selectedRowsIds.includes(
                                        media.id
                                      )}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                      }}
                                      onChange={(v) => {
                                        v.stopPropagation();
                                        if (v.target.checked) {
                                          setSelectedRowsIds([
                                            ...selectedRowsIds,
                                            media.id,
                                          ]);
                                        } else {
                                          // selectedRowsIds.splice(selectedRowsIds.indexOf(mediaIndex), 1);
                                          selectedRowsIds.filter(
                                            (temp) => temp.id !== media.id
                                          );
                                        }
                                      }}
                                    />
                                    <div className="btn-group table-dropdown action-end">
                                      <button
                                        type="button"
                                        className="btn-dropdown btn-icon"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                        onClick={(e) => e.stopPropagation()}
                                      >
                                        <Svg name="action-dropdown-icon" />
                                      </button>
                                      <ul className="dropdown-menu dropdown-menu-end">
                                        <li>
                                          <button
                                            className="dropdown-item icon-btn"
                                            type="button"
                                            onClick={() => {
                                              media.is_folder === 1 &&
                                                toggleUpdateFolderModal(media);
                                              media.is_folder === 0 &&
                                                toggleUpdateFileModal(
                                                  "open",
                                                  media
                                                );
                                            }}
                                          >
                                            <Svg name="icon-edit" /> Rename
                                          </button>
                                        </li>
                                        <li>
                                          <button
                                            className="dropdown-item icon-btn"
                                            type="button"
                                            onClick={() => {
                                              openMoveMediaModal([media.id]);
                                            }}
                                          >
                                            <Svg name="move-icon" /> Move
                                          </button>
                                        </li>
                                        <li>
                                          <button
                                            className="dropdown-item icon-btn"
                                            type="button"
                                            onClick={() => {
                                              toggleReuploadFileModal(media);
                                            }}
                                          >
                                            <Svg name="change-icon" /> Change
                                            media
                                          </button>
                                        </li>
                                        <li>
                                          <button
                                            className="dropdown-item icon-btn"
                                            type="button"
                                            onClick={() => {
                                              toggleDeleteFolderFilesModal(
                                                [media.id],
                                                media.user_asset_library_name,
                                                media.asset_type_id
                                              );
                                            }}
                                          >
                                            <Svg name="icon-delete" /> Delete
                                          </button>
                                        </li>
                                        <li>
                                          <button
                                            className="dropdown-item icon-btn"
                                            type="button"
                                            onClick={() => {
                                              if (media.asset_type_id === 6) {
                                                window.open(
                                                  media.link,
                                                  "_blank"
                                                );
                                              } else {
                                                if (
                                                  [
                                                    ...Array(15).keys(),
                                                  ].includes(
                                                    media.asset_type_id
                                                  )
                                                ) {
                                                  window.open(
                                                    `${s3MediaUrl}/${
                                                      media.id
                                                    }.${
                                                      asset_typ_reverse[
                                                        media.asset_type_id
                                                      ]
                                                    }`,
                                                    "_blank"
                                                  );
                                                }
                                              }
                                            }}
                                          >
                                            <Svg name="download-icon" />{" "}
                                            Download
                                          </button>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                  <div
                                    custom-attr={media.asset_type_id}
                                    className="text-wrap"
                                  >
                                    <p title={media.user_asset_library_name}>
                                      {" "}
                                      {media.user_asset_library_name}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            ))
                          ) : (
                            <>No Data Found</>
                          )}
                        </>
                      )}
                    </div>
                    <Pagination
                      currentPage={currentPage}
                      totalPage={totalMediaData}
                      gotoPreviousPage={gotoPreviousPage}
                      changePage={changePage}
                      geToNextPage={geToNextPage}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="customvar-modal modal fade setting-modal"
        id="newFolder"
        tabIndex={-1}
        aria-labelledby="addRoleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <form onSubmit={formik.handleSubmit}>
                <div className={"formstyle p-md-2 account-info"}>
                  <div className="modal-heading">
                    <h4>
                      {formik.values.folder_id ? "Rename" : "Create new"} folder
                    </h4>
                    {formik.values.id && (
                      <input
                        type="hidden"
                        className="form-control"
                        placeholder="Update id"
                        name="id"
                        value={formik.values.folder_id}
                      />
                    )}
                  </div>
                  <div className="form-wrapper mt-3 mb-4">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter folder name"
                        name="name"
                        onChange={formik.handleChange}
                        value={formik.values.name}
                      />
                      {formik.touched.name && formik.errors.name && (
                        <div className="validation-msg">
                          {formik.errors.name}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="button-wrap d-flex justify-content-end">
                    <button
                      type="submit"
                      className="btn btn-primary"
                      disabled={isCreatingFolder}
                    >
                      {isCreatingFolder ? (
                        <div className="spinner-border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      ) : formik.values.folder_id ? (
                        "Rename folder"
                      ) : (
                        "Create folder"
                      )}
                    </button>
                    <button
                      type="button"
                      className="btn btn-bordered color-cancel ms-3"
                      onClick={() => {
                        toggleAddFolderModal("");
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div
        className="customvar-modal modal fade setting-modal"
        id="updateFileModal"
        tabIndex={-1}
        aria-labelledby="addRoleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <form onSubmit={fileFormik.handleSubmit}>
                <div className={"formstyle p-md-2 account-info"}>
                  <div className="modal-heading">
                    <h4>Rename file</h4>
                    {fileFormik.values.id && (
                      <input
                        type="hidden"
                        className="form-control"
                        placeholder="Update id"
                        name="id"
                        value={fileFormik.values.id}
                      />
                    )}
                  </div>
                  <div className="form-wrapper mt-3 mb-4">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter folder name"
                        name="name"
                        onChange={fileFormik.handleChange}
                        value={fileFormik.values.name}
                      />
                      {fileFormik.touched.name && fileFormik.errors.name && (
                        <div className="validation-msg">
                          {fileFormik.errors.name}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="button-wrap d-flex justify-content-end">
                    <button
                      type="submit"
                      className="btn btn-primary"
                      disabled={isCreatingFolder}
                    >
                      {isUpdateingFile ? (
                        <div className="spinner-border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      ) : fileFormik.values.id ? (
                        "Rename file"
                      ) : (
                        "Create folder"
                      )}
                    </button>
                    <button
                      type="button"
                      className="btn btn-bordered color-cancel ms-3"
                      onClick={() => {
                        toggleUpdateFileModal("close", {});
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div
        className="customvar-modal modal fade fileUpload modalDetails"
        id="reuploadFileModal"
        tabIndex={-1}
        aria-labelledby="reuploadFileModal"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-details-width-custom">
          <div className="modal-content">
            <div className="modal-body">
              <div className={"formstyle"}>
                <button
                  type="button"
                  className="btn-close"
                  aria-label="Close"
                  onClick={() => {
                    toggleReuploadFileModal(null);
                  }}
                />

                <div className="supported-files">
                  {" "}
                  Supported media files - PNG, JPG, SVG, GIFs, PPT, PDF, MP4{" "}
                </div>
                <div className="file-upload-wrapper text-center d-flex align-items-center justify-content-center">
                  {/*ReUpload Media*/}
                  {isReuploadMedia && (
                    <div className="text-inner">
                      <div className="title">Drag & drop file here or</div>
                      <div className="icon-option-wrap d-flex flex-wrap justify-content-center">
                        <div className="upload-type-wrap upload">
                          <div
                            className="icon-wrap"
                            onClick={() => {
                              handleReuploadClick();
                            }}
                          >
                            <Svg name="upload-icon" />
                          </div>
                          <p>Upload</p>
                          <input
                            type="file"
                            ref={hiddenReuploadFileInput}
                            hidden
                            onChange={onReuploadChange}
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  {/*reupload simple Link */}
                  {isAddLink && (
                    <div className="text-inner link-box">
                      <div className="title">Enter Link</div>
                      <form onSubmit={addLinkFormik.handleSubmit}>
                        <div className="d-md-flex selected-option">
                          <div className="icon-wrapper link">
                            <div className="icon-wrap">
                              <Svg name="link-icon" />
                            </div>
                          </div>
                          <div className="flex-grow-1 field-wrapper">
                            <div className="form-group mb-3">
                              <div className="row gx-2">
                                <div className="col-md-12 mb-3 position-relative">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Link"
                                    name="link"
                                    onChange={addLinkFormik.handleChange}
                                    value={addLinkFormik.values.link}
                                  />
                                  {addLinkFormik.touched.link &&
                                    addLinkFormik.errors.link && (
                                      <div className="validation-msg">
                                        {addLinkFormik.errors.link}
                                      </div>
                                    )}
                                </div>
                                <div className="col-md-12 position-relative">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Name"
                                    name="name"
                                    onChange={addLinkFormik.handleChange}
                                    value={addLinkFormik.values.name}
                                  />
                                  {addLinkFormik.touched.name &&
                                    addLinkFormik.errors.name && (
                                      <div className="validation-msg">
                                        {addLinkFormik.errors.name}
                                      </div>
                                    )}
                                </div>
                              </div>
                            </div>
                            <button className="btn btn-primary" type="submit">
                              Upload
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  )}

                  {/*reupload Vimeo Link */}
                  {isAddVimeoLink && (
                    <div className="text-inner link-box">
                      <div className="title">Enter Link</div>
                      <form onSubmit={vimeoLinkFormik.handleSubmit}>
                        <div className="d-md-flex selected-option">
                          <div className="icon-wrapper vimeo">
                            <div className="icon-wrap">
                              <Svg name="vimeo-icon" />
                            </div>
                          </div>
                          <div className="flex-grow-1 field-wrapper">
                            <div className="form-group mb-3">
                              <div className="row gx-2">
                                <div className="col-md-12 mb-3 position-relative">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Link"
                                    name="link"
                                    onChange={vimeoLinkFormik.handleChange}
                                    value={vimeoLinkFormik.values.link}
                                  />
                                  {vimeoLinkFormik.touched.link &&
                                    vimeoLinkFormik.errors.link && (
                                      <div className="validation-msg">
                                        {vimeoLinkFormik.errors.link}
                                      </div>
                                    )}
                                </div>
                                <div className="col-md-12  position-relative">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Name"
                                    name="name"
                                    onChange={vimeoLinkFormik.handleChange}
                                    value={vimeoLinkFormik.values.name}
                                  />
                                  {vimeoLinkFormik.touched.name &&
                                    vimeoLinkFormik.errors.name && (
                                      <div className="validation-msg">
                                        {vimeoLinkFormik.errors.name}
                                      </div>
                                    )}
                                </div>
                              </div>
                            </div>
                            <button
                              disabled={vimeoLinkFormik.isSubmitting}
                              className="btn btn-primary"
                              type="submit"
                            >
                              Upload
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  )}

                  {/*reupload Youtube Link */}
                  {isAddYouTubeLink && (
                    <div className="text-inner link-box">
                      <div className="title">Enter Link</div>
                      <form onSubmit={youTubeLinkFormik.handleSubmit}>
                        <div className="d-md-flex selected-option">
                          <div className="icon-wrapper youtube">
                            <div className="icon-wrap">
                              <Svg name="youtube-icon" />
                            </div>
                          </div>
                          <div className="flex-grow-1 field-wrapper">
                            <div className="form-group mb-3 ">
                              <div className="row gx-2">
                                <div className="col-md-12 mb-3 position-relative">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Link"
                                    name="link"
                                    onChange={youTubeLinkFormik.handleChange}
                                    value={youTubeLinkFormik.values.link}
                                  />
                                  {youTubeLinkFormik.touched.link &&
                                    youTubeLinkFormik.errors.link && (
                                      <div className="validation-msg">
                                        {youTubeLinkFormik.errors.link}
                                      </div>
                                    )}
                                </div>
                                <div className="col-md-12  position-relative">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="name"
                                    name="name"
                                    onChange={youTubeLinkFormik.handleChange}
                                    value={youTubeLinkFormik.values.name}
                                  />
                                  {youTubeLinkFormik.touched.name &&
                                    youTubeLinkFormik.errors.name && (
                                      <div className="validation-msg">
                                        {youTubeLinkFormik.errors.name}
                                      </div>
                                    )}
                                </div>
                              </div>
                            </div>
                            <button
                              className="btn btn-primary"
                              type="submit"
                              disabled={youTubeLinkFormik.isSubmitting}
                            >
                              {youTubeLinkFormik.isSubmitting ? (
                                <div
                                  className="spinner-border text-primary"
                                  role="status"
                                >
                                  <span className="visually-hidden">
                                    Loading...
                                  </span>
                                </div>
                              ) : (
                                "Upload"
                              )}
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="customvar-modal modal fade fileUpload modalDetails"
        id="fileUpload"
        tabIndex={-1}
        aria-labelledby="fileUploadLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-details-width-custom">
          <div className="modal-content">
            <div className="modal-body">
              <div className={"formstyle"}>
                <button
                  type="button"
                  className="btn-close"
                  aria-label="Close"
                  onClick={() => {
                    toggleMedialUploadModal();
                  }}
                />

                <div className="supported-files">
                  {" "}
                  Supported media files - PNG, JPG, SVG, GIFs, PPT, PDF, MP4{" "}
                </div>
                <div className="file-upload-wrapper text-center d-flex align-items-center justify-content-center">
                  {/*Upload Options */}

                  {!isFullSizeFileInput && (
                    <div className="text-inner">
                      <div className="title" {...getRootProps({ style })}>
                        {/* <div className="title" {...getRootProps()}> */}
                        {/* <input {...getInputProps()} /> */}
                        Drag & drop file here or{" "}
                      </div>
                      <div className="icon-option-wrap d-flex flex-wrap justify-content-center">
                        <div
                          className="upload-type-wrap upload"
                          {...getRootProps()}
                        >
                          <div className="icon-wrap">
                            <Svg name="upload-icon" />
                          </div>
                          <input {...getInputProps()} />
                          <p>Upload</p>
                        </div>
                        <div
                          className="upload-type-wrap link"
                          onClick={() => {
                            setIsAddLink(true);
                            setIsFullSizeFileInput(true);
                          }}
                        >
                          <div className="icon-wrap">
                            <Svg name="link-icon" />
                          </div>
                          <p>Add link</p>
                        </div>
                        <div
                          className="upload-type-wrap vimeo"
                          onClick={() => {
                            setIsAddVimeoLink(!isAddVimeoLink);
                            setIsFullSizeFileInput(true);
                          }}
                        >
                          <div className="icon-wrap">
                            <Svg name="vimeo-icon" />
                          </div>
                          <p>Vimeo link</p>
                        </div>
                        <div
                          className="upload-type-wrap youtube"
                          onClick={() => {
                            setIsAddYouTubeLink(!isAddYouTubeLink);
                            setIsFullSizeFileInput(true);
                          }}
                        >
                          <div className="icon-wrap">
                            <Svg name="youtube-icon" />
                          </div>
                          <p>Youtube link</p>
                        </div>
                      </div>
                    </div>
                  )}

                  {/*Enter simple Link */}
                  {isAddLink && (
                    <div className="text-inner link-box">
                      <div className="title">Enter Link</div>
                      <form onSubmit={addLinkFormik.handleSubmit}>
                        <div className="d-md-flex selected-option">
                          <div className="icon-wrapper link">
                            <div className="icon-wrap">
                              <Svg name="link-icon" />
                            </div>
                          </div>
                          <div className="flex-grow-1 field-wrapper">
                            <div className="form-group mb-3 ">
                              <div className="row gx-2">
                                <div className="col-md-12 mb-3 position-relative">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Link"
                                    name="link"
                                    onChange={addLinkFormik.handleChange}
                                    value={addLinkFormik.values.link}
                                  />
                                  {addLinkFormik.errors.link && (
                                    <div className="validation-msg">
                                      {addLinkFormik.errors.link}
                                    </div>
                                  )}
                                </div>
                                <div className="col-md-12 position-relative">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Name"
                                    name="name"
                                    onChange={addLinkFormik.handleChange}
                                    value={addLinkFormik.values.name}
                                  />
                                  {addLinkFormik.errors.name && (
                                    <div className="validation-msg">
                                      {addLinkFormik.errors.name}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                            <button className="btn btn-primary" type="submit">
                              Upload
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  )}

                  {/*Enter Vimeo Link */}
                  {isAddVimeoLink && (
                    <div className="text-inner link-box">
                      <div className="title">Enter Link</div>
                      <form onSubmit={vimeoLinkFormik.handleSubmit}>
                        <div className="d-md-flex selected-option">
                          <div className="icon-wrapper vimeo">
                            <div className="icon-wrap">
                              <Svg name="vimeo-icon" />
                            </div>
                          </div>
                          <div className="flex-grow-1 field-wrapper">
                            <div className="form-group mb-3">
                              <div className="row gx-2">
                                <div className="col-md-12 mb-3 position-relative">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Link"
                                    name="link"
                                    onChange={vimeoLinkFormik.handleChange}
                                    value={vimeoLinkFormik.values.link}
                                  />
                                  {vimeoLinkFormik.errors.link && (
                                    <div className="validation-msg">
                                      {vimeoLinkFormik.errors.link}
                                    </div>
                                  )}
                                </div>
                                <div className="col-md-12  position-relative">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Name"
                                    name="name"
                                    onChange={vimeoLinkFormik.handleChange}
                                    value={vimeoLinkFormik.values.name}
                                  />
                                  {vimeoLinkFormik.errors.name && (
                                    <div className="validation-msg">
                                      {vimeoLinkFormik.errors.name}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                            <button
                              disabled={vimeoLinkFormik.isSubmitting}
                              className="btn btn-primary"
                              type="submit"
                            >
                              Upload
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  )}

                  {/*Enter Youtube Link */}
                  {isAddYouTubeLink && (
                    <div className="text-inner link-box">
                      <div className="title">Enter Link</div>
                      <form onSubmit={youTubeLinkFormik.handleSubmit}>
                        <div className="d-md-flex selected-option">
                          <div className="icon-wrapper youtube">
                            <div className="icon-wrap">
                              <Svg name="youtube-icon" />
                            </div>
                          </div>
                          <div className="flex-grow-1 field-wrapper">
                            <div className="form-group mb-3">
                              <div className="row gx-2">
                                <div className="col-md-12 mb-3 position-relative">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Link"
                                    name="link"
                                    onChange={youTubeLinkFormik.handleChange}
                                    value={youTubeLinkFormik.values.link}
                                  />
                                  {youTubeLinkFormik.errors.link && (
                                    <div className="validation-msg">
                                      {youTubeLinkFormik.errors.link}
                                    </div>
                                  )}
                                </div>
                                <div className="col-md-12 position-relative">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="name"
                                    name="name"
                                    onChange={youTubeLinkFormik.handleChange}
                                    value={youTubeLinkFormik.values.name}
                                  />
                                  {youTubeLinkFormik.errors.name && (
                                    <div className="validation-msg">
                                      {youTubeLinkFormik.errors.name}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                            <button
                              className="btn btn-primary"
                              type="submit"
                              disabled={youTubeLinkFormik.isSubmitting}
                            >
                              {youTubeLinkFormik.isSubmitting ? (
                                <div
                                  className="spinner-border text-primary"
                                  role="status"
                                >
                                  <span className="visually-hidden">
                                    Loading...
                                  </span>
                                </div>
                              ) : (
                                "Upload"
                              )}
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  )}

                  {/*Upload Percentage */}

                  {isFileUploading && (
                    <div className="upload-media d-flex align-items-center link-box">
                      <div className="icon-wrap">
                        <Svg name="media" />
                      </div>
                      <div className="flex-grow-1 text-progress">
                        <div className="text d-flex justify-content-between">
                          <div className="file-name">
                            {" "}
                            {selectedFiles.name}{" "}
                          </div>
                          <div className="percentage">
                            {fileUploadingProgress}%
                          </div>
                        </div>
                        <div className="progress">
                          <div
                            className="progress-bar"
                            role="progressbar"
                            style={{ width: `${fileUploadingProgress}%` }}
                            aria-valuenow={fileUploadingProgress}
                            aria-valuemin={0}
                            aria-valuemax={100}
                          />
                        </div>
                      </div>

                      <button className="remove-icon">
                        <Svg name="remove-file-icon" />
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="customvar-modal modal custom-modal fade deleteModal"
        id="deleteModal"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div
                className={
                  "formvawrapper delete-modal-wrap modal-setting-medium"
                }
              >
                <div className={"formstyle p-md-2"}>
                  <div className="delete-body-wrap delete-language">
                    <div className="heading">
                      <h3>
                        Delete{" "}
                        {selectedRowsIds.length === 0
                          ? currentDeleteMediaType
                            ? currentDeleteMediaType === 1 ||
                              currentDeleteMediaType === 2 ||
                              currentDeleteMediaType === 3 ||
                              currentDeleteMediaType === 4
                              ? " media"
                              : " file"
                            : " folder"
                          : "files & folders"}
                        ?
                      </h3>
                    </div>
                    <div className="text-normal">
                      <p>
                        Are you sure you want to delete{" "}
                        <b>
                          {selectedRowsIds.length === 0
                            ? currentDeleteFolderName
                            : ""}
                        </b>
                        {selectedRowsIds.length === 0
                          ? currentDeleteMediaType
                            ? currentDeleteMediaType === 1 ||
                              currentDeleteMediaType === 2 ||
                              currentDeleteMediaType === 3 ||
                              currentDeleteMediaType === 4
                              ? " media"
                              : " file"
                            : " folder"
                          : "files & folders"}
                        ?
                      </p>
                    </div>
                    <div className="button-wrap d-flex justify-content-end">
                      <button
                        type="button"
                        disabled={isDeleteingFile}
                        className="btn btn-delete"
                        onClick={() => {
                          deleteFiles();
                        }}
                      >
                        {isDeleteingFile ? (
                          <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        ) : (
                          "Delete"
                        )}
                      </button>
                      <button
                        type="button"
                        disabled={isDeleteingFile}
                        className="btn btn-bordered color-cancel ms-3"
                        data-bs-dismiss="modal"
                        onClick={() => {
                          setSelectedRowsIds([]);
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {uploadingFiles.length > 0 && (
        <div className="file-uploading-prompt">
          <div className="modal-header">
            <h5 className="modal-title">Uploading files </h5>
            <button
              type="button"
              className="btn-close"
              onClick={() => {
                setUploadingFiles([]);
              }}
            />
          </div>
          <div className="modal-body">
            <div className="file-list-wrap">
              {uploadingFiles.map((file, uploadFileIndex) => {
                return (
                  <div
                    className={`file-list d-flex align-items-center  ${
                      file.uploadProgress < 100 ? "uploading" : ""
                    }`}
                    key={uploadFileIndex}
                  >
                    <div className="icon-wrap">
                      {file.fileTypeId === 2 || file.fileTypeId === 3 ? (
                        <img
                          src={URL.createObjectURL(file.file)}
                          alt={file.fileName}
                          className="img-fluid"
                        />
                      ) : (
                        <></>
                      )}
                      {file.fileTypeId === 1 && <Svg name="video-file-icon" />}
                      {(file.fileTypeId === 7 || file.fileTypeId === 12) && (
                        <Svg name="ppt-icon" />
                      )}
                      {file.fileTypeId === 8 && <Svg name="pdf-icon" />}
                      {(file.fileTypeId === 13 || file.fileTypeId === 14) && (
                        <Svg name="doc-file-icon" />
                      )}
                    </div>
                    <div className="flex-grow-1 text-wrap">
                      <div className="title">{file.fileName}</div>
                      <div className="status-text">
                        {file.uploadProgress < 100 &&
                        !cancleUploadingFiles.includes(file.uniqueId)
                          ? "Uploading..."
                          : !cancleUploadingFiles.includes(file.uniqueId)
                          ? "Uploaded"
                          : "Upload Cancelled"}
                      </div>
                    </div>
                    <div className="status-wrap">
                      <div
                        className="icon"
                        onClick={() => {
                          cancleUploadingFiles.push(file.uniqueId);
                          tempCancleArray.push(file.uniqueId);
                          setCancleUploadingFiles([...cancleUploadingFiles]);
                        }}
                      >
                        {file.uploadProgress === 100 && (
                          <Svg name="checked-icon" />
                        )}
                        {cancleUploadingFiles.includes(file.uniqueId) && (
                          <Svg name="remove-icon" />
                        )}
                        {file.uploadProgress !== 100 &&
                          !cancleUploadingFiles.includes(file.uniqueId) &&
                          currentUploadingFile !== file.uniqueId && (
                            <Svg name="remove-icon-2" />
                          )}
                      </div>
                    </div>
                    <div className="progress">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{ width: `${file.uploadProgress}%` }}
                        aria-valuenow={file.uploadProgress}
                        aria-valuemin={0}
                        aria-valuemax={100}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}

      <div
        className={
          openMoveFileModal
            ? "customvar-modal modal show fade fileUpload uploadMedia fileMove"
            : "customvar-modal modal fade fileUpload uploadMedia fileMove"
        }
        id="fileMove"
      >
        <div className="modal-dialog modal-dialog-centered modal-details-width modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              Select a destination to move file
            </div>
            <div className="modal-body">
              <MoveMediaModal
                openMoveFileModal={openMoveFileModal}
                moveMediaData={moveMediaData}
                closeMoveMediaModal={closeMoveMediaModal}
                toggleAddFolderModal={toggleAddFolderModal}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
