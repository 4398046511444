import axios from "../utils/axios";
import { BASE_URL } from "./urls";

export const countryList = async (payload) => {
  const token = localStorage.getItem("token");
  const response = await axios({
    headers: { Authorization: `Bearer ${token}` },
    method: "POST",
    url: `${BASE_URL}/country/countryList`,
    data: payload,
  });

  return response.data;
};
export const regionList = async () => {
  const token = localStorage.getItem("token");
  const response = await axios({
    headers: { Authorization: `Bearer ${token}` },
    method: "GET",
    url: `${BASE_URL}/country/regionList`,
  });

  return response.data;
};
