import { Formik } from "formik";
import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import {
  deleteTeamMember,
  getTeamDetail,
  updateTeam,
  addTeamMember,
} from "../../../actions/team";
import { getUsers } from "../../../actions/user";
import { getRoles } from "../../../actions/userRole";
import { addTeamMemberSchema, editTeamSchema } from "../../../Schema/Team";
import DashboardHeader from "../../common/DashboardHeader";
import DashboardSidebar from "../../common/DashboardSidebar";
import Svg from "../../common/Svg";
import CreatableSelect from "react-select/creatable";
import DeleteTeamMember from "../modals/DeleteTeamMember";

export default function Edit() {
  const { state } = useLocation();

  const [userList, setUserList] = useState([]);
  const [filteredUserList, setFilteredUserList] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [teamDetail, setTeamDetail] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isaddingMember, setIsaddingMember] = useState(false);
  const [isFetchingDetail, setIsFetchingDetail] = useState(false);
  const [isDeleteingMemberModalOpen, setIsDeleteingMemberModalOpen] =
    useState(false);
  const [teamMembers, setTeamMembers] = useState([]);
  const [deleteTeamMemberData, setDeleteTeamMemberData] = useState(null);

  const columns = [
    {
      name: "Name",
      selector: (row) => row.user_name,
    },
    {
      name: "Role",
      selector: (row) => row.role_name,
    },

    {
      name: "Action",
      cell: (row) => (
        <>
          <div className="btn-group table-dropdown action-end">
            <button
              type="button"
              className="btn-dropdown btn-icon"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <Svg name="action-dropdown-icon" />
            </button>
            <ul className="dropdown-menu dropdown-menu-end">
              <li>
                <button
                  className="dropdown-item icon-btn"
                  type="button"
                  onClick={() => {
                    openTeamMemberDeleteModal(row.user_id);
                  }}
                >
                  <Svg name="icon-delete" /> Delete
                </button>
              </li>
            </ul>
          </div>
        </>
      ),
    },
  ];

  const getAllUser = async () => {
    let serverParams = { startingLimit: 0, resultsPerPage: 1000, search: "" };
    return new Promise(async (resolve, reject) => {
      try {
        const res = await getUsers(serverParams);
        if (res.statusCode === 200) {
          let userOptions = [];
          res.data.map((ele) => {
            userOptions.push({ value: ele.id, label: ele.name });
          });
          setUserList(userOptions);
          resolve(res);
        } else {
          reject(res);
          toast.error(res.msg);
        }
      } catch (error) {
        reject(error);
        toast.error(error.response.data.msg);
      }
    });
  };

  const getAllRole = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await getRoles();
        if (res.statusCode === 200) {
          let roleOptions = [];
          res.data.map((ele) => {
            roleOptions.push({
              value: ele.id,
              label: ele.role_name,
            });
          });
          setRoleList(roleOptions);
          resolve(res);
        } else {
          reject(res);
          toast.error(res.msg);
        }
      } catch (error) {
        reject(error);
        toast.error(error);
      }
    });
  };

  const fetchTeamDetail = async (teamId) => {
    setIsFetchingDetail(true);
    let dataToSend = {
      team_id: teamId,
    };
    try {
      const res = await getTeamDetail(dataToSend);
      if (res.statusCode === 200) {
        setTeamDetail(res.data);
        setTeamMembers(res.teamMembers);
      } else {
        toast.error(res.msg);
      }
    } catch (error) {
      toast.error(error.response.data.msg);
    } finally {
      setIsFetchingDetail(false);
    }
  };

  const editTeam = async (data) => {
    setIsUpdating(true);
    try {
      const res = await updateTeam(data);
      if (res.statusCode === 200) {
        console.log(res);
        fetchTeamDetail(data.team_id);
      }
    } catch (error) {
      toast.error(error);
    } finally {
      setIsUpdating(false);
    }
  };

  const addMember = async (data) => {
    setIsaddingMember(true);
    try {
      const res = await addTeamMember(data);
      if (res.statusCode === 200) {
        console.log(res);
      }
    } catch (error) {
      toast.error(error);
    } finally {
      setIsaddingMember(false);
    }
  };

  const openTeamMemberDeleteModal = (teamMemberId) => {
    let dataTosend = {
      team_id: state.teamId,
      team_member_id: teamMemberId,
    };
    setDeleteTeamMemberData(dataTosend);
    let deteleTeamMemberModal = document.getElementById("deleteModal");
    let modal = window.bootstrap.Modal.getOrCreateInstance(
      deteleTeamMemberModal
    );
    if (modal && !modal._isShown) {
      modal.show();
      setIsDeleteingMemberModalOpen(true);
    }
  };

  const closeTeamMemberDeleteModal = () => {
    let deteleTeamMemberModal = document.getElementById("deleteModal");
    let modal = window.bootstrap.Modal.getOrCreateInstance(
      deteleTeamMemberModal
    );
    if (modal && modal._isShown) {
      modal.hide();
      setIsDeleteingMemberModalOpen(false);
      fetchTeamDetail(state.teamId);
    }
  };

  async function getDetails() {
    await getAllUser();
    await getAllRole();
    if (state && state.teamId) {
      fetchTeamDetail(state.teamId);
    }
  }

  useEffect(() => {
    let teamMemberIds = teamMembers.map((e) => e.user_id);
    setFilteredUserList(
      userList.filter((e) => !teamMemberIds.includes(e.value))
    );
  }, [teamMembers]);

  useEffect(() => {
    getDetails();
  }, [state]);

  return (
    <>
      <div className="d-flex flex-column main-outer-wrapper main-site-wrapper ">
        <DashboardHeader />
        <div id="viewport" className="d-flex flex-grow-1">
          <DashboardSidebar />
          <div className="flex-grow-1 page-content-flex">
            <div className="page-content-wrap d-flex flex-column">
              <div className="page-heading">
                <h1>Edit team</h1>
                {/* <p className='pt-2'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed tortor tellus, accumsan non sollicitudin at,</p> */}
              </div>
              <div className="role-wrapper flex-grow-1">
                <div className="create-team-form">
                  <Formik
                    initialValues={{
                      team_id: state && state.teamId ? state.teamId : "",
                      team_name: teamDetail && teamDetail.team_name,
                    }}
                    enableReinitialize
                    validationSchema={editTeamSchema}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                      let dataToSend = {
                        team_name: values.team_name,
                        team_id: values.team_id,
                      };
                      editTeam(dataToSend);
                      resetForm();
                      setSubmitting(false);
                    }}
                  >
                    {({
                      values,
                      errors,
                      handleChange,
                      handleSubmit,
                      isSubmitting,
                      resetForm,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="col-lg-12 position-relative">
                            <div className="form-group mb-3">
                              <label>Team name</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Team name"
                                name="team_name"
                                defaultValue={values.team_name}
                                onChange={handleChange}
                              />
                            </div>
                            {errors.team_name && (
                              <div className="validation-msg">
                                {errors.team_name}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="button-wrap d-flex pt-3">
                          <button
                            type="submit"
                            disabled={isSubmitting}
                            className="btn btn-primary"
                          >
                            {isUpdating ? (
                              <>
                                <span
                                  className="white-spinner spinner-border spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                                <span className="visually-hidden">
                                  Loading...
                                </span>
                              </>
                            ) : (
                              "Save"
                            )}
                          </button>
                          {/* Remove 'disabled' once input fields are filled whihh means the button should look like below once filled*/}
                          {/* <button type="button" className="btn btn-primary">Save</button> */}
                          <button
                            type="button"
                            onClick={() => {
                              resetForm();
                            }}
                            className="btn btn-bordered color-cancel ms-3"
                          >
                            Cancel
                          </button>
                        </div>
                      </form>
                    )}
                  </Formik>
                  <Formik
                    initialValues={{
                      team_id: state && state.teamId ? state.teamId : "",
                      user_id: "",
                      role_id: "",
                    }}
                    enableReinitialize
                    validationSchema={addTeamMemberSchema}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                      console.log(values);
                      let dataToSend = {
                        team_id: values.team_id,
                        team_member_id: values.user_id,
                        role_id: values.role_id,
                      };
                      addMember(dataToSend);
                      resetForm();
                      setSubmitting(false);
                      fetchTeamDetail(values.team_id);
                    }}
                  >
                    {({
                      values,
                      errors,
                      resetForm,
                      handleSubmit,
                      setFieldValue,
                      isSubmitting,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        {console.log("errors", errors)}
                        <div className="row pt-3">
                          <div className="col-lg-6 position-relative">
                            <div className="form-group mb-3">
                              <label>Add member</label>
                              <CreatableSelect
                                isClearable
                                value={
                                  filteredUserList
                                    ? filteredUserList.find(
                                        (option) =>
                                          option.value === values.user_id
                                      )
                                    : ""
                                }
                                options={filteredUserList}
                                onChange={(v) => {
                                  setFieldValue("user_id", v.value);
                                }}
                              />
                            </div>
                            {errors.user_id && (
                              <div className="validation-msg">
                                {errors.user_id}
                              </div>
                            )}
                          </div>
                          <div className="col-lg-6 position-relative">
                            <div className="form-group mb-3">
                              <label>Select Role</label>
                              <CreatableSelect
                                isClearable
                                value={
                                  roleList
                                    ? roleList.find(
                                        (option) =>
                                          option.value === values.role_id
                                      )
                                    : ""
                                }
                                options={roleList}
                                onChange={(v) => {
                                  setFieldValue("role_id", v.value);
                                }}
                              />
                            </div>
                            {errors.role_id && (
                              <div className="validation-msg">
                                {errors.role_id}
                              </div>
                            )}
                          </div>
                          <div className="button-wrap d-flex pt-3">
                            <button type="submit" disabled={isaddingMember} className="btn btn-primary">
                              {isaddingMember ? (
                                <>
                                  <span
                                    className="white-spinner spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                  ></span>
                                  <span className="visually-hidden">
                                    Loading...
                                  </span>
                                </>
                              ) : (
                                "Save"
                              )}
                            </button>
                            {/* Remove 'disabled' once input fields are filled whihh means the button should look like below once filled*/}
                            {/* <button type="button" className="btn btn-primary">Save</button> */}
                            <button
                              type="button"
                              onClick={() => {
                                resetForm();
                              }}
                              className="btn btn-bordered color-cancel ms-3"
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </form>
                    )}
                  </Formik>

                  {/* if there are added users */}

                  <div className="form-group pt-3">
                    <div className="page-heading mb-2">
                      <h2>Added Members</h2>
                    </div>
                    <div className="table-responsive">
                      <div className="table-wrap custom-data-table table-media-wrapper table-without-pagination">
                        <DataTable
                          columns={columns}
                          data={teamMembers}
                          // pagination
                        />
                      </div>
                    </div>
                    {/* <div className="user-list">
                      <div className="list-item d-flex align-items-center">
                          <div className="flex-grow-1"> Niel Johnson <span className='status invitation-sent'>Invitation Sent</span> </div>
                          <a href="#" className='action-btn'> <Svg name="table-delete" /> </a>
                      </div>
                      <div className="list-item d-flex align-items-center">
                          <div className="flex-grow-1"> Thomas Michel <span className='status invitation-approved'>Invitation Approved</span></div>
                          <a href="#" className='action-btn'> <Svg name="table-delete" /> </a>
                      </div>
                    </div>  */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="customvar-modal modal custom-modal fade deleteModal"
        id="deleteModal"
      >
        <div className="modal-dialog modal-dialog-centered">
          <DeleteTeamMember
            deleteTeamMemberData={deleteTeamMemberData}
            closeTeamMemberDeleteModal={closeTeamMemberDeleteModal}
          />
        </div>
      </div>
    </>
  );
}
