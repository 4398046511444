import React, { useState } from 'react'
import ReactDatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css"
import { Link } from 'react-router-dom'
import DashboardHeader from '../common/DashboardHeader'
import DashboardSidebar from '../common/DashboardSidebar'
import ScheduleHeader from './ScheduleHeader'
import Svg from '../common/Svg'
import CreatableSelect from 'react-select/creatable';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

const timedropdown = [
    { value: 'time-1', label: '09:30 AM' },
    { value: 'time-2', label: '09:45 AM' },
    { value: 'time-3', label: '10:00 AM' },
    { value: 'time-4', label: '10:15 AM' },
    { value: 'time-5', label: '10:30 AM' },
    { value: 'time-6', label: '10:45 AM' },
    { value: 'time-7', label: '11:00 AM' }
]

const Duration = [
    { value: 'duration-1', label: '09:45 AM (15 mins)' },
    { value: 'duration-2', label: '10:00 AM (30 mins)' },
    { value: 'duration-3', label: '10:15 AM (45 mins)' },
    { value: 'duration-4', label: '10:30 AM (1 hr)' },
    { value: 'duration-5', label: '11:00 AM (1.5 hr)' },
    { value: 'duration-6', label: '11:30 AM (2 hr)' }
]
const timeZones = [
    { value: 'tz-1', label: 'Timezone 1' },
    { value: 'tz-2', label: 'Timezone 2' },
    { value: 'tz-3', label: 'Timezone 3' },
    { value: 'tz-4', label: 'Timezone 4' },
    { value: 'tz-5', label: 'Timezone 5' },
]

const region = [
    { value: 'region-1', label: 'US East' },
    { value: 'region-2', label: 'US West' },
    { value: 'region-3', label: 'Europe' },
    { value: 'region-4', label: 'APAC' },
]

export default function Schedule() {
    const [startDate, setStartDate] = useState(new Date());

    return (
        <div className='d-flex flex-column main-outer-wrapper main-site-wrapper '>
            <DashboardHeader />
            <div id="viewport" className="d-flex flex-grow-1">
                <DashboardSidebar />
                <div className="flex-grow-1 page-content-flex">
                    <ScheduleHeader />

                    <div className="page-content-wrap">
                        <div className="heading-section page-heading d-md-flex align-items-center">
                            <div className="flex-grow-1">
                                <h1>Schedule Immersive(Guided) Event</h1>
                                <p>Users can explore on their own</p>
                            </div>
                            <div className="button-wrap mt-3 mt-md-0">
                                <Link
                                    to="/immersive-self-guided/select-experience"
                                    className="btn btn-primary add-btn"
                                >
                                    Select experience
                                    <Svg name="right-arrow" />
                                </Link>
                            </div>
                        </div>
                        <div className="schedule-form account-info">
                            <div className="meeting-card pt-0 border-0 pb-0">
                                <div className="row">
                                    <div className="col-xl-7">
                                        <div className="mb-3 meeting-title ">
                                            <input type="text" className="form-control" placeholder='Add Title' />
                                        </div>
                                        <div className="mb-3">
                                            <textarea className="form-control" placeholder="Add description" style={{ height: 60 }} defaultValue={""} />


                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xl-7">
                                    <hr className='dark-20' />
                                </div>
                            </div>
                            <div className="meeting-card border-0 date-time-column">
                                <div className="meeting-heading">Date & Time </div>
                                <div className="row">
                                    <div className="col-xl-7">
                                        <div className="field-wrapper icon-field-wrapper">

                                            <div className="form-group d-flex align-items-lg-center align-items-start">
                                                <div className="icon-wrap">
                                                    <Svg name="time-picker" />
                                                </div>
                                                <div className="media-body flex-grow-1 d-md-flex align-items-lg-center align-items-start date-time-flex">

                                                    <div className="date-time d-lg-flex flex-grow-1">
                                                        <div className="date position-relative">
                                                            <span className='text pe-2 mob-view'>Date</span>
                                                            <ReactDatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
                                                        </div>
                                                        <div className="d-flex align-items-center my-2 my-md-0 cstom-select">
                                                            <span className='text pe-2'>Starts at</span>
                                                            <div className="time">
                                                                <CreatableSelect
                                                                    // isMulti
                                                                    // isClearable
                                                                    defaultValue={timedropdown[1]}
                                                                    options={timedropdown}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="d-flex align-items-center cstom-select">
                                                            <span className='text pe-2'>to</span>
                                                            <div className="time">
                                                                <CreatableSelect
                                                                    // isMulti
                                                                    // isClearable
                                                                    // menuIsOpen = {true}
                                                                    defaultValue={Duration[1]}
                                                                    options={Duration}
                                                                />
                                                            </div>
                                                        </div>


                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="meeting-card border-0 pt-0 timezne-wrpr">
                                <div className="meeting-heading">Timezone </div>
                                <div className="field-wrapper icon-field-wrapper">
                                    <div className="row">
                                        <div className="col-xl-7">
                                            <div className="form-group d-flex align-items-lg-center align-items-start">
                                                <div className="icon-wrap">
                                                    <Svg name="timer-icon" />
                                                </div>
                                                <div className="media-body flex-grow-1 d-md-flex align-items-lg-center align-items-start date-time-flex">
                                                    <div className="date-time d-lg-flex flex-grow-1">
                                                        <div className="d-flex align-items-center  timezone-dropdown dropdown">
                                                            <span className="text pe-2 mob-view">
                                                                Time zone
                                                            </span>
                                                            <div className="timezone ps-1 cstom-select position-relative">
                                                                <CreatableSelect
                                                                    placeholder={"Select Timezone"}
                                                                    options={timeZones}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xl-7">
                                    <hr className='dark-20' />
                                </div>
                            </div>


                            <div className="schedule-form account-info">
                                <div className="row">
                                    <div className="col-xxl-5 col-lg-6">

                                        <div className="meeting-card border-0">
                                            <div className="meeting-heading d-flex ">
                                                <div className="headingg flex-grow-1">
                                                    Add No. of participants by region (Max 15)
                                                </div>
                                                {/* <div className="action-btn">
                                                <button className='action-btn delete'>
                                                <Svg name="add-icon" />
                                                </button>
                                                </div> */}
                                            </div>
                                            <div className="form-field-wrapper">
                                                <div className="flex-wrap d-flex field-inner align-items-center">
                                                    <div className="select-wrap flex-grow-1 cstom-select">
                                                        <CreatableSelect
                                                            // isMulti
                                                            // isClearable
                                                            placeholder={'Select region'}
                                                            options={region}
                                                        />
                                                    </div>
                                                    <input type="number" className="form-control" defaultValue={'04'} />
                                                    <OverlayTrigger overlay={<Tooltip>Delete</Tooltip>}>
                                                        <button className='action-btn delete'>
                                                            <Svg name="icon-delete" />
                                                        </button>
                                                    </OverlayTrigger>

                                                </div>
                                                <div className="flex-wrap d-flex field-inner align-items-center">
                                                    <div className="select-wrap flex-grow-1 cstom-select">
                                                        <CreatableSelect
                                                            // isMulti
                                                            // isClearable
                                                            placeholder={'Select region'}
                                                            options={region}
                                                        />
                                                    </div>
                                                    <input type="number" className="form-control" defaultValue={'04'} />
                                                    <OverlayTrigger overlay={<Tooltip>Delete</Tooltip>}>
                                                        <button className='action-btn delete'>
                                                            <Svg name="icon-delete" />
                                                        </button>
                                                    </OverlayTrigger>
                                                </div>
                                                <div className="flex-wrap d-flex field-inner align-items-center">
                                                    <div className="select-wrap flex-grow-1 cstom-select">
                                                        <CreatableSelect
                                                            // isMulti
                                                            // isClearable
                                                            placeholder={'Select region'}
                                                            options={region}
                                                        />
                                                    </div>
                                                    <input type="number" className="form-control" defaultValue={'04'} />
                                                    <OverlayTrigger overlay={<Tooltip>Delete</Tooltip>}>
                                                        <button className='action-btn delete'>
                                                            <Svg name="icon-delete" />
                                                        </button>
                                                    </OverlayTrigger>
                                                </div>

                                            </div>
                                        </div>
                                    </div>


                                </div>
                                <div className="button-wrap mb-3">
                                    <div className="">
                                        <button className='btn btn-bordered'>Add</button>
                                    </div>
                                </div>

                            </div>



                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
