import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  deleteMeeting,
  getDraftMeetingList,
  getMeetingDetail,
  getMeetingList,
  getParticipants,
  getPastMeetingList,
} from "../../actions/meeting";
import DashboardHeader from "../common/DashboardHeader";
import DashboardSidebar from "../common/DashboardSidebar";
import DeleteMeetingModal from "../common/DeleteMeetingModal";
import Svg from "../common/Svg";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/ReactToastify.min.css";
import ContentLoader from "react-content-loader";

import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
// import moment from 'moment';

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import CreateEvent from "../common/CreateEvent";
import { getShortName } from "../../utils/common";
import Pagination from "../common/Pagination";

const moment = require("moment");

const Loader = (props) => (
  <ContentLoader viewBox="0 0 100% 100%" height={380} width="100%" {...props}>
    <rect x="0" y="0" rx="4" ry="4" width="520" height="60" />
    <rect x="0" y="80" rx="4" ry="4" width="520" height="60" />
    <rect x="0" y="160" rx="4" ry="4" width="520" height="60" />
  </ContentLoader>
);

const DetailLoader = (props) => (
  <ContentLoader viewBox="0 0 100% 100%" height={250} width="100%" {...props}>
    <rect x="0" y="0" rx="3" ry="3" width="200" height="50" />
    <rect x="0" y="70" rx="3" ry="3" width="150" height="30" />
    <rect x="180" y="70" rx="3" ry="3" width="150" height="30" />
    <rect x="360" y="70" rx="3" ry="3" width="150" height="30" />
    <rect x="540" y="70" rx="3" ry="3" width="150" height="30" />
    <rect x="0" y="120" rx="3" ry="3" width="170" height="30" />
    <rect x="190" y="120" rx="3" ry="3" width="170" height="30" />
    <rect x="0" y="180" rx="3" ry="3" width="150" height="40" />
    <rect x="180" y="180" rx="3" ry="3" width="150" height="40" />
    <rect x="360" y="180" rx="3" ry="3" width="150" height="40" />
    <rect x="540" y="180" rx="3" ry="3" width="150" height="40" />
    <rect x="740" y="180" rx="3" ry="3" width="150" height="40" />
  </ContentLoader>
);

export default function List({ moduleName }) {
  const navigate = useNavigate();

  const [meetingList, setMeetingList] = useState([]);
  const [pastMeetingList, setPastMeetingList] = useState([]);
  const [darftMeetingList, setDraftMeetingList] = useState([]);
  const [meetingDetail, setMeetingDetail] = useState([]);
  const [participants, setParticipants] = useState([]);

  const [cuurentTabId, setCurrentTabId] = useState(1);

  const [isFetchingMeetingList, setIsfetchingMeetingList] = useState(false);
  const [isFetchingPastMeetingList, setIsfetchingPastMeetingList] =
    useState(false);
  const [isFetchingDraftMeetingList, setIsfetchingDraftMeetingList] =
    useState(false);
  const [isFetchingMeetingDetail, setIsfetchingMeetingDetail] = useState(false);

  const [meetinglistServerParams, setMeetingListServerParams] = useState({
    startingLimit: 0,
    resultsPerPage: 6,
    search: "",
    sort_by: "",
    start_date: "",
    end_date: "",
    sort_order: "asc",
  });

  const [totalPage, setTotalPage] = useState(0);

  const [currentPage, setCurrentPage] = useState(1);

  const [currentMeetingId, setCurrentMeetingId] = useState("");
  const [deleteMeetingId, setDeleteMeetingId] = useState("");
  const [isDeleteing, setIsDeleteing] = useState(false);

  const tabs = [
    { id: 1, label: "Upcoming" },
    { id: 2, label: "Past" },
    { id: 3, label: "Draft" },
  ];

  const changeTab = (tabId) => {
    setTotalPage(0);
    setCurrentPage(1);
    let tempParams = {
      ...meetinglistServerParams,
      search: "",
      startingLimit: 0,
      resultsPerPage: 6,
    };
    setCurrentTabId(tabId);
    setMeetingDetail(null);
    setMeetingListServerParams(tempParams);
    if (tabId === 1) {
      fetchMeetingList(tempParams);
    }
    if (tabId === 2) {
      fetchPastMeetingList(tempParams);
    }
    if (tabId === 3) {
      fetchDraftMeetingList(tempParams);
    }
  };

  const handleSearchChange = (e) => {
    let tempParams = { ...meetinglistServerParams, search: e.target.value };
    setMeetingListServerParams(tempParams);
    if (cuurentTabId === 1) {
      fetchMeetingList(tempParams);
    }
    if (cuurentTabId === 2) {
      fetchPastMeetingList(tempParams);
    }
    if (cuurentTabId === 3) {
      fetchDraftMeetingList(tempParams);
    }
  };

  const fetchMeetingList = async (meetinglistServerParams) => {
    setIsfetchingMeetingList(true);
    try {
      const meetingListResponse = await getMeetingList(meetinglistServerParams);
      if (meetingListResponse.statusCode === 200) {
        setMeetingList(meetingListResponse.data);
        setCurrentMeetingId(meetingListResponse.data[0].id);
        fetchMeetingDetail(meetingListResponse.data[0].id);
        getMeetingParticipants(meetingListResponse.data[0].id);
        let RoundedTotal = Math.ceil(
          meetingListResponse.total_count /
            meetinglistServerParams.resultsPerPage
        );
        setTotalPage(RoundedTotal);
      }
    } catch (error) {
      toast.error(error.response.data.msg);
    } finally {
      setIsfetchingMeetingList(false);
    }
  };

  const fetchPastMeetingList = async (meetinglistServerParams) => {
    setIsfetchingPastMeetingList(true);
    try {
      const pastMeetingListResponse = await getPastMeetingList(
        meetinglistServerParams
      );
      if (pastMeetingListResponse.statusCode === 200) {
        setPastMeetingList(pastMeetingListResponse.data);
        if (pastMeetingListResponse.data) {
          setCurrentMeetingId(pastMeetingListResponse.data[0].id);
          fetchMeetingDetail(pastMeetingListResponse.data[0].id);
          getMeetingParticipants(pastMeetingListResponse.data[0].id);
          let RoundedTotal = Math.ceil(
            pastMeetingListResponse.total_count /
              meetinglistServerParams.resultsPerPage
          );
          setTotalPage(RoundedTotal);
        }
      }
    } catch (error) {
      toast.error(error.response.data.msg);
    } finally {
      setIsfetchingPastMeetingList(false);
    }
  };

  const fetchDraftMeetingList = async (meetinglistServerParams) => {
    setIsfetchingDraftMeetingList(true);
    try {
      const draftMeetingListResponse = await getDraftMeetingList(
        meetinglistServerParams
      );
      if (draftMeetingListResponse.statusCode === 200) {
        setDraftMeetingList(draftMeetingListResponse.data);
        if (draftMeetingListResponse.data) {
          setCurrentMeetingId(draftMeetingListResponse.data[0].id);
          fetchMeetingDetail(draftMeetingListResponse.data[0].id);
          getMeetingParticipants(draftMeetingListResponse.data[0].id);
          let RoundedTotal = Math.ceil(
            draftMeetingListResponse.total_count /
              meetinglistServerParams.resultsPerPage
          );
          setTotalPage(RoundedTotal);
        }
      }
    } catch (error) {
      toast.error(error.response.data.msg);
    } finally {
      setIsfetchingDraftMeetingList(false);
    }
  };

  const changePage = (page) => {
    setTotalPage(0);
    setCurrentPage(page);
    setMeetingListServerParams({
      ...meetinglistServerParams,
      startingLimit:
        page == 1 ? 0 : (page - 1) * meetinglistServerParams.resultsPerPage,
    });
    if (cuurentTabId === 1) {
      fetchMeetingList({
        ...meetinglistServerParams,
        startingLimit:
          page == 1 ? 0 : (page - 1) * meetinglistServerParams.resultsPerPage,
      });
    }
    if (cuurentTabId === 2) {
      fetchPastMeetingList({
        ...meetinglistServerParams,
        startingLimit:
          page == 1 ? 0 : (page - 1) * meetinglistServerParams.resultsPerPage,
      });
    }
    if (cuurentTabId === 3) {
      fetchDraftMeetingList({
        ...meetinglistServerParams,
        startingLimit:
          page == 1 ? 0 : (page - 1) * meetinglistServerParams.resultsPerPage,
      });
    }
  };

  const gotoPreviousPage = () => {
    let pageStarting = 0;
    setCurrentPage(currentPage - 1);
    if (currentPage === 1) {
      setMeetingListServerParams({
        ...meetinglistServerParams,
        startingLimit: 0,
      });
    } else {
      pageStarting =
        meetinglistServerParams.startingLimit -
        meetinglistServerParams.resultsPerPage;
    }
    setMeetingListServerParams({
      ...meetinglistServerParams,
      startingLimit: pageStarting,
    });
    if (cuurentTabId === 1) {
      fetchMeetingList({
        ...meetinglistServerParams,
        startingLimit: pageStarting,
      });
    }
    if (cuurentTabId === 2) {
      fetchPastMeetingList({
        ...meetinglistServerParams,
        startingLimit: pageStarting,
      });
    }
    if (cuurentTabId === 3) {
      fetchDraftMeetingList({
        ...meetinglistServerParams,
        startingLimit: pageStarting,
      });
    }
  };

  const geToNextPage = () => {
    setCurrentPage(currentPage + 1);
    changePage(currentPage + 1);
  };

  const fetchMeetingDetail = async (meetingId) => {
    setIsfetchingMeetingDetail(true);
    let dataToSend = {
      id: meetingId,
    };
    try {
      const meetingDetailResponse = await getMeetingDetail(dataToSend);
      if (meetingDetailResponse.statusCode === 200) {
        setMeetingDetail(meetingDetailResponse.data[0]);
      }
    } catch (error) {
      toast.error(error.response.data.msg);
    } finally {
      setIsfetchingMeetingDetail(false);
    }
  };

  const getMeetingParticipants = async (meetingId) => {
    let dataToSend = {
      id: meetingId,
    };
    try {
      const res = await getParticipants(dataToSend);
      if (res.statusCode === 200) {
        setParticipants(res.data);
      } else {
        toast.error(res.msg);
      }
    } catch (error) {
      toast.error(error.response.data.msg);
    }
  };

  const deleteEvent = async (meetingId) => {
    setIsDeleteing(true);
    let dataTosend = {
      id: meetingId,
    };
    console.log("dataTosend", dataTosend);
    try {
      const meetingListResponse = await deleteMeeting(dataTosend);
      if (meetingListResponse.statusCode === 200) {
        closeDeleteModal();
        setMeetingDetail(null);
        if (cuurentTabId === 1) {
          fetchMeetingList(meetinglistServerParams);
        }
        if (cuurentTabId === 2) {
          fetchPastMeetingList(meetinglistServerParams);
        }
        if (cuurentTabId === 3) {
          fetchDraftMeetingList(meetinglistServerParams);
        }
      } else {
        toast.error(meetingListResponse.msg);
      }
    } catch (error) {
      toast.error(error.msg);
    } finally {
      setIsDeleteing(false);
    }
  };

  const openDeleteModal = (meetingId) => {
    setDeleteMeetingId(meetingId);
    let deleteModal = document.getElementById("deleteModal");
    let modal = window.bootstrap.Modal.getOrCreateInstance(deleteModal);
    if (modal && !modal._isShown) {
      modal.show();
    }
  };

  const closeDeleteModal = () => {
    let deleteModal = document.getElementById("deleteModal");
    let modal = window.bootstrap.Modal.getOrCreateInstance(deleteModal);
    if (modal && modal._isShown) {
      modal.hide();
    }
  };

  const openAddMeetingTypeModal = (meetingId) => {
    setDeleteMeetingId(meetingId);
    let createEventModal = document.getElementById("createEvent");
    let modal = window.bootstrap.Modal.getOrCreateInstance(createEventModal);
    if (modal && !modal._isShown) {
      modal.show();
    }
  };

  const closeAddMeetingTypeModal = () => {
    let createEventModal = document.getElementById("createEvent");
    let modal = window.bootstrap.Modal.getOrCreateInstance(createEventModal);
    if (modal && modal._isShown) {
      modal.hide();
    }
  };

  useEffect(() => {
    changeTab(cuurentTabId);
  }, []);

  const [state, setState] = useState({
    start: moment().subtract(29, "days"),
    end: moment(),
  });

  const { start, end } = state;
  const handleCallback = (start, end) => {
    let startDate = moment(start).format("yyyy-MM-DD HH:mm:SS");
    let endDate = moment(end).format("yyyy-MM-DD HH:mm:SS");
    setState({ start, end });
    console.log("start, end", startDate, endDate);
    let tempParams = {
      ...meetinglistServerParams,
      start_date: startDate,
      end_date: endDate,
    };
    setMeetingListServerParams(tempParams);
    console.log("tempParams", tempParams);
    if (cuurentTabId === 1) {
      fetchMeetingList(tempParams);
    }
    if (cuurentTabId === 2) {
      fetchPastMeetingList(tempParams);
    }
    if (cuurentTabId === 3) {
      fetchDraftMeetingList(tempParams);
    }
  };
  const label = start.format("DD/MM/YY") + " - " + end.format("DD/MM/YY");

  return (
    <>
      <ToastContainer position="top-right" />
      <div className="d-flex flex-column main-outer-wrapper main-site-wrapper ">
        <DashboardHeader />
        <div id="viewport" className="d-flex flex-grow-1">
          <DashboardSidebar />
          <div className="flex-grow-1 page-content-flex">
            <div className="page-content-wrap pb-0">
              <div className="heading-section page-heading d-md-flex align-items-center justify-content-between">
                <div className="left-right">
                  <h1>Events</h1>
                  <p>Check out your list of events</p>
                </div>
                <div className="right-wrap mt-3 mt-md-0">
                  <button
                    className="btn btn-primary add-btn mt-2 mt-md-0"
                    type="button"
                    onClick={() => {
                      openAddMeetingTypeModal();
                    }}
                  >
                    <Svg name="plus" /> Create Event
                  </button>
                </div>
              </div>
              <div className="meeting-pg">
                <div className="nav-tabs-wrapper vmersive-tabs mb-0">
                  <ul className="nav nav-tabs" id="meetings" role="tablist">
                    {tabs.map((tab, tabIndex) => (
                      <li
                        className="nav-item"
                        role="presentation"
                        key={tabIndex}
                      >
                        <button
                          className={
                            tab.id == cuurentTabId
                              ? "nav-link active"
                              : "nav-link"
                          }
                          id={`tab-${tab.id}-tab`}
                          data-bs-toggle="tab"
                          data-bs-target={`#tab-${tab.id}`}
                          type="button"
                          role="tab"
                          aria-controls={`tab-${tab.id}`}
                          aria-selected="true"
                          onClick={() => {
                            changeTab(tab.id);
                          }}
                        >
                          {tab.label}{" "}
                        </button>
                      </li>
                    ))}
                  </ul>
                  {/* <Link
                    to="/events/schedule"
                    type="button"
                    className="btn btn-primary add-btn mb-3 mb-md-0 mt-3 mt-md-0"
                  >
                    <Svg name="plus" /> Create Event
                  </Link> */}
                </div>
                <div className="page-wrapper">
                  <div className="tab-content" id="meetingsContent">
                    <div
                      className="tab-pane fade show active"
                      id="tab-1"
                      role="tabpanel"
                      aria-labelledby="tab-1-tab"
                    >
                      {/** Empty State Starts **/}

                      {/* <div className="empty-state text-center">
                        <div className="icon-text-wrap">
                          <div className="icon-wrap">
                            <Svg name="upcoming-empty" />
                          </div>
                          <div className="text-wrap">
                            <p>There are no Events scheduled <br /> for this Item.</p>
                          </div>
                          <div className="right-wrap mt-3 mt-md-4">
                            
                              <button
                                className="btn btn-primary add-btn"
                                type="button"
                                data-bs-toggle="modal"
                                data-bs-target="#createEvent"
                              >
                                <Svg name="plus" /> Create Event
                              </button>
                              </div>
                            
                          </div>
                        </div> */}

                      {/** Empty State Ends **/}

                      <div className="meeting-pg-wrapper d-flex">
                        <div className="meeting-list-wrap">
                          <div className="tab-search search-filter mb-4">
                            <div className="drop-wrap d-sm-flex align-items-center mb-3 list-search">
                              {/* <label className="flex-grow-1">Sort By - </label> */}

                              <div className="dropdown table-dropdown ques-options">
                                <button
                                  className="btn dropdown-tgl"
                                  type="button"
                                  id="sort-by"
                                  data-bs-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  All Events
                                </button>
                                <div
                                  className="dropdown-menu"
                                  aria-labelledby="sort-by"
                                >
                                  <a className="dropdown-item" href="#">
                                    {" "}
                                    All events{" "}
                                  </a>
                                  <a className="dropdown-item" href="#">
                                    {" "}
                                    Meeting{" "}
                                  </a>
                                  <a className="dropdown-item" href="#">
                                    {" "}
                                    Immersive (Guided){" "}
                                  </a>
                                  <a className="dropdown-item" href="#">
                                    {" "}
                                    Immersive (Self-guided){" "}
                                  </a>
                                  <a className="dropdown-item" href="#">
                                    {" "}
                                    VR Experience{" "}
                                  </a>
                                </div>
                              </div>

                              <div className="drop-wrap">
                                <DateRangePicker
                                  initialSettings={{
                                    showDropdowns: true,
                                    startDate: start.toDate(),
                                    endDate: end.toDate(),
                                    ranges: {
                                      Today: [
                                        moment().toDate(),
                                        moment().toDate(),
                                      ],
                                      Yesterday: [
                                        moment().subtract(1, "days").toDate(),
                                        moment().subtract(1, "days").toDate(),
                                      ],
                                      "Last 7 Days": [
                                        moment().subtract(6, "days").toDate(),
                                        moment().toDate(),
                                      ],
                                      "Last 30 Days": [
                                        moment().subtract(29, "days").toDate(),
                                        moment().toDate(),
                                      ],
                                      "This Month": [
                                        moment().startOf("month").toDate(),
                                        moment().endOf("month").toDate(),
                                      ],
                                      "Last Month": [
                                        moment()
                                          .subtract(1, "month")
                                          .startOf("month")
                                          .toDate(),
                                        moment()
                                          .subtract(1, "month")
                                          .endOf("month")
                                          .toDate(),
                                      ],
                                    },
                                  }}
                                  onCallback={handleCallback}
                                >
                                  <div
                                    id="reportrange"
                                    className="date-range-picker"
                                  >
                                    {label}
                                  </div>
                                </DateRangePicker>
                              </div>
                            </div>

                            <div className="search-filter-padding pe-0">
                              <input
                                type="text"
                                className="form-control custom-search-input"
                                placeholder="Search events"
                                value={meetinglistServerParams.search}
                                onChange={handleSearchChange}
                              />
                            </div>
                          </div>

                          {/** Empty State No Results **/}
                          {/* <div className="empty-state text-center">
                            <div className="icon-text-wrap icon-width-search">
                              <div className="icon-wrap">
                                <Svg name="search-empty" />
                              </div>
                              <div className="text-wrap">
                                <h4>No results found </h4>
                                <p>We couldn't find what you searched for please try another way</p>
                              </div>

                            </div>
                          </div> */}

                          {/** Empty State No Results Ends**/}

                          <div className="today-list">
                            {/* <div className="heading">Today</div> */}
                            <div className="list-wrapper">
                              {isFetchingMeetingList ? (
                                <Loader />
                              ) : meetingList.length > 0 ? (
                                <div className="meeting-list">
                                  {meetingList.map((meeting, meetingIndex) => (
                                    <div
                                      className="meeting-list-wrapper type-1"
                                      key={meetingIndex}
                                      onClick={() => {
                                        setMeetingDetail(null);
                                        setCurrentMeetingId(meeting.id);
                                        fetchMeetingDetail(meeting.id);
                                        getMeetingParticipants(meeting.id);
                                      }}
                                    >
                                      <a
                                        href="#"
                                        className={
                                          currentMeetingId === meeting.id
                                            ? "d-flex main-meeting active"
                                            : "d-flex main-meeting"
                                        }
                                      >
                                        <div className="text-wrapper flex-grow-1">
                                          <div className="heading">
                                            <h5 title={meeting.event_name}>
                                              {meeting.event_name}
                                            </h5>
                                          </div>
                                          <ul className="category">
                                            <li>Metaverse Meeting</li>
                                            {/* <li>
                                              Event ID : {meeting.event_code}
                                            </li> */}
                                          </ul>
                                        </div>
                                        <div className="date-time text-end">
                                          <p>
                                            {moment(meeting.event_date_time)
                                              .utc()
                                              .format("hh:mm A")}
                                            |{" "}
                                            {moment(
                                              meeting.event_end_date_time
                                            ).diff(
                                              moment(meeting.event_date_time),
                                              "minutes"
                                            )}{" "}
                                            mins
                                          </p>
                                          <p>
                                            {moment(
                                              meeting.event_date_time
                                            ).format("DD MMM, YYYY")}
                                          </p>
                                        </div>
                                      </a>
                                    </div>
                                  ))}
                                </div>
                              ) : (
                                <div className="meeting-list">
                                  No Data Found.
                                </div>
                              )}
                            </div>
                          </div>
                          <Pagination
                            currentPage={currentPage}
                            totalPage={totalPage}
                            gotoPreviousPage={gotoPreviousPage}
                            changePage={changePage}
                            geToNextPage={geToNextPage}
                          />
                        </div>
                        {/**For Mobile, this will toggel from bottom, so add class active so that it can toggle from bottom */}
                        <div className="meeting-data flex-grow-1 active">
                          {/**Remove Class on click of this button*/}
                          <button
                            type="button"
                            className="btn-close d-block d-lg-none"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          />
                          {isFetchingMeetingDetail ? (
                            <DetailLoader />
                          ) : (
                            <>
                              {meetingDetail ? (
                                <div className="meeting-info">
                                  <div className="title-wra">
                                    <h2 title={meetingDetail.event_name}>
                                      {meetingDetail.event_name}
                                    </h2>
                                    <p>{meetingDetail.event_description}</p>
                                  </div>
                                  <ul className="meta">
                                    <li className="d-flex align-items-center">
                                      <Svg name="calendar" />{" "}
                                      {moment(
                                        meetingDetail.event_date_time
                                      ).format("DD MMM, YYYY")}
                                    </li>
                                    <li className="d-flex align-items-center">
                                      <Svg name="clock" />{" "}
                                      {moment(meetingDetail.event_date_time)
                                        .utc()
                                        .format("h:mm A")}{" "}
                                    </li>
                                    <li className="d-flex align-items-center">
                                      <Svg name="timer-icon" />{" "}
                                      {moment(
                                        meetingDetail.event_end_date_time
                                      ).diff(
                                        moment(meetingDetail.event_date_time),
                                        "minutes"
                                      )}{" "}
                                      mins{" "}
                                    </li>
                                  </ul>
                                  <ul className="meta user-list-wrapper">
                                    <li>
                                      <span className="text">
                                        People invited{" "}
                                      </span>
                                      <div className="user-list">
                                        {participants.length > 0 &&
                                          participants.map((participant) => (
                                            <OverlayTrigger
                                              overlay={
                                                <Tooltip>
                                                  {participant.name}
                                                </Tooltip>
                                              }
                                            >
                                              <span>
                                                {participant.name &&
                                                  getShortName(
                                                    participant.name
                                                  )}
                                              </span>
                                            </OverlayTrigger>
                                          ))}
                                      </div>
                                    </li>
                                  </ul>
                                  <div className="meeting-details">
                                    <div className="meeting-list-inner">
                                      <span className="heading">
                                        Host name:
                                      </span>
                                      <span className="value">
                                        Jason Trevas
                                      </span>
                                    </div>
                                    <div className="meeting-list-inner">
                                      <span className="heading">
                                        Event Type:{" "}
                                      </span>
                                      <span className="value">
                                        Metaverse meeting{" "}
                                        <OverlayTrigger
                                          overlay={
                                            <Tooltip>
                                              Hosted experience with multiplayer
                                              avatar-based video collaboration
                                            </Tooltip>
                                          }
                                        >
                                          <button className="btn btn-link btn-tooltip p-0 ms-2">
                                            <Svg name="tooltip-icon" />
                                          </button>
                                        </OverlayTrigger>
                                      </span>
                                    </div>
                                    <div className="meeting-list-inner">
                                      <span className="heading">Region: </span>
                                      <span className="value">Europe </span>
                                    </div>
                                    <div className="meeting-list-inner">
                                      <span className="heading">
                                        Time zone:{" "}
                                      </span>
                                      <span className="value">
                                        {meetingDetail.event_timezone}
                                      </span>
                                    </div>
                                    <div className="meeting-list-inner">
                                      <span className="heading">
                                        Selected experiences:{" "}
                                      </span>
                                      <span className="value">
                                        <button
                                          type="button"
                                          data-bs-toggle="modal"
                                          data-bs-target="#modalDetails"
                                          srno="1"
                                          value="1"
                                        >
                                          {meetingDetail.experience_name}
                                        </button>
                                      </span>
                                    </div>

                                    <div className="meeting-list-inner">
                                      <span className="heading">
                                        Meeting ID:{" "}
                                      </span>
                                      <span className="value">
                                        {" "}
                                        {meetingDetail.event_code}
                                      </span>
                                    </div>
                                    <div className="meeting-list-inner">
                                      <span className="heading">
                                        Created by:{" "}
                                      </span>
                                      <span className="value">
                                        {meetingDetail.event_creator}
                                      </span>
                                    </div>
                                    <div className="meeting-list-inner">
                                      <span className="heading">
                                        Created on:{" "}
                                      </span>
                                      <span className="value">
                                        {moment(
                                          meetingDetail.add_date_time
                                        ).format("DD MMM, YYYY")}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="button-wrapper pt-2 pt-md-4">
                                    <button
                                      type="button"
                                      className="btn btn-primary"
                                    >
                                      {" "}
                                      Start{" "}
                                    </button>
                                    {/* <button
                                      type="button"
                                      className="btn btn-bordered alternate-color icon-btn"
                                    >
                                      <Svg name="icon-copy" /> Copy invitation{" "}
                                    </button> */}
                                    <button
                                      type="button"
                                      className="btn btn-bordered alternate-color icon-btn"
                                      onClick={() => {
                                        navigator.clipboard.writeText(
                                          meetingDetail?.link ?? null
                                        );
                                      }}
                                    >
                                      <Svg name="icon-copy" /> Copy host link{" "}
                                    </button>

                                    <OverlayTrigger
                                      overlay={<Tooltip>Edit</Tooltip>}
                                    >
                                      <button
                                        type="button"
                                        className="btn btn-bordered alternate-color icon-btn no-text"
                                        onClick={() => {
                                          navigate("/events/schedule", {
                                            state: meetingDetail.id,
                                          });
                                        }}
                                      >
                                        <Svg name="icon-edit" />
                                      </button>
                                    </OverlayTrigger>
                                    <OverlayTrigger
                                      overlay={<Tooltip>Delete</Tooltip>}
                                    >
                                      <button
                                        type="button"
                                        className="btn btn-bordered alternate-color danger icon-btn no-text"
                                        onClick={() => {
                                          openDeleteModal(meetingDetail.id);
                                        }}
                                      >
                                        <Svg name="icon-delete" />
                                      </button>
                                    </OverlayTrigger>
                                  </div>
                                </div>
                              ) : (
                                "No Data Found"
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="tab-2"
                      role="tabpanel"
                      aria-labelledby="tab-2-tab"
                    >
                      {/** Empty State Starts **/}

                      {/* <div className="empty-state text-center">
                        <div className="icon-text-wrap">
                          <div className="icon-wrap">
                            <Svg name="past-empty" />
                          </div>
                          <div className="text-wrap">
                            <p>There are no past events. You can <br /> begin by creating one.</p>
                          </div>
                          <div className="right-wrap mt-3 mt-md-4">
                            <button
                              className="btn btn-primary add-btn"
                              type="button"
                              data-bs-toggle="modal"
                              data-bs-target="#createEvent"
                            >
                              <Svg name="plus" /> Create Event
                            </button>
                          </div>
                        </div>
                      </div> */}

                      {/** Empty State Ends **/}
                      <div className="meeting-pg-wrapper d-flex">
                        <div className="meeting-list-wrap">
                          <div className="tab-search search-filter mb-4">
                            <div className="drop-wrap d-sm-flex align-items-center mb-3 list-search">
                              {/* <label className="flex-grow-1">Sort By - </label> */}

                              <div className="dropdown table-dropdown ques-options">
                                <button
                                  className="btn dropdown-tgl"
                                  type="button"
                                  id="sort-by"
                                  data-bs-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  All Events
                                </button>
                                <div
                                  className="dropdown-menu"
                                  aria-labelledby="sort-by"
                                >
                                  <a className="dropdown-item" href="#">
                                    {" "}
                                    All events{" "}
                                  </a>
                                  <a className="dropdown-item" href="#">
                                    {" "}
                                    Meeting{" "}
                                  </a>
                                  <a className="dropdown-item" href="#">
                                    {" "}
                                    Immersive (Guided){" "}
                                  </a>
                                  <a className="dropdown-item" href="#">
                                    {" "}
                                    Immersive (Self-guided){" "}
                                  </a>
                                  <a className="dropdown-item" href="#">
                                    {" "}
                                    VR Experience{" "}
                                  </a>
                                </div>
                              </div>

                              <div className="drop-wrap">
                                <DateRangePicker
                                  initialSettings={{
                                    showDropdowns: true,
                                    startDate: start.toDate(),
                                    endDate: end.toDate(),
                                    ranges: {
                                      Today: [
                                        moment().toDate(),
                                        moment().toDate(),
                                      ],
                                      Yesterday: [
                                        moment().subtract(1, "days").toDate(),
                                        moment().subtract(1, "days").toDate(),
                                      ],
                                      "Last 7 Days": [
                                        moment().subtract(6, "days").toDate(),
                                        moment().toDate(),
                                      ],
                                      "Last 30 Days": [
                                        moment().subtract(29, "days").toDate(),
                                        moment().toDate(),
                                      ],
                                      "This Month": [
                                        moment().startOf("month").toDate(),
                                        moment().endOf("month").toDate(),
                                      ],
                                      "Last Month": [
                                        moment()
                                          .subtract(1, "month")
                                          .startOf("month")
                                          .toDate(),
                                        moment()
                                          .subtract(1, "month")
                                          .endOf("month")
                                          .toDate(),
                                      ],
                                    },
                                  }}
                                  onCallback={handleCallback}
                                >
                                  <div
                                    id="reportrange"
                                    className="date-range-picker"
                                  >
                                    {label}
                                  </div>
                                </DateRangePicker>
                              </div>
                            </div>

                            <div className="search-filter-padding pe-0">
                              <input
                                type="text"
                                className="form-control custom-search-input"
                                placeholder="Search events"
                                value={meetinglistServerParams.search}
                                onChange={handleSearchChange}
                              />
                            </div>
                          </div>
                          <div className="today-list">
                            {/* <div className="heading">Today</div> */}
                            <div className="list-wrapper">
                              {isFetchingPastMeetingList ? (
                                <Loader />
                              ) : pastMeetingList.length > 0 ? (
                                <div className="meeting-list">
                                  {pastMeetingList.map(
                                    (pastMeeting, pastMeetingIndex) => (
                                      <div
                                        className="meeting-list-wrapper type-1"
                                        key={pastMeetingIndex}
                                        onClick={() => {
                                          setMeetingDetail(null);
                                          setCurrentMeetingId(pastMeeting.id);
                                          fetchMeetingDetail(pastMeeting.id);
                                          getMeetingParticipants(
                                            pastMeeting.id
                                          );
                                        }}
                                      >
                                        <a
                                          href="#"
                                          className={
                                            currentMeetingId === pastMeeting.id
                                              ? "d-flex main-meeting active"
                                              : "d-flex main-meeting"
                                          }
                                        >
                                          <div className="text-wrapper flex-grow-1">
                                            <div className="heading">
                                              <h5
                                                title={pastMeeting.event_name}
                                              >
                                                {pastMeeting.event_name}
                                              </h5>
                                            </div>
                                            <ul className="category">
                                              <li>Metaverse Meeting</li>
                                              {/* <li>
                                                Event ID :{" "}
                                                {pastMeeting.event_code}
                                              </li> */}
                                            </ul>
                                          </div>
                                          <div className="date-time text-end">
                                            <p>
                                              {moment(
                                                pastMeeting.event_date_time
                                              )
                                                .utc()
                                                .format("hh:mm A")}{" "}
                                              |{" "}
                                              {moment(
                                                pastMeeting.event_end_date_time
                                              ).diff(
                                                moment(
                                                  pastMeeting.event_date_time
                                                ),
                                                "minutes"
                                              )}{" "}
                                              mins
                                            </p>
                                            <p>
                                              {moment(
                                                pastMeeting.event_date_time
                                              ).format("DD MMM, YYYY")}
                                            </p>
                                          </div>
                                        </a>
                                      </div>
                                    )
                                  )}
                                </div>
                              ) : (
                                <div className="meeting-list">
                                  No Data Found.
                                </div>
                              )}
                            </div>
                          </div>
                          <Pagination
                            currentPage={currentPage}
                            totalPage={totalPage}
                            gotoPreviousPage={gotoPreviousPage}
                            changePage={changePage}
                            geToNextPage={geToNextPage}
                          />
                        </div>
                        {/**For Mobile, this will toggel from bottom, so add class active so that it can toggle from bottom */}
                        <div className="meeting-data flex-grow-1 active">
                          {/**Remove Class on click of this button*/}
                          <button
                            type="button"
                            className="btn-close d-block d-lg-none"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          />

                          {isFetchingMeetingDetail ? (
                            <DetailLoader />
                          ) : (
                            <>
                              {meetingDetail ? (
                                <div className="meeting-info">
                                  <div className="title-wra">
                                    <h2 title={meetingDetail.event_name}>
                                      {meetingDetail.event_name}
                                    </h2>
                                    <p>{meetingDetail.event_description}</p>
                                  </div>
                                  <ul className="meta">
                                    <li className="d-flex align-items-center">
                                      <Svg name="calendar" />{" "}
                                      {moment(
                                        meetingDetail.event_date_time
                                      ).format("DD MMM, YYYY")}
                                    </li>
                                    <li className="d-flex align-items-center">
                                      <Svg name="clock" />{" "}
                                      {moment(meetingDetail.event_date_time)
                                        .utc()
                                        .format("h:mm A")}
                                    </li>
                                    <li className="d-flex align-items-center">
                                      <Svg name="timer-icon" />{" "}
                                      {moment(
                                        meetingDetail.event_end_date_time
                                      ).diff(
                                        moment(meetingDetail.event_date_time),
                                        "minutes"
                                      )}{" "}
                                      mins{" "}
                                    </li>
                                  </ul>
                                  <ul className="meta user-list-wrapper">
                                    <li>
                                      <span className="text">
                                        People invited{" "}
                                      </span>
                                      <div className="user-list">
                                        {participants.length > 0 &&
                                          participants.map((participant) => (
                                            <OverlayTrigger
                                              overlay={
                                                <Tooltip>
                                                  {participant.name}
                                                </Tooltip>
                                              }
                                            >
                                              <span>
                                                {participant.name &&
                                                  getShortName(
                                                    participant.name
                                                  )}
                                              </span>
                                            </OverlayTrigger>
                                          ))}
                                      </div>
                                    </li>
                                  </ul>
                                  <div className="meeting-details">
                                    <div className="meeting-list-inner">
                                      <span className="heading">
                                        Host name:
                                      </span>
                                      <span className="value">
                                        Jason Trevas
                                      </span>
                                    </div>
                                    <div className="meeting-list-inner">
                                      <span className="heading">
                                        Event Type:{" "}
                                      </span>
                                      <span className="value">
                                        Metaverse meeting{" "}
                                        <OverlayTrigger
                                          overlay={
                                            <Tooltip>
                                              Hosted experience with multiplayer
                                              avatar-based video collaboration
                                            </Tooltip>
                                          }
                                        >
                                          <button className="btn btn-link btn-tooltip p-0 ms-2">
                                            <Svg name="tooltip-icon" />
                                          </button>
                                        </OverlayTrigger>
                                      </span>
                                    </div>
                                    <div className="meeting-list-inner">
                                      <span className="heading">Region: </span>
                                      <span className="value">Europe </span>
                                    </div>
                                    <div className="meeting-list-inner">
                                      <span className="heading">
                                        Time zone:{" "}
                                      </span>
                                      <span className="value">
                                        {meetingDetail.event_timezone}
                                      </span>
                                    </div>
                                    <div className="meeting-list-inner">
                                      <span className="heading">
                                        Selected experiences:{" "}
                                      </span>
                                      <span className="value">
                                        <button
                                          type="button"
                                          data-bs-toggle="modal"
                                          data-bs-target="#modalDetails"
                                          srno="1"
                                          value="1"
                                        >
                                          {meetingDetail.experience_name}
                                        </button>
                                      </span>
                                    </div>
                                    <div className="meeting-list-inner">
                                      <span className="heading">
                                        Meeting ID:{" "}
                                      </span>
                                      <span className="value">
                                        {meetingDetail.event_code}
                                      </span>
                                    </div>
                                    <div className="meeting-list-inner">
                                      <span className="heading">
                                        Created by:{" "}
                                      </span>
                                      <span className="value">
                                        {meetingDetail.event_creator}
                                      </span>
                                    </div>
                                    <div className="meeting-list-inner">
                                      <span className="heading">
                                        Created on:{" "}
                                      </span>
                                      <span className="value">
                                        {moment(
                                          meetingDetail.add_date_time
                                        ).format("DD MMM, YYYY")}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="button-wrapper pt-2 pt-md-4">
                                    <button
                                      type="button"
                                      className="btn btn-primary"
                                    >
                                      {" "}
                                      Start{" "}
                                    </button>
                                    {/* <button
                                      type="button"
                                      className="btn btn-bordered alternate-color icon-btn"
                                    >
                                      <Svg name="icon-copy" /> Copy invitation{" "}
                                    </button> */}
                                    <button
                                      type="button"
                                      className="btn btn-bordered alternate-color icon-btn"
                                      onClick={() => {
                                        navigator.clipboard.writeText(
                                          meetingDetail?.link ?? null
                                        );
                                      }}
                                    >
                                      <Svg name="icon-copy" /> Copy host link{" "}
                                    </button>
                                    <OverlayTrigger
                                      overlay={<Tooltip>Edit</Tooltip>}
                                    >
                                      <button
                                        type="button"
                                        className="btn btn-bordered alternate-color icon-btn no-text"
                                        onClick={() => {
                                          navigate("/events/schedule", {
                                            state: meetingDetail.id,
                                          });
                                        }}
                                      >
                                        <Svg name="icon-edit" />
                                      </button>
                                    </OverlayTrigger>
                                    <OverlayTrigger
                                      overlay={<Tooltip>Delete</Tooltip>}
                                    >
                                      <button
                                        type="button"
                                        className="btn btn-bordered alternate-color danger icon-btn no-text"
                                        onClick={() => {
                                          openDeleteModal(meetingDetail.id);
                                        }}
                                      >
                                        <Svg name="icon-delete" />
                                      </button>
                                    </OverlayTrigger>
                                  </div>
                                </div>
                              ) : (
                                "No Data Found"
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="tab-3"
                      role="tabpanel"
                      aria-labelledby="tab-3-tab"
                    >
                      {/** Empty State Starts **/}

                      {/* <div className="empty-state text-center">
                        <div className="icon-text-wrap">
                          <div className="icon-wrap">
                            <Svg name="draft-empty" />
                          </div>
                          <div className="text-wrap">
                            <p>Your draft is empty. Click here to start creating your event now.</p>
                          </div>
                          <div className="right-wrap mt-3 mt-md-4">
                            <button
                              className="btn btn-primary add-btn"
                              type="button"
                              data-bs-toggle="modal"
                              data-bs-target="#createEvent"
                            >
                              <Svg name="plus" /> Create Event
                            </button>
                          </div>
                        </div>
                      </div> */}

                      {/** Empty State Ends **/}

                      <div className="meeting-pg-wrapper d-flex">
                        <div className="meeting-list-wrap">
                          <div className="tab-search search-filter mb-4">
                            <div className="drop-wrap d-sm-flex align-items-center mb-3 list-search">
                              {/* <label className="flex-grow-1">Sort By - </label> */}

                              <div className="dropdown table-dropdown ques-options">
                                <button
                                  className="btn dropdown-tgl"
                                  type="button"
                                  id="sort-by"
                                  data-bs-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  All Events
                                </button>
                                <div
                                  className="dropdown-menu"
                                  aria-labelledby="sort-by"
                                >
                                  <a className="dropdown-item" href="#">
                                    {" "}
                                    All events{" "}
                                  </a>
                                  <a className="dropdown-item" href="#">
                                    {" "}
                                    Meeting{" "}
                                  </a>
                                  <a className="dropdown-item" href="#">
                                    {" "}
                                    Immersive (Guided){" "}
                                  </a>
                                  <a className="dropdown-item" href="#">
                                    {" "}
                                    Immersive (Self-guided){" "}
                                  </a>
                                  <a className="dropdown-item" href="#">
                                    {" "}
                                    VR Experience{" "}
                                  </a>
                                </div>
                              </div>

                              <div className="drop-wrap">
                                <DateRangePicker
                                  initialSettings={{
                                    showDropdowns: true,
                                    startDate: start.toDate(),
                                    endDate: end.toDate(),
                                    ranges: {
                                      Today: [
                                        moment().toDate(),
                                        moment().toDate(),
                                      ],
                                      Yesterday: [
                                        moment().subtract(1, "days").toDate(),
                                        moment().subtract(1, "days").toDate(),
                                      ],
                                      "Last 7 Days": [
                                        moment().subtract(6, "days").toDate(),
                                        moment().toDate(),
                                      ],
                                      "Last 30 Days": [
                                        moment().subtract(29, "days").toDate(),
                                        moment().toDate(),
                                      ],
                                      "This Month": [
                                        moment().startOf("month").toDate(),
                                        moment().endOf("month").toDate(),
                                      ],
                                      "Last Month": [
                                        moment()
                                          .subtract(1, "month")
                                          .startOf("month")
                                          .toDate(),
                                        moment()
                                          .subtract(1, "month")
                                          .endOf("month")
                                          .toDate(),
                                      ],
                                    },
                                  }}
                                  onCallback={handleCallback}
                                >
                                  <div
                                    id="reportrange"
                                    className="date-range-picker"
                                  >
                                    {label}
                                  </div>
                                </DateRangePicker>
                              </div>
                            </div>

                            <div className="search-filter-padding pe-0">
                              <input
                                type="text"
                                className="form-control custom-search-input"
                                placeholder="Search events"
                                value={meetinglistServerParams.search}
                                onChange={handleSearchChange}
                              />
                            </div>
                          </div>
                          <div className="today-list">
                            {/* <div className="heading">Today</div> */}
                            <div className="list-wrapper">
                              {isFetchingDraftMeetingList ? (
                                <Loader />
                              ) : darftMeetingList.length > 0 ? (
                                <div className="meeting-list">
                                  {darftMeetingList.map(
                                    (draftMeeting, draftMeetingIndex) => (
                                      <div
                                        className="meeting-list-wrapper type-1"
                                        key={draftMeetingIndex}
                                        onClick={() => {
                                          setMeetingDetail(null);
                                          setCurrentMeetingId(draftMeeting.id);
                                          fetchMeetingDetail(draftMeeting.id);
                                          getMeetingParticipants(
                                            draftMeeting.id
                                          );
                                        }}
                                      >
                                        <a
                                          href="#"
                                          className={
                                            currentMeetingId === draftMeeting.id
                                              ? "d-flex main-meeting active"
                                              : "d-flex main-meeting"
                                          }
                                        >
                                          <div className="text-wrapper flex-grow-1">
                                            <div className="heading">
                                              <h5
                                                title={draftMeeting.event_name}
                                              >
                                                {draftMeeting.event_name}
                                              </h5>
                                            </div>
                                            <ul className="category">
                                              <li>Metaverse Meeting</li>
                                              {/* <li>
                                                Event ID :{" "}
                                                {draftMeeting.event_code}
                                              </li> */}
                                            </ul>
                                          </div>
                                          <div className="date-time text-end">
                                            <p>
                                              {moment(
                                                draftMeeting.event_date_time
                                              )
                                                .utc()
                                                .format("hh:mm A")}{" "}
                                              |{" "}
                                              {moment(
                                                draftMeeting.event_end_date_time
                                              ).diff(
                                                moment(
                                                  draftMeeting.event_date_time
                                                ),
                                                "minutes"
                                              )}{" "}
                                              mins
                                            </p>
                                            <p>
                                              {moment(
                                                draftMeeting.event_date_time
                                              ).format("DD MMM, YYYY")}
                                            </p>
                                          </div>
                                        </a>
                                      </div>
                                    )
                                  )}
                                </div>
                              ) : (
                                <>No Data Found</>
                              )}
                            </div>
                          </div>
                          <Pagination
                            currentPage={currentPage}
                            totalPage={totalPage}
                            gotoPreviousPage={gotoPreviousPage}
                            changePage={changePage}
                            geToNextPage={geToNextPage}
                          />
                        </div>
                        {/**For Mobile, this will toggel from bottom, so add class active so that it can toggle from bottom */}
                        <div className="meeting-data flex-grow-1 active">
                          {/**Remove Class on click of this button*/}
                          <button
                            type="button"
                            className="btn-close d-block d-lg-none"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          />
                          {isFetchingMeetingDetail ? (
                            <DetailLoader />
                          ) : (
                            <>
                              {meetingDetail ? (
                                <div className="meeting-info">
                                  <div className="title-wra">
                                    <h2 title={meetingDetail.event_name}>
                                      {meetingDetail.event_name}
                                    </h2>
                                    <p>{meetingDetail.event_description}</p>
                                  </div>
                                  <ul className="meta">
                                    <li className="d-flex align-items-center">
                                      <Svg name="calendar" />{" "}
                                      {moment(
                                        meetingDetail.event_date_time
                                      ).format("DD MMM, YYYY")}
                                    </li>
                                    <li className="d-flex align-items-center">
                                      <Svg name="clock" />{" "}
                                      {moment(meetingDetail.event_date_time)
                                        .utc()
                                        .format("h:mm A")}
                                    </li>
                                    <li className="d-flex align-items-center">
                                      <Svg name="timer-icon" />{" "}
                                      {moment(
                                        meetingDetail.event_end_date_time
                                      ).diff(
                                        moment(meetingDetail.event_date_time),
                                        "minutes"
                                      )}{" "}
                                      mins{" "}
                                    </li>
                                    {/* <li className="d-flex align-items-center d-none">
                                      <Svg name="meetings" />{" "}
                                      {meetingDetail.number_of_participants}{" "}
                                    </li> */}
                                  </ul>
                                  <ul className="meta user-list-wrapper">
                                    <li>
                                      <span className="text">
                                        People invited{" "}
                                      </span>
                                      <div className="user-list">
                                        {participants.length > 0 &&
                                          participants.map((participant) => (
                                            <OverlayTrigger
                                              overlay={
                                                <Tooltip>
                                                  {participant.name}
                                                </Tooltip>
                                              }
                                            >
                                              <span>
                                                {participant.name &&
                                                  getShortName(
                                                    participant.name
                                                  )}
                                              </span>
                                            </OverlayTrigger>
                                          ))}
                                      </div>
                                    </li>
                                  </ul>
                                  <div className="meeting-details">
                                    <div className="meeting-list-inner">
                                      <span className="heading">
                                        Host name:
                                      </span>
                                      <span className="value">
                                        Jason Trevas
                                      </span>
                                    </div>
                                    <div className="meeting-list-inner">
                                      <span className="heading">
                                        Event Type:{" "}
                                      </span>
                                      <span className="value">
                                        Metaverse meeting{" "}
                                        <OverlayTrigger
                                          overlay={
                                            <Tooltip>
                                              Hosted experience with multiplayer
                                              avatar-based video collaboration
                                            </Tooltip>
                                          }
                                        >
                                          <button className="btn btn-link btn-tooltip p-0 ms-2">
                                            <Svg name="tooltip-icon" />
                                          </button>
                                        </OverlayTrigger>
                                      </span>
                                    </div>
                                    <div className="meeting-list-inner">
                                      <span className="heading">Region: </span>
                                      <span className="value">Europe </span>
                                    </div>
                                    <div className="meeting-list-inner">
                                      <span className="heading">
                                        Time zone:{" "}
                                      </span>
                                      <span className="value">
                                        {meetingDetail.event_timezone}
                                      </span>
                                    </div>
                                    <div className="meeting-list-inner">
                                      <span className="heading">
                                        Selected experiences:{" "}
                                      </span>
                                      <span className="value">
                                        <button
                                          type="button"
                                          data-bs-toggle="modal"
                                          data-bs-target="#modalDetails"
                                          srno="1"
                                          value="1"
                                        >
                                          {meetingDetail.experience_name}
                                        </button>
                                      </span>
                                    </div>
                                    <div className="meeting-list-inner">
                                      <span className="heading">
                                        Meeting ID:{" "}
                                      </span>
                                      <span className="value">
                                        {" "}
                                        {meetingDetail.event_code}
                                      </span>
                                    </div>
                                    <div className="meeting-list-inner">
                                      <span className="heading">
                                        Created by:{" "}
                                      </span>
                                      <span className="value">
                                        {meetingDetail.event_creator}
                                      </span>
                                    </div>
                                    <div className="meeting-list-inner">
                                      <span className="heading">
                                        Created on:{" "}
                                      </span>
                                      <span className="value">
                                        {moment(
                                          meetingDetail.add_date_time
                                        ).format("DD MMM, YYYY")}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="button-wrapper pt-2 pt-md-4">
                                    <button
                                      type="button"
                                      className="btn btn-primary"
                                    >
                                      {" "}
                                      Start{" "}
                                    </button>
                                    {/* <button
                                      type="button"
                                      className="btn btn-bordered alternate-color icon-btn"
                                    >
                                      <Svg name="icon-copy" /> Copy invitation{" "}
                                    </button> */}
                                    <button
                                      type="button"
                                      className="btn btn-bordered alternate-color icon-btn"
                                      onClick={() => {
                                        navigator.clipboard.writeText(
                                          meetingDetail?.link ?? null
                                        );
                                      }}
                                    >
                                      <Svg name="icon-copy" /> Copy host link{" "}
                                    </button>
                                    <OverlayTrigger
                                      overlay={<Tooltip>Edit</Tooltip>}
                                    >
                                      <button
                                        type="button"
                                        className="btn btn-bordered alternate-color icon-btn no-text"
                                        onClick={() => {
                                          navigate("/events/schedule", {
                                            state: meetingDetail.id,
                                          });
                                        }}
                                      >
                                        <Svg name="icon-edit" />
                                      </button>
                                    </OverlayTrigger>
                                    <OverlayTrigger
                                      overlay={<Tooltip>Delete</Tooltip>}
                                    >
                                      <button
                                        type="button"
                                        className="btn btn-bordered alternate-color danger icon-btn no-text"
                                        onClick={() => {
                                          openDeleteModal(meetingDetail.id);
                                        }}
                                      >
                                        <Svg name="icon-delete" />
                                      </button>
                                    </OverlayTrigger>
                                  </div>
                                </div>
                              ) : (
                                "No Data Found"
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="customvar-modal modal custom-modal fade deleteModal"
        id="deleteModal"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <DeleteMeetingModal
                deleteId={deleteMeetingId}
                handleDelete={deleteEvent}
                isDeleteing={isDeleteing}
                closeDeleteModal={closeDeleteModal}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className="customvar-modal modal fade fileUpload createEvent"
        id="createEvent"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <CreateEvent closeModal={closeAddMeetingTypeModal} />
        </div>
      </div>
    </>
  );
}
