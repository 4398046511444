import React, { useEffect, useState } from "react";
import Svg from "./Svg";
import DataTable from "react-data-table-component";
import template4 from "../../assets/images/template-4.jpg";
import { getMediaList, moveFiles } from "../../actions/media";
import { toast, ToastContainer } from "react-toastify";
import { getThumbnail } from "../../utils/imageThumbnail";
const moment = require("moment");

const TableLoader = () => (
  <div style={{ fontSize: "20px", fontWeight: 500, padding: "24px" }}>
    <>
      <span
        className="spinner-border spinner-border-sm"
        role="status"
        aria-hidden="true"
      ></span>
      <span className="visually-hidden">Loading...</span>
    </>
  </div>
);

const TableNoData = () => (
  <div style={{ padding: "24px" }}>Nothing to show here</div>
);

export default function MoveMediaModal({
  openMoveFileModal,
  moveMediaData,
  closeMoveMediaModal,
  toggleAddFolderModal,
}) {
  const [serverParams, setServerParams] = useState({
    startingLimit: 0,
    resultsPerPage: 100,
    parent_id: "",
    file_type: "11",
    search: "",
    sort_by: "name",
    sort_order: "asc",
  });
  const [isFetching, setIsFetching] = useState(false);
  const [mediaData, setMediaData] = useState([]);
  const [folderData, setFolderData] = useState([]);
  const [movingMedia, setMovingMedia] = useState(false);
  const [parentFolderId, setParentFolderId] = useState("");

  const getMediaFolderList = async (serverParams) => {
    setIsFetching(true);
    try {
      const mediaListResponse = await getMediaList(serverParams);
      if (mediaListResponse.statusCode === 200) {
        setMediaData(mediaListResponse.data);
        // setTotalMediaData(mediaListResponse.total_count);
      }
    } catch (error) {
      toast.error(error.response.data.msg);
    } finally {
      setIsFetching(false);
    }
  };
  const getFolderMediaList = async (folderDataObj) => {
    if (folderDataObj.is_folder === 1) {
      setFolderData([...folderData, folderDataObj]);
      const tempParams = {
        ...serverParams,
        // file_type: "",
        parent_id: folderDataObj.id,
      };
      setServerParams(tempParams);
      getMediaFolderList(tempParams);
    }
  };
  const columns = [
    {
      name: "Name",
      width: "350px",
      sortable: true,
      sortField: "user_asset_library_name",
      selector: (row) => row.user_asset_library_name,
      cell: (row) => (
        <>
          <div
            className="d-flex table-media align-items-center"
            onClick={() => {
              getFolderMediaList(row);
            }}
          >
            <div
              className="img-wrapper"
              onClick={() => {
                setParentFolderId(row.id);
              }}
            >
              {row.is_folder === 1 && <Svg name="folder-icon" />}
              {row.is_folder === 0 && (
                <>
                  {/* {row.user_asset_library_name.split(".").pop()} */}
                  {/* {row.asset_type_id === null && (
                      <>
                        {row.user_asset_library_name.split(".").pop() ===
                          "pdf" && <Svg name="pdf-icon" />}
                        {row.user_asset_library_name.split(".").pop() ===
                          "ppt" && <Svg name="ppt-icon" />}
                        {row.user_asset_library_name.split(".").pop() ===
                          "mp4" && <Svg name="video-file-icon" />}
                        {row.asset_type_id === 6 && <Svg name="youtube-icon" />}
                        {row.asset_type_id === 10 && <Svg name="vimeo-icon" />}
                        {["png", "jpg", "jpeg", "gif", "svg"].includes(
                          row.user_asset_library_name.split(".").pop()
                        ) && <img src={row.link} alt="" className="img-fluid" />}
                      </>
                    )} */}
                  {row.asset_type_id && (
                    <>
                      {row.asset_type_id === 6 && getThumbnail(row.link) && (
                        <img
                          src={getThumbnail(row.link)}
                          alt={row.user_asset_library_name}
                        />
                      )}
                      {row.asset_type_id === 7 && <Svg name="ppt-icon" />}
                      {row.asset_type_id === 8 && <Svg name="pdf-icon" />}
                      {row.asset_type_id === 10 && (
                        <Svg name="vimeo-icon-list" />
                      )}
                      {["png", "jpg", "jpeg", "gif", "svg"].includes(
                        row.user_asset_library_name.split(".").pop()
                      ) && <img src={row.link} alt="" className="img-fluid" />}
                      {["mp4"].includes(
                        row.user_asset_library_name.split(".").pop()
                      ) && (
                        <img
                          src={`${row.link}.jpg`}
                          alt=""
                          className="img-fluid"
                        />
                      )}
                    </>
                  )}
                  {!row.asset_type_id && <Svg name="doc-file-icon" />}
                  {/* <Svg name="ppt-icon" />
  
                    <Svg name="doc-file-icon" /> */}
                  {/* <img src={template4} alt="" className="img-fluid" /> */}
                </>
              )}
            </div>
            <div
              className="flex-grow-1 ps-3"
              onClick={() => {
                if ([...Array(11).keys()].includes(row.asset_type_id)) {
                  window.open(row.link, "_blank");
                }
                setParentFolderId(row.id);
              }}
            >
              <span className="file-name strong mb-0">
                {row.user_asset_library_name}
              </span>
            </div>
          </div>
        </>
      ),
    },
    {
      name: "Type",
      sortable: false,
      sortField: "asset_type_id",
      selector: (row) =>
        row.asset_type_id === 1
          ? "mp4"
          : row.asset_type_id === 2
          ? "png"
          : row.asset_type_id === 3
          ? "jpg"
          : row.asset_type_id === 4
          ? "gif"
          : row.asset_type_id === 5
          ? "html"
          : row.asset_type_id === 6
          ? "youtube link"
          : row.asset_type_id === 7
          ? "ppt"
          : row.asset_type_id === 8
          ? "pdf"
          : row.asset_type_id === 9
          ? "SVG"
          : row.asset_type_id === 10
          ? "vimeo link"
          : row.asset_type_id === 11
          ? "jpeg"
          : "-",
    },
    {
      name: "Date modified",
      sortable: true,
      sortField: "add_date_time",
      selector: (row) => moment(row.add_date_time).format("DD MMM, YYYY"),
    },
  ];

  const goToBackAllMediaList = () => {
    setFolderData([]);
    setServerParams({
      ...serverParams,
      parent_id: "",
    });
    getMediaFolderList({
      ...serverParams,
      parent_id: "",
    });
  };

  const moveMedia = async () => {
    setMovingMedia(true);
    try {
      let dataToSend = {
        id: moveMediaData,
        parent_id: parentFolderId,
      };
      const res = await moveFiles(dataToSend);
      if (res.statusCode === 200) {
        setFolderData([]);
        setServerParams({ ...serverParams, parent_id: "" });
        closeMoveMediaModal();
      }
    } catch (error) {
    } finally {
      setMovingMedia(false);
    }
  };

  useEffect(() => {
    if (openMoveFileModal) {
      getMediaFolderList(serverParams);
    }
  }, [openMoveFileModal]);

  useEffect(() => {
    if (folderData.length > 0) {
      const tempParams = {
        ...serverParams,
        parent_id:
          folderData.length > 0 ? folderData[folderData.length - 1]["id"] : "",
      };
      setServerParams(tempParams);
      getMediaFolderList(tempParams);
    }
  }, [folderData]);

  return (
    <>
      <ToastContainer position="top-right" />

      <div className={"formstyle"}>
        <div className="tab-3-wrapper">
          <div className="page-wrapper">
            <div className="heading-section page-heading d-md-flex align-items-center justify-content-between mb-3">
              {folderData.length === 0 && <h1>Media</h1>}
              {folderData.length > 0 && (
                <nav className="folder-breadcrumbs" aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li
                      className="breadcrumb-item"
                      onClick={() => {
                        goToBackAllMediaList();
                      }}
                    >
                      <a href="#">Media</a>
                    </li>
                    {folderData.map((folder, folderIndex) => (
                      <li
                        className="breadcrumb-item active cursor-pointer"
                        aria-current="page"
                        key={folderIndex}
                        onClick={() => {
                          setFolderData(folderData.slice(0, folderIndex + 1));
                        }}
                      >
                        <Svg name="folder-icon" />{" "}
                        {folder.user_asset_library_name}
                      </li>
                    ))}
                  </ol>
                </nav>
              )}
            </div>
            <div className="table-wrapper-view">
              <div className="tab-search search-filter d-flex align-items-center">
                <div className="flex-grow-1 search-filter-padding">
                  <input
                    type="text"
                    className="form-control custom-search-input"
                    placeholder="Search files"
                  />
                </div>
                <div className="drop-wrap d-flex align-items-center">
                  <label>File type - </label>
                  <div className="dropdown table-dropdown ques-options">
                    <button
                      className="btn dropdown-tgl file-type"
                      type="button"
                      id="file-type"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      All
                    </button>
                    <div className="dropdown-menu" aria-labelledby="file-type">
                      <a className="dropdown-item" href="#">
                        {" "}
                        All{" "}
                      </a>
                      <a className="dropdown-item" href="#">
                        {" "}
                        PNG{" "}
                      </a>
                      <a className="dropdown-item" href="#">
                        {" "}
                        JPG{" "}
                      </a>
                      <a className="dropdown-item" href="#">
                        {" "}
                        SVG{" "}
                      </a>
                      <a className="dropdown-item" href="#">
                        {" "}
                        PPT{" "}
                      </a>
                      <a className="dropdown-item" href="#">
                        {" "}
                        GIFs{" "}
                      </a>
                      <a className="dropdown-item" href="#">
                        {" "}
                        PDF{" "}
                      </a>
                      <a className="dropdown-item" href="#">
                        {" "}
                        MP4{" "}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="table-responsive">
                <div className="table-wrap custom-data-table table-media-wrapper media-checks">
                  <DataTable
                    // title="Movies"
                    columns={columns}
                    data={mediaData}
                    // selectableRows
                    progressPending={isFetching}
                    progressComponent={<TableLoader />}
                    noDataComponent={<TableNoData />}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-footer btn-160 justify-content-md-between justify-content-end">
        <button
          type="button"
          className="btn btn-link p-0"
          onClick={() => {
            toggleAddFolderModal("createFromModal");
          }}
        >
          Create new folder
        </button>
        <div className="button-wrap d-flex align-items-center flex-grow-1 ps-md-3 justify-content-end ">
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => {
              moveMedia();
            }}
          >
            {movingMedia ? (
              <>
                <span
                  className="white-spinner spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Loading...</span>
              </>
            ) : (
              "Move here"
            )}
          </button>
          <button
            type="button"
            className="btn btn-bordered color-cancel ms-3"
            data-bs-dismiss="modal"
          >
            Cancel
          </button>
        </div>
      </div>
    </>
  );
}
