import React from 'react'
import DashboardHeader from '../common/DashboardHeader'
import DashboardSidebar from '../common/DashboardSidebar'
import Svg from '../common/Svg'
import ReactEcharts from "echarts-for-react";


export default function Index() {
    var echarts = require("echarts");

    const timeSpent = {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            }
        },
        xAxis: {
            type: 'category',
            name: '',
            nameLocation: 'middle',
            nameGap: 40,
            boundaryGap: false,
            data: ['Airport', 'Warehouse', 'Cold Storage', 'Dockyard', 'Smart Parking', 'Smart Factory', 'Data Center', 'Command Center', 'Virtual Expo', 'Shopping Mall']
        },
        yAxis: {
            type: 'value',
            name: 'Active time (Mins)',
            nameLocation: 'middle',
            nameGap: 60,
        },
        series: [
            {
                data: [40, 25, 20, 25, 30, 35, 38, 45, 20, 26, 35],
                type: 'line',
                smooth: true,
                symbol: 'circle',
                symbolSize: 5,

                itemStyle: {
                    color: '#3CCF4E'
                },
                areaStyle: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        {
                            offset: 0,
                            color: 'rgba(60, 207, 78, 0.58)'
                        },
                        {
                            offset: 1,
                            color: 'rgba(60, 207, 78, 0.0)'
                        }
                    ])
                },
            }
        ]
    }

    const mediaEngagement = {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            }
        },
        legend: {},
        grid: {
            // left: '3%',
            // right: '4%',
            // bottom: '3%',
            // containLabel: true
        },
        xAxis: {
            type: 'category',
            data: ['Images', 'Videos', 'Links', 'PDF', 'PPT'],
            axisTick: {
                alignWithLabel: true
            },
            axisLabel: {
                interval: 0,
                //   rotate: 40
            },
            splitLine: {
                show: false
            }
        },
        yAxis: {
            type: 'value',
            name: 'Views',
            nameLocation: 'middle',
            nameGap: 40,
        },
        series: [

            {
                name: '',
                barWidth: '60px',
                type: 'bar',
                stack: 'Survey',
                emphasis: {
                    focus: 'series'
                },
                data: [30, 7, 20, 9, 22],

                itemStyle: {
                    color: '#3CCF4E'
                },
            }
        ]
    };

    return (
        <div className='d-flex flex-column main-outer-wrapper main-site-wrapper '>
            <DashboardHeader />
            <div id="viewport" className="d-flex flex-grow-1">
                <DashboardSidebar />
                <div className="flex-grow-1 page-content-flex">

                    <div className="page-content-wrap bg-dashboard">

                        <div className="page-wrapper analytics-page">
                            <div className="stats-list mb__30">
                                <div className="row">
                                    <div className="col-lg mb-3 mb-lg-0">
                                        <div className="stats-wrap analytics-stat stat-1 text-center">
                                            <div className="text-wrap">
                                                <h3>157 </h3>
                                                <p>Total events</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg mb-3 mb-lg-0">
                                        <div className="stats-wrap analytics-stat stat-2 text-center">
                                            <div className="text-wrap">
                                                <h3>50 Mins </h3>
                                                <p>Avg. event duration</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg mb-3 mb-lg-0">
                                        <div className="stats-wrap analytics-stat stat-3 text-center">
                                            <div className="text-wrap">
                                                <h3>161 </h3>
                                                <p>Unique participants</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg mb-3 mb-lg-0">
                                        <div className="stats-wrap analytics-stat stat-4 text-center">
                                            <div className="text-wrap">
                                                <h3>17 Hrs </h3>
                                                <p>Total time spent</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg mb-3 mb-lg-0">
                                        <div className="stats-wrap analytics-stat stat-5 text-center">
                                            <div className="text-wrap">
                                                <h3>20k </h3>
                                                <p>Media clicks</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="dashboard-cards-wraper row">
                                <div className="col-lg-12 mb-4">
                                    <div className="card dashboard-card min-100">
                                        <div className="dashboard-card-header d-md-flex justify-content-between align-items-center">
                                            <div className="left-wrap">
                                                <h3>Average time spent in top ten Experiences </h3>
                                            </div>
                                            <div className="right-wrap custom-dropdown avg-dropdown">
                                                <button type="button" className="btn dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                                    All Events
                                                </button>
                                                <ul className="dropdown-menu">
                                                    <li>
                                                        <button className="dropdown-item icon-btn" type="button">Rename</button>
                                                    </li>
                                                    <li>
                                                        <button className="dropdown-item icon-btn" type="button">Move</button>
                                                    </li>
                                                    <li>
                                                        <button className="dropdown-item icon-btn" type="button">Copy</button>
                                                    </li>
                                                    <li>
                                                        <button className="dropdown-item icon-btn" type="button">Copy link</button>
                                                    </li>
                                                    <li>
                                                        <button className="dropdown-item icon-btn" type="button">Delete</button>
                                                    </li>

                                                </ul>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="chart-wrapper">
                                                <ReactEcharts className='chart-height' option={timeSpent} />

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-8 mb-4 mb-lg-0">
                                    <div className="card dashboard-card min-100">
                                        <div className="dashboard-card-header d-md-flex justify-content-between mb-2">
                                            <div className="left-wrap">
                                                <h3>Media Engagement by total no. of participants</h3>
                                            </div>
                                            <div className="right-wrap custom-dropdown">
                                                <div className="mb-3">
                                                    <button type="button" className="btn dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                                        Event with client
                                                    </button>
                                                    <ul className="dropdown-menu">
                                                        <li>
                                                            <button className="dropdown-item icon-btn" type="button">Event 1</button>
                                                        </li>
                                                        <li>
                                                            <button className="dropdown-item icon-btn" type="button">Event 2</button>
                                                        </li>
                                                        <li>
                                                            <button className="dropdown-item icon-btn" type="button">Event 3</button>
                                                        </li>
                                                        <li>
                                                            <button className="dropdown-item icon-btn" type="button">Event 4</button>
                                                        </li>
                                                        <li>
                                                            <button className="dropdown-item icon-btn" type="button">Event 5</button>
                                                        </li>

                                                    </ul>
                                                </div>
                                                <div className="mb-0">
                                                    <button type="button" className="btn dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                                        All Hotspots
                                                    </button>
                                                    <ul className="dropdown-menu">
                                                        <li>
                                                            <button className="dropdown-item icon-btn" type="button">Hotspot 1</button>
                                                        </li>
                                                        <li>
                                                            <button className="dropdown-item icon-btn" type="button">Hotspot 2</button>
                                                        </li>
                                                        <li>
                                                            <button className="dropdown-item icon-btn" type="button">Hotspot 3</button>
                                                        </li>
                                                        <li>
                                                            <button className="dropdown-item icon-btn" type="button">Hotspot 4</button>
                                                        </li>
                                                        <li>
                                                            <button className="dropdown-item icon-btn" type="button">Hotspot 5</button>
                                                        </li>

                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="chart-wrapper">
                                                <ReactEcharts className='chart-height' option={mediaEngagement} />

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="card dashboard-card min-100">
                                        <div className="dashboard-card-header d-md-flex justify-content-between align-items-center">
                                            <div className="left-wrap">
                                                <h3>Traffic by device</h3>
                                            </div>
                                            <div className="right-wrap custom-dropdown">
                                                <button type="button" className="btn dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                                    Select event
                                                </button>
                                                <ul className="dropdown-menu">
                                                    <li>
                                                        <button className="dropdown-item icon-btn" type="button">Event 1</button>
                                                    </li>
                                                    <li>
                                                        <button className="dropdown-item icon-btn" type="button">Event 2</button>
                                                    </li>
                                                    <li>
                                                        <button className="dropdown-item icon-btn" type="button">Event 3</button>
                                                    </li>
                                                    <li>
                                                        <button className="dropdown-item icon-btn" type="button">Event 4</button>
                                                    </li>
                                                    <li>
                                                        <button className="dropdown-item icon-btn" type="button">Event 5</button>
                                                    </li>

                                                </ul>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="row gx-3 stats-list traffic-stat">
                                                <div className="col-lg-6 mt-3">
                                                    <div className="stats-wrap analytics-stat stat-1">
                                                        <div className="heading d-flex align-items-center mb-2">
                                                            <div className="flex-grow-1">
                                                                <p>Desktop</p>
                                                            </div>
                                                            <div className="icon-wrap">
                                                                <Svg name="icon-desktop" />
                                                            </div>
                                                        </div>
                                                        <h3 className='mb-0'>157 </h3>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 mt-3">
                                                    <div className="stats-wrap analytics-stat stat-2">
                                                        <div className="heading d-flex align-items-center mb-2">
                                                            <div className="flex-grow-1">
                                                                <p>Mobile</p>
                                                            </div>
                                                            <div className="icon-wrap">
                                                                <Svg name="icon-mobile" />
                                                            </div>
                                                        </div>
                                                        <h3 className='mb-0'>157 </h3>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 mt-3">
                                                    <div className="stats-wrap analytics-stat stat-5">
                                                        <div className="heading d-flex align-items-center mb-2">
                                                            <div className="flex-grow-1">
                                                                <p>Tablet</p>
                                                            </div>
                                                            <div className="icon-wrap">
                                                                <Svg name="icon-tablet" />
                                                            </div>
                                                        </div>
                                                        <h3 className='mb-0'>157 </h3>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 mt-3">
                                                    <div className="stats-wrap analytics-stat stat-3">
                                                        <div className="heading d-flex align-items-center mb-2">
                                                            <div className="flex-grow-1">
                                                                <p>VR</p>
                                                            </div>
                                                            <div className="icon-wrap">
                                                                <Svg name="icon-vr" />
                                                            </div>
                                                        </div>
                                                        <h3 className='mb-0'>157 </h3>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
