import React, { useState } from 'react';
import DashboardHeader from '../common/DashboardHeader'
import DashboardSidebar from '../common/DashboardSidebar'
import ScheduleHeader from './ScheduleHeader'
import Svg from '../common/Svg'
import { Link } from 'react-router-dom'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import DataTable from "react-data-table-component";


const columns = [
    {
        name: "Event",
        selector: (row) => row.name,
    },
    {
        name: "Type",
        selector: (row) => row.type,
    },

    {
        name: "",
        width: "100px",
        cell: (row) => (
            <>
                <button className='btn btn-primary'>Use</button>
            </>
        ),
    },
];

const data = [
    {
        id: 1,
        name: "Smart Factory Solutions",
        type: "Metaverse meeting ",
    },
    {
        id: 2,
        name: "Europe Customer Experience center",
        type: "Immersive (Guided)",
    },
    {
        id: 3,
        name: "Virtual car showroom",
        type: "Immersive ( Self-guided)",
    },
    {
        id: 4,
        name: "Americas Customer Experience center",
        type: "VR Experience",
    },
    {
        id: 5,
        name: "Meeting with client",
        type: "Metaverse meeting ",
    },
    {
        id: 6,
        name: "Europe Customer Experience center",
        type: "Immersive (Guided)",
    },
    {
        id: 7,
        name: "Smart Factory Solutions",
        type: "Metaverse meeting ",
    },
    {
        id: 8,
        name: "Europe Customer Experience center",
        type: "Immersive (Guided)",
    },
    {
        id: 9,
        name: "Smart Factory Solutions",
        type: "Metaverse meeting ",
    },
    {
        id: 10,
        name: "Europe Customer Experience center",
        type: "Immersive (Guided)",
    },

];


export default function CreateForm() {
    const [showSidebar, setShowSidebar] = useState(false);
    function toggleSidebar() {
        setShowSidebar(!showSidebar);
    }

    function closeSidebar() {
        setShowSidebar(false);
    }
    return (
        <>
            <div className='d-flex flex-column main-outer-wrapper main-site-wrapper '>
                <DashboardHeader />
                <div id="viewport" className="d-flex flex-grow-1">
                    <DashboardSidebar />
                    <div className="flex-grow-1 page-content-flex">
                        <ScheduleHeader />
                        <div className="page-content-wrap">
                            <div className="heading-section page-heading d-md-flex align-items-center justify-content-between">
                                <div className="left-right">
                                    <h1>Create Lead-gen Form</h1>
                                    {/* <p>Select an experience or <a data-bs-toggle="modal" data-bs-target="#leaveScheduling" className='text-primary cursor-pointer text-deco'>create a new experience</a></p> */}
                                </div>
                                <div className="right-wrap mt-3 mt-md-0">
                                    <Link to="/immersive-self-guided/share" type="button" className="btn btn-primary add-btn"> Share </Link>
                                </div>
                            </div>

                            <div className="exp-button-wrapper-outer">
                                {/* <div className="choose-form">
                                    <div className="inner-box-wrapper text-center">
                                        <div className="emty-wrap">
                                            <h3> Hi there!</h3>
                                            <p>Start your project with an empty form or customize a predesigned form</p>
                                        </div>
                                        <div className="button-wrap gap-10 d-flex justify-content-center mt-3 mt-md-4">
                                            <button type="button" className="btn btn-primary"> Empty form </button>
                                            <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#useTemplate"> Use template </button>
                                        </div>
                                    </div>
                                </div> */}
                                <div className="form-builder-custom">

                                    <div className="main-components account-info">
                                        <div className="field-wrapper-outer">
                                            <div className="field-wrapper-inner">
                                                <button className="sort-button">Reorder</button>
                                                <div className="action-buttons">
                                                    <OverlayTrigger overlay={<Tooltip>Edit Field</Tooltip>}>
                                                        <button className="btn btn-white" onClick={toggleSidebar}>
                                                            <Svg name="icon-edit" />
                                                        </button>
                                                    </OverlayTrigger>
                                                    <OverlayTrigger overlay={<Tooltip>Remove Field</Tooltip>}>
                                                        <button className="btn btn-white">
                                                            <Svg name="icon-delete" />
                                                        </button>
                                                    </OverlayTrigger>
                                                </div>
                                                <div className="form-field">
                                                    <label htmlFor="">Name</label>
                                                    <input type="text" className="form-control" />
                                                </div>
                                            </div>

                                            <div className="field-wrapper-inner">
                                                <button className="sort-button">Reorder</button>
                                                <div className="action-buttons">
                                                    <OverlayTrigger overlay={<Tooltip>Edit Field</Tooltip>}>
                                                        <button className="btn btn-white" onClick={toggleSidebar}>
                                                            <Svg name="icon-edit" />
                                                        </button>
                                                    </OverlayTrigger>
                                                    <OverlayTrigger overlay={<Tooltip>Remove Field</Tooltip>}>
                                                        <button className="btn btn-white">
                                                            <Svg name="icon-delete" />
                                                        </button>
                                                    </OverlayTrigger>
                                                </div>
                                                <div className="form-field">
                                                    <label htmlFor="">Email</label>
                                                    <input type="text" className="form-control" />
                                                </div>
                                            </div>

                                            <div className="field-wrapper-inner">
                                                <button className="sort-button">Reorder</button>
                                                <div className="action-buttons">
                                                    <OverlayTrigger overlay={<Tooltip>Edit Field</Tooltip>}>
                                                        <button className="btn btn-white" onClick={toggleSidebar}>
                                                            <Svg name="icon-edit" />
                                                        </button>
                                                    </OverlayTrigger>
                                                    <OverlayTrigger overlay={<Tooltip>Remove Field</Tooltip>}>
                                                        <button className="btn btn-white">
                                                            <Svg name="icon-delete" />
                                                        </button>
                                                    </OverlayTrigger>
                                                </div>
                                                <div className="form-field">
                                                    <label htmlFor="">Address</label>
                                                    <textarea className="form-control" rows={2} />
                                                </div>
                                            </div>

                                            <div className="field-wrapper-inner">
                                                <button className="sort-button">Reorder</button>
                                                <div className="action-buttons">
                                                    <OverlayTrigger overlay={<Tooltip>Edit Field</Tooltip>}>
                                                        <button className="btn btn-white" onClick={toggleSidebar}>
                                                            <Svg name="icon-edit" />
                                                        </button>
                                                    </OverlayTrigger>
                                                    <OverlayTrigger overlay={<Tooltip>Remove Field</Tooltip>}>
                                                        <button className="btn btn-white">
                                                            <Svg name="icon-delete" />
                                                        </button>
                                                    </OverlayTrigger>
                                                </div>
                                                <div className="form-field">
                                                    <label htmlFor="">Message</label>
                                                    <textarea className="form-control" rows={2} />
                                                </div>
                                            </div>

                                            <div className="field-wrapper-inner">
                                                <button className="sort-button">Reorder</button>
                                                <div className="action-buttons">
                                                    <OverlayTrigger overlay={<Tooltip>Edit Field</Tooltip>}>
                                                        <button className="btn btn-white" onClick={toggleSidebar}>
                                                            <Svg name="icon-edit" />
                                                        </button>
                                                    </OverlayTrigger>
                                                    <OverlayTrigger overlay={<Tooltip>Remove Field</Tooltip>}>
                                                        <button className="btn btn-white">
                                                            <Svg name="icon-delete" />
                                                        </button>
                                                    </OverlayTrigger>
                                                </div>
                                                <div className="form-field">
                                                    <label>Radio Label 1</label>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="radio" name="radioField" id="radioField1" defaultChecked />
                                                        <label className="form-check-label" htmlFor="radioField1"> Option 1 </label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="radio" name="radioField" id="radioField2" />
                                                        <label className="form-check-label" htmlFor="radioField2"> Option 2 </label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="radio" name="radioField" id="radioField3" />
                                                        <label className="form-check-label" htmlFor="radioField3"> Option 3 </label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="field-wrapper-inner">
                                                <button className="sort-button">Reorder</button>
                                                <div className="action-buttons">
                                                    <OverlayTrigger overlay={<Tooltip>Edit Field</Tooltip>}>
                                                        <button className="btn btn-white" onClick={toggleSidebar}>
                                                            <Svg name="icon-edit" />
                                                        </button>
                                                    </OverlayTrigger>
                                                    <OverlayTrigger overlay={<Tooltip>Remove Field</Tooltip>}>
                                                        <button className="btn btn-white">
                                                            <Svg name="icon-delete" />
                                                        </button>
                                                    </OverlayTrigger>
                                                </div>
                                                <div className="form-field">
                                                    <label>Choose Option</label>
                                                    <select className="form-control">
                                                        <option selected>Choose Option</option>
                                                        <option value={1}>One</option>
                                                        <option value={2}>Two</option>
                                                        <option value={3}>Three</option>
                                                    </select>

                                                </div>
                                            </div>

                                        </div>
                                        <div className="add-field-wrapper">
                                            <button className='btn btn-bordered w-100' data-bs-toggle="modal" data-bs-target="#addFields">Add Component</button>
                                        </div>
                                    </div>
                                    {/** on click of edit button add active class and on click of cross button remove it **/}
                                    <div className={`customize-components ${showSidebar ? 'active' : ''}`}>
                                        {/** on click of cross button remove active class from sidebar **/}
                                        <button
                                            type="button"
                                            className="btn-close d-block d-lg-none"
                                            aria-label="Close"
                                            onClick={closeSidebar}
                                        />
                                        <h4>Edit Field</h4>
                                        {/** Text Based Input Customization **/}
                                        {/* <div className="accordion" id="accordionBuilderName">
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="headingName1">
                                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseName1"
                                                        aria-expanded="true" aria-controls="collapseName1">
                                                        Basic
                                                    </button>
                                                </h2>
                                                <div id="collapseName1" className="accordion-collapse collapse show" aria-labelledby="headingName1"
                                                    data-bs-parent="#accordionBuilderName">
                                                    <div className="accordion-body account-info">
                                                        <div className="mb-3">
                                                            <label htmlFor="">Label</label>
                                                            <input type="text" className="form-control" defaultValue="Name" />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label htmlFor="">Default value</label>
                                                            <input type="text" className="form-control" />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label htmlFor="">Placeholder</label>
                                                            <input type="text" className="form-control" />
                                                        </div>
                                                        <div className="form-check mb-0">
                                                            <input
                                                                type="checkbox"
                                                                className="form-check-input"
                                                                id="requiredName1"
                                                            />
                                                            <label className="form-check-label" htmlFor="requiredName1">
                                                                Required
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="headingName2">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                        data-bs-target="#collapseName2" aria-expanded="false" aria-controls="collapseName2">
                                                        Value Restriction
                                                    </button>
                                                </h2>
                                                <div id="collapseName2" className="accordion-collapse collapse" aria-labelledby="headingName2"
                                                    data-bs-parent="#accordionBuilderName">
                                                    <div className="accordion-body account-info">
                                                        <div className="mb-3">
                                                            <label htmlFor="">Min length</label>
                                                            <input type="text" className="form-control" placeholder="min" />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label htmlFor="">Max length</label>
                                                            <input type="text" className="form-control" placeholder='max' />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}

                                        {/** Radio Box Customization **/}

                                        <div className="accordion" id="accordionBuilderRadio1">
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="headingRadio1">
                                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseRadio1"
                                                        aria-expanded="true" aria-controls="collapseRadio1">
                                                        Basic
                                                    </button>
                                                </h2>
                                                <div id="collapseRadio1" className="accordion-collapse collapse show" aria-labelledby="headingRadio1"
                                                    data-bs-parent="#accordionBuilderRadio1">
                                                    <div className="accordion-body account-info">
                                                        <div className="mb-3">
                                                            <label htmlFor="">Label</label>
                                                            <input type="text" className="form-control" defaultValue="Radio Label 1" />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label htmlFor="">Type</label>
                                                            <select className="form-control">
                                                                <option value={1}>Radio Buttons</option>
                                                                <option value={2}>Checkbox</option>
                                                            </select>
                                                        </div>
                                                        <div className="form-check mb-0">
                                                            <input type="checkbox" className="form-check-input" id="requiredName1" />
                                                            <label className="form-check-label" htmlFor="requiredName1"> Required </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="headingRadio2">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                        data-bs-target="#collapseRadio2" aria-expanded="false" aria-controls="collapseRadio2">
                                                        Pre-defined values
                                                    </button>
                                                </h2>
                                                <div id="collapseRadio2" className="accordion-collapse collapse" aria-labelledby="headingRadio2"
                                                    data-bs-parent="#accordionBuilderRadio1">
                                                    <div className="accordion-body account-info">

                                                        <div class="label-action d-flex align-items-center">
                                                            <label for="" class="flex-grow-1 pe-2"> Values </label>
                                                            <div class="action-button">
                                                                <OverlayTrigger overlay={<Tooltip>Add Option</Tooltip>}>
                                                                    <button className='action-btn btn'>
                                                                        <Svg name="plus" />
                                                                    </button>
                                                                </OverlayTrigger>
                                                            </div>
                                                        </div>
                                                        <div className="input-action d-flex align-items-center">
                                                            <div className="flex-grow-1 pe-2">
                                                                <input type="text" className="form-control" defaultValue="Option 1" />
                                                            </div>
                                                            <div class="action-button">
                                                                <OverlayTrigger overlay={<Tooltip>Remove</Tooltip>}>
                                                                    <button className='action-btn btn'>
                                                                        <Svg name="icon-delete" />
                                                                    </button>
                                                                </OverlayTrigger>

                                                            </div>
                                                        </div>
                                                        <div className="input-action d-flex align-items-center">
                                                            <div className="flex-grow-1 pe-2">
                                                                <input type="text" className="form-control" defaultValue="Option 2" />
                                                            </div>
                                                            <div class="action-button">
                                                                <OverlayTrigger overlay={<Tooltip>Remove</Tooltip>}>
                                                                    <button className='action-btn btn'>
                                                                        <Svg name="icon-delete" />
                                                                    </button>
                                                                </OverlayTrigger>

                                                            </div>
                                                        </div>
                                                        <div className="input-action d-flex align-items-center">
                                                            <div className="flex-grow-1 pe-2">
                                                                <input type="text" className="form-control" defaultValue="Option 3" />
                                                            </div>
                                                            <div class="action-button">
                                                                <OverlayTrigger overlay={<Tooltip>Remove</Tooltip>}>
                                                                    <button className='action-btn btn'>
                                                                        <Svg name="icon-delete" />
                                                                    </button>
                                                                </OverlayTrigger>

                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/** Dropdown Customization **/}
                                        {/* <div className="accordion" id="accordionBuilderSelect1">
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="headingSelect1">
                                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSelect1"
                                                        aria-expanded="true" aria-controls="collapseSelect1">
                                                        Basic
                                                    </button>
                                                </h2>
                                                <div id="collapseSelect1" className="accordion-collapse collapse show" aria-labelledby="headingSelect1"
                                                    data-bs-parent="#accordionBuilderSelect1">
                                                    <div className="accordion-body account-info">
                                                        <div className="mb-3">
                                                            <label htmlFor="">Label</label>
                                                            <input type="text" className="form-control" defaultValue="Choose Option" />
                                                        </div>
                                                        <div className="form-check mb-0">
                                                            <input type="checkbox" className="form-check-input" id="requiredName1" />
                                                            <label className="form-check-label" htmlFor="requiredName1"> Required </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="headingSelect2">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                        data-bs-target="#collapseSelect2" aria-expanded="false" aria-controls="collapseSelect2">
                                                        Pre-defined values
                                                    </button>
                                                </h2>
                                                <div id="collapseSelect2" className="accordion-collapse collapse" aria-labelledby="headingSelect2"
                                                    data-bs-parent="#accordionBuilderSelect1">
                                                    <div className="accordion-body account-info">

                                                        <div class="label-action d-flex align-items-center">
                                                            <label for="" class="flex-grow-1 pe-2"> Values </label>
                                                            <div class="action-button">
                                                                <OverlayTrigger overlay={<Tooltip>Add Option</Tooltip>}>
                                                                    <button className='action-btn btn'>
                                                                        <Svg name="plus" />
                                                                    </button>
                                                                </OverlayTrigger>
                                                            </div>
                                                        </div>
                                                        <div className="input-action d-flex align-items-center">
                                                            <div className="flex-grow-1 pe-2">
                                                                <input type="text" className="form-control" defaultValue="Option 1" />
                                                            </div>
                                                            <div class="action-button">
                                                                <OverlayTrigger overlay={<Tooltip>Remove</Tooltip>}>
                                                                    <button className='action-btn btn'>
                                                                        <Svg name="icon-delete" />
                                                                    </button>
                                                                </OverlayTrigger>

                                                            </div>
                                                        </div>
                                                        <div className="input-action d-flex align-items-center">
                                                            <div className="flex-grow-1 pe-2">
                                                                <input type="text" className="form-control" defaultValue="Option 2" />
                                                            </div>
                                                            <div class="action-button">
                                                                <OverlayTrigger overlay={<Tooltip>Remove</Tooltip>}>
                                                                    <button className='action-btn btn'>
                                                                        <Svg name="icon-delete" />
                                                                    </button>
                                                                </OverlayTrigger>

                                                            </div>
                                                        </div>
                                                        <div className="input-action d-flex align-items-center">
                                                            <div className="flex-grow-1 pe-2">
                                                                <input type="text" className="form-control" defaultValue="Option 3" />
                                                            </div>
                                                            <div class="action-button">
                                                                <OverlayTrigger overlay={<Tooltip>Remove</Tooltip>}>
                                                                    <button className='action-btn btn'>
                                                                        <Svg name="icon-delete" />
                                                                    </button>
                                                                </OverlayTrigger>

                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="customvar-modal modal fade fileUpload uploadMedia fileMove" id="useTemplate" >
                <div className="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            Select event to use its form.
                        </div>
                        <div className="modal-body">
                            <div className="formstyle">
                                <div className="tab-3-wrapper">
                                    <div className="page-wrapper">
                                        <div className="table-wrapper-view">
                                            <div className="tab-search search-filter d-flex align-items-center">
                                                <div className="flex-grow-1 search-filter-padding pe-0">
                                                    <input type="text" className="form-control custom-search-input" placeholder="Search" />
                                                </div>
                                            </div>
                                            <div className="table-responsive">
                                                <div className="table-wrap custom-data-table table-media-wrapper list-min-height">
                                                    <DataTable
                                                        columns={columns}
                                                        data={data}
                                                    />
                                                    {/** Apply pagination here **/}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div
                className="customvar-modal setting-modal modal fade"
                id="addFields"
                tabIndex={-1}
                aria-labelledby="addFieldsLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="formstyle p-md-2 account-info">
                                <div className="modal-heading">
                                    <h4>Add Fields</h4>
                                </div>
                                <div className="form-wrapper">
                                    <div className="list-components">
                                        <div className="list-wrapper">
                                            <div className="row gx-2">
                                                <div className="col-lg-6">
                                                    <div className='list-inner d-flex align-items-center selected'>
                                                        <div className="flex-grow-1 text-wrap">
                                                            Name
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className='list-inner d-flex align-items-center'>
                                                        <div className="flex-grow-1 text-wrap">
                                                            Email
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className='list-inner d-flex align-items-center'>

                                                        <div className="flex-grow-1 text-wrap">
                                                            Address
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className='list-inner d-flex align-items-center'>

                                                        <div className="flex-grow-1 text-wrap">
                                                            Description
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className='list-inner d-flex align-items-center'>

                                                        <div className="flex-grow-1 text-wrap">
                                                            Radio Buttons
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className='list-inner d-flex align-items-center'>

                                                        <div className="flex-grow-1 text-wrap">
                                                            Select Dropdown
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="button-wrap d-flex justify-content-end">
                                    <button type="button" className="btn btn-primary" > Add </button>
                                    <button type="button" className="btn btn-bordered color-cancel ms-3" data-bs-dismiss="modal" > Cancel </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}
