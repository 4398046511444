import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { toast } from "react-toastify";
import { getExperienceDetail } from "../../actions/experience";

export default function ExperirenceDetailModal({
  experience,
  closeDetailModal,
}) {
  const [isFetchingDetail, setIsFetchingDetail] = useState(false);
  const [experianceDetail, setExperianceDetail] = useState(null);
  const [sliderImgs, setSliderImgs] = useState([]);

  const fetchExperienceDetail = async (experienceId) => {
    setIsFetchingDetail(true);
    let dataToSend = {
      id: experienceId,
    };
    await getExperienceDetail(dataToSend)
      .then((res) => {
        if (res.statusCode === 200) {
          setExperianceDetail(res.data);
          let images = res.data?.templates?.map((template) => template.image);
          setSliderImgs(images);
        } else {
          toast.error(res.msg);
        }
      })
      .catch((error) => {
        console.log("error", error);
      })
      .finally(() => {
        setIsFetchingDetail(false);
      });
  };

  const settings = {
    slidesToShow: 1,
    speed: 500,
    slidesToScroll: 1,
    arrows: true,
    dots: true,
  };

  useEffect(() => {
    if (experience) {
      fetchExperienceDetail(experience.id);
    }
  }, [experience]);

  return (
    <>
      {isFetchingDetail ? (
        <>
          <span
            className="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Loading...</span>
        </>
      ) : (
        
        <div className={"formstyle"}>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              closeDetailModal();
            }}
          ></button>
          {experianceDetail ? (
            <div className="row gx-0">
              <div className="col-lg-6">
                <div className="img-wrap">                                                                          
                  <Slider {...settings}>
                    {sliderImgs.map((image, imgIndex) => (
                      <div className="img-inner" key={imgIndex}>
                        <img className="trending-img" src={image} alt="" />
                      </div>
                    ))}
                  </Slider>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="text-wrapper">
                  <ul className="mata-info">
                    <li className="hotspots">
                      {experianceDetail.templates.length} HotSpots
                    </li>
                    {/* <li className='mediaspots'>13 UI Cards</li> */}
                  </ul>
                  <h3>{experianceDetail.name}</h3>

                  <div className="text-wrap">
                    <p>{experianceDetail.description}</p>
                  </div>
                  <div className="text-wrap area-wrap d-md-flex">
                    <strong className="align-self-start pt-1 areas">
                      Areas :{" "}
                    </strong>
                    <div className="flex-grow-1 chips-wrap d-flex flex-wrap ps-md-3 pt-2 pt-md-0">
                      {experianceDetail.templates.map((template) => (
                        <span className="chips" key={template.id}>
                          <p>{template.name}</p>
                        </span>
                      ))}
                    </div>
                  </div>
                  <div className="button-wrap">
                    <button className="btn btn-primary w-auto" 
                    onClick={() => {closeDetailModal(experience.id)}}>
                      Select Experience
                    </button>
                    {/* <button className='btn btn-bordered ms-3 w-auto'>Preview</button> */}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <>No Data Found</>
          )}
        </div>
      )}
    </>
  );
}
