import React from 'react'
import { Link } from 'react-router-dom'
import Svg from './Svg'

export default function Logo() {
    return (
        <div className="left-menu d-flex align-items-center">
            <Link to="#" className='home-icon'>
                <Svg name="logo" />
            </Link>
        </div>
    )
}
