import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import Copyright from '../common/Copyright'
import Svg from '../common/Svg'

export default function OTP() {
    const { state } = useLocation();
    return (
        <div className='d-flex flex-column site-main-wrapper login-page'>

            <div className="flex-grow-1 main-content-wrap d-flex align-items-center justify-content-center">
                <div className="container">
                    <div className="form-outer-wrapper">
                        <div className="logo-wrap text-center">
                            <Link to="/#0t" className='home-logo'>
                                <Svg name="logo" />
                            </Link>
                        </div>
                        <div className="form-wrapper mb__20">
                            <div className="form-heading text-center">
                                <h3>Let us know it’s you</h3>
                                <p>Last step! To secure your account, enter the code  we just send to <span className="text-primary">{state ? state : ""}</span></p>
                            </div>
                            <div className="field-wrapper">
                                <div className="form-floating mb__20 position-relative">
                                    <input type="email" className="form-control" placeholder='Enter OTP' />
                                    <label htmlFor="floatingInput" className="mb-0">Enter OTP</label>
                                </div>
                                <div className="additional-info d-flex justify-content-end mb__20">
                                    <a href="#1"> <b> Resend code</b> </a>
                                </div>
                            </div>
                            <div className="button-wrap">
                                <button type="button" className="btn-primary btn w-100">Verify OTP</button>
                            </div>
                            <div className="outer-form-text text-center">
                                <p className='mb-0'>Wrong email address? <Link to="/forgot-password"> Change it</Link></p>
                            </div>
                        </div>
                        <Copyright />
                    </div>
                </div>
            </div>
        </div>
    )
}
