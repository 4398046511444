import React from "react";
import { Link } from "react-router-dom";

export default function ExperienceSubHeader(props) {
  return (
    <div className="meeting-header">
      <ul>
        <li>
          <Link
            to={
              props.data.mode === "editMode"
                ? "/experiences/edit-experience"
                : "/experiences/create-experience"
            }
            state={
              props.data.mode === "editMode"
                ? props.data.experienceId
                : {
                    experienceId: props.data.experienceId,
                  }
            }
            content-attr="Create experience"
            className={
              props.editExperience || props.createExperience ? "active" : ""
            }
            onClick={props.handleClick}
          >
            {props.data.mode === "editMode"
              ? "Edit Experience"
              : "Create Experience"}
          </Link>
        </li>
        <li>
          <Link
            to={
              props.data.mode === "editMode"
                ? "/experiences/edit-experience/select-hotspot"
                : "/experiences/create-experience/select-hotspot"
            }
            state={props.data}
            content-attr="Select Hotspots"
            className={props.selectMediaSpot ? "active" : ""}
            onClick={props.handleClick}
          >
            Select Hotspots
          </Link>
        </li>
        <li>
          <Link
            to={
              props.data.mode === "editMode"
                ? "/experiences/edit-experience/select-hotspot/add-media"
                : "/experiences/create-experience/select-hotspot/add-media"
            }
            state={props.data}
            content-attr="Add Media"
            className={props.addMedia ? "active" : ""}
            onClick={props.handleClick}
          >
            Add Media
          </Link>
        </li>
        <li onClick={props.handleClick}>
          <Link
            to={
              props.data.mode === "editMode"
                ? "/experiences/edit-experience/select-hotspot/add-media/reorder"
                : "/experiences/create-experience/select-hotspot/add-media/reorder"
            }
            content-attr="Reorder"
            state={props.data}
            className={props.reorder ? "active" : ""}
          >
            Reorder
          </Link>
        </li>
      </ul>
    </div>
  );
}
