import * as Yup from "yup";

export const createRoleSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  permissions: Yup.mixed().test(
    "is-empty",
    "permissions is required",
    function (value) {
      if (Object.keys(value).length === 0) {
        return;
      } else {
        return value;
      }
    }
  ),
});
