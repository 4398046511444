import React from 'react'
import Svg from '../common/Svg'
import { Popover, Whisper, Button } from 'rsuite';
import "rsuite/dist/rsuite.css";
const speaker = (
    <Popover>
        <p>You can only select metaspaces from one build</p>
    </Popover>
);
export default function MediaSpotTooltip() {
    return (
        <>
            <Whisper
                placement="auto"
                // trigger="hover"
                // controlId="control-id-hover-enterable"
                speaker={speaker}
                className='tooltip-text'
            // enterable
            >
                <Button className='btn btn-link ms-2'><Svg name="tooltip-icon" /></Button>
            </Whisper>
        </>
    )
}
