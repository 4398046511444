import React from 'react'
import DashboardHeader from '../common/DashboardHeader'
import DashboardSidebar from '../common/DashboardSidebar'
import ScheduleHeader from './ScheduleHeader'
import { Link } from 'react-router-dom'
import CreatableSelect from 'react-select/creatable';
import Svg from '../common/Svg'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

const region = [
    { value: 'region-1', label: 'US East' },
    { value: 'region-2', label: 'US West' },
    { value: 'region-3', label: 'Europe' },
    { value: 'region-4', label: 'APAC' },
]

export default function InviteParticipants() {
    return (
        <>
            <div className='d-flex flex-column main-outer-wrapper main-site-wrapper '>
                <DashboardHeader />
                <div id="viewport" className="d-flex flex-grow-1">
                    <DashboardSidebar />
                    <div className="flex-grow-1 page-content-flex">
                        <ScheduleHeader />
                        <div className="page-content-wrap">

                            <div className="heading-section page-heading d-md-flex align-items-center justify-content-between">
                                <div className="left-right">
                                    <h1>Invite participants</h1>
                                    <p>Smart Factory Solutions </p>
                                </div>
                                <div className="right-wrap mt-3 mt-md-0">
                                    <Link to="/vr-experience/share" type="button" className="btn btn-primary add-btn"> Share </Link>
                                </div>
                            </div>


                            <div className="schedule-form account-info">
                                <div className="row">
                                    <div className="col-xxl-4 col-lg-6">
                                        <div className="meeting-card border-0 ">
                                            <div className="meeting-heading">Add participants (maximum 15)</div>
                                            <div className="field-wrapper participants-form">
                                                <div className="mb-3">
                                                    <input type="text" className="form-control" placeholder='First name*' />
                                                </div>
                                                <div className="mb-3">
                                                    <input type="text" className="form-control" placeholder='Last name*' />
                                                </div>
                                                <div className="mb-3">
                                                    <input type="email" className="form-control" placeholder='Enter email*' />
                                                </div>
                                                <div className="mb-3 cstom-select">
                                                    <div className="d-flex align-items-center">
                                                        <div className="select-wrap flex-grow-1">
                                                            <CreatableSelect
                                                                // isMulti
                                                                // isClearable
                                                                placeholder={'Select region'}
                                                                options={region}
                                                            />
                                                        </div>
                                                        <OverlayTrigger overlay={<Tooltip>Enter region for <br />best experience</Tooltip>}><button className='btn btn-link btn-tooltip p-0 ms-2'><Svg name="tooltip-icon" /></button></OverlayTrigger>
                                                    </div>
                                                </div>



                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-xxl-4 col-lg-6 participant-list-wrapper">
                                        <div className="meeting-card border-0 ps-0 ps-xxl-3">
                                            <div className="meeting-heading border-bottom">Participants (4)</div>

                                            <ul className='participant-list'>
                                                <li>
                                                    <span className='chips d-flex align-items-center justify-content-between'>
                                                        <p>Adam Divine</p>
                                                        <div className="button-wrap">
                                                            <OverlayTrigger overlay={<Tooltip>Copy</Tooltip>}>
                                                                <button className='action-btn'>
                                                                    <Svg name="icon-copy" />
                                                                </button>
                                                            </OverlayTrigger>

                                                            <OverlayTrigger overlay={<Tooltip>Send Invitation</Tooltip>}>
                                                                <button className='action-btn'>
                                                                    <Svg name="send-inv" />
                                                                </button>
                                                            </OverlayTrigger>

                                                            <OverlayTrigger overlay={<Tooltip>Delete</Tooltip>}>
                                                                <button className='action-btn'>
                                                                    <Svg name="icon-delete" />
                                                                </button>
                                                            </OverlayTrigger>
                                                        </div>
                                                    </span>
                                                </li>
                                                <li>
                                                    <span className='chips d-flex align-items-center justify-content-between'>
                                                        <p>Ian Dooley</p>
                                                        <div className="button-wrap">
                                                            <OverlayTrigger overlay={<Tooltip>Copy</Tooltip>}>
                                                                <button className='action-btn'>
                                                                    <Svg name="icon-copy" />
                                                                </button>
                                                            </OverlayTrigger>

                                                            <OverlayTrigger overlay={<Tooltip>Send Invitation</Tooltip>}>
                                                                <button className='action-btn'>
                                                                    <Svg name="send-inv" />
                                                                </button>
                                                            </OverlayTrigger>

                                                            <OverlayTrigger overlay={<Tooltip>Delete</Tooltip>}>
                                                                <button className='action-btn'>
                                                                    <Svg name="icon-delete" />
                                                                </button>
                                                            </OverlayTrigger>
                                                        </div>
                                                    </span>
                                                </li>
                                                <li>
                                                    <span className='chips d-flex align-items-center justify-content-between'>
                                                        <p>Richard S</p>
                                                        <div className="button-wrap">
                                                            <OverlayTrigger overlay={<Tooltip>Copy</Tooltip>}>
                                                                <button className='action-btn'>
                                                                    <Svg name="icon-copy" />
                                                                </button>
                                                            </OverlayTrigger>

                                                            <OverlayTrigger overlay={<Tooltip>Send Invitation</Tooltip>}>
                                                                <button className='action-btn'>
                                                                    <Svg name="send-inv" />
                                                                </button>
                                                            </OverlayTrigger>

                                                            <OverlayTrigger overlay={<Tooltip>Delete</Tooltip>}>
                                                                <button className='action-btn'>
                                                                    <Svg name="icon-delete" />
                                                                </button>
                                                            </OverlayTrigger>
                                                        </div>
                                                    </span>
                                                </li>
                                                <li>
                                                    <span className='chips d-flex align-items-center justify-content-between'>
                                                        <p>Joel Mott</p>
                                                        <div className="button-wrap">
                                                            <OverlayTrigger overlay={<Tooltip>Copy</Tooltip>}>
                                                                <button className='action-btn'>
                                                                    <Svg name="icon-copy" />
                                                                </button>
                                                            </OverlayTrigger>

                                                            <OverlayTrigger overlay={<Tooltip>Send Invitation</Tooltip>}>
                                                                <button className='action-btn'>
                                                                    <Svg name="send-inv" />
                                                                </button>
                                                            </OverlayTrigger>

                                                            <OverlayTrigger overlay={<Tooltip>Delete</Tooltip>}>
                                                                <button className='action-btn'>
                                                                    <Svg name="icon-delete" />
                                                                </button>
                                                            </OverlayTrigger>
                                                        </div>
                                                    </span>
                                                </li>


                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="button-wrap mb-3">
                                    <div className="">
                                        <button className='btn btn-bordered'>Add</button>
                                    </div>
                                </div>





                            </div>



                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
