import { Formik } from "formik";
import { useState } from "react";
import PasswordChecklist from "react-password-checklist";
import { changePassword } from "../../../actions/auth";
import { changePasswordSchema } from "../../../Schema/User";

export default function ChangePassword({ closeModal }) {
  const [password, setPassword] = useState("");
  const [passwordAgain, setPasswordAgain] = useState("");
  const [isPasswordChanging, setIsPasswordChanging] = useState(false);

  return (
    <div className="modal-body">
      <div className={"formstyle account-info"}>
        <div className="row">
          <div className="col-lg-6">
            <div className="text-wrap">
              <h3>Change password</h3>
              <p>
                Your new password must be different from previously used
                passwords.
              </p>
              <div className="password-req">
                <h6>Requirements</h6>
                <PasswordChecklist
                  rules={[
                    "minLength",
                    "capital",
                    "number",
                    "specialChar",
                    //"match"
                  ]}
                  minLength={5}
                  value={password}
                  valueAgain={passwordAgain}
                  onChange={(isValid) => {}}
                  messages={{
                    minLength: "Minimum of 6 characters",
                    capital: "Contains an uppercase letter",
                    number: "Contains a number (0-9)",
                    match: "Contains special characters (!@#$%&*.)",
                    // match: "Password Matched?",
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <Formik
              initialValues={{
                password: "",
                newPassword: "",
                confirmPassword: "",
              }}
              validationSchema={changePasswordSchema}
              onSubmit={(values, { setErrors, setSubmitting, resetForm }) => {
                let dataToSend = {
                  password: values.password,
                  newPassword: values.newPassword,
                  confirmPassword: values.confirmPassword,
                };
                setIsPasswordChanging(true);
                changePassword(dataToSend)
                  .then((res) => {
                    if (res.statusCode === 400) {
                      setErrors({ password: res.msg });
                    } else {
                      closeModal();
                      resetForm();
                    }
                  })
                  .catch((error) => {
                    console.log("error", error);
                  })
                  .finally(() => {
                    setIsPasswordChanging(false);
                  });
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                isSubmitting,
              }) => (
                <form onSubmit={handleSubmit}>
                  <div className="form-wrapper">
                    <div className="position-relative">
                      <div className="mb-3">
                        <input
                          type="password"
                          className="form-control"
                          placeholder="Current password"
                          name="password"
                          value={values.password}
                          onChange={handleChange}
                        />
                      </div>
                      {errors.password && touched.password && (
                        <div className="validation-msg">{errors.password}</div>
                      )}
                    </div>
                    <div className="position-relative">
                      <div className="mb-3">
                        <input
                          type="password"
                          className="form-control"
                          placeholder="New passwod"
                          name="newPassword"
                          value={values.newPassword}
                          onChange={handleChange}
                        />
                      </div>
                      {errors.newPassword && touched.newPassword && (
                        <div className="validation-msg">
                          {errors.newPassword}
                        </div>
                      )}
                    </div>
                    <div className="position-relative">
                      <div className="mb-3">
                        <input
                          type="password"
                          className="form-control"
                          placeholder="Confirm new password"
                          value={values.confirmPassword}
                          name="confirmPassword"
                          onChange={handleChange}
                        />
                      </div>
                      {errors.confirmPassword && touched.confirmPassword && (
                        <div className="validation-msg">
                          {errors.confirmPassword}
                        </div>
                      )}
                    </div>

                    <div className="button-wrap pt-2">
                      <button
                        className="btn btn-primary w-100 mb-2"
                        type="submit"
                      >
                        {isPasswordChanging ? (
                          <>
                            <span
                              className="white-spinner spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            <span className="visually-hidden">Loading...</span>
                          </>
                        ) : (
                          " Change Password"
                        )}
                      </button>
                      <button
                        className="btn btn-bordered color-cancel w-100"
                        onClick={() => closeModal()}
                        type="button"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}
