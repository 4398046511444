import empty from "../../assets/images/empty.jpg";
import { getThumbnail } from "../../utils/imageThumbnail";

export default function MediaViewModal({
  currentMediaData,
  closeMediaViewModal,
  toggleImagePreview,
}) {
  return (
    <div className="modal-dialog modal-dialog-centered modal-details-width">
      <div className="modal-content">
        {currentMediaData && (
          <>
            {console.log("currentMediaData", currentMediaData)}
            <div className="modal-header">
              <div className="title-wrap modal-ttl">
                <h5 className="modal-title">
                  {toggleImagePreview
                    ? currentMediaData.unique_name
                    : currentMediaData.name}
                </h5>
                {/* <p className="mb-0">Resolution: 2014 x 1024</p> */}
              </div>
              <button
                type="button"
                className="btn-close"
                onClick={() => {
                  closeMediaViewModal(null);
                }}
              ></button>
            </div>
            <div className="modal-body p-3">
              <div className="img-wrap">
                {currentMediaData.media_type === 6 &&
                  getThumbnail(currentMediaData.media_url) && (
                    <img
                      src={getThumbnail(currentMediaData.media_url)}
                      alt={currentMediaData.name}
                      className="img-fluid w-100"
                    />
                  )}
                {["png", "jpg", "jpeg", "gif", "svg"].includes(
                  currentMediaData.name.split(".").pop()
                ) &&
                  currentMediaData.media_type !== 6 && (
                    <img
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = empty;
                      }}
                      src={
                        toggleImagePreview
                          ? currentMediaData.default_media_url
                          : currentMediaData.media_url
                      }
                      alt=""
                      className="img-fluid w-100"
                    />
                  )}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
