import React, { useEffect, useState } from "react";
import DashboardHeader from "../../common/DashboardHeader";
import DashboardSidebar from "../../common/DashboardSidebar";
import CreatableSelect from "react-select/creatable";
import Svg from "../../common/Svg";
import DataTable from "react-data-table-component";
import { toast } from "react-toastify";
import { getUsers } from "../../../actions/user";
import { getRoles } from "../../../actions/userRole";
import { Formik } from "formik";
import { createTeamSchema } from "../../../Schema/Team";
import { addTeam } from "../../../actions/team";
import { useNavigate } from "react-router-dom";

const columns = [
  {
    name: "Name",
    selector: (row) => row.name,
  },
  {
    name: "Role",
    selector: (row) => row.role,
  },

  {
    name: "Action",
    cell: (row) => (
      <>
        <div className="btn-group table-dropdown action-end">
          <button
            type="button"
            className="btn-dropdown btn-icon"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <Svg name="action-dropdown-icon" />
          </button>
          <ul className="dropdown-menu dropdown-menu-end">
            <li>
              <button
                className="dropdown-item icon-btn"
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#deleteModal"
              >
                <Svg name="icon-delete" /> Delete
              </button>
            </li>
          </ul>
        </div>
      </>
    ),
  },
];

const data = [
  {
    id: 1,
    name: "Sam Adams",
    role: "Admin",
  },
  {
    id: 2,
    name: "Tim Mayers",
    role: "Host",
  },
];

export default function Create() {
  const [userList, setUserList] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [isCreating, setIsCreating] = useState(false);

  const navigate = useNavigate();

  const getAllUser = async () => {
    let serverParams = { startingLimit: 0, resultsPerPage: 1000, search: "" };
    try {
      const res = await getUsers(serverParams);
      if (res.statusCode === 200) {
        let userOptions = [];
        res.data.map((ele) => {
          userOptions.push({ value: ele.id, label: ele.name });
        });
        setUserList(userOptions);
      } else {
        toast.error(res.msg);
      }
    } catch (error) {
      toast.error(error.response.data.msg);
    }
  };

  const getAllRole = async () => {
    try {
      const res = await getRoles();
      if (res.statusCode === 200) {
        let roleOptions = [];
        res.data.map((ele) => {
          roleOptions.push({
            value: ele.id,
            label: ele.role_name,
          });
        });
        setRoleList(roleOptions);
      } else {
        toast.error(res.msg);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const createTeam = async (data) => {
    setIsCreating(true);
    try {
      const res = await addTeam(data);
      if (res.statusCode === 200) {
        navigate("/settings/teams/list");
      }
    } catch (error) {
      toast.error(error);
    } finally {
      setIsCreating(false);
    }
  };

  useEffect(() => {
    getAllUser();
    getAllRole();
  }, []);

  return (
    <div className="d-flex flex-column main-outer-wrapper main-site-wrapper ">
      <DashboardHeader />
      <div id="viewport" className="d-flex flex-grow-1">
        <DashboardSidebar />
        <div className="flex-grow-1 page-content-flex">
          <div className="page-content-wrap d-flex flex-column">
            <div className="page-heading">
              <h1>Create team</h1>
              {/* <p className='pt-2'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed tortor tellus, accumsan non sollicitudin at,</p> */}
            </div>
            <div className="role-wrapper flex-grow-1">
              <div className="create-team-form">
                <Formik
                  initialValues={{
                    team_name: "",
                    is_active: "1",
                  }}
                  enableReinitialize
                  validationSchema={createTeamSchema}
                  onSubmit={(values, { setSubmitting, resetForm }) => {
                    let dataToSend = {
                      team_name: values.team_name,
                      is_active: values.is_active,
                    };
                    createTeam(dataToSend);
                    resetForm();
                    setSubmitting(false);
                  }}
                >
                  {({
                    values,
                    errors,
                    handleChange,
                    handleSubmit,
                    resetForm,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-lg-12 position-relative">
                          <div className="form-group mb-3">
                            <label>Team name</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Team name"
                              name="team_name"
                              defaultValue={values.team_name}
                              onChange={handleChange}
                            />
                          </div>
                          {errors.team_name && (
                            <div className="validation-msg">
                              {errors.team_name}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="button-wrap d-flex pt-3">
                        <button
                          type="submit"
                          disabled={isCreating}
                          className="btn btn-primary"
                        >
                          {isCreating ? (
                            <>
                              <span
                                className="white-spinner spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </>
                          ) : (
                            "Save"
                          )}
                        </button>
                        {/* Remove 'disabled' once input fields are filled whihh means the button should look like below once filled*/}
                        {/* <button type="button" className="btn btn-primary">Save</button> */}
                        <button
                          type="button"
                          onClick={() => {
                            resetForm();
                            navigate("/settings/teams/list");
                          }}
                          className="btn btn-bordered color-cancel ms-3"
                        >
                          Cancel
                        </button>
                      </div>
                    </form>
                  )}
                </Formik>

                {/* if there are added users */}

                {/* <div className="form-group pt-3">
                    <div className="page-heading mb-2">
                      <h2>Added Members</h2>
                    </div>
                    <div className="table-responsive">
                      <div className="table-wrap custom-data-table table-media-wrapper table-without-pagination">
                        <DataTable
                          columns={columns}
                          data={data}
                          // pagination
                        />
                      </div>
                    </div>
                    //  <div className="user-list">
                    //                             <div className="list-item d-flex align-items-center">
                    //                                 <div className="flex-grow-1"> Niel Johnson <span className='status invitation-sent'>Invitation Sent</span> </div>
                    //                                 <a href="#" className='action-btn'> <Svg name="table-delete" /> </a>
                    //                             </div>
                    //                             <div className="list-item d-flex align-items-center">
                    //                                 <div className="flex-grow-1"> Thomas Michel <span className='status invitation-approved'>Invitation Approved</span></div>
                    //                                 <a href="#" className='action-btn'> <Svg name="table-delete" /> </a>
                    //                             </div>
                    //                         </div> 
                  </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
