import * as Yup from "yup";

const regMatch = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
const youTubEmbedRegMatch  = /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))\/embed.([\w\-]+)(\S+)?$/gm;

export const createFolderSchema = Yup.object().shape({
    name: Yup.string()
    .required("Folder name is required")
});

export const updateFileSchema = Yup.object().shape({
    name: Yup.string()
    .required("File name is required")
});

export const mediaUploadSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    link: Yup.string().required("Link is required").matches(regMatch, "Link should be a valid URL"),
});

export const youtubeLinkSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    link: Yup.string().required("Link is required").matches(youTubEmbedRegMatch, "Link must be a valid Youtube embed url")
});

export const reUploadFileSchema = Yup.object().shape({
    user_asset_library_name: Yup.string().required("Name is required"),
    link: Yup.string().required("Link is required").matches(regMatch, "Link should be a valid URL")
});