import * as Yup from "yup";

export const updateUserSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  designation: Yup.string().required("Designation is required"),
  region_code: Yup.string().required("Please select Region"),
  country_code: Yup.string().required("Please select country"),
  timezone: Yup.string().required("Please select timezone"),
  default_region_code: Yup.string().required("Please select default Region"),
});

export const changePasswordSchema = Yup.object().shape({
  password: Yup.string()
    .required("Password is required")
    .min(6, "Password must be at least 6 characters")
    .matches(/[A-Z]/, "Password must contain an uppercase letter")
    .matches(/[0-9]/, "Password must contain a number")
    .matches(/[\W_]/, "Password must contain special characters"),
  newPassword: Yup.string()
    .required("New password is required")
    .notOneOf([Yup.ref('password'), null], 'New password cannot be the same as the old password')
    .min(6, "New password must be at least 6 characters")
    .matches(/[A-Z]/, "New password must contain an uppercase letter")
    .matches(/[0-9]/, "New password must contain a number")
    .matches(/[\W_]/, "New password must contain special characters"),
  confirmPassword: Yup.string()
    .required("Confirm password is required")
    .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
    .min(6, "Confirm password must be at least 6 characters")
    .matches(/[A-Z]/, "Confirm password must contain an uppercase letter")
    .matches(/[0-9]/, "Confirm password must contain a number")
    .matches(/[\W_]/, "Confirm password must contain special characters"),
});
