import { useEffect, useState } from "react";
import { range } from "../../utils/common";

export default function Pagination({
  currentPage,
  totalPage,
  gotoPreviousPage,
  changePage,
  geToNextPage,
}) {
  const [showDots, setShowDots] = useState(false);
  const [leftPages, setLeftPages] = useState([]);
  const [rightPages, setRightPages] = useState([]);

  const currentPageIsBetweenRange = (leftTotalPages, even) => {
    const firstItem = 1;
    const lastItem = totalPage;
    const start = currentPage - leftTotalPages + 2;
    const end = currentPage + leftTotalPages - 2 - even;

    setShowDots(true);

    const leftPageArray = [1, ...range(start, end + 1)];
    const rightPageArray = [totalPage];

    if (start - 1 === firstItem + 1) {
      leftPageArray.splice(1, 0, 2);
    }

    if (end + 1 === lastItem - 1) {
      rightPageArray.splice(0, 0, end + 1);
    }
    setLeftPages(leftPageArray);
    setRightPages(rightPageArray);
    return {
      leftPages: leftPageArray,
      rightPages: rightPageArray,
    };
  };

  const currentPageEqualsLeftTotal = (leftTotalPages, even) => {
    const end = currentPage + leftTotalPages - 1 - even;
    setShowDots(true);
    setLeftPages(range(1, end + 1));
    setRightPages([totalPage]);
    return {
      leftPages: range(1, end + 1),
      rightPages: [totalPage],
    };
  };

  const currentPageEqualsRightTotal = (leftTotalPages) => {
    const start = currentPage - leftTotalPages + 1;
    setShowDots(true);
    setLeftPages([1]);
    setRightPages(range(start, totalPage + 1));
    return {
      leftPages: [1],
      rightPages: range(start, totalPage + 1),
    };
  };

  const pageItems = () => {
    const totalVisible = 7; // no. of pageitems to show
    const maxLength = Math.min(totalVisible, totalPage);
    if (totalPage <= maxLength) {
      setShowDots(false);
      setLeftPages(range(1, totalPage + 1));
      setRightPages([]);
      return { leftPages: range(1, totalPage + 1), rightPages: [] };
    }
    const even = maxLength % 2 === 0 ? 1 : 0; // used to add 1 if maxLength is even
    const leftTotalPages = Math.floor(maxLength / 2);
    const rightTotalPages = totalPage - leftTotalPages + 1 + even;

    if (currentPage > leftTotalPages && currentPage < rightTotalPages) {
      return currentPageIsBetweenRange(leftTotalPages, even);
    }

    if (currentPage === leftTotalPages) {
      return currentPageEqualsLeftTotal(leftTotalPages, even);
    }

    if (currentPage === rightTotalPages) {
      return currentPageEqualsRightTotal(leftTotalPages);
    }
    setShowDots(true);
    setLeftPages(range(1, leftTotalPages + 1));
    setRightPages(range(rightTotalPages, totalPage + 1));
    return {
      leftPages: range(1, leftTotalPages + 1),
      rightPages: range(rightTotalPages, totalPage + 1),
    };
  };

  useEffect(() => {
    pageItems();
  }, [currentPage, totalPage]);

  return (
    <div className="pagination-wrap d-flex justify-content-end">
      <ul className="pagination mb-0">
        <li className={currentPage == 1 ? "disabled page-item" : "page-item"}>
          <span
            className="page-link prev"
            tabIndex={-1}
            disabled={currentPage == 1 ? true : false}
            onClick={() => {
              gotoPreviousPage();
            }}
          >
            Previous
          </span>
        </li>
        {leftPages.length > 0 &&
          leftPages.map((page, pageIndex) => (
            <li
              className={
                currentPage === page ? "page-item active" : "page-item"
              }
              key={pageIndex}
              onClick={() => {
                changePage(page);
              }}
            >
              <span className="page-link">
                {/* {pageIndex + 1} */}
                {page}
              </span>
            </li>
          ))}
        {showDots && (
          <li>
            <div className="page dots">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="2"
                viewBox="0 0 12 2"
              >
                <g transform="translate(-460 -747)">
                  <circle
                    cx="1"
                    cy="1"
                    r="1"
                    transform="translate(460 747)"
                    fill="currentColor"
                  />
                  <circle
                    cx="1"
                    cy="1"
                    r="1"
                    transform="translate(465 747)"
                    fill="currentColor"
                  />
                  <circle
                    cx="1"
                    cy="1"
                    r="1"
                    transform="translate(470 747)"
                    fill="currentColor"
                  />
                </g>
              </svg>
            </div>
          </li>
        )}
        {rightPages.length > 0 &&
          rightPages.map((page, pageIndex) => (
            <li
              className={
                currentPage === page ? "page-item active" : "page-item"
              }
              key={pageIndex}
              onClick={() => {
                changePage(page);
              }}
            >
              <span className="page-link">
                {/* {pageIndex + 1} */}
                {page}
              </span>
            </li>
          ))}
        <li
          className={
            currentPage == totalPage ? "disabled page-item" : "page-item"
          }
        >
          <span
            onClick={() => {
              geToNextPage();
            }}
            className="page-link next"
            disabled={currentPage == totalPage ? true : false}
          >
            Next
          </span>
        </li>
      </ul>
    </div>
  );
}
