import axios from "../utils/axios";
import { BASE_URL } from "./urls";

export const createFolder = async (payload) => {
  const token = localStorage.getItem("token");

  const response = await axios({
    headers: { Authorization: `Bearer ${token}` },
    method: "POST",
    url: `${BASE_URL}/asset/createFolder`,
    data: payload,
  });

  return response.data;
};

export const updateFolder = async (payload) => {
  const token = localStorage.getItem("token");
  
  const response = await axios({
    headers: { Authorization: `Bearer ${token}` },
    method: "PATCH",
    url: `${BASE_URL}/asset/updateFolderName`,
    data: payload,
  });

  return response.data;
};

export const updateFile = async (payload) => {
  const token = localStorage.getItem("token");

  const response = await axios({
    headers: { Authorization: `Bearer ${token}` },
    method: "PATCH",
    url: `${BASE_URL}/asset/updateFileName`,
    data: payload,
  });

  return response.data;
};

export const updateFileStatus = async (payload) => {
  const token = localStorage.getItem("token");

  const response = await axios({
    headers: { Authorization: `Bearer ${token}` },
    method: "PATCH",
    url: `${BASE_URL}/asset/updateFileStatus`,
    data: payload,
  });

  return response.data;
};

export const getMediaList = async (payload) => {
  const token = localStorage.getItem("token");
  const response = await axios({
    headers: { Authorization: `Bearer ${token}` },
    method: "POST",
    url: `${BASE_URL}/asset/getMediaList`,
    data: payload,
  });

  return response.data;
};

export const deleteMediaFiles = async (payload) => {
  const token = localStorage.getItem("token");
  const response = await axios({
    headers: { Authorization: `Bearer ${token}` },
    method: "DELETE",
    url: `${BASE_URL}/asset/deleteFiles`,
    data: payload,
  });

  return response.data;
};

export const uploadMedia = async (payload) => {
  const token = localStorage.getItem("token");
  const response = await axios({
    headers: { Authorization: `Bearer ${token}` },
    method: "POST",
    url: `${BASE_URL}/asset/uploadFiles`,
    data: payload,
  });

  return response.data;
};

export const reUploadFiles = async (payload) => {
  const token = localStorage.getItem("token");
  const response = await axios({
    headers: { Authorization: `Bearer ${token}` },
    method: "PATCH",
    url: `${BASE_URL}/asset/reuploadFiles`,
    data: payload,
  });

  return response.data;
};

export const moveFiles = async (payload) => {
  const token = localStorage.getItem("token");
  const response = await axios({
    headers: { Authorization: `Bearer ${token}` },
    method: "PUT",
    url: `${BASE_URL}/asset/moveFiles`,
    data: payload,
  });

  return response.data;
};