import { useState } from "react";
import { deleteTeamMember } from "../../../actions/team";
import { toast } from "react-toastify";

export default function DeleteTeamMember({
  deleteTeamMemberData,
  closeTeamMemberDeleteModal,
}) {
  const [isDeleteingMember, setIsDeleteingMember] = useState(false);

  const deleteMember = async () => {
    setIsDeleteingMember(true);
    try {
      const res = await deleteTeamMember(deleteTeamMemberData);
      if (res.statusCode === 200) {
        closeTeamMemberDeleteModal();
      }
    } catch (error) {
      toast.error(error);
    } finally {
      setIsDeleteingMember(false);
    }
  };
  return (
    <div className="modal-content">
      <div className="modal-body">
        <div className={"formvawrapper delete-modal-wrap modal-setting-medium"}>
          <div className={"formstyle p-md-2"}>
            <div className="delete-body-wrap delete-language">
              <div className="heading">
                <h3>Remove Member?</h3>
              </div>
              <div className="text-normal">
                <p>Are you sure you want to remove member from the team.</p>
              </div>
              <div className="button-wrap d-flex justify-content-end">
                <button
                  type="button"
                  className="btn btn-delete"
                  onClick={() => {
                    deleteMember();
                  }}
                >
                  {isDeleteingMember ? (
                    <>
                      <span
                        className="white-spinner spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      <span className="visually-hidden">Loading...</span>
                    </>
                  ) : (
                    "Remove"
                  )}
                </button>
                <button
                  type="button"
                  className="btn btn-bordered color-cancel ms-3"
                  onClick={() => {
                    closeTeamMemberDeleteModal();
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
